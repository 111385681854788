import React, { useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import styled from "styled-components";
import footerlogo from "../../../assets/images/footer-logologin.png";
import preenrollmentLeftImage from "../../../assets/images/preenrollment-left-image.png";
import loginTopImg from "../../../assets/images/login-top-img.png";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import * as Library from "../../../utils/Library";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import { APP_TITLE } from "../../../config/constant";
import moment from "moment/moment";

import { useNavigate, useParams } from "react-router";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";
import SingleField from "./components/SimpleField";

import LogoInput from "./components/LogoInput";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    & {
        height: 100dvh;
        overflow: scroll;

        background: var(--backgrounds-lines-n-600);
        display: flex;
        justify-content: center;
    }
    .header {
        padding: 24px 24px 0px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        height: 64px;
        position: relative;
        backdrop-filter: blur(40px);
    }
    .image-2 {
        flex-shrink: 0;
        width: 96px;
        height: 30px;
        position: relative;
        object-fit: cover;
    }
    .frame-29222 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29518 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }

    .FrameLeft {
        display: flex;
        flex: 1 1 0;
        place-content: center;
    }

    .mandatory-fields-span2 {
        color: var(--text-and-icons-gray-200);
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: 12px;
        font-weight: var(--subtitle-3-font-weight, 400);
    }

    .FrameRight {
        display: flex;
        flex: 1 1 0;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        justify-items: center;
    }

    .mobileBackground-enrollment {
        border-radius: 16px;
        background: rgba(16, 20, 27, 0.7);
        backdrop-filter: blur(50px);
        padding: 0 24px 24px 24px;
    }

    .Footer {
        position: absolute;
        bottom: 40px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }

    .TermsConditions {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .PrivacySecurity {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .BotagNorgeAs2025 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .AppVersion120 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .LoginTopImg {
        width: 132px;
        height: 42px;
        flex-shrink: 0;
        display: flex;
        position: absolute;
        top: 59px;
        left: 54px;
    }

    .LeftContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //padding-left: 96px;
    }

    .group-1000011088 {
    }

    .LeftImg {
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        position: relative;
        top: -1%;
        left: 7%;
    }

    .LeftTitle {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 4rem;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        letter-spacing: -0.06rem;

        display: flex;
        align-self: center;
    }

    .LeftTitle strong {
        display: contents;
        color: #fff;
        font-family: Roboto;
        font-size: 4rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.06rem;
    }

    .Frame313264 {
        max-width: 400px;
        min-width: 320px;
        flex-direction: column;
        gap: 15px;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    .Image1 {
        width: 208px;
        height: 51px;
        background: url(${footerlogo}) 0% 0% / contain no-repeat;
    }

    .Frame313326 {
        align-self: stretch;
        //height: 290px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .Frame313266 {
        align-self: stretch;
        //height: 262px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        display: flex;
    }

    .Frame313263 {
        align-self: stretch;
        height: 23px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .LoginWithYourCredentials {
        align-self: stretch;
        text-align: center;
        color: white;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        height: 23px;
    }

    .Frame313260 {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 11px;
        display: flex;
        align-self: stretch;
    }
    .ellipse-2 {
        background: #ff9900;
        border-radius: 50%;
        width: 343px;
        height: 423px;
        position: absolute;
        left: 11px;
        top: 781px;
        filter: blur(201.45px);
    }
    .ellipse-1 {
        background: #ff99004d;
        border-radius: 50%;
        width: 250px;
        height: 1005px;
        position: absolute;
        left: -87px;
        top: 378px;
        filter: blur(130.95px);
    }
    .rectangle-10 {
        background: rgba(16, 20, 27, 0.8);
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .InputField {
        height: 50px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
        align-self: stretch;
    }

    .EmailAddress {
        align-self: stretch;
        color: var(--text-icons-gray-200);
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Frame313324 {
        align-self: stretch;
        width: 100%;
        height: 28px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        display: flex;
        background: var(--backgrounds-lines-n-600, #10141b);
        outline: none;
        border: 0;

        color: var(--text-icons-green-500);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame313324.disabled {
        color: #7c868b !important;
        -webkit-text-fill-color: #7c868b !important;
        /* color: #3c485a !important;
        -webkit-text-fill-color: #3c485a !important; */
        cursor: not-allowed;
    }

    input.Frame313324 {
        height: 14px;
    }

    .Line2 {
        align-self: stretch;
        height: 0px;
        width: 100%;
        border-top: 1px #3c485a solid;
    }

    .Password {
        align-self: stretch;
        color: #7c868b;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Frame313261 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .Frame313327 {
        width: 393px;
        height: 28px;
        position: relative;
    }

    .EyeOff {
        width: 16px;
        height: 16px;
        top: 0px;
        position: absolute;
        right: 8px;
    }

    .Icon {
        width: 16px;
        height: 16px;
        position: absolute;
    }

    .ForgotPassword {
        align-self: stretch;
        text-align: right;
        color: var(--text-icons-gray-200);
        font-size: 12px;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: normal;
        word-wrap: break-word;
        cursor: pointer;
    }

    .Share {
        color: #4f5b6d;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15px;
        word-wrap: break-word;
    }

    .loginTermsPrivacyPolicies {
        align-self: stretch;
        text-align: center;
        color: var(--text-and-icons-gray-200);
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Error {
        width: 100%;
        height: 100%;
        padding: 16px;
        background: rgba(255, 54, 54, 0.1);
        border-radius: 4px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        display: inline-flex;
    }

    .EmailInvalidSection {
        flex: 1 1 0;
        text-align: center;
        color: #ff3636;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        transition: all 0.3s ease;
    }

    .DialogPregraf {
        width: 296px;
        font-size: 12px;
        align-items: center;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        margin-bottom: 16px;
        font-style: normal;
        line-height: normal;
    }

    .DialogLayout {
        margin-bottom: 28px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px transparent;
    }

    .frame-313865 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        position: relative;
        /* margin: 0 auto; */
    }
    .frame-313866 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .terms-conditions {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .privacy-security {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .b-otag-norge-as-2025 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .app-version-1-2-0 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }

    .frame-1 {
        display: flex;
        flex-direction: column;
        gap: 39px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 58px;
        margin-top: 40px;
    }
    .the-brand-stage-for-botag-technology {
        color: #ffffff;
        text-align: center;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 58px;
    }
    .the-brand-stage-for-botag-technology-span {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 100;
    }
    .the-brand-stage-for-botag-technology-span2 {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 300;
    }
    .the-brand-stage-for-botag-technology-span3 {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 100;
    }
`;

const StyledThankYou = styled.div`
    &.frame-313266,
    &.frame-313266 * {
        box-sizing: border-box;
    }
    &.frame-313266 {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }
    .image-1 {
        flex-shrink: 0;
        width: 208px;
        height: 51px;
        position: relative;
        object-fit: contain;
    }
    .line-4 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-259 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-286 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .thank-you-for-pre-enrolling-on-b-otag {
        color: #ffffff;
        text-align: center;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 32px;
        font-weight: 700;
        position: relative;
    }
    .frame-285 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .please-check-your-email-for-next-steps {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
        position: relative;
    }
`;
/* #endregion */

export default function AcceptPage() {
    document.title = `${APP_TITLE} - User Invite`;

    const FrameRightMobile = {
        flexDirection: "column",
        height: "100dvh"
    };

    /* #region STATES */
    const navigate = useNavigate();
    const { inviteID } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);

    const [showTAC, setShowTAC] = useState({
        termsAndConditions: false,
        privacyPolicy: false
    });

    //FORM FIELDS
    const [profilePhotoData, setProfilePhotoData] = useState(null);

    const [firstName, setFirstName] = useState("");
    const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
    const [role, setRole] = useState("");
    const [roleIsInvalid, setRoleIsInvalid] = useState(false);
    const [email, setEmail] = useState("");
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [phoneNum, setPhoneNum] = useState("");
    const [phoneNumIsInvalid, setPhoneNumIsInvalid] = useState(false);

    const [isPageLocked, setIsPageLocked] = useState(false);

    const [showThankYou, setShowThankYou] = useState(false);
    const [isMobile, setIsMobile] = useState(true);

    /* #endregion */

    /* #region METHODS */
    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleValidation = () => {
        let formIsValid = true;
        if (!firstName) {
            setFirstNameIsInvalid(true);
            formIsValid = false;
        }
        if (!lastName) {
            setLastNameIsInvalid(true);
            formIsValid = false;
        }

        if (!role) {
            setRoleIsInvalid(true);
            formIsValid = false;
        }
        if (!validateEmail(email)) {
            setEmailIsInvalid(true);
            formIsValid = false;
        }
        if (!phoneNum) {
            setPhoneNumIsInvalid(true);
            formIsValid = false;
        }
        return formIsValid;
    };

    const handleSubmit = async () => {
        setFirstNameIsInvalid(false);
        setRoleIsInvalid(false);
        setEmailIsInvalid(false);
        setPhoneNumIsInvalid(false);

        if (!handleValidation()) return;

        setIsLoading(true);

        const phoneNumberCountryCode = phoneNum.split(" ")[0];
        const phoneNumber = phoneNum.split(" ")[1];

        const formData = {
            platform: "BOstageR",
            inviteID,
            phoneNumber,
            phoneNumberCountryCode,
            profilePhotoData
        };

        await Library.makeCommonPostRequest("invites", "acceptUserInvite", formData, false, setIsLoading).then(res => {
            if (res.data.status !== 200) return;
            setShowThankYou(true);
        });
    };

    const handleInvitationError = (errorMessage, errorTitle = "Invitation Error", redirect = true, setIsPageLocked) => {
        setIsPageLocked(true);
        Library.showToastMessage({
            type: "error",
            title: errorTitle,
            description: errorMessage,
            onClose: () => setIsPageLocked(false)
        });
        if (redirect) navigate("/login");
    };

    const updateInviteRequest = () => {
        Library.makeCommonPostRequest(
            "Invites",
            "updateInvite",
            { inviteID: inviteID, status: "Accepted" },
            false,
            setIsLoading
        ).then(res => {
            if (res.data.status !== 200) {
                return;
            }
        });
    };

    const getInviteData = () => {
        Library.makeCommonPostRequest(
            "Invites",
            "getInvite",
            { inviteID: inviteID },
            false,
            setIsLoading,
            undefined,
            undefined,
            false
        ).then(res => {
            if (res.data.status !== 200) {
                handleInvitationError(res.data.message, "Invitation Error", true, setIsPageLocked);
                return;
            }

            const data = res.data.data;

            if (!data || !["invited", "accepted"].includes(data?.status?.status?.toLowerCase())) {
                handleInvitationError("This invitation is no longer valid.", "Invitation Error", true, setIsPageLocked);
                return;
            }
            // if (data?.inviteType?.toLowerCase() !== "labelbrand" && data?.inviteType?.toLowerCase() !== "retailer") {
            //     handleInvitationError("Invalid invite type.", "Invitation Error", true, setIsPageLocked);
            //     return;
            // }

            // setCurrentInviteType(data?.inviteType?.toLowerCase());

            if (data?.status?.status?.toLowerCase() === "invited") {
                updateInviteRequest();
            }

            const phoneNumer = `${data?.contactMobileNumberCountryCode}${" "}${data?.contactMobileNumber}`;

            setFirstName(data?.contactFirstName);
            setLastName(data?.contactLastName);
            setRole(data?.entityUserRole);
            setEmail(data?.contactEmail);
            setPhoneNum(phoneNumer);
        });
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!inviteID) {
            Library.showToastMessage({
                type: "error",
                title: "Error",
                description: "Invalid invite link!"
            });
            navigate("/login");
        } else {
            getInviteData();
        }
    }, [inviteID]);

    useEffect(() => {
        if (profilePhotoData && firstName && lastName && role && email && phoneNum) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [profilePhotoData && firstName && lastName && role && email && phoneNum]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    /* #endregion */

    return (
        <StyledPage>
            <div className={`overlay-lock ${isPageLocked ? "enabled" : "disabled"}`} />
            {showTAC.termsAndConditions && (
                <TermsAndPrivacyPanel
                    type="terms"
                    onCloseClicked={() =>
                        setShowTAC({
                            termsAndConditions: false,
                            privacyPolicy: false
                        })
                    }
                />
            )}
            {showTAC.privacyPolicy && (
                <TermsAndPrivacyPanel
                    type="privacy"
                    onCloseClicked={() =>
                        setShowTAC({
                            termsAndConditions: false,
                            privacyPolicy: false
                        })
                    }
                />
            )}
            {!isMobile && (
                <div className="FrameLeft">
                    <img src={loginTopImg} className="LoginTopImg" alt="BOtag Technology" />

                    <div className="LeftContainer">
                        <img src={preenrollmentLeftImage} className="LeftImg" alt="BOtag Technology" />
                        <div className="group-1000011088 d-none"></div>
                        <label className="LeftTitle d-none">
                            THE LABEL BRAND STAGE <br />
                            FOR <strong>BO</strong>TAG TECHNOLOGY
                        </label>
                    </div>
                </div>
            )}

            {isMobile && (
                <>
                    <div className="ellipse-2"></div>
                    <div className="ellipse-1"></div>
                    <div className="rectangle-10"></div>
                </>
            )}

            <div className="FrameRight" style={isMobile ? FrameRightMobile : {}}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center"
                        //overflowY: "visible",
                        //overflowX: "hidden"
                    }}>
                    {isMobile && (
                        <div className="header">
                            <img className="image-2" src={loginTopImg} alt="" />
                            <div className="frame-29222">
                                <div className="frame-29518"></div>
                            </div>
                            <div className="frame-29219">
                                <div className="frame-29518"></div>
                            </div>
                        </div>
                    )}
                    <div
                        className={`Frame313264 ${isMobile && !showThankYou ? "mobileBackground-enrollment" : ""}`}
                        style={{
                            height: showThankYou ? "100%" : "auto",
                            overflow: "auto"
                        }}>
                        {!showThankYou ? (
                            <>
                                {!isMobile && <div className="Image1" style={{ marginBottom: "0px" }} />}

                                {isMobile && (
                                    <div className="frame-1">
                                        <div className="the-brand-stage-for-botag-technology">
                                            <span>
                                                <span className="the-brand-stage-for-botag-technology-span">
                                                    THE BRAND STAGE FOR 
                                                    <br />
                                                </span>
                                                <span className="the-brand-stage-for-botag-technology-span2">BO</span>
                                                <span className="the-brand-stage-for-botag-technology-span3">
                                                    TAG TECHNOLOGY
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="Frame313326">
                                    <div className="Frame313266">
                                        <div className="Frame313263">
                                            <div className="LoginWithYourCredentials">PRE-ENROLLMENT FORM</div>
                                        </div>
                                        <ContainerLoader
                                            isLoading={isLoading}
                                            isLoadingOverlay={true}
                                            addBlurFilters={true}>
                                            <div className="Frame313260">
                                                <LogoInput
                                                    onUpload={setProfilePhotoData}
                                                    value={profilePhotoData}
                                                    isRequiredAsterisk={true}
                                                />
                                                <SingleField
                                                    title="First Name"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                    isRequiredAsterisk={true}
                                                    isInvalid={firstNameIsInvalid}
                                                    errorMessage="First Name is required."
                                                    isDisabled={true}
                                                />
                                                <SingleField
                                                    title="Last Name"
                                                    type="text"
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)}
                                                    isRequiredAsterisk={true}
                                                    isInvalid={lastNameIsInvalid}
                                                    errorMessage="Last Name is required."
                                                    isDisabled={true}
                                                />
                                                <SingleField
                                                    title="Role"
                                                    type="text"
                                                    value={role}
                                                    onChange={e => setRole(e.target.value)}
                                                    isRequiredAsterisk={true}
                                                    isInvalid={roleIsInvalid}
                                                    errorMessage="Role is required."
                                                    isDisabled={true}
                                                />
                                                <SingleField
                                                    title="Email"
                                                    type="text"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    isRequiredAsterisk={true}
                                                    isInvalid={emailIsInvalid}
                                                    errorMessage="Email is required."
                                                    isDisabled={true}
                                                />
                                                <SingleField
                                                    title="Phone number"
                                                    type="text"
                                                    value={phoneNum}
                                                    onChange={e => setPhoneNum(e.target.value)}
                                                    isRequiredAsterisk={true}
                                                    isInvalid={phoneNumIsInvalid}
                                                    errorMessage="Phone number is required."
                                                    isDisabled={true}
                                                />

                                                <div className="mandatory-fields">
                                                    <span
                                                        className={`mandatory-fields-span2 ${
                                                            buttonDisable ? "text-orange-500" : ""
                                                        }`}>
                                                        *mandatory fields
                                                    </span>
                                                </div>
                                                <span className="mandatory-fields-span2">
                                                    Problems pre-enrolling? Let us know: <b>support@botag.no</b>
                                                </span>
                                                <BtnLargeSolid
                                                    className={`w-100 ${isLoading ? "loading-filters" : ""}`}
                                                    disabled={isLoading || buttonDisable}
                                                    onClick={handleSubmit}
                                                    textStyle={{ fontSize: "0.75rem" }}
                                                    style={{ height: "45px" }}
                                                    activeColor="var(--App-Accent-Outline)"
                                                    normalColor="var(--App-Accent-Active)"
                                                    hoverColor="var(--App-Accent-Hover)">
                                                    CREATE ACCOUNT
                                                </BtnLargeSolid>
                                                <div
                                                    className={`loginTermsPrivacyPolicies ${
                                                        isLoading ? "loading-filters" : ""
                                                    }`}>
                                                    <span style={{ fontSize: "11px" }}>
                                                        By continuing, you agree to BOtag Norge AS{" "}
                                                    </span>
                                                    <span
                                                        className="underline cursor-pointer"
                                                        onClick={() => {
                                                            setShowTAC({
                                                                termsAndConditions: true,
                                                                privacyPolicy: false
                                                            });
                                                        }}>
                                                        Terms of Use
                                                    </span>
                                                    <span> and </span>
                                                    <span
                                                        className="underline cursor-pointer"
                                                        onClick={() => {
                                                            setShowTAC({
                                                                termsAndConditions: false,
                                                                privacyPolicy: true
                                                            });
                                                        }}>
                                                        Privacy Policy
                                                    </span>
                                                    <span>.</span>
                                                </div>
                                            </div>
                                        </ContainerLoader>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <StyledThankYou className="frame-313266">
                                <img className="image-1" src={footerlogo} alt="FooterLogo" />
                                <div className="line-4"></div>
                                <div className="frame-259">
                                    <div className="frame-286">
                                        <div className="thank-you-for-pre-enrolling-on-b-otag">
                                            Thank you for
                                            <br />
                                            pre-enrolling on BOtag
                                        </div>
                                    </div>
                                    <div className="frame-285">
                                        <div className="frame-160">
                                            <BtnLargeSolid
                                                className={"w-100"}
                                                onClick={() => navigate("/login")}
                                                textStyle={{ fontSize: "0.75rem" }}
                                                style={{ height: "45px" }}
                                                activeColor="var(--App-Accent-Outline)"
                                                normalColor="var(--App-Accent-Active)"
                                                hoverColor="var(--App-Accent-Hover)">
                                                LOGIN
                                            </BtnLargeSolid>
                                        </div>
                                    </div>
                                </div>
                            </StyledThankYou>
                        )}
                    </div>
                </div>

                {isMobile && (
                    <div
                        className="frame-313865"
                        style={{ marginTop: "13px", marginBottom: "30px", gap: "0px", height: "37px" }}>
                        <div className="frame-313866">
                            <div
                                className="terms-conditions cursor-pointer"
                                onClick={() => {
                                    setShowTAC({
                                        termsAndConditions: true,
                                        privacyPolicy: false
                                    });
                                }}>
                                Terms &amp; Conditions
                            </div>
                            <div
                                className="privacy-security cursor-pointer"
                                onClick={() => {
                                    setShowTAC({
                                        termsAndConditions: false,
                                        privacyPolicy: true
                                    });
                                }}>
                                Privacy &amp; Security
                            </div>
                        </div>
                        <div className="b-otag-norge-as-2025">BOtag Norge AS © {moment().format("yyyy")}</div>
                        <div className="app-version-1-2-0">App Version {packageJson.version}</div>
                    </div>
                )}
            </div>

            {!isMobile && (
                <div className="Footer">
                    <div className="BotagNorgeAs2025">BOtag Norge AS © {moment().format("yyyy")}</div>
                    <div className="AppVersion120">App Version {packageJson.version}</div>
                </div>
            )}
        </StyledPage>
    );
}
