import React, { useEffect } from "react";
import styled from "styled-components";
import xClose from "../../../../assets/icons/x-close1.svg";

const AppletPickerWrapper = styled.div`
    &.applet-picker-dlalog-card,
    &.applet-picker-dlalog-card * {
        box-sizing: border-box;
    }
    &.applet-picker-dlalog-card {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 8.89px;
        padding: 53.33px;
        display: flex;
        flex-direction: column;
        gap: 35.56px;
        align-items: flex-start;
        justify-content: flex-start;
        position: sticky;
        box-shadow: 0px 0px 106.89px 0px rgba(17, 22, 32, 0.51);
        padding: 53px 177px;
        width: 80%;
    }
    .frame-1000011208 {
        display: flex;
        flex-direction: column;
        gap: 35.56px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .layout-3-i-pad {
        flex-shrink: 0;
        width: 1137.78px;
        //height: 755.56px;
        position: relative;
        //transform-origin: 0 0;
        //transform: rotate(-90deg) scale(1, 1);
        backdrop-filter: blur(166.67px);
    }
    .screen {
        width: 89.25%;
        height: 92.24%;
        position: absolute;
        right: 5.38%;
        left: 5.38%;
        bottom: 3.88%;
        top: 3.88%;
        overflow: visible;
    }
    .i-pad-device-frame {
        width: 100%;
        //height: 100%;
        //position: absolute;
        overflow: visible;
    }
    .frame-162 {
        display: flex;
        flex-direction: row;
        gap: 17.78px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: absolute;
        right: 22.24px;
        top: 22.22px;
    }
    .x-close {
        flex-shrink: 0;
        width: 35.56px;
        height: 35.56px;
        position: relative;
        overflow: visible;
    }
`;

export default function AppletPickerPreview({ imgSrc, onClose, isOpen }) {
    useEffect(() => {
        if (!isOpen) return;
        document.addEventListener("click", e => {
            if (!e.target.closest(".applet-picker-dlalog-card") && !e.target.closest(".frame-1000011203")) {
                onClose();
            }
        });
    }, []);

    return (
        <AppletPickerWrapper className="applet-picker-dlalog-card">
            <div className="frame-1000011208 w-100">
                <div className="layout-3-i-pad w-100">
                    <img className="i-pad-device-frame w-100" src={imgSrc} alt="" />
                </div>
            </div>
            <div className="frame-162">
                <img className="x-close cursor-pointer" src={xClose} alt="" onClick={onClose} />
            </div>
        </AppletPickerWrapper>
    );
}
