import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider, Outlet, useLocation, useNavigate, useBlocker } from "react-router-dom";
import "./App.css";
import { AppQueryClient } from "./api/queryClient";
import "./assets/css/Colors.css";
import "./assets/css/UtilityClasses.css";
import NoInternetMarker from "./components/common/NoInternetMarker";
import FullScreenLoader from "./components/common/loaders/FullScreenLoader";
import PreLoader from "./components/common/loaders/PreLoader";
import ControlBar from "./components/common/mainApp/ControlBar";
import FiltersHeader from "./components/common/mainApp/FiltersHeader";
import Footer from "./components/common/mainApp/Footer";
import Header from "./components/common/mainApp/Header";
import NavigationBar from "./components/common/mainApp/NavigationBar";
import * as Library from "./utils/Library";
import { setData } from "./utils/redux/userDataSlice";
import { navigationBar } from "./config/navigationBarConfig";
import AppContext from "./AppContext";
import { AllRoutes } from "./routes/AllRoutes";
import ProductsSidePanel from "./components/common/mainApp/ProductsSidePanel";
import MobileNotAvailable from "./components/pages/MobileNotAvailable";
import { ToastContainer } from "react-toastify";
import PopupManager from "./components/common/PopupManager";
import { setNavigations } from "./utils/redux/navigationAccessSlice";
import { verifyUserIsOnboarding } from "./utils/navigationHelper";
import TermsAndPrivacyPanel from "./components/common/termsandConditions/TermsAndPrivacyPanel";
import useUnsavedChangesWarning from "./utils/hooks/useUnsavedChangesWarning";
import FullScreenOverlay from "./components/common/overlay/FullScreenOverlay";
import DialogBoxComponent from "./components/common/dialogs/DialogBoxComponent";
import LayoutContext from "./LayoutContext";

export default function App() {
    const router = createBrowserRouter([{ element: <AppTemplate />, children: AllRoutes }]);
    return <RouterProvider router={router} />;
}

function AppTemplate() {
    if (["development", "testing", "production", "staging"].includes(process.env.NODE_ENV)) {
        window.BOLibrary = Library;
    }

    /* #region STATES */
    const ref = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname;
    const navigations = useSelector(state => state.navigationAccesses);

    const isPublicAccessPageURL = ["/pre-enrollment-invited", "/pre-enrollment", "/login", "/resetpassword"].some(url =>
        path.startsWith(url)
    );

    /* #region CONTEXT VARIABLES */
    const {
        getUserDataToken,
        setGetUserDataToken,
        refreshUserData,
        setRefreshUserData,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        isLoadingApp,
        setIsLoadingApp,
        showUnsavedChangesPopup,
        setShowUnsavedChangesPopup,
        setUserData,
        redirectURL,
        setRedirectURL
    } = React.useContext(AppContext);

    const {
        setShowLogoutPopup,
        layoutType,
        setLayoutType,
        controlBarLeftBtns,
        setControlBarLeftBtns,
        controlBarCenterBtns,
        setControlBarCenterBtns,
        controlBarRigthBtns,
        setControlBarRightBtns,
        isMobile,
        setIsMobile,
        filterHeaderProperties,
        setFilterHeaderProperties
    } = React.useContext(LayoutContext);
    /* #endregion */
    const [showMasterLayout, setShowMasterLayout] = useState(!isPublicAccessPageURL && path !== "/");

    const [navigationBarMenuBtns, setNavigationBarMenuBtns] = useState([]);
    const [showTAC, setShowTAC] = useState({
        termsAndConditions: false,
        PrivacyPolicy: false
    });
    const [currentFilterHeaderLocation, setCurrentFilterHeaderLocation] = useState("");

    const userIsLoggedIn = !!localStorage.getItem("token");
    useUnsavedChangesWarning(hasUnsavedChanges, "You have unsaved changes. Do you want to leave this page?");

    const newBOimageDisabled = () => {
        const nav = navigations?.navigationAccesses;
        return nav?.BOimages?.navigationPanel;
    };
    const [headerCenterBtns, setHeaderCenterBtns] = useState([
        { title: "Add Store", disabled: false, to: "/stores/create" },
        { title: "New Request", disabled: false, to: "/labelbrands/request" },
        { title: "New BOIMAGE", disabled: newBOimageDisabled() ? false : true, to: "/boimages/create" }
    ]);

    /* #endregion */

    /* #region METHODS */
    const getUserDataFunction = (
        id,
        currentCompanyID = undefined,
        currentGroupID = undefined,
        redirectToHomepage = false
    ) => {
        //const formData = { token: id, currentCompanyID: currentCompanyID, currentGroupID: currentGroupID };

        const formData = { BOstagesAccountID: id };
        Library.makePostRequest("getUserData", formData, false, setIsLoadingApp).then(res => {
            if (res.data.status === 200) {
                setShowMasterLayout(true);
                const data = res.data.data;

                setUserData(data);

                //const status = data?.BOStagesAccount?.status;
                //const billingPlan = data?.currentCompany?.billingPlan;
                const userName =
                    data?.BOStagesAccount?.firstName +
                    (data?.BOStagesAccount?.lastName ? " " + data?.BOStagesAccount?.lastName : "");
                const userEmail = data?.BOStagesAccount?.email;

                const currentCompanyName = data?.currentCompany?.name;
                const currentCompanyID = data?.currentCompany?._id;
                const currentCompanyLogo = data?.currentCompany?.logoURL;
                //const currentCompanyStatus = data?.currentCompany?.status;
                const currentOrganizationID = data?.organization?._id;
                const currentOrganizationName = data?.organization?.name;
                const currentOrganizationCountry = data?.organization?.countryValue;
                const currentOrganizationLogo = data?.organization?.mainImageUrl;
                const activeBrands = data?.activeLabelBrands;
                const activeStores = data?.activeStores;
                const activeCompanies = data?.activeCompanies;
                const activeFFCenters = data?.activeFFCenters;
                const activeRetailBrands = data?.activeRetailBrands;
                const NDAaccepted = data?.NDAaccepted ?? false;
                const profilePhoto = data?.BOStagesAccount?.profilePhotoUrl;
                const currentProfileRole = data?.role;
                const subscriptionPlanID = data?.organization?.subscriptionPlanID;
                const subscriptionPlanName = data?.organization?.subscriptionPlanName;
                const BOstagesAccountID = data?.BOStagesAccount?._id;
                const activeConnectedBrands = parseInt(data?.activeConnectedBrands) || 0;
                //const currentStatus = data?.invite?.status?.status ?? "Enrolling";

                //localStorage.setItem("status", currentStatus);
                localStorage.setItem("userFirstName", data?.BOStagesAccount?.firstName);
                localStorage.setItem("userLastName", data?.BOStagesAccount?.lastName || "-");
                localStorage.setItem("billingPlan", subscriptionPlanName || "-");
                localStorage.setItem("subscriptionPlanID", subscriptionPlanID || "");
                localStorage.setItem("userName", userName);
                localStorage.setItem("userEmail", userEmail);
                localStorage.setItem("currentCompanyName", currentCompanyName);
                localStorage.setItem("currentCompanyID", currentCompanyID);
                localStorage.setItem("currentCompanyLogo", currentCompanyLogo);
                localStorage.setItem("currentOrganizationID", currentOrganizationID);
                localStorage.setItem("currentOrganizationName", currentOrganizationName);
                localStorage.setItem("currentOrganizationCountry", currentOrganizationCountry);
                localStorage.setItem("activeCompanies", activeCompanies ?? "-");
                localStorage.setItem("activeBrands", activeBrands ?? "-");
                localStorage.setItem("activeStores", activeStores ?? "-");
                localStorage.setItem("activeFFCenters", activeFFCenters ?? "-");
                localStorage.setItem("activeRetailBrands", activeRetailBrands ?? "-");
                localStorage.setItem("currentOrganizationName", currentOrganizationName ?? "-");
                localStorage.setItem("NDAaccepted", NDAaccepted ?? false);
                if (profilePhoto) localStorage.setItem("currentProfilePhoto", profilePhoto);
                localStorage.setItem("currentProfileRole", currentProfileRole ?? "--");
                localStorage.setItem("currentOrganizationLogo", currentOrganizationLogo);
                localStorage.setItem("labelBrands", JSON.stringify(data?.labelBrands) ?? "[]");
                localStorage.setItem("retailBrands", JSON.stringify(data?.retailBrands) ?? "[]");
                localStorage.setItem("invite", JSON.stringify(data?.invite));
                localStorage.setItem("BOstagesAccountID", BOstagesAccountID);
                localStorage.setItem("tempRegistrationNumber", data?.currentCompany?.registrationNumber || "");
                localStorage.setItem("stripeAccountID", data?.currentCompany?.stripeAccountID || "");
                localStorage.setItem("activeConnectedBrands", activeConnectedBrands);
                localStorage.setItem("subscriptionPlanTime", data?.organization?.subscriptionPlanTime || "");
                //redux save userData
                dispatch(setData({ userAppData: data }));
                dispatch(setNavigations({ navigationAccesses: data?.navigationAccesses }));

                const userIsOnboarding = verifyUserIsOnboarding(data?.navigationAccesses) !== "/dashboard";
                let currentStatus = "--";

                if (userIsOnboarding) {
                    currentStatus = data?.invite?.status?.status ?? "Enrolling";
                } else {
                    if (activeConnectedBrands > 0) currentStatus = "Active";
                    else currentStatus = "Enrolled";
                }
                localStorage.setItem("status", currentStatus);

                //redirect to home (usually after a login success)
                if (redirectToHomepage) {
                    setShowMasterLayout(true);

                    if (redirectURL) {
                        //TODO: REMOVE LATER THIS CONSOLE LOG
                        console.log("Redirecting to: ", redirectURL);
                        navigate(redirectURL);
                        setRedirectURL(null);
                        return;
                    }

                    const redirectOnboardingURL = verifyUserIsOnboarding(data?.navigationAccesses);
                    if (redirectOnboardingURL) {
                        navigate(redirectOnboardingURL);
                    }
                }
            } else {
                localStorage.clear();
            }
        });
    };

    /** This function is handling whether to show the homepage or onboarding page, and checking whether to show the master layout when user is logged in (header, controlbar, footer, etc)
        If the URL is a public access page URL, it will ignore other flows (Examples: /pre-enrollment-invited, /pre-enrollment, /login (forcing logout url))
    **/
    const handleAppHomepageFlow = () => {
        const token = localStorage.getItem("token");

        //if the URL is an public access page URL, then don't show the master layout and clear login and local storage
        if (isPublicAccessPageURL) {
            localStorage.clear();
            setShowMasterLayout(false);
            return;
        } else if (!showMasterLayout) {
            //if the master layout is undefined, we cannot proceed until it is defined
            return;
        }
        //else if there is not a token and the URL is not root page, then don't show the master layout and clear login and local storage (will show login page)
        else if (!token && path !== "/") {
            setShowMasterLayout(false);
            localStorage.clear();
            navigate("/login");
            return;
        }
        //else, handle redirect to homepage or onboarding page
        else if (path === "/" && token) {
            const redirectOnboardingURL = verifyUserIsOnboarding(navigations?.navigationAccesses);

            if (!redirectOnboardingURL) return;
            if (redirectOnboardingURL !== "/" && redirectOnboardingURL !== path) {
                navigate(redirectOnboardingURL);
            }
        }
    };

    const shouldShowMobileWarning = () => {
        const path = location.pathname;
        const exceptionURL = path.startsWith("/pre-enrollment-invited") || path.startsWith("/pre-enrollment");

        return isMobile && !exceptionURL;
    };

    const getRouteName = () => {
        const path = location.pathname;
        const routes = path.split("/");
        return routes[1];
    };

    const logoutIfNoToken = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            setShowMasterLayout(false);
            navigate("/login");
        }
    };
    /* #endregion */

    /* #region EFFECTS */
    const blocker = useBlocker(() => {
        return hasUnsavedChanges && !showUnsavedChangesPopup;
    });

    useEffect(() => {
        if (blocker.state === "blocked" && hasUnsavedChanges) {
            if (!userIsLoggedIn) {
                setHasUnsavedChanges(false);
                setShowLogoutPopup(false);
                blocker.reset();

                localStorage.clear();
            } else {
                setShowUnsavedChangesPopup(true);
            }
        }
        //else console.log("State: ", blocker.state);
    }, [blocker.state]);

    // This useEffect is called when the location changes
    useEffect(() => {
        if (!showMasterLayout) {
            //if the master layout is undefined, we cannot proceed until it is defined
            return;
        }

        const path = location.pathname;
        const navigationBarMenuBtns = navigationBar(path, navigations);
        setNavigationBarMenuBtns(navigationBarMenuBtns);

        if (currentFilterHeaderLocation !== location.pathname) return;

        //check GET params for layoutType:
        const urlParams = new URLSearchParams(window.location.search);
        const layoutTypeParam = urlParams.get("layoutType");

        if (!layoutTypeParam && filterHeaderProperties?.gridEnabled) setLayoutType("Grid");
        else if (layoutTypeParam === "list") {
            setLayoutType("List");
        }

        handleAppHomepageFlow();
    }, [location, navigations, showMasterLayout, currentFilterHeaderLocation]);

    useEffect(() => {
        setCurrentFilterHeaderLocation(location.pathname);
    }, [filterHeaderProperties]);

    useEffect(() => {
        if (!userIsLoggedIn) setShowUnsavedChangesPopup(false);
    }, [userIsLoggedIn]);

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            setShowMasterLayout(false);

            if (!isPublicAccessPageURL) {
                setRedirectURL(location.pathname);
                navigate("/login");
            }
            return;
        }

        const interval = setInterval(logoutIfNoToken, 2000);
        return () => clearInterval(interval);
    }, [localStorage.getItem("token"), location.pathname]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getUserDataFunction(
                localStorage.getItem("token"),
                localStorage.getItem("currentCompanyID"),
                localStorage.getItem("currentGroupID")
            );
        }
    }, []);

    useEffect(() => {
        if (!getUserDataToken) return;

        getUserDataFunction(getUserDataToken, undefined, undefined, true);
        setGetUserDataToken(null);
    }, [getUserDataToken]);

    useEffect(() => {
        if (!refreshUserData) return;
        setRefreshUserData(false);
        const token = localStorage.getItem("token");
        if (!token) {
            console.log("No token found to refresh user data");
            return;
        }
        getUserDataFunction(token, undefined, undefined, false);
    }, [refreshUserData]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (window.location.hostname === "retailerr.bostages.com") {
            const path = window.location.pathname;
            window.location.href = `https://retailer.bostages.com${path}`;
        }
    }, []);
    /* #endregion */

    /* #region APP PROPS */
    const MainLayoutProps = {
        setHeaderCenterBtns: setHeaderCenterBtns,
        setControlBarLeftBtns: setControlBarLeftBtns,
        setControlBarCenterBtns: setControlBarCenterBtns,
        setControlBarRightBtns: setControlBarRightBtns,
        setFilterHeaderProperties: setFilterHeaderProperties,
        filterHeaderProperties: filterHeaderProperties,
        setRefreshUserData: setGetUserDataToken //TODO: LISTEN TO THIS AND REFRESH USER DATA
        //getUserDataFunction: getUserDataFunction //TODO: THIS WILL BE REPLACED BY setRefreshUserData
    };
    /* #endregion */

    return (
        <div className={`AppContainer route-${getRouteName()}`} style={{ overflow: "hidden", position: "relative" }}>
            <ToastContainer />
            {shouldShowMobileWarning() && (
                <div className="mobile-warning">
                    <MobileNotAvailable />
                </div>
            )}

            <FullScreenLoader className={shouldShowMobileWarning() ? "d-none" : ""} isLoading={isLoadingApp}>
                {showTAC?.TermsAndPrivacyPanel && (
                    <TermsAndPrivacyPanel
                        type="terms"
                        onCloseClicked={() => {
                            setShowTAC({
                                TermsAndPrivacyPanel: false,
                                PrivacyPolicy: false
                            });
                        }}
                    />
                )}
                {showTAC?.PrivacyPolicy && (
                    <TermsAndPrivacyPanel
                        type="privacy"
                        onCloseClicked={() => {
                            setShowTAC({
                                TermsAndPrivacyPanel: false,
                                PrivacyPolicy: false
                            });
                        }}
                    />
                )}

                <FullScreenOverlay show={!!showUnsavedChangesPopup}>
                    <DialogBoxComponent
                        title="Abandon editing?"
                        message="Your changes will not be saved."
                        heading="Just checking..."
                        onClickYes={() => {
                            setShowUnsavedChangesPopup(false);
                            if (hasUnsavedChanges) {
                                setHasUnsavedChanges(false);
                                blocker.proceed();
                            }
                        }}
                        onClickNo={() => {
                            setShowLogoutPopup(false);
                            setShowUnsavedChangesPopup(false);
                            if (hasUnsavedChanges) {
                                blocker.reset();
                            }
                        }}
                    />
                </FullScreenOverlay>

                <QueryClientProvider client={AppQueryClient}>
                    {/* process.env.REACT_APP_IS_TESTING && (<ReactQueryDevtools initialIsOpen={false} position="bottom-left" />) */}
                    <div className="wrapper" ref={ref} id="myscreen">
                        <NoInternetMarker />

                        <Suspense
                            fallback={
                                <div>
                                    <PreLoader />
                                </div>
                            }>
                            {showMasterLayout ? (
                                <div className="main-wrapper">
                                    <PopupManager />
                                    <div className="navigation-container">
                                        <NavigationBar menuBtns={navigationBarMenuBtns} />
                                    </div>
                                    <div className="main-content">
                                        <div className="header-container">
                                            <Header
                                                centerBtns={headerCenterBtns}
                                                //hideHeader={hideHeader}
                                                getUserDataFunction={getUserDataFunction}
                                            />
                                        </div>
                                        <div className="controlbar-container">
                                            <ControlBar
                                                leftBtns={controlBarLeftBtns}
                                                centerBtns={controlBarCenterBtns}
                                                rigthBtns={controlBarRigthBtns}></ControlBar>
                                        </div>
                                        {filterHeaderProperties?.customContentHeader ? (
                                            filterHeaderProperties.customContentHeader
                                        ) : (
                                            <div className="ml-24px mr-24px">
                                                <FiltersHeader
                                                    layoutType={layoutType}
                                                    onLayoutTypeChange={setLayoutType}
                                                    {...filterHeaderProperties}
                                                />
                                            </div>
                                        )}
                                        <div className="content router-container">
                                            <Outlet context={{ MainLayoutProps, layoutType }} />
                                        </div>
                                    </div>
                                    {(location?.pathname?.startsWith("/boimages/create") ||
                                        location?.pathname?.startsWith("/boimages/edit")) && <ProductsSidePanel />}
                                </div>
                            ) : (
                                <Outlet context={{ MainLayoutProps, layoutType }} />
                            )}

                            {showMasterLayout && (
                                <div className="footer-wrapper">
                                    <Footer setShowTAC={setShowTAC} />
                                </div>
                            )}
                        </Suspense>
                    </div>
                </QueryClientProvider>
            </FullScreenLoader>
        </div>
    );
}
