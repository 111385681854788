import LabelBrandsConnectionsPage from "../components/pages/labelBrands/LabelBrandsConnectionsPage";
import LabelBrandsInvitationsPage from "../components/pages/labelBrands/LabelBrandsInvitationsPage";
import LabelBrandsRequestsPage from "../components/pages/labelBrands/LabelBrandsRequestsPage";
import LabelBrandsRequestPage from "../components/pages/labelBrands/LabelBrandsRequestPage";

const routes = [
    {
        path: "/labelbrands/connections",
        element: <LabelBrandsConnectionsPage />
    },
    {
        path: "/labelbrands/invitations",
        element: <LabelBrandsInvitationsPage />
    },
    {
        path: "/labelbrands/requests",
        element: <LabelBrandsRequestsPage />
    },
    {
        path: "/labelbrands/request",
        element: <LabelBrandsRequestPage />
    }
];

export default routes;
