import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import * as Library from "../../../utils/Library";
import { useDispatch, useSelector } from "react-redux";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import LayoutContext from "../../../LayoutContext";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import UploadLogosCardStandard from "../../common/forms/UploadLogosCardStandard";
import AddressFormCardStandard from "../../common/forms/AddressFormCardStandard";
import { hasUserCompletedOnboarding, userIsOnBoardingInPage } from "../../../utils/navigationHelper";
import AppContext from "../../../AppContext";
import FormFieldTagsDropdown from "../../common/forms/FormFieldTagsDropdown";
import FormFieldTagsAutocomplete from "../../common/forms/FormFieldTagsAutocomplete";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;

const CardTitleButtonStyled = styled.div`
    &.btn-sm-inverted-card-header,
    &.btn-sm-inverted-card-header * {
        box-sizing: border-box;
    }
    &.btn-sm-inverted-card-header {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 16px;
        position: relative;
        cursor: pointer;
    }
    .button {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 8px;
        letter-spacing: 0.0125em;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;
    }
`;
/* #endregion SCOPED CSS */

export default function CreateBrandPage() {
    document.title = `${APP_TITLE} - Brands Create`;

    /* #region VARS */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { showIsCompanySavedPopup, setShowIsCompanySavedPopup, setShowIsBrandSavedPopup } = useContext(LayoutContext);
    const { setHasUnsavedChanges } = useContext(AppContext);
    const dispatch = useDispatch();
    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };
    const [isLoading, setIsLoading] = useState(false);
    const [showFullscreenLoader, setShowFullscreenLoader] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [brandFormValues, setBrandFormValues] = useState({
        brand: "",
        industry: "",
        toCompany: "",
        lifestyle: "",
        productCategories: "",
        website: "",
        instagram: "",
        facebook: "",
        mainLogo: null,
        compactLogo: null,
        mainLogoWhite: null,
        mainLogoBlack: null,
        compactLogoWhite: null,
        compactLogoBlack: null,
        googlePlusCode: "",
        brandCountry: "",
        brandCity: "",
        brandPostalCode: "",
        brandAddressOne: "",
        brandAddressTwo: "",
        brandLatitude: "",
        brandLongitude: "",
        genderTags: [],
        ageGroupTags: [],
        // lifestyleTags: [], comment because can be used in future
        // majorMarkets: [],
        hashtags: ""
    });
    const [logoFieldsState, setLogoFieldsState] = useState();
    const isUserOnboarding = userIsOnBoardingInPage(navigations, "retailBrands");
    const navigate = useNavigate();
    const formBrandID = useRef();
    const formBrandFieldsRefs = [];
    const brandAccountLogosRef = useRef();
    const formTags = useRef();
    const formTagsRef = [];
    const formBrandAddress = useRef();

    const [POSOptions, setPOSOptions] = useState([]);
    const [inMarketOptions, setInMarketOptions] = useState([]);
    const [utilizingShopifyOptions] = useState([
        { value: "Yes", name: "Yes" },
        { value: "No", name: "No" }
    ]);
    const [mainProductOptions, setMainProductOptions] = useState([]);
    const [hashtags, setHashtags] = useState("");

    const toCompanyOptions = useMemo(
        () => [
            {
                name: localStorage.getItem("currentCompanyName"),
                value: localStorage.getItem("currentOrganizationID"),
                selected: isUserOnboarding ? true : false
            }
        ],
        []
    );
    const dropDownData = useMemo(
        () => ({
            POSOptions,
            inMarketOptions,
            mainProductOptions,
            toCompanyOptions,
            utilizingShopifyOptions
        }),
        [POSOptions, inMarketOptions, mainProductOptions, toCompanyOptions, utilizingShopifyOptions]
    );

    const [readyToSave, setShowReadyToSave] = useState(false);
    const retailBrandData = JSON.parse(localStorage.getItem("retailBrands"))?.[0];
    const [retailBrandDetails, setRetailBrandDetails] = useState({
        retailBrandName: isUserOnboarding ? retailBrandData?.name || "" : "",
        retaillBrandWebsite: isUserOnboarding ? retailBrandData?.socialMedia?.website || "" : "",
        retailBrandInstagram: isUserOnboarding ? retailBrandData?.socialMedia?.instagram || "" : ""
    });
    const handleReadyToShowBox = () => {
        setShowReadyToSave(true);
    };

    const formFieldBrands = [
        <FormField
            type="text"
            title="Retail brand name"
            tooltip="Retail brand name"
            requiredType={isUserOnboarding ? "mandatoryLocked" : "mandatory"}
            assistiveText=""
            varName="brand"
            ref={el => (formBrandFieldsRefs.brand = el)}
            value={retailBrandDetails?.retailBrandName}
            //onTextBlur={value => addHashtags(value)}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropDownData?.toCompanyOptions || []}
            title="To Company"
            tooltip="To Company"
            requiredType={isUserOnboarding ? "mandatoryLocked" : "mandatory"}
            assistiveText=""
            varName="toCompany"
            ref={el => (formBrandFieldsRefs.toCompany = el)}
        />,
        <FormFieldTagsDropdown
            type="tags"
            title="Product categories"
            tooltip="Product categories"
            requiredType="mandatory"
            varName="productCategories"
            ref={el => (formBrandFieldsRefs.productCategories = el)}
            //onTextChange={value => addHashtags(value?.name)}
            autoCompleteStaticData={dropDownData?.mainProductOptions || []}
        />,
        <FormFieldTagsDropdown
            type="dropdown"
            title="In markets"
            tooltip="In markets"
            requiredType="mandatory"
            varName="inMarkets"
            ref={el => (formBrandFieldsRefs.inMarkets = el)}
            //onTextChange={value => addHashtags(value?.name)}
            autoCompleteStaticData={dropDownData?.inMarketOptions || []}
        />,

        <FormField
            type="text"
            title="Website"
            tooltip="Website"
            assistiveText=""
            varName="website"
            //requiredType={isUserOnboarding ? "locked" : "simple"}
            placeholder="https://"
            ref={el => (formBrandFieldsRefs.website = el)}
            value={retailBrandDetails?.retaillBrandWebsite}
        />,
        <FormField
            type="text"
            title="Instagram"
            tooltip="Instagram"
            assistiveText=""
            //requiredType={isUserOnboarding ? "locked" : "simple"}
            varName="instagram"
            placeholder="https://www.instagram.com/"
            ref={el => (formBrandFieldsRefs.instagram = el)}
            value={retailBrandDetails?.retailBrandInstagram}
        />,
        <FormField
            type="text"
            title="Facebook"
            tooltip="Facebook"
            assistiveText=""
            varName="facebook"
            placeholder="https://www.facebook.com/"
            ref={el => (formBrandFieldsRefs.facebook = el)}
            //value={brandFormValues?.facebook}
        />,
        <FormField
            type="text"
            title="X"
            tooltip="X"
            assistiveText=""
            varName="x"
            placeholder="https://www.x.com/"
            ref={el => (formBrandFieldsRefs.x = el)}
            //value={brandFormValues?.x}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropDownData?.utilizingShopifyOptions || []}
            title="Utilizing Shopify"
            tooltip="Utilizing Shopify"
            requiredType="mandatory"
            varName="utilizingShopify"
            ref={el => (formBrandFieldsRefs.utilizingShopify = el)}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropDownData?.POSOptions || []}
            title="POS"
            tooltip="POS"
            requiredType="mandatory"
            varName="pos"
            ref={el => (formBrandFieldsRefs.pos = el)}
        />
    ];

    const formTagFields = [
        <FormFieldTagsAutocomplete
            title="Tags"
            formFieldColumSize={1}
            tooltip="Tags"
            requiredType="mandatory"
            varName="Tags"
            ref={el => (formTagsRef.Tags = el)}
            allowNewTags={true}
            placeholderWidth="150px"
            type="tags"
            noSearchResultsHideMessage={true}
            tagAddWhenEnterPressed={true}
            /* autoCompleteStaticData={[
                { value: "1", name: "Tag1" },
                { value: "2", name: "Tag2" },
                { value: "3", name: "Tag3" }
            ]} */
        />,
        <FormFieldTagsAutocomplete
            title="Hashtags"
            formFieldColumSize={1}
            tooltip="Hashtags"
            placeholder="Please enter to add hashtags"
            placeholderWidth="315px"
            requiredType="recommended"
            value={hashtags}
            assistiveText=""
            varName="Hashtags"
            tagType="hashtags"
            ref={el => (formTagsRef.Hashtags = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            /* autoCompleteStaticData={[
                { value: "1", name: "Hashtags1" },
                { value: "2", name: "Hashtags2" },
                { value: "3", name: "Hashtags3" }
            ]} */
        />
    ];
    /* #endregion */

    /* #region EFFECTS */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Library.makePostRequest("getRetailBrandFormFields");
                const { productCategories, pos, markets } = response.data.data;

                setMainProductOptions(
                    productCategories.map(item => {
                        return { value: item._id, name: item.name };
                    })
                );

                setPOSOptions(
                    pos.map(item => {
                        return { value: item._id, name: item.name };
                    })
                );

                setInMarketOptions(
                    markets.map(item => {
                        return { value: item._id, name: item.name };
                    })
                );
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        generateLogoFields(null, null, null, null, null, null);
        setRetailBrandDetails({
            retailBrandName: isUserOnboarding ? retailBrandData?.name || "" : "",
            retaillBrandWebsite: isUserOnboarding ? retailBrandData?.socialMedia?.website || "" : "",
            retailBrandInstagram: isUserOnboarding ? retailBrandData?.socialMedia?.instagram || "" : ""
        });
    }, []);

    useEffect(() => {
        const saveIsEnabled = !saveBtnDisabled && !isLoading;

        setupHeaders(props, saveIsEnabled, handleReadyToShowBox);
    }, [props?.layoutType, saveBtnDisabled, isLoading]);

    useEffect(() => {
        checkFormIsValid(false);
    }, [brandFormValues]);

    useEffect(() => {
        if (!navigations?.navigationAccesses) return;

        if (!navigations.navigationAccesses.retailBrands.createModeOnly) {
            //if user is not onboarding on RetailBrand, redirecting to home page
            if (!Library.isLocalhost("Update RetailBrand Data")) {
                navigate("/");
            }
            return;
        }
    }, [navigations]);
    /* #endregion */

    /* #region METHODS */

    const checkFormIsValid = (highlightValidation = false) => {
        const allBrandIdFieldsAreValid = formBrandID?.current?.allFieldsAreValid(highlightValidation);
        const allBrandLogosAreValid = brandAccountLogosRef?.current?.allFieldsAreValid(highlightValidation);
        const allAddressFieldsAreValid = formBrandAddress?.current?.allFieldsAreValid(highlightValidation);
        const allTagsFieldsAreValid = formTags?.current?.allFieldsAreValid(highlightValidation);

        const allFieldsAreValid =
            allBrandIdFieldsAreValid && allTagsFieldsAreValid && allAddressFieldsAreValid && allBrandLogosAreValid;

        setSaveBtnDisabled(!allFieldsAreValid);
        return allFieldsAreValid;
    };

    const onSaveClicked = async () => {
        const formBrandIDformValues = formBrandID?.current?.getAllFieldValues();
        const formTagValues = formTags?.current?.getAllFieldValues();
        const brandAccountLogoValues = brandAccountLogosRef?.current?.getAllFieldValues();
        const formBrandAddressValues = formBrandAddress?.current?.getAllFieldValues();
        const extractTagNames = arr => arr?.map(item => item?.tagName);

        //TODO: Eventually we should have and use the currentRetailBrandID in the localStorage
        const retailBrandID = JSON.parse(localStorage.getItem("retailBrands"))[0]?._id || "";
        const jsonPostData = {
            retailBrandID: retailBrandID || "",
            name: formBrandIDformValues?.brand || "",
            companyObjID: localStorage.getItem("currentCompanyID") || "",
            productCategories: formBrandIDformValues?.productCategories?.map(item => item?.value) || [],
            inMarkets: formBrandIDformValues?.inMarkets?.map(item => item?.value) || [],
            utilizingShopify: formBrandIDformValues?.utilizingShopify?.value === "Yes",

            socialMedia: {
                website: formBrandIDformValues?.website || "",
                facebook: formBrandIDformValues?.facebook || "",
                instagram: formBrandIDformValues?.instagram || "",
                x: formBrandIDformValues?.x || ""
            },
            logos: {
                mainLogoURL: brandAccountLogoValues?.mainLogoData || "",
                mainLogoWhiteURL: brandAccountLogoValues?.mainLogoInWhite || "",
                mainLogoBlackURL: brandAccountLogoValues?.mainLogoInBlack || "",
                alternativeLogoURL: brandAccountLogoValues?.compactLogoData || "",
                alternativeLogoWhiteURL: brandAccountLogoValues?.compactLogoInWhite || "",
                alternativeLogoBlackURL: brandAccountLogoValues?.compactLogoInBlack || ""
            },
            images: {
                BOtagHomeData: brandAccountLogoValues?.mainLogoData || ""
            },
            location: {
                latitude: formBrandAddressValues?.latitude || "",
                longitude: formBrandAddressValues?.longitude || "",
                googlePlusCode: formBrandAddressValues?.googlePlusCode || "",
                country: formBrandAddressValues?.country?.name || "",
                countryValue: formBrandAddressValues?.country?.name || "",
                city: formBrandAddressValues?.city?.name || "",
                postalCode: formBrandAddressValues?.postalCode || "",
                addressLine1: formBrandAddressValues?.addressOne || "",
                addressLine2: formBrandAddressValues?.addressTwo || "",
                addressNumber: ""
            },
            tags: {
                tags: extractTagNames(formTagValues?.Tags) || [],
                hashTags: extractTagNames(formTagValues?.Hashtags) || []
            },
            POS: {
                ID: formBrandIDformValues?.pos?.value || ""
            }
        };

        setHasUnsavedChanges(false);
        const res = await Library.makePostRequest("updateRetailBrand", jsonPostData, false, setIsLoading);

        if (res?.data?.status === 200) {
            const updatedNavigations = {
                ...navigations.navigationAccesses,
                retailBrands: {
                    navigationPanel: true,
                    createModeOnly: false
                },
                stores: {
                    navigationPanel: true,
                    createModeOnly: true,
                    controlBar: {
                        POS: true
                    }
                }
            };
            dispatch(setNavigations({ navigationAccesses: updatedNavigations }));

            const brandLogos = res.data?.data?.logos;
            const brandDataWithLogos = {
                ...retailBrandData,
                logos: brandLogos
            };

            localStorage.setItem("retailBrands", JSON.stringify([brandDataWithLogos]));

            if (navigations?.navigationAccesses?.organization?.status === "Active") navigate("/dashboard");
            else navigate("/stores/pos");
            localStorage.setItem("activeRetailBrands", 1);
            setShowIsBrandSavedPopup(true);
        } else setHasUnsavedChanges(true);
    };

    const generateLogoFields = (
        largeLogoImageData,
        compactLogoData,
        mainLogoInWhiteData,
        mainLogoInBlackData,
        compactLogoInWhiteData,
        compactLogoInBlackData
    ) => {
        const logosState = [
            {
                label: "Main logo",
                varName: "mainLogoData",
                requiredType: "mandatory",
                imageData: largeLogoImageData,
                gridColumnSize: 2
            },
            {
                label: "Compact logo",
                varName: "compactLogoData",
                requiredType: "recommended",
                imageData: compactLogoData,
                gridColumnSize: 2
            },
            {
                label: "Main logo in white",
                varName: "mainLogoInWhite",
                requiredType: "recommended",
                imageData: mainLogoInWhiteData,
                gridColumnSize: 1
            },
            {
                label: "Main logo in black",
                varName: "mainLogoInBlack",
                requiredType: "recommended",
                imageData: mainLogoInBlackData,
                gridColumnSize: 1
            },
            {
                label: "Compact logo in white",
                varName: "compactLogoInWhite",
                requiredType: "recommended",
                imageData: compactLogoInWhiteData,
                gridColumnSize: 1
            },
            {
                label: "Compact logo in black",
                varName: "compactLogoInBlack",
                requiredType: "recommended",
                imageData: compactLogoInBlackData,
                gridColumnSize: 1
            }
        ];

        setLogoFieldsState(logosState);
    };

    /*
    const addHashtags = value => {
        try {
            if (!value) return;
            let newStr = hashtags;

            if (!newStr.includes(value)) {
                newStr = newStr.length ? newStr + ` #${value.replace(/\s/g, "-")}` : `#${value.replace(/\s/g, "-")}`;
            }

            setHashtags(newStr);
        } catch (error) {
            console.log(value, error);
        }
    };

    const removeHashtags = value => {
        if (!value) return;
        let str = `${value.replace(/\s/g, "-")}`;
        if (!str.startsWith("#")) str = `#${str}`;

        let newHashtags = hashtags.replace(str, "");
        newHashtags = newHashtags.replace(/\s+/g, " ");

        setHashtags(newHashtags);
    };
    */

    const getCompanyData = async () => {
        const res = await Library.makeCommonPostRequest(
            "Company",
            "getCompany",
            { companyID: localStorage.getItem("currentCompanyID") },
            false,
            setShowFullscreenLoader
        );

        if (res?.data?.status !== 200) return;

        const companyData = res?.data?.data;
        return companyData;
    };

    const logosSameAsCompany = async () => {
        const companyData = await getCompanyData();
        if (!companyData) return;

        const {
            mainLogoURL,
            compactLogoURL,
            mainLogoWhiteURL,
            mainLogoBlackURL,
            compactLogoWhiteURL,
            compactLogoBlackURL
        } = companyData;

        generateLogoFields(
            mainLogoURL ? { url: mainLogoURL, name: "mainLogo" } : null,
            compactLogoURL ? { url: compactLogoURL, name: "compactLogo" } : null,
            mainLogoWhiteURL ? { url: mainLogoWhiteURL, name: "mainLogoWhite" } : null,
            mainLogoBlackURL ? { url: mainLogoBlackURL, name: "mainLogoBlack" } : null,
            compactLogoWhiteURL ? { url: compactLogoWhiteURL, name: "compactLogoWhite" } : null,
            compactLogoBlackURL ? { url: compactLogoBlackURL, name: "compactLogoBlack" } : null
        );
    };

    const addressSameAsCompany = async () => {
        const companyData = await getCompanyData();
        if (!companyData) return;

        const { addressLine1, addressLine2, city, countryValue, googlePlusCode, latitude, longitude, postalCode } =
            companyData?.location;

        /*formBrandAddress?.current?.setLocationDetails({
            googlePlusCode,
            country: countryValue,
            city,
            postalCode,
            addressOne: addressLine1,
            addressTwo: addressLine2,
            latitude,
            longitude
        });*/

        setBrandFormValues({
            ...brandFormValues,
            googlePlusCode,
            brandCountry: countryValue,
            brandCity: city,
            brandPostalCode: postalCode,
            brandAddressOne: addressLine1,
            brandAddressTwo: addressLine2,
            brandLatitude: latitude,
            brandLongitude: longitude
        });
    };
    /* #endregion METHODS */

    return (
        <StyledPage>
            <FullScreenOverlay show={showIsCompanySavedPopup}>
                <DialogBoxComponent
                    buttonWidth={"50%"}
                    onClickYes={() => setShowIsCompanySavedPopup(false)}
                    title="Company is now saved!"
                    heading="Success!"
                    headingColor="#00BB00"
                    message={
                        "<div style='font-size: 12px; margin: 0 -5px;'>You can now proceed with enrolling your brand</div>"
                    }
                    // useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                    showConfirm={() => setShowIsCompanySavedPopup(false)}
                    showCancel={false}
                    confirmText="CONTINUE"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={readyToSave}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Ready to save?"
                    message={`<div style>You will not be able to change the Brand name <span style="font-weight:600;">${retailBrandDetails?.retailBrandName}</span> nor the website <span style="font-weight:600;">${retailBrandDetails?.retaillBrandWebsite}</span> without contacting BOtag support team.</div>`}
                    onClickYes={() => {
                        setShowReadyToSave(false);
                        onSaveClicked();
                    }}
                    loading={isLoading}
                    loadingHeading={"SUBSCRIBING.."}
                    onClickNo={() => {
                        setShowReadyToSave(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="SAVING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={isLoading}
                />
            </FullScreenOverlay>

            <FullScreenLoader isLoading={showFullscreenLoader} />

            <div className="flex flex-dir-column gap-16 w-100">
                <FormCard
                    title="RETAIL BRAND ID"
                    ref={formBrandID}
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={formFieldBrands}
                    formFieldRefs={formBrandFieldsRefs}
                    onFormChanged={checkFormIsValid}
                    showUnsavedChangesPopup={true}
                />

                <UploadLogosCardStandard
                    cardTitle="RETAIL BRAND LOGOS"
                    key="brandLogos"
                    ref={brandAccountLogosRef}
                    logoFields={logoFieldsState}
                    onLogoFieldsChanged={() => checkFormIsValid(false)}
                    afterTitleComponent={<CardTitleButton title="SAME AS COMPANY" onClick={logosSameAsCompany} />}
                />

                <AddressFormCardStandard
                    cardTitle="RETAIL BRAND MAIN OFFICE"
                    ref={formBrandAddress}
                    pinLabel={retailBrandDetails?.retailBrandName}
                    unnamedPinLabel={"Unnamed Brand"}
                    useGoogleMapsDarkMode={true}
                    hasKYCAssistiveText={false}
                    onFormChanged={checkFormIsValid}
                    afterTitleComponent={<CardTitleButton title="SAME AS COMPANY" onClick={addressSameAsCompany} />}
                    locationDetails={{
                        googlePlusCode: brandFormValues.googlePlusCode,
                        country: brandFormValues.brandCountry,
                        city: brandFormValues.brandCity,
                        postalCode: brandFormValues.brandPostalCode,
                        addressOne: brandFormValues.brandAddressOne,
                        addressTwo: brandFormValues.brandAddressTwo,
                        latitude: brandFormValues.brandLatitude,
                        longitude: brandFormValues.brandLongitude
                    }}
                    onLocationDetailsChange={locationDetails => {
                        const {
                            googlePlusCode,
                            country,
                            city,
                            postalCode,
                            addressOne,
                            addressTwo,
                            latitude,
                            longitude
                        } = locationDetails;

                        setBrandFormValues({
                            ...brandFormValues,
                            googlePlusCode,
                            brandCountry: country,
                            brandCity: city,
                            brandPostalCode: postalCode,
                            brandAddressOne: addressOne,
                            brandAddressTwo: addressTwo,
                            brandLatitude: latitude,
                            brandLongitude: longitude
                        });
                    }}
                />

                <FormCard
                    ref={formTags}
                    title="TARGETS & HASHTAGS"
                    numOfFieldsPerLine={1}
                    showDefaultRightLegends={true}
                    formFields={formTagFields}
                    formFieldRefs={formTagsRef}
                    onFormChanged={() => {
                        checkFormIsValid(false);
                    }}
                    showUnsavedChangesPopup={true}
                />
            </div>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS AND METHODS */
function setupHeaders(props, saveButtonEnabled, onBtnClicked) {
    const brandsAvailable = JSON.parse(localStorage.getItem("retailBrands"))?.length;
    const createMode = props?.navigations?.navigationAccesses?.retailBrands?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Brands", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/brands/create", active: true, disabled: false, title: "Brands" },
        {
            to: "/brands/users",
            active: false,
            disabled: true, //!hasUserCompletedOnboarding(props?.navigations),
            title: "Users"
        }
    ]);
    if (brandsAvailable) {
        props.setControlBarRightBtns([
            { to: "/brands", active: false, disabled: createMode, title: "View", icon: "eye" },
            { to: "/brands/create", active: true, disabled: false, title: "Add", icon: "plus" },
            { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
        ]);
    } else {
        props.setControlBarRightBtns([
            { to: "/brands/create", active: true, disabled: false, title: "Add", icon: "plus" }
        ]);
    }

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="SAVE" btnEnabled={saveButtonEnabled} onClick={onBtnClicked} />
        )
    });
}

export const CardTitleButton = ({ title, onClick }) => {
    return (
        <CardTitleButtonStyled className="btn-sm-inverted-card-header" onClick={onClick}>
            <div className="button">{title}</div>
        </CardTitleButtonStyled>
    );
};
/* #endregion */
