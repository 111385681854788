import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import ProductCardComponent from "../../pages/products/ProductCardComponent";
import * as Library from "../../../utils/Library";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

export default function InfinityGridCompanyCards(props) {
    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [pageLimit] = useState(10);
    const [queryKey] = useState(props?.queryKey ?? uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getProductsData",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            limit: pageLimit,
            searchName: props?.searchName,
            barcode: props?.barcode,
            ...(props?.currentLabelBrandID !== "all" &&
                props?.currentLabelBrandID && { brandIDs: [props.currentLabelBrandID] })
        },
        () => {
            setScroll(true);
        }
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!isLoading && !isFetchingNextPage) {
            if (data?.pages?.[0]?.data?.result?.length === 0 || !data?.pages?.[0]?.data?.result) {
                props?.setNoDataFound(true);
            }
        }
    }, [isLoading, isFetchingNextPage, data]);
    useEffect(() => {
        if (props.currentLabelBrandID) {
            refetch();
        }
    }, [props.currentLabelBrandID]);
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() + 1);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            //console.log("scroll event added");
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            //console.log("scroll event removed");
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        //console.log("scrolling");
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            //console.log("fetching next page");
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    /* #endregion */
    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: pageLimit }, () => (
                        <CardGridSkeleton key={uuidv4()} height="725px" imageHeight="465px" />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            return (
                                <ProductCardComponent
                                    key={item?.botagID}
                                    title={item?.styleName}
                                    image={item?.image}
                                    id={item?.botagID}
                                    isCardEnabled={item?.status?.toLowerCase() !== "suspended"} //TODO: check if this is needed
                                    //isCardSuspended={item?.status?.toLowerCase() === "suspended"} //TODO: check if this is needed
                                    // tagOnclick={() => console.log("tag clicked")}
                                    //editIcon={{ onClick: "clickable" }}
                                    primaryButton={{
                                        title: "DETAILS"
                                    }}
                                    secondaryButton={{
                                        title: "BOIMAGE"
                                    }}
                                    values={[
                                        {
                                            key: "Brand",
                                            value: item?.brand
                                        },
                                        {
                                            key: "Reference",
                                            value: item?.reference
                                        },
                                        {
                                            key: "Colors",
                                            value: item?.colors
                                        },
                                        {
                                            key: "Sizes",
                                            value: item?.sizes
                                        },
                                        {
                                            key: "Category",
                                            value: item?.category
                                        }
                                    ]}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <ProductCardComponent key={index} isCardEnabled={false} />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} values={[]} height="725px" imageHeight="465px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
