import React, { useContext, useEffect, useRef, useState } from "react";
import { APP_HOMEPAGE_ROUTE, APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import * as Library from "../../../utils/Library";
import FormField from "../../common/forms/FormField";
import FormCard from "../../common/forms/FormCard";
import ListComponent from "../../common/lists/ListComponent";
import { useSelector } from "react-redux";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import FormFieldTagsDropdown from "../../common/forms/FormFieldTagsDropdown";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import AppContext from "../../../AppContext";
import { useSearchParams } from "react-router-dom";
import { getDropdownData } from "../../../utils/dropdownData";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FormFieldTextAutocomplete from "../../common/forms/FormFieldTextAutocomplete";

import { maskEmail, maskName } from "../../../utils/valuesMasking";
import { defaultStatusColors } from "../../../utils/CardUtils";
import PopupContext from "../../../PopupContext";
import { verifyUserIsOnboarding } from "../../../utils/navigationHelper";

export default function LabelBrandsRequestPage() {
    document.title = `${APP_TITLE} - Label Brands Invitations`;

    /* #region STATES */
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const name = searchParams.get("name");
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const { setHasUnsavedChanges } = useContext(AppContext);

    const [brandName, setBrandName] = useState("");

    const [openConfirmRequestDialog, setOpenConfirmRequestDialog] = useState(false);

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [storesList, setStoresList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formIsLoading, setFormIsLoading] = useState(false);
    const [labelBrandExists, setLabelBrandExists] = useState(false);
    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [formLabelsPrefillFields, setFormLabelsPrefillFields] = useState({
        contactName: "",
        email: ""
    });
    const retailsBrandData = JSON.parse(localStorage.getItem("retailBrands") || "[]");
    const formattedRetailBrandOptions = retailsBrandData.map(brand => ({
        value: brand._id,
        name: brand.name,
        selected: true
    }));
    const [fromRetailBrandOption] = useState(formattedRetailBrandOptions);
    /* #endregion */

    /* #region METHODS */
    const prefillLabelBrandData = async data => {
        if (!data || !data?.value) return;
        const { value } = data;

        const res = await Library.makePostRequest("getLabelBrandData", { labelBrandID: value }, false, null);

        if (res?.data?.status === 200) {
            const labelBrandData = res?.data?.data;

            setBrandName(labelBrandData?.name);

            const { accountOwnerName, accountOwnerEmail } = labelBrandData;
            setFormLabelsPrefillFields({
                contactName: accountOwnerName,
                email: accountOwnerEmail
            });

            // setSelectedTaxCode(taxPreLabel);
            setLabelBrandExists(true); // Set retailBrandExists to true when a valid retail brand is selected
        }
    };

    const handleLabelsBrandChange = value => {
        setBrandName(value);
        setLabelBrandExists(false); // Set retailBrandExists to false when the retail brand value changes
        //reset the prefill fields
        setFormLabelsPrefillFields({
            contactName: "",
            email: ""
        });
    };

    const checkFormIsValid = (highlightValidation = false) => {
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(highlightValidation);

        setSaveButtonEnabled(allFieldsAreValid);
        return allFieldsAreValid;
    };

    const handleSaveClicked = async () => {
        setOpenConfirmRequestDialog(false);
        if (!formCardRef) {
            Library.showToastMessage({
                title: "Error",
                message: "FormCard references not found.",
                type: "error"
            });
            return;
        }

        // Check if all fields are valid
        const allFieldsAreValid = checkFormIsValid(true);
        if (!allFieldsAreValid) {
            Library.showToastMessage({
                title: "Error",
                message: "Please verify all fields.",
                type: "error"
            });
            return;
        }

        // Get all field values
        const fieldValues = formCardRef.current.getAllFieldValues();

        setHasUnsavedChanges(false);

        const labelBrandName = fieldValues.brands.name;

        const labelBrandID = fieldValues.brands.value;
        const storeIDs = fieldValues.toStore.map(store => store.value);
        const contactName = fieldValues?.contactLastName
            ? fieldValues.contactName + " " + fieldValues.contactLastName
            : fieldValues.contactName;
        const contactEmail = fieldValues.email;
        const country = fieldValues?.country?.name;

        if (contactName === "" || contactEmail === "") {
            Library.showToastMessage({
                title: "Error",
                message: "Contact Name and Email are required.",
                type: "error"
            });
            return;
        }

        const allStores = storesList.length;
        const numberOfStores = storeIDs.length === allStores ? "all" : storeIDs.length;

        const res = await inviteBrand(labelBrandName, labelBrandID, storeIDs, contactName, contactEmail, country);

        const retailBrandName = localStorage.getItem("currentOrganizationName");

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                title: "Request Sent!",
                message: `The label brand ${labelBrandName} will get a request by email to accept ${numberOfStores} ${retailBrandName} stores as their fulfillment centers.`,
                type: "success"
            });

            const page = verifyUserIsOnboarding(navigations?.navigationAccesses);
            const isOnboarding = page !== APP_HOMEPAGE_ROUTE;
            if (isOnboarding) {
                navigate("/companies/kyc-policy");
            } else {
                navigate("/labelbrands/requests");
            }
        } else {
            setHasUnsavedChanges(true);
        }
    };

    const inviteBrand = async (labelBrandName, labelBrandID, storeIDs, contactName, contactEmail, country) => {
        // setFormIsLoading(true);
        const inviterBOStagesAccountID = localStorage.getItem("BOstagesAccountID");
        const inviterName = localStorage.getItem("userFirstName");

        const postData = {
            labelBrandID,
            newLabelBrandName: !labelBrandID ? labelBrandName : null,
            storeIDs,
            contactName,
            contactEmail,
            inviterName,
            inviterBOStagesAccountID,
            country
        };

        const res = await Library.makePostRequest("inviteLabelBrand", postData, false, setFormIsLoading);
        return res;
    };

    /* #endregion */

    /* #region FORM FIELDS */
    const formCardRef = useRef();
    const formFields1Refs = [];

    const FormCardFields1 = [
        <FormField
            type="dropdown"
            title="From Retail Brand"
            dropdownData={fromRetailBrandOption}
            varName="fromRetailBrand"
            isDropdownMultiselect={false}
            isDropdownSearchable={true}
            requiredType="locked"
            ref={el => (formFields1Refs.fromRetailBrand = el)}
        />,
        <FormFieldTextAutocomplete
            type="text"
            title="To Label Brand"
            tooltip="Enter the Label brand"
            requiredType="mandatory"
            varName="brands"
            autoCompleteAPIData={{
                apiName: "searchLabelBrandsForFFC",
                apiSearchFieldName: "labelBrandName",
                returnArrayName: "data",
                returnFieldIDName: "_id",
                returnFieldValueName: "name"
            }}
            value={name}
            ref={el => (formFields1Refs.brands = el)}
            itemNotSelectedWillClear={false}
            noSearchResultsHideMessage={true}
            onSelectedItemChange={prefillLabelBrandData}
            onTextChange={v => handleLabelsBrandChange(v)}
        />,
        <FormFieldTagsDropdown
            title="Stores"
            tooltip="Enter the Store"
            requiredType="mandatory"
            varName="toStore"
            ref={el => (formFields1Refs.toStore = el)}
            autoCompleteStaticData={storesList}
            allowNewTags={true}
            scrollableSize={340}
            addSelectAllOption={true}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            assistiveText="KYC"
            isDropdownSearchable={true}
            tooltip="Select country"
            varName="country"
            requiredType="mandatory"
            ref={el => (formFields1Refs.country = el)}
        />,
        <FormField
            type="text"
            title="Contact first name"
            tooltip="Enter the Contact First Name"
            varName="contactName"
            ref={el => (formFields1Refs.contactName = el)}
            value={formLabelsPrefillFields.contactName}
            useValueMasking={formLabelsPrefillFields.contactName ? (labelBrandExists ? true : false) : false}
            requiredType={
                formLabelsPrefillFields.contactName ? (labelBrandExists ? "locked" : "mandatory") : "mandatory"
            }
        />,
        <FormField
            type="text"
            title="Last name"
            tooltip="Enter the Contact Last Name"
            varName="contactLastName"
            ref={el => (formFields1Refs.contactLastName = el)}
            value={formLabelsPrefillFields?.contactLastName}
        />,
        <FormField
            type="email"
            title="Email"
            tooltip="Enter the Email"
            varName="email"
            ref={el => (formFields1Refs.email = el)}
            value={formLabelsPrefillFields.email}
            requiredType={formLabelsPrefillFields.email ? (labelBrandExists ? "locked" : "mandatory") : "mandatory"}
            useValueMasking={formLabelsPrefillFields.email ? (labelBrandExists ? true : false) : false}
        />
    ];
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props, navigations, saveButtonEnabled, () => setOpenConfirmRequestDialog(true));
    }, [props?.layoutType, saveButtonEnabled]);

    useEffect(() => {
        const getStores = async () => {
            const res = await Library.makeCommonPostRequest(
                "Stores",
                "getStoresData",
                {
                    companyID: localStorage.getItem("currentCompanyID"),
                    page: 1,
                    limit: 1000
                },
                false,
                setIsLoading
            );
            const stores = res?.data?.data?.result ?? [];

            const mappedStores = stores.map(store => ({ value: store.id, name: store.name }));
            setStoresList(mappedStores);
        };
        getStores();
    }, []);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Request sent to",
            selector: row => row?.labelBrand?.name ?? "--"
        },
        {
            name: "BOtag ID",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Requested on",
            selector: row => formatDefaultDateTime(row?.createdAt) ?? "--"
        },
        {
            name: "Accepted on",
            selector: row => (row?.statusLog ? formatDefaultDateTime(row?.statusLog[0]?.date) : "--")
        },
        {
            name: "Contact person",
            selector: row =>
                row?.status === "Accepted" ? row?.contactFirstName : maskName(row?.contactFirstName) ?? "--"
        },
        {
            name: "Contact email",
            selector: row => (row?.status === "Accepted" ? row?.contactEmail : maskEmail(row?.contactEmail) ?? "--")
        },
        {
            name: "Requested stores",
            selector: row => row?.requestedStores ?? "--"
        },
        {
            name: "Accepted stores",
            selector: row => row?.acceptedStores ?? "--"
        },
        {
            name: "To brand",
            selector: row => row?.retailBrand?.name ?? "--"
        }
    ];
    /* #endregion */

    return (
        <>
            <FullScreenLoader isLoading={isLoading || formIsLoading} />

            <FullScreenOverlay show={openConfirmRequestDialog}>
                <DialogBoxComponent
                    title="Ready to request?"
                    message={`Ready to request ${brandName} to accept your stores as their fulfillment centers?`}
                    showCancel={true}
                    heading="JUST CHECKING..."
                    headingColor="var(--text-icons-blue-500)"
                    onClickYes={() => {
                        handleSaveClicked();
                    }}
                    onClickNo={() => {
                        setOpenConfirmRequestDialog(false);
                    }}
                />
            </FullScreenOverlay>

            <FormCard
                ref={formCardRef}
                title="SEND REQUEST TO A LABEL BRAND"
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={FormCardFields1}
                formFieldRefs={formFields1Refs}
                onFormChanged={checkFormIsValid}
                showUnsavedChangesPopup={true}
                setIsloading={formIsLoading}
            />

            <ListComponent
                title="Requests"
                title2="(Last 10)"
                columns={listColumns}
                api={{
                    endpoint: "getRequestsToLabelBrands",
                    formData: {
                        companyID: localStorage.getItem("currentCompanyID"),
                        BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                        page: 1,
                        limit: 10
                    }
                    // onSuccess: mapListData
                }}
                showOnlyLast10={true}
                showLoader={false}
                pageLimit={10}
                currentPage={1}
                queryKey={"labelBrandsRequestsList"}
                actions={{
                    includeActions: false, //TODO: Check if is to add actions on request page or only on requests
                    editIsVisible: false,
                    suspendIsVisible: false,
                    removeIsVisible: false,
                    extraActions: [
                        {
                            title: "Remove",
                            onClick: undefined
                        },
                        {
                            title: "Resend"
                            // onClick: row => {
                            //     if (row?.status !== "Request" && row?.status !== "Invited") return undefined;
                            //     handleAcceptInvitation(row?._id);
                            // },
                            // isDisabled: row => {
                            //     return row?.status !== "Request" && row?.status !== "Invited";
                            // }
                        }
                    ]
                }}
                includeStatus={{
                    stickyStatus: true,
                    statusFromPath: "status",
                    statusColors: defaultStatusColors
                }}
                sortByCreatedAt={true}
            />
        </>
    );
}

function setupHeaders(props, navigations, saveButtonEnabled, onBtnClicked) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);

    props.setControlBarCenterBtns([
        { to: "/labelbrands/connections", active: false, disabled: createMode, title: "CONNECTIONS" },
        { to: "/labelbrands/invitations", active: false, disabled: false, title: "INVITATIONS" },
        { to: "/labelbrands/requests", active: true, disabled: false, title: "REQUESTS" }
    ]);
    props.setControlBarRightBtns([
        { to: "/labelbrands/requests", active: false, disabled: createMode, title: "View", icon: "eye" },
        { to: "#", active: true, disabled: createMode, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="REQUEST" btnEnabled={saveButtonEnabled} onClick={onBtnClicked} />
        )
    });
}
