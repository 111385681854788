/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../../assets/css/components/CheckboxDark.css";
import { Tooltip } from "antd";
import { formatColorHex } from "../../../utils/Formatter";

/* #region Styled Components */
const CustomNestedCols = styled.tr`
    &.frame-1000011268,
    &.frame-1000011268 * {
        box-sizing: border-box;
    }
    &.frame-1000011268 {
        background: #10141b;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: calc(100vw - 308px);
    }
    .frame-1000011296 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        width: 468.41px;
        position: relative;
    }
    .frame-1000011283 {
        border-style: solid;
        border-color: #262e3e;
        border-width: 0px 0px 1px 0px;
        padding: 8px 0px 8px 0px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 30px;
    }
    .label {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        opacity: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011270 {
        opacity: 0;
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        height: 84px;
        position: relative;
    }
    .frame-1000011288 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .rectangle-7 {
        background: #000000;
        border-radius: 2px;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .orange {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        //display: flex;
        align-items: center;
        justify-content: center;
        display: block;
        padding-right: 8px;
    }
    .frame-1000011289 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .rectangle-72 {
        background: #b4ea70;
        border-radius: 2px;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .green {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011290 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .rectangle-73 {
        background: #9747ff;
        border-radius: 2px;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .purple {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011369 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-10000112902 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        width: 96px;
        position: relative;
    }
    .frame-10000112702 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-10000112882 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-10000112892 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-10000112903 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-1000011305 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011284 {
        background: #141922;
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        max-width: 534px;
        overflow: auto;
    }

    .frame-1000011284::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    .frame-10000112832 {
        //border-style: solid;
        //border-color: #262e3e;
        //border-width: 0px 0px 1px 0px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    ._31-32 {
        color: var(--text-and-icons-blue-500, #00aeef) !important;
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        //display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        display: block;
        align-content: center;
        padding: 0px 8px;
        border-bottom: 1px solid #262e3e;
    }
    ._31-34 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._31-36 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._31-38 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._31-40 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._32-32 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._32-34 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._32-36 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._32-38 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._32-40 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 53.38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-10000112842 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-10000112703 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .frame-1000011339 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._12 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .group-1 {
        flex-shrink: 0;
        width: 33px;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .table-progress-bar {
        background: #10141b;
        border-radius: 9999px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 33px;
        height: 1px;
        position: absolute;
        left: 10.62px;
        bottom: 0px;
    }
    .rectangle-258 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 9px;
        height: 1px;
        position: relative;
    }
    .frame-1000011340 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._8 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rectangle-2582 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 6px;
        height: 1px;
        position: relative;
    }
    .frame-1000011341 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._5 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .table-progress-bar2 {
        background: #10141b;
        border-radius: 9999px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 33px;
        height: 1px;
        position: absolute;
        left: 10.62px;
        top: 27px;
    }
    .rectangle-2583 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 7px;
        height: 1px;
        position: relative;
    }
    .rectangle-2584 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 7px;
        height: 1px;
        position: absolute;
        left: 10.96px;
        top: 27px;
    }
    .frame-1000011271 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    ._10 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .group-12 {
        flex-shrink: 0;
        width: 33.41px;
        height: 1px;
        position: static;
    }
    .rectangle-2585 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 9999px;
        width: 5px;
        height: 1px;
        position: absolute;
        left: 10.21px;
        top: 27px;
    }
    .rectangle-2586 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 3px;
        height: 1px;
        position: relative;
    }
    .rectangle-2587 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 9999px;
        width: 3px;
        height: 1px;
        position: absolute;
        left: 10.62px;
        top: 27px;
    }
    ._3 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rectangle-2588 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 14px;
        height: 1px;
        position: relative;
    }
    .rectangle-2589 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 9999px;
        width: 14px;
        height: 1px;
        position: absolute;
        left: 10.62px;
        top: 27px;
    }
    .frame-1000011280 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .group-13 {
        flex-shrink: 0;
        width: 33.38px;
        height: 1px;
        position: static;
    }
    .rectangle-25810 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 9999px;
        width: 29px;
        height: 1px;
        position: absolute;
        left: 10.24px;
        top: 27px;
    }
    ._6 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011281 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    ._7 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rectangle-25811 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 9999px;
        width: 28px;
        height: 1px;
        position: absolute;
        left: 10.82px;
        top: 27px;
    }
    ._4 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011282 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .group-14 {
        flex-shrink: 0;
        width: 33.18px;
        height: 1px;
        position: static;
    }
    .rectangle-25812 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 9999px;
        width: 29px;
        height: 1px;
        position: absolute;
        left: 10.44px;
        top: 27px;
    }
    .rectangle-25813 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 9px;
        height: 1px;
        position: relative;
    }
    ._1 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-10000112833 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .frame-1000011342 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-10000112843 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .rectangle-25814 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 7px;
        height: 1px;
        position: relative;
    }
    .frame-1000011285 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .frame-1000011343 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .rectangle-25815 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 1px;
        height: 1px;
        position: relative;
    }
    .frame-1000011286 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .frame-1000011345 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .rectangle-25816 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 5px;
        height: 1px;
        position: relative;
    }
    .rectangle-25817 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 2px;
        height: 1px;
        position: relative;
    }
    .frame-1000011346 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._0 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011287 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .rectangle-259 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 9999px;
        flex-shrink: 0;
        width: 4px;
        height: 1px;
        position: relative;
    }
    .rectangle-6 {
        background: #10141b;
        border-radius: 500px;
        flex-shrink: 0;
        width: 4px;
        height: 525px;
        position: absolute;
        left: 529px;
        bottom: -517px;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, 1);
    }
    .rectangle-74 {
        background: #262e3e;
        border-radius: 500px;
        flex-shrink: 0;
        width: 4px;
        height: 91px;
        position: absolute;
        left: 95px;
        bottom: -83px;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, 1);
    }
    .frame-10000112872 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 53.38px;
        position: relative;
    }
    .frame-1000011291 {
        border-style: solid;
        border-color: #262e3e;
        border-width: 0px 0px 1px 0px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .total {
        color: var(--text-and-icons-blue-500, #00aeef) !important;
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        width: 59.5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011306 {
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    ._60 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._26 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ._25 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011297 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        width: 468.41px;
        position: relative;
    }
`;
/* #endregion */

export default function StockExpandedComponent(props) {
    const { data } = props;

    console.log("props", props);
    /* #region VARS */

    // hardcoded testing data
    /*const data = [
        {
            colorName: "Orange",
            colorCode: "#FFA500",
            sizes: ["31-32", "33-34", "35-36", "37-38", "39-40"],
            stock: [19, 10, 8, 6, 4],
            stockPercentage: [80, 70, 20, 50, 90]
        },
        {
            colorName: "Blue",
            colorCode: "#0000FF",
            sizes: ["31-32", "33-34", "35-36", "37-38", "39-40"],
            stock: [2, 10, 8, 6, 4],
            stockPercentage: [10, 70, 20, 50, 90]
        },
        {
            colorName: "Green",
            colorCode: "#008000",
            sizes: ["31-32", "33-34", "35-36", "37-38", "39-40"],
            stock: [12, 10, 48, 6, 4],
            stockPercentage: [40, 40, 20, 70, 30]
        }
    ];*/

    //const navigate = useNavigate();
    const [mappedData, setMappedData] = useState([]);
    const [headerSizes, setHeaderSizes] = useState([]);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!data) return;

        let tmp = data?.expandItems?.map(row => {
            return {
                ...row,
                totalStock: row?.stock.reduce((acc, curr) => acc + curr, 0)
            };
        });

        const isSizesStandard = checkSizesAreStandard(data?.sizes);

        if (isSizesStandard) {
            //TODO: REMOVE LATER TMP CODE: hardcode order of sizes defined in tmpSizes array
            const tmpSizes = ["XXS", "XS", "S", "S/M", "M", "L", "L/XL", "XL", "XL/XXL", "XXL", "2XL", "3XL"];

            const newHeaderSizes = tmpSizes.filter(size => data?.sizes.indexOf(size) > -1);

            tmp = tmp.map(row => {
                const tmpStock = [];
                const tmpStockPercentage = [];

                for (let i = 0; i < tmpSizes.length; i++) {
                    //tmpSizes.forEach(size => {
                    const size = tmpSizes[i];
                    const index = row?.sizes?.findIndex(s => s?.toLowerCase().includes(size.toLowerCase()));
                    if (index === -1) continue;
                    tmpStock.push(row?.stock?.[index] || 0);
                    tmpStockPercentage.push(row?.stockPercentage?.[index] || 0);
                }

                //check for missing sizes outside the tmpSizes and add them at the end
                row?.sizes?.forEach((size, index) => {
                    if (tmpSizes.indexOf(size) === -1) {
                        tmpSizes.push(size);
                        tmpStock.push(row?.stock?.[index] || 0);
                        tmpStockPercentage.push(row?.stockPercentage?.[index] || 0);
                        newHeaderSizes.push(size);
                    }
                });

                return {
                    ...row,
                    sizes: tmpSizes,
                    stock: tmpStock,
                    stockPercentage: tmpStockPercentage
                };
            });

            setHeaderSizes(newHeaderSizes);
        } else setHeaderSizes(data?.sizes);

        setMappedData(tmp);
    }, [data]);
    /* #endregion */

    /* #region METHODS */
    const checkSizesAreStandard = sizes => {
        const checkExistsXL = sizes?.findIndex(size => size?.toLowerCase().includes("xl")) > -1;
        const checkExistsM = sizes?.findIndex(size => size?.toLowerCase().includes("m")) > -1;

        return checkExistsXL && checkExistsM;
    };

    const getIndicatorColor = percent => {
        if (percent >= 80) return "#00bb00";
        if (percent >= 50) return "#ff9900";
        return "#ff3636";
    };
    /* #endregion */

    /* #region COMPUTED */

    /* #endregion */

    return (
        <CustomNestedCols className="frame-1000011268">
            <div className="frame-1000011369">
                <div className="frame-10000112902">
                    <div className="frame-1000011283">
                        <div className="label">Empty</div>
                    </div>
                    <div className="frame-10000112702">
                        {mappedData?.map((row, index) => (
                            <div key={index} className="frame-10000112882">
                                <div
                                    className="rectangle-7"
                                    style={{ backgroundColor: formatColorHex(row?.colorCode) }}></div>
                                <Tooltip title={row?.colorName} placement="top" color="rgba(255, 255, 255, 0.1)">
                                    <div className="orange text-one-line">{row?.colorName}</div>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="frame-1000011305">
                    <div className="frame-1000011284">
                        <div className="frame-10000112832">
                            {
                                /*mappedData?.[0]?.sizes?.map((size, index) => (*/
                                /*props?.data?.sizes?.map((size, index) => ( */
                                headerSizes?.map((size, index) => (
                                    <div key={index} className="_31-32 text-one-line">
                                        <Tooltip title={size} placement="top" color="rgba(255, 255, 255, 0.1)">
                                            {size}
                                        </Tooltip>
                                    </div>
                                ))
                            }
                        </div>

                        {mappedData?.map((rowColor, index) => (
                            <div key={index} className="frame-10000112842">
                                {rowColor?.stock?.slice(0, props?.data?.sizes?.length).map((stock, index) => (
                                    <div className="frame-10000112703">
                                        <div className="frame-1000011339">
                                            <div className="_12">{stock}</div>
                                            <div className="group-1">
                                                <div className="table-progress-bar">
                                                    <div
                                                        className="rectangle-258"
                                                        style={{
                                                            width: `${rowColor?.stockPercentage?.[index] || 0}%`,
                                                            backgroundColor: getIndicatorColor(
                                                                rowColor?.stockPercentage?.[index] || 0
                                                            )
                                                        }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    {mappedData?.[0]?.stock?.length > 1 && (
                        <div className="frame-10000112872">
                            <div className="frame-1000011291">
                                <div className="total">Total</div>
                            </div>
                            <div className="frame-1000011306">
                                {mappedData?.map((rowColor, index) => (
                                    <div key={index} className="_60">
                                        {rowColor?.totalStock}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </CustomNestedCols>
    );
}

/* #region SMALL COMPONENTS */
/* #endregion */

/* #region old code */
// eslint-disable-next-line no-unused-vars
const OldCode = () => {
    /*
                            <div className="frame-1000011271">
                                <div className="frame-1000011339">
                                    <div className="_10">10</div>
                                    <div className="group-12">
                                        <div className="table-progress-bar"></div>
                                        <div className="rectangle-2585"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011340">
                                    <div className="_5">5</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2586"></div>
                                        </div>
                                        <div className="rectangle-2587"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011341">
                                    <div className="_3">3</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-1000011280">
                                <div className="frame-1000011340">
                                    <div className="_5">5</div>
                                    <div className="group-13">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-25810"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011339">
                                    <div className="_10">10</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011341">
                                    <div className="_6">6</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-1000011281">
                                <div className="frame-1000011341">
                                    <div className="_7">7</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-25811"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011340">
                                    <div className="_4">4</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011339">
                                    <div className="_2">2</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-1000011282">
                                <div className="frame-1000011339">
                                    <div className="_3">3</div>
                                    <div className="group-14">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-25812"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011340">
                                    <div className="_2">2</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25813"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011341">
                                    <div className="_1">1</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-10000112833">
                                <div className="frame-1000011341">
                                    <div className="_8">8</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011342">
                                    <div className="_7">7</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25813"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011339">
                                    <div className="_4">4</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-10000112843">
                                <div className="frame-1000011339">
                                    <div className="_6">6</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011342">
                                    <div className="_6">6</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2582"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011340">
                                    <div className="_3">3</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25814"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-1000011285">
                                <div className="frame-1000011341">
                                    <div className="_4">4</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                                <div className="frame-1000011343">
                                    <div className="_3">3</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25815"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011339">
                                    <div className="_1">1</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-2588"></div>
                                        </div>
                                        <div className="rectangle-2589"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-1000011286">
                                <div className="frame-1000011345">
                                    <div className="_3">3</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25816"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011343">
                                    <div className="_1">1</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25817"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011346">
                                    <div className="_0">0</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25815"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-1000011287">
                                <div className="frame-1000011345">
                                    <div className="_2">2</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-259"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011343">
                                    <div className="_0">0</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25815"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011346">
                                    <div className="_0">0</div>
                                    <div className="group-1">
                                        <div className="table-progress-bar">
                                            <div className="rectangle-25815"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        <div className="rectangle-6"></div>
                        <div className="rectangle-74"></div>
                        */
};
/* #endregion */
