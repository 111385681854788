import React, { useEffect, useState } from "react";
import InfinityGridLabelBrandsConnections from "../../common/cardContainers/InfinityGridLabelBrandsConnections";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import * as Library from "../../../utils/Library";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { AppQueryClient } from "../../../api/queryClient";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { defaultStatusColors } from "../../../utils/CardUtils";
import ProductFiltersHeader from "../../common/mainApp/mainWindowHeaders/ProductFiltersHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function LabelBrandsConnectionsPage() {
    document.title = `${APP_TITLE} - Label Brands Connections`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const navigate = useNavigate();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoading, setIsLoading] = useState(false);

    const infiniteGridQuerykey = "LabelBrandsConnectionsGrid";
    const listQueryKey = "LabelBrandsConnectionsList";

    const [showInvitationAccepted, setShowInvitationAccepted] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, navigations, onClickRefreshAction);
    }, [props?.layoutType]);

    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Connection",
            selector: row => row?.retailBrand?.name ?? "--"
        },
        {
            name: "BOTAG IG",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Connected on",
            selector: row => formatDefaultDateTime(row?.createdAt) ?? "--"
        },
        {
            name: "Max stores allowed",
            selector: row => row?.maxStores ?? "--"
        },
        {
            name: "Already affiliated stores",
            selector: row => row?.affiliatedStores ?? "--"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const handleAcceptInvitation = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "acceptRetailerInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        ); //TODO: THIS IS THROWING AN ERROR: Invite type 'Retailer' not allowed for this operation

        if (res?.data?.status === 200) {
            /* Library.showToastMessage({
                type: "success",
                title: "Invitation accepted!",
                message: "You can now proceed with affiliating your stores to this brand."
            }); */
            setShowInvitationAccepted(true);

            onClickRefreshAction();
        }
    };

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={showInvitationAccepted}>
                <DialogBoxComponent
                    title="Invitation accepted!"
                    message="You can now proceed with affiliating your stores to this brand."
                    showCancel={false}
                    confirmText="CONTINUE"
                    heading="SUCCESS!"
                    headingColor="var(--text-icons-green-500)"
                    onClickYes={() => {
                        setShowInvitationAccepted(false);
                        //navigate("/ffcenters/management");
                    }}
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridLabelBrandsConnections
                    //minColumnWidth="370px"
                    minColumnWidth="238px"
                    limit="8"
                    queryKey={infiniteGridQuerykey}
                    // statusFromPath="status"
                    onAcceptInvitation={handleAcceptInvitation}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="BRANDS"
                    //title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    api={{
                        endpoint: "getConnectedLabelBrands",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            page: 1,
                            limit: 10
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={10}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Disconnect",
                                onClick: undefined
                            },
                            {
                                title: "More Stores",
                                onClick: undefined
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, navigations, onRefreshAction) {
    const createMode = navigations?.navigationAccesses?.labelbrands?.createModeOnly;

    props.setControlBarLeftBtns([
        //{ to: "#", disabled: true, title: "Label Brands", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([
        { to: "/labelbrands/connections", active: true, disabled: createMode, title: "CONNECTIONS" },
        { to: "/labelbrands/invitations", active: false, disabled: false, title: "INVITATIONS" },
        { to: "/labelbrands/requests", active: false, disabled: false, title: "REQUESTS" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/labelbrands/request", active: false, disabled: false, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <ProductFiltersHeader
                visible={true}
                gridEnabled={true}
                listEnabled={true}
                onRefreshAction={onRefreshAction}
                searchBoxPlaceholder="Search"
            />
        )
    });
}
