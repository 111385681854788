import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying brand information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the brand.
 * @param {string} props.id - The ID of the brand.
 * @param {boolean} props.hasBorders - Indicates if the card has borders.
 * @param {string} [props.titlePrefix="BRAND"] - The prefix for the title.
 * @param {string} [props.idPrefix="BOTAG ID"] - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the brand.
 * @param {Object[]} props.details - The details to display in the card.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.edit - The edit button configuration.
 * @param {Object} props.suspend - The suspend button configuration.
 * @param {Object} props.delete - The delete button configuration.
 * @returns {JSX.Element} The rendered BrandsGridCard component.
 */
export default function BrandsGridCard(props) {
    let status = props?.data?.status ?? "--";

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={{ title: "ADD STORES" }}
                secondaryButton={{ title: "ADD FFCENTERS" }}
                title={props?.title}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "BRAND"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageHasPadding={true}
                details={[
                    { key: "Created by:", value: props?.data?.createdBy || "--" },
                    { key: "Created on:", value: props?.data?.createdOn || "--" },
                    { key: "Company:", value: props?.data?.companyName || "--" },
                    { key: "Utilizing shopify:", value: props?.data?.utilizingShopify ? "YES" : "NO" },
                    { key: "Stores:", value: props?.data?.activeStores ?? "--" },
                    { key: "Fulfilment Centers:", value: props?.data?.activeFFCenters ?? "--" },
                    { key: "POS:", value: props?.data?.pos ?? "--" },
                    { key: "Status:", value: getSpanWithColor(status ?? "--", defaultStatusColors) }

                    /*  { key: "Created by:", value: props?.data?.createdBy || "--" },
                    { key: "Created on:", value: props?.data?.createdOn || "--" },
                    { key: "Active on:", value: props?.data?.activeOn || "--" },
                    { key: "Status:", value: getSpanWithColor(status ?? "--", defaultStatusColors) },
                    { key: "Brand account admin: ", value: props?.data?.brandAccountAdmin || "--" },
                    { key: "Phone number:", value: props?.data?.phoneNumber || "--" },
                    { key: "Contact email:", value: props?.data?.contactEmail || "--" },
                    { key: "Country:", value: props?.data?.country || "--" }
                    //keys: "Enrolled on", "Company", "Industry", "Status", "Active Stores", "Active FF Centers", "Active BOimages", "Active Users", */
                ]}
                edit={{ hidden: false, ...props?.edit }}
                suspend={props?.suspend}
                delete={props?.delete}
            />
        </StyledPage>
    );
}
