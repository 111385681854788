import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import SwitchButton from "../../common/buttons/SwitchButton";
import ListComponent from "../../common/lists/ListComponent";
import { defaultStatusColors } from "../../../utils/CardUtils";
import OrganizeFiltersHeader from "../../common/mainApp/mainWindowHeaders/OrganizeFiltersHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

const infiniteGridQuerykey = "FFCGrid";
const listQueryKey = "FFCList";

export default function FFCinvitationsPerStore() {
    document.title = `${APP_TITLE} - Fullfillment Centers`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("List");
    const [onlyAffiliatedBrands, setOnlyAffiliatedBrands] = useState(false);
    /* #endregion */

    /* #region Methods */
    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, onClickRefreshAction, navigations);
    }, [props?.layoutType]);

    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Store Name",
            selector: row => row?.name ?? "--",

            sortable: true
        },
        {
            name: "City",
            selector: row => row?.city ?? "--"
        },
        {
            name: "Country",
            selector: row => row?.country ?? row?.countryValue ?? "--"
        },
        {
            name: "Store ID",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Brands",
            selector: row => row?.labelBrandFFCs?.length ?? "--"
        },
        {
            name: "POS System",
            selector: row => row?.POSname || "--"
        },
        {
            name: "POS Status",
            selector: row => row?.POSstatus || "--"
        }
    ];

    const expandedRowsColumns = [
        {
            name: "Store Name",
            selector: row => row?.labelBrandName ?? "--"
        },
        {
            name: "City",
            selector: row => `Invitation available: ${row?.invitationsAvailable ?? "--"}`
        },
        {
            name: "Country",
            selector: row => ""
        },
        {
            name: "Store ID",
            selector: row => ""
        },
        {
            name: "Brands",
            selector: row => ""
        },
        {
            name: "POS System",
            selector: row => ""
        },
        {
            name: "POS Status",
            selector: row => ""
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            {/*viewLayoutStyle === "Grid" && (
                <InfinityGridFFCsCards
                    minColumnWidth="238px"
                    limit={12}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                />
            ) */}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    middleCardTopComponent={
                        <SwitchButton
                            className="flex justify-center w-100"
                            height="17px"
                            title="All Brands"
                            rightTitle="Affiliated Brands"
                            onToogleChanged={value => setOnlyAffiliatedBrands(value)}
                        />
                    }
                    title="Fulfilment Centers"
                    columns={listColumns}
                    pageLimit={20}
                    selectableRows={false}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Accept"
                            },
                            {
                                title: "Suspend"
                            },
                            {
                                title: "Remove"
                            }
                        ]
                    }}
                    expandableRows={true}
                    expandedRowsColumns={expandedRowsColumns}
                    expandedRowName="labelBrandFFCs"
                    includeStatus={{
                        width: "100px",
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    defaultSortFieldId={1}
                    defaultSortAsc={true}
                    api={{
                        endpoint: "getFulfillmentCentersManagementData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            onlyAffiliatedBrands
                        },
                        onSuccess: data => {
                            return data?.map(row => ({
                                ...row,
                                status: row?.status ?? "Not affiliated",
                                labelBrandFFCs: row?.labelBrandFFCs?.map(brand => ({
                                    ...brand,
                                    status: brand?.status ?? "Not affiliated"
                                }))
                            }));
                        }
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, onRefreshAction, navigations) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters", active: true, disabled: createMode, title: "AFFILIATIONS" },
        { to: "/ffcenters/management", active: false, disabled: false, title: "MANAGEMENT" },
        { to: "/stock", active: false, disabled: false, title: "STOCK" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/labelbrands/request", active: false, disabled: createMode, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <OrganizeFiltersHeader
                visible={true}
                gridEnabled={true}
                listEnabled={true}
                onRefreshAction={onRefreshAction}
                searchBoxPlaceholder="Search"
            />
        )
    });
}
