/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import "../../../assets/css/components/CheckboxDark.css";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { useNavigate } from "react-router";

/* #region Styled Components */
const CustomNestedCols = styled.tr`
    & {
        display: flex;
        align-items: stretch;
        width: 100%;
        box-sizing: border-box;
        font-size: 12px !important;
        font-weight: 400;
        font-family: Roboto;
        border-bottom: 1px solid #333f50 !important;
        min-height: 42px;
        border-bottom: none !important;
    }

    td {
        min-height: 42px;
        line-height: 30px;

        //one line ellipsis
    }
    td > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.nested-row-tr-header > td {
        color: var(--text-icons-gray-200, #7c868b) !important;
        font-size: 11px !important;
    }

    button.three-btn-class {
        width: calc(100% / 3 - 8px / 3);
        text-overflow: ellipsis;
        overflow: hidden;
        align-items: center;
    }

    .td-bottom-border {
        border-bottom: 1px solid #333f50 !important;
    }

    .nested-col-first {
        width: 35px;
        flex: 0 0 35px;
        min-width: 35px;
        margin: 0px;
        opacity: 0;
    }

    .nested-col-last {
    }

    .nested-col-default {
        padding-left: 8px;
        padding-right: 0px;

        -webkit-box-flex: 1;
        flex: 1 0 0px;
        max-width: 100%;
        min-width: 100px;

        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        box-sizing: border-box;
    }
`;

const CustomButton = styled.button`
    & {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: auto;
        height: 25px;

        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-200, #262e3e);
        background-color: transparent;

        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 7px;
    }

    &.disabled {
        color: var(--text-icons-gray-300, #4f5b6d);
    }
`;

const FloatingDiv = styled.div`
    & {
        display: flex;
        padding: 0px 3px;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        width: fit-content;

        border-radius: 2px;
        background: var(--Buttons-All-Platforms-btn-action-green, #080);

        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 8px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
/* #endregion */

export default function FFCsCustomExpandedComponent(props) {
    const { data, handleOnAcceptClick, handleOnSuspendClick, handleOnRemoveClick } = props;

    /* #region VARS */
    const navigate = useNavigate();
    const expandedRowName = "connectedBrands";

    const expandedRowsColumns = [
        {
            name: "Label brand",
            selector: row => (
                <div className="flex align-items-center">
                    <FloatingDiv>New</FloatingDiv>
                    <span style={{ marginLeft: "5px" }}>{row?.labelBrandName ?? "--"}</span>
                </div>
            ),
            minWidth: "14.5%",
            maxWidth: "14.5%"
            // 235px / 1620px = 14.5%
        },
        {
            name: "Country",
            selector: row => row?.labelBrandCountryValue || "--",
            minWidth: "10.8%",
            maxWidth: "10.8%"
        },
        {
            name: "Invitations available",
            selector: row => (
                <div className="flex align-items-center gap-8px">
                    {row?.isAllStores ? (
                        <FloatingDiv
                            style={{
                                position: "absolute",
                                left: "-12px"
                            }}>
                            All
                        </FloatingDiv>
                    ) : null}
                    <span style={{ width: "46px", flexShrink: 0 }}>
                        {row?.invitationsAvailable ?? "--"}/{row?.invitationsAvailable + row?.numberOfFFCs ?? "--"}
                    </span>
                    <CustomButton
                        className={`${isRequestMoreEnabled(row) ? "" : "disabled"}`}
                        onClick={() => handleClickRequestMore(row)}>
                        Request more
                    </CustomButton>
                    <CustomButton
                        className={`${isCheckStockEnabled(row) ? "" : "disabled"}`}
                        onClick={() => handleClickCheckStock(row)}
                        style={{ marginLeft: "25px" }}>
                        Check stock
                    </CustomButton>
                </div>
            ),
            minWidth: "260px",
            maxWidth: "18.5%"
        },
        {
            name: "Status",
            selector: row => getStatusColumn(row, "status", defaultStatusColors),
            minWidth: "9.4%",
            maxWidth: "9.4%"
        },
        {
            name: "Actions",
            hideHeaderName: true,
            selector: row => "Actions",
            minWidth: "14.75%",
            maxWidth: "14.75%"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const getStatusColumn = (row, includeStatusWithArrayFields, statusColors) => {
        let statusValue = "";

        if (typeof row === "object") {
            statusValue = row?.status || "Unknown";
        } else if (Array.isArray(row)) {
            statusValue = getArrayFieldValue(row, includeStatusWithArrayFields) || "Unknown";
        }

        //TODO: This is a temporary fix for the "Not Connected" status value
        if (statusValue === "Not Connected") statusValue = "Not connected";

        let colorValue = "text-gray-200";
        let found = false;

        for (const color of statusColors) {
            for (const value of color.forValues) {
                if (value.toLowerCase() === statusValue.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        //HARDCODED STATUS COLORS AS REQUESTED
        if (statusValue === "Suspended") colorValue = "text-red-500";

        return (
            <div>
                <div className="flex align-items-center">
                    <div className={`status-dot bg-${colorValue}`}></div>
                    <span className={colorValue}>{statusValue}</span>
                </div>
            </div>
        );
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };
    /* #endregion */

    /* #region COMPUTED */
    const isAffiliateEnabled = row => {
        if (row?.parentPOSstatus !== "Connected") return false;
        if (row?.status === "Suspended") return true;
        if (row?.invitationsAvailable <= 0) return false;
        if (row?.status === "Not affiliated") return true;
        if (row?.status === "Fulfilling") return false;
        return false;
    };

    const isSuspendEnabled = row => {
        if (row?.parentPOSstatus !== "Connected") return false;
        if (row?.status === "Not affiliated") return false;
        if (row?.status === "Suspended") return false;
        if (row?.status === "Fulfilling") return true;
        return false;
    };

    const isRemoveEnabled = row => {
        if (row?.parentPOSstatus !== "Connected") return false;
        if (row?.status === "Not affiliated") return false;
        if (row?.status === "Suspended") return true;
        if (row?.status === "Fulfilling") return true;
        return false;
    };

    const isRequestMoreEnabled = row => {
        return false; //TODO: Implement
    };

    const isCheckStockEnabled = row => {
        return row?.status === "Fulfilling";
    };

    const handleClickAffiliate = row => {
        if (!isAffiliateEnabled(row)) return;

        if (handleOnAcceptClick) {
            handleOnAcceptClick(row, true);
        }
    };

    const handleClickSuspend = row => {
        if (!isSuspendEnabled(row)) return;

        if (handleOnSuspendClick) {
            handleOnSuspendClick(row, true);
        }
    };

    const handleClickRemove = row => {
        if (!isRemoveEnabled(row)) return;

        handleOnRemoveClick(row, true);
    };

    const handleClickRequestMore = row => {
        if (!isRequestMoreEnabled(row)) return;
    };

    const handleClickCheckStock = row => {
        if (!isCheckStockEnabled(row)) return;
        navigate("/stock?storeID=" + row?.parentID + "&storeName=" + row?.parentName);
    };

    /* #endregion */

    return (
        <div>
            <table
                className="CustomExpandedRow"
                style={{
                    width: "100%",
                    maxWidth: "100%",
                    marginBotton: "20px",
                    background: "var(--backgrounds-lines-n-500)",
                    //radius 4px
                    borderRadius: "4px"
                }}>
                <tbody>
                    <CustomNestedCols className="nested-row-tr-header">
                        <td
                            className="nested-col-default td-bottom-border"
                            key="nested-col-1"
                            style={{ minWidth: "17.7%", maxWidth: "17.7%", paddingLeft: "0" }}></td>

                        {expandedRowsColumns?.map((col, index) => {
                            return (
                                <td
                                    className="nested-col-default td-bottom-border"
                                    key={index}
                                    style={{ minWidth: col?.minWidth, maxWidth: col?.maxWidth }}>
                                    {col?.hideHeaderName ? null : <span title={col.name}>{col.name}</span>}
                                </td>
                            );
                        })}

                        <td
                            className="nested-col-default td-bottom-border"
                            key="nested-col-action"
                            style={{ minWidth: "13.33%", maxWidth: "13.33%" }}></td>
                    </CustomNestedCols>
                    {data?.[expandedRowName]?.length === 0 ? (
                        <CustomNestedCols className="nested-row-tr">
                            <td
                                className="nested-col-default"
                                key="nested-col-1"
                                style={{ minWidth: "17.7%", maxWidth: "17.7%", paddingLeft: "0" }}></td>

                            <td className="nested-col-default">No data available</td>
                            {Array.from({ length: expandedRowsColumns.length - 1 }).map((_, index) => (
                                <td className="nested-col-default" key={index}></td>
                            ))}

                            <td
                                className="nested-col-default"
                                key="nested-col-action"
                                style={{ minWidth: "13.33%", maxWidth: "13.33%" }}></td>
                        </CustomNestedCols>
                    ) : (
                        data?.[expandedRowName]?.map((row, index) => {
                            return (
                                <CustomNestedCols className="nested-row-tr" key={index}>
                                    <td
                                        className="nested-col-default"
                                        key="nested-col-1"
                                        style={{ minWidth: "17.7%", maxWidth: "17.7%", paddingLeft: "0" }}></td>

                                    {expandedRowsColumns?.map((col, index) => {
                                        return (
                                            <td
                                                className="nested-col-default w-100"
                                                key={index}
                                                style={{
                                                    minWidth: col?.minWidth,
                                                    maxWidth: col?.maxWidth
                                                }}>
                                                {index === 4 ? (
                                                    <div className="flex gap-4px">
                                                        <CustomButton
                                                            className={`three-btn-class ${
                                                                isAffiliateEnabled(row) ? "" : "disabled"
                                                            }`}
                                                            onClick={() => handleClickAffiliate(row)}>
                                                            Affiliate
                                                        </CustomButton>
                                                        <CustomButton
                                                            className={`three-btn-class ${
                                                                isSuspendEnabled(row) ? "" : "disabled"
                                                            }`}
                                                            onClick={() => handleClickSuspend(row)}>
                                                            Suspend
                                                        </CustomButton>
                                                        <CustomButton
                                                            className={`three-btn-class ${
                                                                isRemoveEnabled(row) ? "" : "disabled"
                                                            }`}
                                                            onClick={() => handleClickRemove(row)}>
                                                            Remove
                                                        </CustomButton>
                                                    </div>
                                                ) : (
                                                    <span title={col.selector(row)}>{col.selector(row)}</span>
                                                )}
                                            </td>
                                        );
                                    })}

                                    <td
                                        className="nested-col-default"
                                        key="nested-col-action"
                                        style={{ minWidth: "13.33%", maxWidth: "13.33%" }}></td>
                                </CustomNestedCols>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
}
