import CompaniesPage from "../components/pages/companies/CompaniesPage";
import CompaniesUsersPage from "../components/pages/companies/CompaniesUsersPage";
import CompanyCreatePage from "../components/pages/companies/CreateCompanyPage";
import CompaniesUsersInvitePage from "../components/pages/companies/CompaniesUsersInvitePage";
import KycPolicyPage from "../components/pages/companies/KycPolicyPage";

const routes = [
    {
        path: "/companies",
        element: <CompaniesPage />
    },
    {
        path: "/companies/users",
        element: <CompaniesUsersPage />
    },
    {
        path: "/companies/users/invite",
        element: <CompaniesUsersInvitePage />
    },
    {
        path: "/companies/create",
        element: <CompanyCreatePage />
    },
    {
        path: "/companies/kyc-policy",
        element: <KycPolicyPage />
    }
];

export default routes;
