import React from "react";
import styled from "styled-components";
import ContainerLoader from "../loaders/ContainerLoader";

const StyledPage = styled.div`
    & {
        display: flex;
        width: 246px;
        padding: 12px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-500, #141922);

        /* drop-shadow-100 */
        box-shadow: 0px 0px 10px 0px #111620;
    }

    .NotificationItemsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
    }

    .Box {
        width: 16px;
        height: 16px;
        position: relative;
    }

    .Icon {
        width: 13.33px;
        height: 12px;
        left: 1.33px;
        top: 2px;
        position: absolute;
        border: 1px #d1d1d1 solid;
    }

    .NotificationItem {
        align-self: stretch;
        padding: 8px;
        border-radius: 4px;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        display: inline-flex;
        border-radius: 0;

        border-bottom: 1px solid rgba(60, 72, 90, 0.26);
    }

    .NotificationItem:last-child {
        border-bottom: none;
    }

    .NotificationItemTexts {
        flex: 1 1 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        display: inline-flex;
    }

    .TitleText {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .DescriptionText {
        color: var(--text-icons-gray-200, #7c868b);
        text-align: right;

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .NotReadedEllipse {
        width: 8px;
        height: 8px;
        background: #d22626;
        border-radius: 9999px;
    }

    .NotificationItem:hover {
        background: #1e2631;
    }

    .BtnLargeSolid {
        align-self: stretch;
        height: 35px;
        padding: 10px;
        background: #1e2631;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: inline-flex;
    }

    .Button {
        color: #d1d1d1;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.12px;
        word-wrap: break-word;
    }
`;

/**
 * Component for displaying a dropdown of notifications.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.NotificationItems - The array of notification items to display.
 * @param {String} props.NotificationItems.title - The title of the notification.
 * @param {String} props.NotificationItems.description - The description of the notification.
 * @param {Boolean} props.NotificationItems.unread - The flag to indicate if the notification is unread.
 * @param {Boolean} props.isLoadingNotifications - The flag to indicate if the notifications are loading.
 * @param {Object} props.style - The inline style object for the component.
 * @returns {JSX.Element} - The rendered component.
 */
export default function NotificationsDropdown(props) {
    const { NotificationItems } = props;

    return (
        <StyledPage className={props?.className ?? ""} style={props?.style}>
            {props?.isLoadingNotifications ? (
                <ContainerLoader isLoading={props?.isLoadingNotifications} />
            ) : (
                <div className="NotificationItemsContainer">
                    {(!NotificationItems || NotificationItems?.length === 0) && (
                        <div className="NotificationItem">
                            <div className="NotificationItemTexts">
                                <div className="TitleText">No new notifications</div>
                            </div>
                        </div>
                    )}
                    {NotificationItems?.map((item, index) => (
                        <div key={index} className="NotificationItem hover-not-allowed">
                            <div className="Box">{getNotificationIcon(item?.type)}</div>
                            <div className="NotificationItemTexts">
                                <div className="TitleText">{item?.title}</div>
                                <div className="DescriptionText">{item?.description}</div>
                            </div>
                            {(item?.status === "New" || item?.status === "Sent") && (
                                <div className="NotReadedEllipse"></div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            <div className="BtnLargeSolid hover-not-allowed">
                <div className="Button text-gray-300">Show all notifications</div>
            </div>
        </StyledPage>
    );
}

const getNotificationIcon = type => {
    //TODO: REVIEW NOTIFICATIONS FOR RETAIL BRANDS IN BOSTAGE R ?
    switch (type) {
        case "LabelBrandAccept":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                        d="M5.33399 5.83325H5.34065M1.33398 3.96658L1.33398 6.94959C1.33398 7.27572 1.33398 7.43878 1.37082 7.59223C1.40349 7.72828 1.45736 7.85833 1.53046 7.97763C1.61292 8.11219 1.72822 8.22749 1.95882 8.45809L7.07124 13.5705C7.86328 14.3625 8.25929 14.7586 8.71595 14.9069C9.11764 15.0375 9.55033 15.0375 9.95202 14.9069C10.4087 14.7586 10.8047 14.3625 11.5967 13.5705L13.0712 12.096C13.8633 11.304 14.2593 10.9079 14.4077 10.4513C14.5382 10.0496 14.5382 9.6169 14.4077 9.21522C14.2593 8.75856 13.8633 8.36254 13.0712 7.57051L7.95882 2.45809C7.72822 2.22749 7.61292 2.11219 7.47836 2.02973C7.35907 1.95663 7.22901 1.90275 7.09296 1.87009C6.93951 1.83325 6.77645 1.83325 6.45033 1.83325L3.46732 1.83325C2.72058 1.83325 2.34721 1.83325 2.062 1.97858C1.81111 2.10641 1.60714 2.31038 1.47931 2.56126C1.33398 2.84648 1.33398 3.21985 1.33398 3.96658ZM5.66732 5.83325C5.66732 6.01735 5.51808 6.16659 5.33399 6.16659C5.14989 6.16659 5.00065 6.01735 5.00065 5.83325C5.00065 5.64916 5.14989 5.49992 5.33399 5.49992C5.51808 5.49992 5.66732 5.64916 5.66732 5.83325Z"
                        stroke="#D1D1D1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "StoreAccept":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <g clipPath="url(#clip0_4815_2392)">
                        <path
                            d="M11.3682 9.1228H9.19411C8.59909 9.1228 8.11523 9.60666 8.11523 10.2017V11.869C8.11523 12.464 8.59909 12.9479 9.19411 12.9479H11.3682C11.9632 12.9479 12.4471 12.464 12.4471 11.869V10.2017C12.4471 9.60666 11.9632 9.1228 11.3682 9.1228ZM11.6624 11.869C11.6624 12.0325 11.5284 12.1633 11.3682 12.1633H9.19411C9.03064 12.1633 8.89987 12.0292 8.89987 11.869V10.2017C8.89987 10.0382 9.03391 9.90744 9.19411 9.90744H11.3682C11.5317 9.90744 11.6624 10.0415 11.6624 10.2017V11.869Z"
                            fill="#D1D1D1"
                        />
                        <path
                            d="M16 6.09217C16 6.02351 15.9837 5.95813 15.9477 5.89928L13.5676 1.71456C13.499 1.5936 13.3682 1.51514 13.2276 1.51514H2.76911C2.62852 1.51514 2.49775 1.59033 2.4291 1.71456L0.052309 5.89928C0.0196159 5.95813 0 6.02351 0 6.09217C0 7.03046 0.565591 7.83471 1.37311 8.19107V15.0926C1.37311 15.3084 1.54965 15.4849 1.76543 15.4849H3.93298C3.93625 15.4849 3.93952 15.4849 3.94279 15.4849H6.98325C6.98651 15.4849 6.98978 15.4849 6.99305 15.4849H14.2313C14.4471 15.4849 14.6236 15.3084 14.6236 15.0926V8.24011C14.6236 8.22376 14.6236 8.21068 14.6204 8.19761C15.4311 7.84125 16 7.03373 16 6.09217ZM2.99796 2.30304H12.9988L14.859 5.57562H1.14099L2.99796 2.30304ZM11.387 6.35698C11.2628 7.06315 10.6449 7.59932 9.90274 7.59932C9.16061 7.59932 8.54271 7.06315 8.41847 6.35698H11.387ZM7.5848 6.35698C7.46056 7.06315 6.84267 7.59932 6.10053 7.59932C5.3584 7.59932 4.7405 7.06315 4.613 6.35698H7.5848ZM0.810789 6.35698H3.78259C3.65836 7.06315 3.03719 7.59932 2.29506 7.59932C1.55292 7.60259 0.935023 7.06315 0.810789 6.35698ZM6.59093 14.7003H4.3351V10.4665C4.3351 10.1592 4.58357 9.90745 4.89416 9.90745H6.03515C6.34246 9.90745 6.5942 10.1559 6.5942 10.4665V14.7003H6.59093ZM13.839 14.7003H7.37556V10.4665C7.37556 9.72764 6.77401 9.12282 6.03188 9.12282H4.89089C4.15202 9.12282 3.5472 9.72437 3.5472 10.4665V14.7035H2.15774V8.38396C2.20351 8.38723 2.24928 8.38723 2.29506 8.38723C3.08623 8.38723 3.78586 7.9851 4.19779 7.37374C4.60973 7.9851 5.30936 8.38723 6.10053 8.38723C6.8917 8.38723 7.58807 7.9851 8.00327 7.37374C8.4152 7.9851 9.11483 8.38723 9.90274 8.38723C10.6939 8.38723 11.3903 7.9851 11.8022 7.37374C12.2141 7.9851 12.9138 8.38723 13.7049 8.38723C13.7507 8.38723 13.7932 8.38396 13.839 8.38396V14.7003ZM13.7049 7.60259C12.9628 7.60259 12.3449 7.06642 12.2207 6.36025H15.1925C15.065 7.06315 14.4471 7.60259 13.7049 7.60259Z"
                            fill="#D1D1D1"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_4815_2392">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "LabelBrandOrder":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                        d="M13.6673 5.83333V11.3C13.6673 12.4201 13.6673 12.9802 13.4493 13.408C13.2576 13.7843 12.9516 14.0903 12.5753 14.282C12.1475 14.5 11.5874 14.5 10.4673 14.5H5.53398C4.41388 14.5 3.85383 14.5 3.426 14.282C3.04968 14.0903 2.74372 13.7843 2.55197 13.408C2.33398 12.9802 2.33398 12.4201 2.33398 11.3V5.83333M2.40065 2.5H13.6007C13.974 2.5 14.1607 2.5 14.3033 2.57266C14.4288 2.63658 14.5307 2.73856 14.5947 2.86401C14.6673 3.00661 14.6673 3.1933 14.6673 3.56667V4.76667C14.6673 5.14004 14.6673 5.32672 14.5947 5.46933C14.5307 5.59477 14.4288 5.69676 14.3033 5.76067C14.1607 5.83333 13.974 5.83333 13.6007 5.83333H2.40065C2.02728 5.83333 1.8406 5.83333 1.69799 5.76067C1.57255 5.69676 1.47056 5.59477 1.40665 5.46933C1.33398 5.32672 1.33398 5.14004 1.33398 4.76667V3.56667C1.33398 3.1933 1.33398 3.00661 1.40665 2.86401C1.47056 2.73856 1.57255 2.63658 1.69799 2.57266C1.8406 2.5 2.02728 2.5 2.40065 2.5ZM6.40065 8.16667H9.60065C9.97402 8.16667 10.1607 8.16667 10.3033 8.23933C10.4288 8.30324 10.5307 8.40523 10.5947 8.53067C10.6673 8.67328 10.6673 8.85997 10.6673 9.23333V9.76667C10.6673 10.14 10.6673 10.3267 10.5947 10.4693C10.5307 10.5948 10.4288 10.6968 10.3033 10.7607C10.1607 10.8333 9.97402 10.8333 9.60065 10.8333H6.40065C6.02728 10.8333 5.8406 10.8333 5.69799 10.7607C5.57255 10.6968 5.47056 10.5948 5.40665 10.4693C5.33398 10.3267 5.33398 10.14 5.33398 9.76667V9.23333C5.33398 8.85997 5.33398 8.67328 5.40665 8.53067C5.47056 8.40523 5.57255 8.30324 5.69799 8.23933C5.8406 8.16667 6.02728 8.16667 6.40065 8.16667Z"
                        stroke="#D1D1D1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        default:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path
                        d="M5.33399 5.83325H5.34065M1.33398 3.96658L1.33398 6.94959C1.33398 7.27572 1.33398 7.43878 1.37082 7.59223C1.40349 7.72828 1.45736 7.85833 1.53046 7.97763C1.61292 8.11219 1.72822 8.22749 1.95882 8.45809L7.07124 13.5705C7.86328 14.3625 8.25929 14.7586 8.71595 14.9069C9.11764 15.0375 9.55033 15.0375 9.95202 14.9069C10.4087 14.7586 10.8047 14.3625 11.5967 13.5705L13.0712 12.096C13.8633 11.304 14.2593 10.9079 14.4077 10.4513C14.5382 10.0496 14.5382 9.6169 14.4077 9.21522C14.2593 8.75856 13.8633 8.36254 13.0712 7.57051L7.95882 2.45809C7.72822 2.22749 7.61292 2.11219 7.47836 2.02973C7.35907 1.95663 7.22901 1.90275 7.09296 1.87009C6.93951 1.83325 6.77645 1.83325 6.45033 1.83325L3.46732 1.83325C2.72058 1.83325 2.34721 1.83325 2.062 1.97858C1.81111 2.10641 1.60714 2.31038 1.47931 2.56126C1.33398 2.84648 1.33398 3.21985 1.33398 3.96658ZM5.66732 5.83325C5.66732 6.01735 5.51808 6.16659 5.33399 6.16659C5.14989 6.16659 5.00065 6.01735 5.00065 5.83325C5.00065 5.64916 5.14989 5.49992 5.33399 5.49992C5.51808 5.49992 5.66732 5.64916 5.66732 5.83325Z"
                        stroke="#D1D1D1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
