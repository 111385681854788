import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import SwitchButton from "../../common/buttons/SwitchButton";
import * as Library from "../../../utils/Library";
import { defaultStatusColors, getOpenHoursSpan, getSpanWithColor } from "../../../utils/CardUtils";
import FFCsCustomExpandedComponent from "./FFCsCustomExpandedComponent";
import OrganizeFiltersHeader from "../../common/mainApp/mainWindowHeaders/OrganizeFiltersHeader";
import LayoutContext from "../../../LayoutContext";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

const listQueryKey = "FFCList";

export default function FFCManagement() {
    document.title = `${APP_TITLE} - FFCs Management`;

    /* #region VARS */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { setLayoutType } = useContext(LayoutContext);
    const navigate = useNavigate();
    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [onlyAffiliatedBrands, setOnlyAffiliatedBrands] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);
    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
    const [listData, setListData] = useState([]);
    const [expandAllRows, setExpandAllRows] = useState(false);
    const [currentRetailBrand, setCurrentRetailBrand] = useState(JSON.parse(localStorage.retailBrands)?.[0]);
    const [retailBrandDropdownData, setRetailBrandDropdownData] = useState([]);
    const [allStoresList, setAllStoresList] = useState([]);
    /* #endregion */

    /* #region METHODS */
    const onAcceptRequest = async (labelBrandID, storeID) => {
        const res = await Library.makeCommonPostRequest(
            "FulfillmentCenters",
            "createFulfillmentCenter",
            { labelBrandID, storeID },
            false,
            setLoadingAction
        );
        const data = res?.data;
        //if (data?.status !== 200) return false;
        //return true;
        return data;
    };

    const onDeleteRequest = async fulfillmenterCenterID => {
        const res = await Library.makeCommonPostRequest(
            "FulfillmentCenters",
            "deleteFulfillmentCenter",
            { fulfillmenterCenterID },
            false,
            setLoadingAction
        );
        const data = res?.data;
        if (data?.status !== 200) return false;

        return true;
    };

    const onChangeStatusRequest = async (fulfillmenterCenterID, status) => {
        const res = await Library.makeCommonPostRequest(
            "FulfillmentCenters",
            "updateFulfillmentCenterStatus",
            { fulfillmenterCenterID, status },
            false,
            setLoadingChangeStatus
        );
        const data = res?.data;
        if (data?.status !== 200) return false;
        return true;
    };

    const onClickRefreshAction = () => {
        AppQueryClient.invalidateQueries(listQueryKey);
    };

    const handleOnAcceptClick = async (row, isExpandedRow) => {
        const isParentRow = !isExpandedRow;

        const storeID = row?.parentID;
        const brandObjID = row?.labelBrandObjID;
        const fulfillmentCenterID = row?.fulfillmentCenterID;
        const statusBeforeChange = row?.status;
        let replaceFulfillmentCenterID = undefined;
        let fulfillmentCenterStatus = "Fulfilling";

        if (isParentRow) {
            navigate(`/stores/edit/${row?.ourID}`);
            return;
        } else {
            await changeListExpandedRowStatus(storeID, brandObjID, "Connecting", 0);

            if (statusBeforeChange !== "Suspended") {
                const data = await onAcceptRequest(brandObjID, storeID);
                const success = data?.status === 200;

                if (!success) {
                    await changeListExpandedRowStatus(storeID, brandObjID, "Not fulfilling", 0);
                    onClickRefreshAction();
                    return;
                }
                replaceFulfillmentCenterID = data?.data?.fulfillmenterCenterData?._id;
                fulfillmentCenterStatus = translateFFCStoreStatus(data?.data?.fulfillmenterCenterData?.status);
            } else {
                await changeListExpandedRowStatus(storeID, brandObjID, "Active", 0, false, fulfillmentCenterID);
                changeListExpandedRowStatus(storeID, brandObjID, "Fulfilling", 0);
            }

            const reducedInvitationsAvailable = statusBeforeChange !== "Suspended" ? -1 : 0;
            await changeListExpandedRowStatus(storeID, brandObjID, "Active", reducedInvitationsAvailable);
            updateActiveFFCs(true);
            changeListExpandedRowStatus(
                storeID,
                brandObjID,
                fulfillmentCenterStatus,
                0,
                true,
                undefined,
                replaceFulfillmentCenterID
            );
        }
    };

    const handleOnSuspendClick = async (row, isExpandedRow) => {
        const isParentRow = !isExpandedRow;

        const storeID = row?.parentID;
        const brandObjID = row?.labelBrandObjID;
        const fulfillmentCenterID = row?.fulfillmentCenterID;

        if (isParentRow) {
            return;
        } else {
            await changeListExpandedRowStatus(storeID, brandObjID, "Suspending", 0);

            await changeListExpandedRowStatus(storeID, brandObjID, "Suspended", 0, false, fulfillmentCenterID);
            updateActiveFFCs(false);
        }
    };

    const handleOnRemoveClick = async (row, isExpandedRow) => {
        const isParentRow = !isExpandedRow;

        const storeID = row?.parentID;
        const brandObjID = row?.labelBrandObjID;
        const fulfillmentCenterID = row?.fulfillmentCenterID;
        const statusBeforeChange = row?.status;

        if (isParentRow) {
            return;
        } else {
            setLoadingAction(true);

            await changeListExpandedRowStatus(storeID, brandObjID, "Removing", 0);

            const success = await onDeleteRequest(fulfillmentCenterID);

            if (!success) {
                await changeListExpandedRowStatus(storeID, brandObjID, "Not fulfilling", 0);
                onClickRefreshAction();
                return;
            }

            await changeListExpandedRowStatus(storeID, brandObjID, "Not affiliated", 1);
            if (statusBeforeChange !== "Suspended") updateActiveFFCs(false);
        }
    };

    const updateActiveFFCs = async isIncrement => {
        const activeFFCs = parseInt(localStorage.getItem("activeFFCenters") || 0);
        localStorage.setItem("activeFFCenters", isIncrement ? activeFFCs + 1 : activeFFCs - 1);
    };

    const changeListExpandedRowStatus = async (
        storeID,
        brandObjID,
        newStatus,
        incrementInvitationsAvailable = 0,
        changesOnlyLocal = true,
        fulfillmentCenterID,
        replaceFulfillmentCenterID = undefined
    ) => {
        if (!changesOnlyLocal) {
            if (!fulfillmentCenterID) {
                Library.showToastMessage({
                    type: "error",
                    title: "Change status error",
                    message: "Fulfillment Center ID is required to make the change"
                });
                onClickRefreshAction();
                return;
            }
            const success = await onChangeStatusRequest(fulfillmentCenterID, newStatus);
            if (!success) {
                onClickRefreshAction();
                return;
            }
        }

        const tmp = listData.map(parentRow => {
            let numBrands = 0;
            if (parentRow?._id === storeID) {
                parentRow.connectedBrands = parentRow.connectedBrands.map(brand => {
                    if (brand?.labelBrandObjID === brandObjID) {
                        brand.status = newStatus;
                        brand.invitationsAvailable += incrementInvitationsAvailable;
                        brand.numberOfFFCs = brand.numberOfFFCs - incrementInvitationsAvailable;
                        if (replaceFulfillmentCenterID) brand.fulfillmentCenterID = replaceFulfillmentCenterID;
                    }
                    return brand;
                });
            } else {
                parentRow.connectedBrands = parentRow.connectedBrands.map(brand => {
                    if (brand?.labelBrandObjID === brandObjID) {
                        brand.invitationsAvailable += incrementInvitationsAvailable;
                        brand.numberOfFFCs = brand.numberOfFFCs - incrementInvitationsAvailable;
                    }
                    return brand;
                });
            }

            parentRow.connectedBrands.forEach(brand => {
                numBrands += brand.status === "Active" || brand.status === "Fulfilling" ? 1 : 0;
            });

            parentRow.numBrands = numBrands;
            return parentRow;
        });

        //console.log("New List Data", tmp);

        setListData(tmp);
    };

    const expandAllRowsHandler = () => {
        setExpandAllRows(!expandAllRows);
    };
    /* #endregion */

    /* #region COMPUTED VALUES */
    const translateFFCStoreStatus = status => {
        if (!status) return "Not affiliated";
        switch (status) {
            case "Active":
                return "Fulfilling";
            case "Suspended":
                return "Suspended";
            case "Inactive":
                return "Not affiliated";
            default:
                return status;
        }
    };

    const isAcceptEnabled = row => {
        if (loadingAction || loadingChangeStatus) return false;
        return true;
    };

    const isSuspendEnabled = row => {
        if (loadingAction || loadingChangeStatus) return false;
        return false; //row?.POSstatus === "Connected";
    };

    const isRemovedEnabled = row => {
        if (loadingAction || loadingChangeStatus) return false;
        return false; //row?.POSstatus === "Connected";
    };

    const acceptButtonTitle = (row, isExpandedRow) => {
        const isParentRow = !isExpandedRow;
        if (isParentRow) return "Edit";
        if (row?.status === "Suspended") return "Resume";
        if (row?.invitationsAvailable === 0) return "Request more";
        return "Accept";
    };

    const suspendButtonTitle = (row, isExpandedRow) => {
        const isParentRow = !isExpandedRow;
        if (isParentRow) return "Suspend";
        return "Suspend";
    };

    const removeButtonTitle = (row, isExpandedRow) => {
        const isParentRow = !isExpandedRow;
        if (isParentRow) return "Remove";
        return "Remove";
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            currentRetailBrand,
            setCurrentRetailBrand,
            retailBrandDropdownData,
            allStoresList
        );
    }, [props?.layoutType, currentRetailBrand, retailBrandDropdownData]);

    useEffect(() => {
        const interval = setInterval(() => {
            onClickRefreshAction();
        }, 180000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLayoutType("List");

        const brands = JSON.parse(localStorage.retailBrands);

        const brandDropdownData = brands?.map(brand => {
            return {
                name: brand?.name,
                value: brand?._id
            };
        });
        setRetailBrandDropdownData(brandDropdownData);

        const activeStores = localStorage?.activeStores ?? "0";
        //TODO: Get all stores
        const allStores = [
            {
                name: `All Stores (${activeStores})`,
                value: "all",
                selected: true
            }
        ];

        setAllStoresList(allStores);
    }, []);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const defaultSortSettings = {
        sortable: true,
        sortFunction: (a, b) => a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
    };

    const listColumns = [
        {
            name: (
                <div className="flex align-items-center">
                    <ExpandButton
                        style={{
                            position: "absolute",
                            left: "-30px",
                            top: "13px",
                            cursor: "pointer",
                            paddingRigth: "20px"
                        }}
                        onClick={expandAllRowsHandler}
                        isExpanded={expandAllRows}
                    />
                    <span style={{ marginLeft: "20px" }}>{"Store Name"}</span>
                </div>
            ),
            selector: row => <span style={{ marginLeft: "20px" }}>{row?.name || "--"}</span>,
            width: "262px",
            ...defaultSortSettings
        },
        {
            name: "City, Country",
            selector: row =>
                !row?.city && !row?.country
                    ? "--"
                    : (row?.city || "--") + ", " + (row?.country || row?.countryValue || "--"),
            ...defaultSortSettings,
            width: "12%"
        },
        {
            name: "Pickup",
            selector: row => getSpanWithColor(row?.pickupEnabled, defaultStatusColors),
            width: "4.5%"
        },
        {
            name: "Courier",
            selector: row => getSpanWithColor(row?.courierEnabled, defaultStatusColors),
            width: "4.6%"
        },
        {
            name: "Shipping",
            selector: row => getSpanWithColor(row?.shippingEnabled, defaultStatusColors),
            width: "10.6%"
        },
        {
            name: "Open/Closed",
            selector: row => (row?.status === "Active" ? getOpenHoursSpan(row?.isOpen) : "--"),
            width: "9.9%"
        },
        {
            name: "Brands",
            selector: row => row?.numBrands ?? "--"
        },
        {
            name: "POS System",
            selector: row => row?.POSname || "--"
        },
        {
            name: "POS Status",
            selector: row => getSpanWithColor(row?.POSstatus, defaultStatusColors, "text-gray-100")
        }
    ];

    /* #endregion */

    return (
        <StyledPage>
            <ListComponent
                rightCardTopComponent={
                    <SwitchButton
                        className="flex justify-end w-100"
                        height="17px"
                        title="All Brands"
                        rightTitle="Affiliated Brands"
                        onToogleChanged={value => setOnlyAffiliatedBrands(value)}
                        alwaysGreen={true}
                    />
                }
                title="Fulfilment Centers"
                columns={listColumns}
                pageLimit={20}
                selectableRows={false}
                currentPage={1}
                queryKey={listQueryKey}
                isLoading={loadingAction || loadingChangeStatus}
                actions={{
                    includeActions: true,
                    editIsVisible: false,
                    suspendIsVisible: false,
                    removeIsVisible: false,
                    extraActions: [
                        {
                            title: (row, isExpandedRow) => acceptButtonTitle(row, isExpandedRow),
                            onClick: (row, isExpandedRow) => handleOnAcceptClick(row, isExpandedRow),
                            isDisabled: row => !isAcceptEnabled(row)
                        },
                        {
                            title: (row, isExpandedRow) => suspendButtonTitle(row, isExpandedRow),
                            onClick: (row, isExpandedRow) => handleOnSuspendClick(row, isExpandedRow),
                            isDisabled: row => !isSuspendEnabled(row)
                        },
                        {
                            title: (row, isExpandedRow) => removeButtonTitle(row, isExpandedRow),
                            onClick: (row, isExpandedRow) => handleOnRemoveClick(row, isExpandedRow),
                            isDisabled: row => !isRemovedEnabled(row)
                        }
                    ]
                }}
                expandableRows={true}
                //expandedRowsColumns={expandedRowsColumns}
                expandedRowName="connectedBrands"
                //set all rows expanded if expandAllRows is true
                expandableRowExpanded={row => expandAllRows}
                includeStatus={{
                    width: "150px",
                    stickyStatus: true,
                    statusFromPath: "status",
                    statusColors: defaultStatusColors
                }}
                api={{
                    endpoint: "getFulfillmentCentersManagementData",
                    formData: {
                        companyID: localStorage.getItem("currentCompanyID"),
                        retailBrandID: currentRetailBrand?._id,
                        onlyAffiliatedBrands
                    },
                    onSuccess: data => {
                        const tmp = data?.map((row, index) => {
                            const POSstatus = row?.POSstatus;
                            const POSconnected = POSstatus === "Connected";
                            const parentStatus = !POSconnected ? "Not fulfilling" : row?.status || "Not affiliated";

                            let numBrands = 0;

                            const result = {
                                ...row,
                                status: parentStatus,
                                POSstatus: POSstatus,
                                connectedBrands: row?.connectedBrands?.map(brand => {
                                    const status = !POSconnected
                                        ? "Not fulfilling"
                                        : translateFFCStoreStatus(brand?.fulfillmentCenterStatus);
                                    numBrands += status === "Active" || status === "Fulfilling" ? 1 : 0;

                                    return {
                                        ...brand,
                                        status: status,
                                        parentID: row?._id,
                                        parentName: row?.name,
                                        parentPOSstatus: POSstatus
                                    };
                                })
                            };

                            result.numBrands = numBrands;

                            return result;
                        });

                        setListData(tmp);
                        return tmp;
                    }
                }}
                mappedTempData={listData}
                customExpandedComponent={item =>
                    FFCsCustomExpandedComponent({
                        data: item?.data,
                        onClickRefreshAction,
                        handleOnAcceptClick,
                        handleOnSuspendClick,
                        handleOnRemoveClick
                    })
                }
            />
        </StyledPage>
    );
}

function setupHeaders(
    props,
    onRefreshAction,
    navigations,
    currentRetailBrand,
    setCurrentRetailBrand,
    retailBrandDropdownData,
    allStoresList
) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters", active: false, disabled: createMode, title: "AFFILIATIONS" },
        { to: "/ffcenters/management", active: true, disabled: false, title: "MANAGEMENT" },
        { to: "/stock", active: false, disabled: false, title: "STOCK" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/labelbrands/request", active: false, disabled: createMode, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <OrganizeFiltersHeader
                visible={true}
                gridEnabled={false}
                listEnabled={true}
                hideGridList={false}
                onRefreshAction={onRefreshAction}
                defaultViewOption="List"
                primaryOrganize={{
                    visible: true,
                    dropdownData: retailBrandDropdownData,
                    onDropdownChange: item => {
                        setCurrentRetailBrand({ _id: item?.value, name: item?.name });
                    },
                    selectedValue: currentRetailBrand?.name,
                    disabled: true
                }}
                extraOrganize={{
                    visible: true,
                    dropdownData: allStoresList,
                    //onDropdownChange: onStoreChange,
                    //selectedValue: filteredStores?.[0]?.name,
                    disabled: true
                }}
            />
        )
    });
}

const ExpandButton = props => {
    const { onClick, isExpanded = false, className = "", style = {} } = props;

    return (
        <div onClick={onClick} className={`expand-button ${className}`} style={style}>
            {!isExpanded ? (
                <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                    <path d="M0-.25h24v24H0z" fill="none"></path>
                </svg>
            ) : (
                <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                </svg>
            )}
        </div>
    );
};
