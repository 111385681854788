import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import LabelBrandsInvitationsGridCard from "../gridCards/LabelBrandsInvitationsGridCard";
import * as Library from "../../../utils/Library";
import moment from "moment";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/*  #endregion */

/**
 * Renders an infinite grid of brand cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {number} [props.limit=10] - The maximum number of cards to load per page.
 * @param {string} [props.queryKey] - The query key for fetching data.
 * @param {number} [props.minimumCardsFill] - The minimum number of cards to fill the grid.
 * @param {number} [props.minColumnWidth] - The minimum width of each grid column.
 * @param {Function} [props.onEditAction] - The callback function for the edit action.
 * @param {Function} [props.onDeleteAction] - The callback function for the delete action.
 * @param {Function} [props.onSuspendAction] - The callback function for the suspend action.
 * * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */

export default function InfinityGridLabelBrandsInvitationsCards(props) {
    const pageLimit = props?.limit || 10;

    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [queryKey] = useState(props?.queryKey || uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getLabelBrandInvites",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
            page: 1,
            limit: pageLimit
        },
        () => {
            setScroll(true);
        }
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages?.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() + 1);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    const formatIsoToDateTime = isoDate => {
        if (!isoDate) return "--";
        const res = moment(isoDate).format("DD/MM/YYYY HH:mm");
        if (res === "Invalid date") return "--";
        return res;
    };
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 8 }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="420.28px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            //console.log("item", item?.labelBrand?.logos?.mainLogoURL);
                            return (
                                <LabelBrandsInvitationsGridCard
                                    key={item?._id}
                                    image={item?.labelBrand?.logos?.mainLogoURL || ""}
                                    imageOverlay={item?.labelBrand?.logos?.mainLogoURL || ""}
                                    title={item?.retailBrand?.name || "--"}
                                    titlePrefix="TO RETAIL BRAND"
                                    id={item?.ourID ?? item?.invite?.ourID ?? "--"}
                                    isCardEnabled={true}
                                    isCardSuspended={false}
                                    data={{
                                        invitedBy: item?.labelBrand?.name || "--",
                                        invitedOn: formatIsoToDateTime(item?.createdAt) || "--",
                                        acceptedOn: item?.acceptedOn || "--",
                                        maxStores: item?.maxStores ?? "--",
                                        status: item?.status || "--"
                                    }}
                                    edit={{ hidden: true }}
                                    suspend={{ hidden: true }}
                                    delete={{
                                        onClick:
                                            item?.status === "Request" || item?.status === "Invited"
                                                ? undefined
                                                : props.onHideInvitation.bind(
                                                      this,
                                                      item?._id,
                                                      item?.labelBrand?.name,
                                                      item?.status === "Accepted"
                                                  )
                                    }}
                                    primaryButton={{
                                        title: "Decline",
                                        onClick:
                                            item?.status === "Request" || item?.status === "Invited"
                                                ? props.onDeclineInvitation.bind(
                                                      this,
                                                      item?._id,
                                                      item?.labelBrand?.name
                                                  )
                                                : undefined
                                    }}
                                    secondaryButton={{
                                        title: "Accept",
                                        onClick:
                                            item?.status === "Request" || item?.status === "Invited"
                                                ? props.onAcceptInvitation.bind(this, item?._id, item?.labelBrand?.name)
                                                : undefined
                                    }}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <LabelBrandsInvitationsGridCard
                                key={index}
                                isCardEnabled={false}
                                primaryButton={{ title: "Decline" }}
                                secondaryButton={{ title: "Accept" }}
                                titlePrefix="TO RETAIL BRAND"
                                edit={{ hidden: true }}
                                suspend={{ hidden: true }}
                                delete={{ hidden: false, active: false }}
                            />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} height="420.28px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
