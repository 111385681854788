import React, { useEffect, useState } from "react";
import CardComponentContainer from "../../../common/cardContainers/CardComponentContainer";
import FormField from "../../../common/forms/FormField";
import styled from "styled-components";
import { CheckboxWithContent } from "../StorePOSPage";
import fsPosThumb1 from "../../../../assets/images/fs-pos-thumb1.png";
import fsPosThumb2 from "../../../../assets/images/fs-pos-thumb2.png";
import fsPosThumb3 from "../../../../assets/images/fs-pos-thumb3.png";
import fsPosThumb4 from "../../../../assets/images/fs-pos-thumb4.png";
import smallLoading from "../../../../assets/images/smallLoading.gif";
import Checkbox from "../../../common/checkbox/Checkbox";
import { ReactComponent as InfoCircle } from "../../../../assets/icons/info-circle0.svg";
import { ReactComponent as LinkBroken } from "../../../../assets/icons/link-broken-01.svg";
import { ReactComponent as Hand } from "../../../../assets/icons/hand.svg";
import { ReactComponent as RefreshCCW } from "../../../../assets/icons/refresh-ccw-01.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check2.svg";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    &.frame-194,
    &.frame-194 * {
        box-sizing: border-box;
    }
    &.frame-194 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .stock-sharing-container .disabled-texts span {
        color: var(--text-icons-gray-300, #4f5b6d);
        cursor: not-allowed;
    }

    .frame-1942 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-189 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .dropdown-selector {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 393px;
        position: relative;
    }
    .frame-1000011201 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .btn-large-solid-inverted-01 {
        flex-shrink: 0;
        width: 150px;
        position: relative;
    }
`;

const FrontSystemsStyles = styled.div`
    &.frame-1000011200,
    &.frame-1000011200 * {
        box-sizing: border-box;
    }
    &.frame-1000011200 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011199 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 1;
        width: 100%;
        position: relative;
    }
    .pos-integration-checklists {
        color: #ffffff;
        text-align: left;
        font-size: 10px;
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .frame-1000011203 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        //height: 384px;
        position: relative;
    }
    .frame-1000011204 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        //height: 403.86px;
        position: relative;
    }
    .frame-313878 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 16px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
        backdrop-filter: blur(50px);
    }
    .frame-1000011208 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .group-1000011030 {
        flex-shrink: 0;
        width: 204.33px;
        height: 135.96px;
        position: static;
    }
    .frame-1000011190 {
        width: 204.33px;
        height: 131.76px;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .layout-3-i-pad {
        width: 136px;
        height: 205px;
        position: absolute;
        left: -0.5px;
        top: 135.95px;
        transform-origin: 0 0;
        transform: rotate(-90deg) scale(1, 1);
        backdrop-filter: blur(75px);
    }
    .screen {
        width: 89.25%;
        height: 92.24%;
        position: absolute;
        right: 5.38%;
        left: 5.38%;
        bottom: 3.88%;
        top: 3.88%;
        overflow: visible;
    }
    .i-pad-device-frame {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .frame-1000011191 {
        width: 24.86px;
        height: 8.41px;
        position: absolute;
        left: 89.64px;
        top: 127.55px;
    }
    .button-action {
        background: #232832;
        border-radius: var(--corner-radius-pill, 9999px);
        padding: 2px 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        box-shadow: 10px 18px 200px 0px rgba(0, 0, 0, 1);
    }
    .frame-313822 {
        display: flex;
        flex-direction: row;
        gap: var(--spacings-sm, 8px);
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .image-01 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313881 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 16px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
        backdrop-filter: blur(50px);
    }
    .group-10000110302 {
        flex-shrink: 0;
        width: 205.66px;
        height: 135.96px;
        position: static;
    }
    .frame-10000111902 {
        width: 205.66px;
        height: 131.76px;
        position: absolute;
        left: 58.17px;
        top: 16px;
    }
    .screen2 {
        width: 89.25%;
        height: 92.24%;
        position: absolute;
        right: 5.38%;
        left: 5.38%;
        bottom: 3.88%;
        top: 3.88%;
        overflow: visible;
    }
    .i-pad-device-frame2 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .frame-10000111912 {
        width: 25.02px;
        height: 8.41px;
        position: absolute;
        left: 148.4px;
        top: 143.55px;
    }
    .image-03 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-1000011205 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 403.89px;
        position: relative;
    }
    .frame-3138802 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 16px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
        backdrop-filter: blur(50px);
    }
    .group-10000110303 {
        flex-shrink: 0;
        width: 205.01px;
        height: 135.96px;
        position: static;
    }
    .frame-10000111903 {
        width: 205.01px;
        height: 131.76px;
        position: absolute;
        left: 58.5px;
        top: 16.01px;
    }
    .screen3 {
        width: 89.25%;
        height: 92.24%;
        position: absolute;
        right: 5.38%;
        left: 5.38%;
        bottom: 3.88%;
        top: 3.88%;
        overflow: visible;
    }
    .i-pad-device-frame3 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .frame-10000111913 {
        width: 24.94px;
        height: 8.41px;
        position: absolute;
        left: 148.44px;
        top: 143.56px;
    }
    .image-02 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313882 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 16px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
        backdrop-filter: blur(50px);
    }
    .layout-3-i-pad2 {
        flex-shrink: 0;
        width: 136px;
        height: 205px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(-90deg) scale(1, 1);
        backdrop-filter: blur(75px);
    }
    .screen4 {
        width: 89.25%;
        height: 92.24%;
        position: absolute;
        right: 5.38%;
        left: 5.38%;
        bottom: 3.88%;
        top: 3.88%;
        overflow: visible;
    }
    .i-pad-device-frame4 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .image-04 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .frame-1000011216 {
        //padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 9px;
        position: relative;
    }
    .stock-sharing {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 10px;
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
    }
`;

const StockSharingStyles = styled.div`
    &.input-field-card-layout-3,
    &.input-field-card-layout-3 * {
        box-sizing: border-box;
    }
    &.input-field-card-layout-3 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        //padding: 16px 0px 32px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011216 {
        //padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 9px;
        position: relative;
    }
    .stock-sharing {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 10px;
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
    }
    .frame-1000011217 {
        //padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011200 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313874 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 16px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: row;
        gap: 38px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
        backdrop-filter: blur(50px);
    }
    .frame-1000011221 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-1000011219 {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 16px;
        position: relative;
    }
    .checkbox {
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--text-and-icons-gray-200, #7c868b);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .frame-10000112172 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .info-circle3 {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .allow-label-brands-to-monitor-stock-in-real-time {
        color: #d1d1d1;
        text-align: left;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .allow-label-brands-to-monitor-stock-in-real-time.disabled {
        color: var(--text-icons-gray-300, #4f5b6d);
    }
    .frame-1000011220 {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 16px;
        position: relative;
    }
    .check2 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .frame-10000112173 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: start;
        flex: 1;
        position: relative;
    }
    .i-accept-the-terms-conditions {
        text-align: left;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        width: 325px;
    }
    .i-accept-the-terms-conditions-span {
        color: #d1d1d1;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
    }
    .i-accept-the-terms-conditions-span.disabled {
        color: var(--text-icons-gray-300, #4f5b6d);
    }

    .i-accept-the-terms-conditions-span2 {
        color: #d1d1d1 !important;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        text-decoration: underline;
        cursor: pointer !important;
    }
    .frame-1000011222 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .check3 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .info-circle4 {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .only-stock-of-items-featured-in-b-oimages {
        color: #d1d1d1;
        text-align: left;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        width: 300px;
    }
    .check4 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .info-circle5 {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .all-items-stock {
        color: #d1d1d1;
        text-align: left;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        width: 300px;
    }
    .frame-246 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .dropdown-selector2 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-494 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 8px;
        position: relative;
    }
    .info-circle6 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .chevron-down2 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-1000011207 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
        border-top: 1px solid #3c485a;
        margin-bottom: 24px;
    }

    .radio-button:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #10141b;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid var(--backgrounds-and-lines-n-100, #3c485a);
    }

    .radio-button:checked:after {
        background-color: #080;
        border: 2px solid var(--backgrounds-and-lines-n-100, #3c485a);
    }

    //radio-button disabled styles:
    .radio-button:disabled:after {
        background-color: #10141b;
        border: 1px solid var(--backgrounds-and-lines-n-100, #3c485a);
    }

    .radio-button:disabled:checked:after {
        background-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border: 1px solid var(--backgrounds-and-lines-n-100, #3c485a);
    }
`;

const StyledBtnLargeLoading = styled.div`
    &.btn-small-filled,
    &.btn-small-filled * {
        box-sizing: border-box;
    }
    &.btn-small-filled {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        //flex: 1;
        height: 37px;
        position: relative;
        width: 172px;
        cursor: pointer;
    }
    .frame-1000011209 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: hidden;
    }
    .b-ostages-load-connect-1 {
        width: 12.75px;
        height: 27.43px;
        position: absolute;
        left: 50%;
        translate: -50% -50%;
        top: 50%;
        object-fit: cover;
    }
    .connec-ting {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: left;
        font-size: var(--button-text-large-font-size, 12px);
        letter-spacing: var(--button-text-large-letter-spacing, 0.0125em);
        font-weight: var(--button-text-large-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;

const StyledBtnIcon = styled.div`
    &.frame-29,
    &.frame-29 * {
        box-sizing: border-box;
    }
    &.frame-29 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 37px;
        position: relative;
    }
    .frame-1000011211 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 16px;
        position: relative;
        //transform-origin: 0 0;
        //transform: rotate(0deg) scale(1, -1);
        position: relative;
    }
    .hand {
        flex: 1;
        height: 16px;
        position: relative;
        overflow: visible;
    }
`;
/* #endregion */

export default function StorePOSIntegration({
    selectedPOS,
    selectedPOSName,
    isStoreIDMandatory,
    onConnectClick,
    setShowTAC,
    onIgnoreClick,
    posIsConnecting,
    posIsConnected,
    posConnectFailed,
    setPosIsConnected,
    onThumbnailClick,
    connectFields,
    stockSharingSelected,
    setStockSharingSelected,
    onDisconnectClicked
}) {
    /* #region STATES */
    const [posNumOfSteps, setPosNumOfSteps] = useState(null);
    const [posCurrentStep, setPosCurrentStep] = useState(null);
    const [posSteps, setPosSteps] = useState([]); // example: { isChecked: false, inputText: "" }
    const [dropDownData] = useState([
        { name: "Front Systems", value: "6537dc8613db5d57a8cc5815" },
        { name: "Traze", value: "653122e4a2673ea91c967c5b" },
        { name: "Other", value: "other" }
    ]);
    const [posStoreID, setPosStoreID] = useState(null);
    //const [stockSharingSelected, setStockSharingSelected] = useState([false, false, true, false]);
    const [stockTermsDisabled, setStockTermsDisabled] = useState(true);

    //const [posIsConnecting, setPosIsConnecting] = useState(false);
    //const [posIsConnected, setPosIsConnected] = useState(false);
    //const [posConnectFailed, setPosConnectFailed] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (selectedPOS) {
            onSelectedPOSChange(selectedPOS);
        }
    }, [selectedPOS]);

    useEffect(() => {
        if (!stockSharingSelected?.[0]) {
            setStockTermsDisabled(true);
            if (stockSharingSelected?.[1]) {
                const prevStockSharingSelected = [...stockSharingSelected];
                prevStockSharingSelected[1] = false;
                setStockSharingSelected(prevStockSharingSelected);
            }
        } else {
            setStockTermsDisabled(false);
        }
    }, [stockSharingSelected]);

    useEffect(() => {
        if (!connectFields) return;

        if (connectFields?.StoreId) {
            setPosStoreID(connectFields?.StoreId);

            //mark pos steps as checked
            const steps = [];

            steps[0] = { isChecked: true, inputText: "" };
            steps[1] = { isChecked: true, inputText: "" };
            steps[2] = { isChecked: true, inputText: "" };
            steps[3] = { isChecked: true, inputText: "" };
            steps[4] = { isChecked: true, inputText: "" };
            setPosNumOfSteps(5);
            setPosCurrentStep(5);
            setPosSteps(steps);
        }
    }, [connectFields]);
    /* #endregion */

    /* #region METHODS */
    const onSelectedPOSChange = posID => {
        if (posID === "6537dc8613db5d57a8cc5815") {
            setPosNumOfSteps(5);
            setPosCurrentStep(0);
            setPosSteps([]);
        } else {
            setPosNumOfSteps(null);
            setPosCurrentStep(null);
            setPosSteps([]);
        }
    };

    const checkboxIsDisabled = stepNum => {
        if (posNumOfSteps === null) return true;
        if (posCurrentStep < stepNum) return true;
        else if (posCurrentStep === stepNum) return false;
        else return false;
    };

    const checkboxIsChecked = stepNum => {
        if (posNumOfSteps === null) return false;
        if (posCurrentStep < stepNum) return false;
        else if (posCurrentStep === stepNum) return posSteps?.[stepNum]?.isChecked;
        else return true;
    };

    const onCheckboxClick = (stepClickNum, inputRef) => {
        if (posNumOfSteps === null) return;

        if (posCurrentStep === stepClickNum) {
            onCheckboxSelected(stepClickNum, inputRef);
        }

        if (posCurrentStep > stepClickNum) {
            const newStep = { isChecked: false, inputText: posSteps?.[stepClickNum]?.inputText };

            const newSteps = posSteps.map((step, index) => {
                if (index < stepClickNum) return step;
                else if (index === stepClickNum) return newStep;
                else return { isChecked: false, inputText: step?.inputText };
            });
            setPosSteps(newSteps);
            setPosCurrentStep(stepClickNum);
        }
    };

    const onCheckboxSelected = (stepClickNum, inputRef) => {
        let inputText = "";
        if (inputRef?.isValid && inputRef?.getValue) {
            const isvalid = inputRef?.isValid(true);
            const value = inputRef?.getValue();
            if (!isvalid) return;
            inputText = value;
        }

        if (posCurrentStep === stepClickNum) {
            if (!posSteps?.[stepClickNum]) {
                const newStep = { isChecked: true, inputText: inputText };
                setPosSteps([...posSteps, newStep]);
            } else {
                const allSteps = posSteps.map((step, index) => {
                    if (index === stepClickNum) return { isChecked: true, inputText: inputText };
                    else return step;
                });
                setPosSteps(allSteps);
            }

            setPosCurrentStep(stepClickNum + 1);
        }
    };

    const allCheckboxesAreChecked = () => {
        if (posNumOfSteps === null) return false;
        if (posCurrentStep < posNumOfSteps) return false;

        for (let i = 0; i < posNumOfSteps; i++) {
            if (!posSteps[i]?.isChecked) return false;
        }

        return true;
    };

    const isConnectEnabled = () => {
        if (!posStoreID) return false;
        if (!allCheckboxesAreChecked()) return false;
        //if (isUserOnboarding && !saveButtonEnabled) return false;
        if (stockSharingSelected[0] !== stockSharingSelected[1]) return false;
        return true;
    };

    const onStockSharingClick = index => {
        const newStockSharingSelected = [...stockSharingSelected];
        newStockSharingSelected[index] = !newStockSharingSelected[index];

        if (index === 2) {
            newStockSharingSelected[3] = false;
        }
        if (index === 3) {
            newStockSharingSelected[2] = false;
        }
        setStockSharingSelected(newStockSharingSelected);
    };

    const handleConnectClick = () => {
        onConnectClick({ posStoreID, stockSharingSelected });
    };
    /* #endregion */

    return (
        <StyledPage>
            <CardComponentContainer title="STOCK SHARING">
                <StockSharingStyles className="input-field-card-layout-3">
                    <div className="frame-1000011217 stock-sharing-container mt-16px">
                        <div className="frame-1000011200">
                            <div className="frame-313874">
                                <div className="frame-1000011221">
                                    <div className="frame-1000011219">
                                        <Checkbox
                                            className="checkbox"
                                            isChecked={stockSharingSelected[0]}
                                            onCheckboxClick={() => onStockSharingClick(0)}
                                        />
                                        <div
                                            className={`frame-10000112172 ${
                                                stockTermsDisabled ? "disabled-texts" : ""
                                            }`}>
                                            <InfoCircle
                                                className="info-circle3"
                                                src="info-circle3.svg"
                                                style={{ color: "#d1d1d1" }}
                                            />
                                            <div className="allow-label-brands-to-monitor-stock-in-real-time">
                                                Allow Label Brands to monitor stock in real-time.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frame-1000011220">
                                        <Checkbox
                                            className="checkbox"
                                            isChecked={stockSharingSelected[1]}
                                            disabled={stockTermsDisabled}
                                            onCheckboxClick={() => onStockSharingClick(1)}
                                        />
                                        <div
                                            className={`frame-10000112173 ${
                                                stockTermsDisabled ? "disabled-texts" : ""
                                            }`}>
                                            <div className="i-accept-the-terms-conditions">
                                                <span>
                                                    <span className="i-accept-the-terms-conditions-span">
                                                        I accept the{" "}
                                                    </span>
                                                    <span
                                                        className="i-accept-the-terms-conditions-span2 cursor-pointer"
                                                        onClick={() => setShowTAC(true)}>
                                                        Terms &amp; Conditions
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-1000011222">
                                    <div className="frame-1000011219">
                                        <input
                                            type="radio"
                                            id="allItemsStock"
                                            name="stockSharing"
                                            //value="allItemsStock"
                                            className="radio-button"
                                            //disabled={stockSharingSelected[1] ? false : true}
                                            disabled={!stockSharingSelected[1]}
                                            checked={stockSharingSelected[2]}
                                            onClick={() => onStockSharingClick(2)}
                                        />
                                        <div
                                            className={`frame-10000112172 ${
                                                stockSharingSelected[1] ? "" : "disabled-texts"
                                            }`}>
                                            <InfoCircle
                                                className="info-circle4"
                                                src="info-circle3.svg"
                                                style={{
                                                    color: stockSharingSelected[1] ? "#d1d1d1" : "#4F5B6D"
                                                }}
                                            />
                                            <span className="only-stock-of-items-featured-in-b-oimages">
                                                Only stock of items featured in BOimages
                                            </span>
                                        </div>
                                    </div>
                                    <div className="frame-1000011220">
                                        <input
                                            type="radio"
                                            id="allItemsStock"
                                            name="stockSharing"
                                            //value="allItemsStock"
                                            className="radio-button"
                                            disabled={!stockSharingSelected[1]}
                                            checked={stockSharingSelected[3]}
                                            onClick={() => onStockSharingClick(3)}
                                        />
                                        <div
                                            className={`frame-10000112172 ${
                                                stockSharingSelected[1] ? "" : "disabled-texts"
                                            }`}>
                                            <InfoCircle
                                                className="info-circle4"
                                                src="info-circle3.svg"
                                                style={{
                                                    color: stockSharingSelected[1] ? "#d1d1d1" : "#4F5B6D"
                                                }}
                                            />
                                            <span className="all-items-stock">All items stock</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-246">
                            <FormField
                                title="Select Label Brands"
                                type="dropdown"
                                className="dropdown-selector2"
                                tooltip="Select Label Brands"
                                dropdownData={[{ name: "All", value: "all", selected: true }]}
                                varName="selectLabelBrands"
                                style={{ width: "100%", flexShrink: 0 }}
                                requiredType="locked"
                            />
                        </div>
                    </div>
                </StockSharingStyles>
            </CardComponentContainer>

            <CardComponentContainer title="POS INTEGRATION">
                <div className="frame-194" style={{ marginTop: "16px" }}>
                    <div className="frame-1942">
                        <div className="frame-189">
                            <FormField
                                title="POS"
                                className="dropdown-selector"
                                type="dropdown"
                                tooltip="Select the POS system you are using."
                                dropdownData={dropDownData}
                                varName="dropdownSingleItem"
                                isDropdownMultiselect={false}
                                //ref={el => (formFields1Refs.dropdownSingleItem = el)}
                                style={{ width: "393px", flexShrink: 0 }}
                                onDropdownChange={onSelectedPOSChange}
                                value={selectedPOSName}
                                requiredType="locked"
                            />

                            <FormField
                                title="Store ID"
                                className="frame-1000011201"
                                type="text"
                                placeholder="Insert store ID, check image 1"
                                requiredType={posIsConnected ? "locked" : isStoreIDMandatory ? "mandatory" : "simple"}
                                onTextChange={text => setPosStoreID(text)}
                                value={connectFields?.StoreId}
                            />

                            <div className="frame-new" style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                                <BtnLargeSolidWithLoading
                                    //text="CONNECT"
                                    isLoading={posIsConnecting}
                                    isConnected={posIsConnected}
                                    isConnectFailed={posConnectFailed}
                                    onClick={handleConnectClick}
                                    style={{ marginTop: "21.14px", marginRight: "4px" }}
                                    disabled={!isConnectEnabled()}
                                />

                                {posIsConnected && (
                                    <BtnIcon
                                        svg={<LinkBroken />}
                                        style={{ marginTop: "21.14px", color: "#FF3636" }}
                                        onClick={onDisconnectClicked}
                                    />
                                )}

                                {(posIsConnecting || posConnectFailed) && (
                                    <BtnIcon
                                        svg={<Hand />}
                                        style={{ marginTop: "21.14px", color: "#FF9900" }}
                                        //disabled={!posIsConnecting || (posConnectFailed && !posIsConnecting)}
                                        disabled={true}
                                        onClick={onIgnoreClick}
                                    />
                                )}

                                {(posIsConnecting || posIsConnected || posConnectFailed) && (
                                    <BtnIcon
                                        svg={<RefreshCCW />}
                                        style={{ marginTop: "21.14px", color: "#00AEEF" }}
                                        disabled={!posConnectFailed || !isConnectEnabled()}
                                        onClick={handleConnectClick}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <StockSharingStyles className=" mt-24px mb-24px">
                    <div className="frame-1000011216">
                        <div className="frame-144">
                            <div className="stock-sharing">POS INTEGRATION CHECKLISTS</div>
                            <div className="line-1"></div>
                        </div>
                    </div>
                </StockSharingStyles>

                <FrontSystemsStyles className="frame-1000011200">
                    <div className="frame-1000011199">
                        {(selectedPOS === "other" || selectedPOS === "653122e4a2673ea91c967c5b") && (
                            <div className="flex flex-col gap-16px" style={{ alignItems: "normal" }}>
                                Not Integrated
                            </div>
                        )}

                        {selectedPOS && selectedPOS === "6537dc8613db5d57a8cc5815" && (
                            <div className="w-100 flex flex-column gap-16px">
                                <CheckboxWithContent
                                    description="This store is using Front Systems POS iPad app version (not the web version)."
                                    disabled={checkboxIsDisabled(0) || posIsConnected || !posStoreID}
                                    isChecked={checkboxIsChecked(0)}
                                    onCheckboxClick={inputRef => onCheckboxClick(0, inputRef)}
                                    isCurrentStep={posCurrentStep === 0}
                                    //useSelectedBorder={isUserOnboarding}
                                />

                                <CheckboxWithContent
                                    description="The Omnichannel feature for this store, is turned ON (image 02)."
                                    disabled={checkboxIsDisabled(1) || posIsConnected || !posStoreID}
                                    isChecked={checkboxIsChecked(1)}
                                    onCheckboxClick={inputRef => onCheckboxClick(1, inputRef)}
                                    isCurrentStep={posCurrentStep === 1}
                                    //useSelectedBorder={isUserOnboarding}
                                />

                                <CheckboxWithContent
                                    description="The printer options to print BOtag Courier and Shipping labels for this store are enabled (image 03)."
                                    disabled={checkboxIsDisabled(2) || posIsConnected || !posStoreID}
                                    isChecked={checkboxIsChecked(2)}
                                    onCheckboxClick={inputRef => onCheckboxClick(2, inputRef)}
                                    isCurrentStep={posCurrentStep === 2}
                                    //useSelectedBorder={isUserOnboarding}
                                />

                                <CheckboxWithContent
                                    description="The ‘POS and Online’ option, on ‘Configure refund for omnichannel’ is selected for this store."
                                    disabled={checkboxIsDisabled(3) || posIsConnected || !posStoreID}
                                    isChecked={checkboxIsChecked(3)}
                                    onCheckboxClick={inputRef => onCheckboxClick(3, inputRef)}
                                    isCurrentStep={posCurrentStep === 3}
                                    //useSelectedBorder={isUserOnboarding}
                                />

                                <CheckboxWithContent
                                    description="The Store External ID is configured for this store. <br />You can create the Store External ID yourself. In can be any text you want (ex: a name). It just can't be left blank (image 04)."
                                    disabled={checkboxIsDisabled(4) || posIsConnected || !posStoreID}
                                    isChecked={checkboxIsChecked(4)}
                                    onCheckboxClick={inputRef => onCheckboxClick(4, inputRef)}
                                    isCurrentStep={posCurrentStep === 4}
                                    //useSelectedBorder={isUserOnboarding}
                                />
                            </div>
                        )}
                    </div>
                    <div className="frame-1000011203">
                        <div className="frame-1000011204">
                            <div
                                className="frame-313878 cursor-pointer"
                                onClick={() => onThumbnailClick(1)}
                                style={{ height: "209px" }}>
                                <div className="frame-1000011208">
                                    <img className="group-1000011030" src={fsPosThumb1} alt="Front Systems POS 1" />

                                    <div className="button-action">
                                        <div className="frame-313822">
                                            <div className="frame-313497">
                                                <div className="frame-313349">
                                                    <div className="image-01">Image 01</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="frame-313881 cursor-pointer"
                                onClick={() => onThumbnailClick(2)}
                                style={{ height: "209px" }}>
                                <img className="group-10000110302" src={fsPosThumb2} alt="Front Systems POS 2" />

                                <div className="button-action">
                                    <div className="frame-313822">
                                        <div className="frame-313497">
                                            <div className="frame-313349">
                                                <div className="image-03">Image 02</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-1000011205">
                            <div
                                className="frame-3138802 cursor-pointer"
                                onClick={() => onThumbnailClick(3)}
                                style={{ height: "209px" }}>
                                <img className="group-10000110303" src={fsPosThumb3} alt="Front Systems POS 3" />

                                <div className="button-action">
                                    <div className="frame-313822">
                                        <div className="frame-313497">
                                            <div className="frame-313349">
                                                <div className="image-02">Image 03</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="frame-313882 cursor-pointer"
                                onClick={() => onThumbnailClick(4)}
                                style={{ height: "209px" }}>
                                <img className="group-10000110303" src={fsPosThumb4} alt="Front Systems POS 4" />

                                <div className="button-action">
                                    <div className="frame-313822">
                                        <div className="frame-313497">
                                            <div className="frame-313349">
                                                <div className="image-04">Image 04</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FrontSystemsStyles>
            </CardComponentContainer>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
const BtnLargeSolidWithLoading = ({
    text,
    isLoading = false,
    isConnected = false,
    isConnectFailed,
    onClick,
    disabled = false,
    style = {}
}) => {
    const computedText = isLoading
        ? "CONNECTING..."
        : isConnected
        ? "CONNECTED"
        : isConnectFailed
        ? "COULD NOT CONNECT"
        : "CONNECT";
    const computedColor = isLoading
        ? "#00AEFF"
        : isConnected
        ? "#00BB00"
        : isConnectFailed
        ? "#FF3636"
        : disabled
        ? "#4F5B6D"
        : "#FFFFFF";
    const computedBackground = isLoading || isConnected || isConnectFailed || disabled ? "#10141B" : "#D37E00";
    const computedStyle = {
        ...style,
        background: computedBackground,
        cursor: disabled || isConnected ? "not-allowed" : "pointer"
    };

    return (
        <StyledBtnLargeLoading
            className="btn-small-filled"
            style={computedStyle}
            onClick={disabled || isConnected ? null : onClick}>
            <div className={`frame-1000011209 ${!isLoading && !isConnected && !isConnectFailed ? "d-none" : ""}`}>
                {isLoading ? (
                    <img className="b-ostages-load-connect-1" src={smallLoading} alt="Loading" />
                ) : isConnectFailed ? (
                    <AlertCircle width="16px" height="16px" style={{ color: "#FF3636" }} />
                ) : (
                    isConnected && <Check width="16px" height="16px" />
                )}
            </div>
            <div className="connec-ting" style={{ color: computedColor }}>
                {computedText}
            </div>
        </StyledBtnLargeLoading>
    );
};

const BtnIcon = ({ src, svg, disabled = false, onClick, style = {} }) => {
    const computedStyle = disabled
        ? { ...style, color: "#4F5B6D", cursor: "not-allowed" }
        : { ...style, cursor: "pointer" };

    return (
        <StyledBtnIcon className="frame-29" onClick={disabled ? null : onClick} style={computedStyle}>
            <div className="frame-1000011211">
                {src && <img className="hand" src={src} alt="Icon" />}
                {svg}
            </div>
        </StyledBtnIcon>
    );
};
/* #endregion */
