import React, { useContext, useEffect, useState } from "react";
import InfinityGridLabelBrandsInvitationsCards from "../../common/cardContainers/InfinityGridLabelBrandsInvitationsCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import * as Library from "../../../utils/Library";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { AppQueryClient } from "../../../api/queryClient";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import Checkbox from "../../common/checkbox/Checkbox";
import { defaultStatusColors } from "../../../utils/CardUtils";
import PopupContext from "../../../PopupContext";
import AppContext from "../../../AppContext";
import ProductFiltersHeader from "../../common/mainApp/mainWindowHeaders/ProductFiltersHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const DialogLayoutStyled = styled.div`
    .frame-258,
    .frame-258 * {
        box-sizing: border-box;
    }
    .frame-258 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin-bottom: 32px;
    }
    .frame-285 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-286 {
        padding: 0px 32px 0px 32px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .declining-invitation {
        color: #ffffff;
        text-align: center;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        flex: 1;
    }
    .please-confirm-you-want-to-decline-the-product-invitation {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .do-you-mind-sharing-the-reason {
        color: #ffffff;
        text-align: left;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 14px;
        letter-spacing: 0.0025em;
        font-weight: 700;
        position: relative;
        align-self: stretch;
    }
    .frame-1000011146 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .check2 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .check3 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .check4 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .simple {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 76px;
        position: relative;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        padding: 10px 12px 10px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        border-style: none;
        //noresize?
        resize: none;
    }
    .frame-48.disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }

    .please-specify {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Italic", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        font-style: var(--subtitle-2-font-style, italic);
        position: relative;
        flex: 1;
    }
`;
/* #endregion */

export default function LabelBrandsInvitationsPage() {
    document.title = `${APP_TITLE} - Label Brands Invitations`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const navigate = useNavigate();

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    const { setRefreshUserData } = useContext(AppContext);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoading, setIsLoading] = useState(false);

    const infiniteGridQuerykey = "LabelBrandsInvitationsGrid";
    const listQueryKey = "LabelBrandsInvitationsList";

    const [showInvitationAccept, setShowInvitationAccept] = useState({
        show: false,
        brandName: ""
    });
    const [showInvitationAccepted, setShowInvitationAccepted] = useState(false);
    const [showInvitationDecline, setShowInvitationDecline] = useState({
        show: false,
        brandName: ""
    });
    const [showInvitationHide, setShowInvitationHide] = useState({
        show: false,
        brandName: "",
        wasAccepted: false
    });

    const [currentInviteID, setCurrentInviteID] = useState("");
    const [reasonObj, setReasonObj] = useState({
        reason1: false,
        reason2: false,
        reason3: false,
        reason4: false,
        otherReasonText: ""
    });
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, navigations, onClickRefreshAction);
    }, [props?.layoutType]);

    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "To Retail Brand",
            selector: row => row?.retailBrand?.name ?? "--"
        },
        {
            name: "BOTAG IG",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Invited on",
            selector: row => formatDefaultDateTime(row?.createdAt) ?? "--"
        },
        {
            name: "Accepted on",
            selector: row => formatDefaultDateTime(row?.invitedByName) ?? "--"
        },
        {
            name: "Max Stores",
            selector: row => row?.maxStores ?? "All"
        }
    ];
    /* #endregion */

    /* #region METHODS */

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const handleAcceptInvitation = async (inviteID, brandName) => {
        setCurrentInviteID(inviteID);
        setShowInvitationAccept({
            show: true,
            brandName: brandName
        });
    };

    const handleAcceptInvitationRequest = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "acceptRetailerInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        ); //TODO: THIS IS THROWING AN ERROR: Invite type 'Retailer' not allowed for this operation

        if (res?.data?.status === 200) {
            /*const page = verifyUserIsOnboarding(navigations?.navigationAccesses);
            const isOnboarding = page !== APP_HOMEPAGE_ROUTE;*/

            const currentActiveConnections = parseInt(localStorage.getItem("activeConnectedBrands")) || 0;
            localStorage.setItem("activeConnectedBrands", currentActiveConnections + 1);

            setShowInvitationAccepted(true);
            onClickRefreshAction();
        }
    };

    const handleDeclineInvitation = async (inviteID, brandName) => {
        setCurrentInviteID(inviteID);
        setShowInvitationDecline({
            show: true,
            brandName: brandName
        });
    };

    const handleDeclineInvitationRequest = async (inviteID, declineReason) => {
        setShowInvitationDecline({
            show: false,
            brandName: ""
        });

        const res = await Library.makeCommonPostRequest(
            "Invites",
            "declineRetailerInvite",
            {
                inviteID: inviteID,
                declineReason: declineReason,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation successfully declined!"
            });

            onCloseDeclineInvitation();

            onClickRefreshAction();
        }
    };

    const onCloseDeclineInvitation = () => {
        setShowInvitationDecline({
            show: false,
            brandName: ""
        });
        setCurrentInviteID("");
        setReasonObj({
            reason1: false,
            reason2: false,
            reason3: false,
            reason4: false,
            otherReasonText: ""
        });
    };

    const handleHideInvitation = async (inviteID, brandName, wasAccepted) => {
        setCurrentInviteID(inviteID);
        setShowInvitationHide({
            show: true,
            brandName: brandName,
            wasAccepted: wasAccepted
        });
    };

    const hideInvitationRequest = async inviteID => {
        const BOstagesAccountID = localStorage.getItem("BOstagesAccountID");

        const res = await Library.makeCommonPostRequest(
            "Invites",
            "hideInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: BOstagesAccountID
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation successfully removed!"
            });

            setShowInvitationHide({
                show: false,
                brandName: "",
                wasAccepted: false
            });
            setCurrentInviteID("");

            onClickRefreshAction();
        }
    };

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={showInvitationAccept.show}>
                <DialogBoxComponent
                    title="Ready to accept?"
                    message={`Are you ready to accept the invite from ${showInvitationAccept.brandName}?`}
                    showCancel={true}
                    confirmText="YES"
                    cancelText="NO"
                    heading="ACCEPTING..."
                    headingColor="var(--text-icons-green-500)"
                    onClickYes={() => {
                        setShowInvitationAccept({ show: false, brandName: "" });
                        handleAcceptInvitationRequest(currentInviteID);
                    }}
                    onClickNo={() => {
                        setShowInvitationAccept({ show: false, brandName: "" });
                        setCurrentInviteID("");
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showInvitationAccepted}>
                <DialogBoxComponent
                    title="Invitation accepted!"
                    message="You can now proceed with affiliating your stores to this brand."
                    showCancel={false}
                    confirmText="CONTINUE"
                    heading="SUCCESS!"
                    headingColor="var(--text-icons-green-500)"
                    onClickYes={() => {
                        setShowInvitationAccepted(false);
                        //navigate("/ffcenters/management");
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showInvitationDecline.show}>
                <DialogBoxComponent
                    title="Declining invitation?"
                    //message="You can now proceed with affiliating your stores to this brand."
                    useAlternativeLayout={
                        <DeclineReasonLayout
                            reasonObj={reasonObj}
                            setReasonObj={setReasonObj}
                            brandName={showInvitationDecline.brandName}
                        />
                    }
                    confirmText="CONFIRM"
                    cancelText="CANCEL"
                    heading="DECLINING..."
                    headingColor="var(--text-icons-red-500)"
                    onClickYes={() => {
                        if (
                            (!reasonObj.reason1 && !reasonObj.reason2 && !reasonObj.reason3 && !reasonObj.reason4) ||
                            (reasonObj.reason4 && reasonObj.otherReasonText === "")
                        ) {
                            Library.showToastMessage({
                                type: "error",
                                title: "Please specify the reason for declining the invitation."
                            });
                            return;
                        }

                        let declineReason = "";
                        if (reasonObj.reason1) declineReason = "I don’t know this brand";
                        if (reasonObj.reason2) declineReason = "I don’t want to work with this brand";
                        if (reasonObj.reason3) declineReason = "I’ll stop working with this brand";
                        if (reasonObj.reason4) declineReason = reasonObj.otherReasonText;

                        if (reasonObj.reason4 && reasonObj.otherReasonText === "") {
                            Library.showToastMessage({
                                type: "error",
                                title: "Please specify the reason for declining the invitation."
                            });
                            return;
                        }
                        const inviteID = currentInviteID;
                        handleDeclineInvitationRequest(inviteID, declineReason);
                    }}
                    onClickNo={() => {
                        onCloseDeclineInvitation();
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showInvitationHide.show}>
                <DialogBoxComponent
                    title="Ready to remove?"
                    message={
                        showInvitationHide?.wasAccepted
                            ? `Do you want to remove ${showInvitationHide?.brandName} invitation card? This will not remove the connection with Label Brand. You can find this Label Brand in the Label Brands menu, selecting CONNECTIONS in the control bar.`
                            : `Do you want to remove ${showInvitationHide?.brandName} invitation card?`
                    }
                    showCancel={true}
                    confirmText="CONFIRM"
                    cancelText="CANCEL"
                    heading="ATTENTION"
                    headingColor="var(--text-icons-red-500)"
                    onClickYes={() => {
                        hideInvitationRequest(currentInviteID);
                    }}
                    onClickNo={() => {
                        setShowInvitationHide({
                            show: false,
                            brandName: "",
                            wasAccepted: false
                        });
                        setCurrentInviteID("");
                    }}
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridLabelBrandsInvitationsCards
                    //minColumnWidth="370px"
                    minColumnWidth="238px"
                    limit="8"
                    queryKey={infiniteGridQuerykey}
                    // statusFromPath="status"
                    onAcceptInvitation={handleAcceptInvitation}
                    onDeclineInvitation={handleDeclineInvitation}
                    onHideInvitation={handleHideInvitation}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="BRANDS"
                    //title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    api={{
                        endpoint: "getLabelBrandInvites",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                            page: 1,
                            limit: 10
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={10}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: true,
                        onClickRemove: row => {
                            if (row?.status === "Request" || row?.status === "Invited") return undefined;
                            handleHideInvitation(row?._id, row?.labelBrand?.name, row?.status === "Accepted");
                        },
                        removeIsDisabled: row => {
                            return row?.status === "Request" || row?.status === "Invited";
                        },
                        extraActions: [
                            {
                                title: "Decline",
                                onClick: row => {
                                    if (row?.status !== "Request" && row?.status !== "Invited") return undefined;
                                    handleDeclineInvitation(row?._id, row?.labelBrand?.name);
                                },
                                isDisabled: row => {
                                    return row?.status !== "Request" && row?.status !== "Invited";
                                }
                            },
                            {
                                title: "Accept",
                                onClick: row => {
                                    if (row?.status !== "Request" && row?.status !== "Invited") return undefined;
                                    handleAcceptInvitation(row?._id, row?.labelBrand?.name);
                                },
                                isDisabled: row => {
                                    return row?.status !== "Request" && row?.status !== "Invited";
                                }
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, navigations, onClickRefreshAction) {
    const createMode = navigations?.navigationAccesses?.labelbrands?.createModeOnly;

    props.setControlBarLeftBtns([
        //{ to: "#", disabled: true, title: "Label Brands", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([
        { to: "/labelbrands/connections", active: false, disabled: createMode, title: "CONNECTIONS" },
        { to: "/labelbrands/invitations", active: true, disabled: false, title: "INVITATIONS" },
        { to: "/labelbrands/requests", active: false, disabled: false, title: "REQUESTS" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/labelbrands/request", active: false, disabled: false, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <ProductFiltersHeader
                visible={true}
                gridEnabled={true}
                listEnabled={true}
                onRefreshAction={onClickRefreshAction}
                searchBoxPlaceholder="Search"
            />
        )
    });
}

const DeclineReasonLayout = props => {
    const { reasonObj, setReasonObj, brandName } = props;

    const otherReasonRef = React.createRef();

    useEffect(() => {
        if (reasonObj.reason4) {
            otherReasonRef.current.focus();
        }
    }, [reasonObj.reason4]);

    return (
        <DialogLayoutStyled className="DialogLayout">
            <div className="frame-258">
                <div className="frame-285">
                    <div className="frame-160">
                        <div className="please-confirm-you-want-to-decline-the-product-invitation">
                            Please confirm you want to decline {brandName} invitation.
                            <br />
                        </div>
                        <div className="do-you-mind-sharing-the-reason">Do you mind sharing the reason?</div>
                        <div className="frame-1000011146">
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason1}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: !reasonObj.reason1,
                                            reason2: false,
                                            reason3: false,
                                            reason4: false,
                                            otherReasonText: ""
                                        });
                                    }}
                                />
                                <div className="text">I don’t know this brand</div>
                            </div>
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason2}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: false,
                                            reason2: !reasonObj.reason2,
                                            reason3: false,
                                            reason4: false,
                                            otherReasonText: ""
                                        });
                                    }}
                                />{" "}
                                <div className="text">I don’t want to work with this brand</div>
                            </div>
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason3}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: false,
                                            reason2: false,
                                            reason3: !reasonObj.reason3,
                                            reason4: false,
                                            otherReasonText: ""
                                        });
                                    }}
                                />{" "}
                                <div className="text">I’ll stop working with this brand</div>
                            </div>
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason4}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: false,
                                            reason2: false,
                                            reason3: false,
                                            reason4: !reasonObj.reason4,
                                            otherReasonText: ""
                                        });
                                    }}
                                />{" "}
                                <div className="text">Other reasons:</div>
                            </div>
                            <div className="simple">
                                <div className="input-selector-inverted">
                                    <div className="search">
                                        <textarea
                                            ref={otherReasonRef}
                                            className={`frame-48 ${reasonObj.reason4 ? "" : "disabled"}`}
                                            placeholder="Please specify"
                                            value={reasonObj.otherReasonText}
                                            disabled={!reasonObj.reason4}
                                            onChange={e => {
                                                setReasonObj({ ...reasonObj, otherReasonText: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DialogLayoutStyled>
    );
};
