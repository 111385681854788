import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import AddressFormCardStandard from "../../common/forms/AddressFormCardStandard";
import * as Library from "../../../utils/Library";
import { useDispatch, useSelector } from "react-redux";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import LayoutContext from "../../../LayoutContext";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import { getDropdownData } from "../../../utils/dropdownData";
import { ReactComponent as CalendarIcon } from "../../../assets/images/calender.svg";
import { ReactComponent as Glob } from "../../../assets/images/glob.svg";
import UrlIcon from "../../../assets/images/url-icon.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Instagram from "../../../assets/images/instagram.svg";
import SimpleButtonContentHeader from "./createCompanyComponents/SimpleButtonContentHeader";
import UploadLogosCardStandard from "../../common/forms/UploadLogosCardStandard";
import AppContext from "../../../AppContext";
import { CardTitleButton } from "../brands/CreateBrandPage";
import { userIsOnBoardingInPage } from "../../../utils/navigationHelper";

/* #region SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

export default function CreateCompanyPage() {
    document.title = `${APP_TITLE} - Companies Create`;

    /* #region STATES */
    const navigations = useSelector(state => state.navigationAccesses);
    const { MainLayoutProps, layoutType } = useOutletContext();

    const {
        showIsAccountSavedPopup,
        setShowIsAccountSavedPopup,
        setShowIsCompanySavedPopup,
        readyToSavePopup,
        setReadyToSavePopup
    } = React.useContext(LayoutContext);
    const { setHasUnsavedChanges } = React.useContext(AppContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formCompanyID = useRef();
    const formCompanySocialMedia = useRef();
    const formAccountLogosRef = useRef();
    const formCompanyAddress = useRef();

    const formCompanyFieldsRefs = [];
    const formCompanySocialMediaFieldsRefs = [];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const [isLoading, setIsLoading] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [companyName, setCompanyName] = useState("");
    const [companyAddressFormValues, setCompanyDetails] = useState({
        legalBusinessName: "",
        googlePlusCode: "",
        companyCountry: "",
        companyAddressCountry: "",
        companyCity: "",
        companyPostalCode: "",
        companyAddressOne: "",
        companyAddressTwo: "",
        companyLatitude: "",
        companyLongitude: ""
    });
    const [companyIDCountry, setCompanyIDCountry] = useState("");
    const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState("");
    const [selectedTaxCode, setSelectedTaxCode] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [taxNumber, setTaxNumber] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [logoFieldsState, setLogoFieldsState] = useState(null);
    /* #endregion */

    /* #region METHODS */
    const selectTimezoneAndPhoneNumber = data => {
        const selectedCountryID = data?.id;
        const phoneCountryCodes = getDropdownData("countryCodeOptions");
        const taxIdData = getDropdownData("taxNumberOptions");
        const selectedPhoneCountryCodeTMP = phoneCountryCodes.find(country => country.id === selectedCountryID);
        const selectedTaxCode = taxIdData?.find(country => country.value === selectedCountryID);
        setSelectedPhoneCountryCode(selectedPhoneCountryCodeTMP?.value);
        setSelectedTaxCode(selectedTaxCode?.value ?? "");
        setTaxNumber(selectedTaxCode?.value === "NO" ? registrationNumber : "");
    };
    //TODO:====================| Temporary |=========================
    useEffect(() => {
        const countryValue = localStorage.getItem("currentOrganizationCountry");
        const currentCountryObj = countryOptions.find(country => country.value === countryValue);
        selectTimezoneAndPhoneNumber(currentCountryObj);
        setCompanyIDCountry(currentCountryObj.value);
    }, [countryOptions]);
    //===============================================================

    // if false -> don't highlight invalid fields
    const checkFormIsValid = (highlightValidation = false) => {
        const allCompanyIdFieldsAreValid = formCompanyID.current.allFieldsAreValid(highlightValidation);
        const allAddressFieldsAreValid = formCompanyAddress.current.allFieldsAreValid(highlightValidation);
        const allLogosAreValid = formAccountLogosRef.current.allFieldsAreValid(highlightValidation);
        const allSocialFieldsAreValid = formCompanySocialMedia.current.allFieldsAreValid(highlightValidation);

        const allFieldsAreValid =
            allCompanyIdFieldsAreValid && allLogosAreValid && allAddressFieldsAreValid && allSocialFieldsAreValid;
        setSaveButtonEnabled(allFieldsAreValid);

        return allFieldsAreValid;
    };

    const onSaveClicked = async () => {
        const companyIDFormValues = formCompanyID.current.getAllFieldValues();
        const socialMediaFormValues = formCompanySocialMedia.current.getAllFieldValues();
        const logoFieldValues = formAccountLogosRef.current.getAllFieldValues();
        const companyAddressFormValues = formCompanyAddress.current.getAllFieldValues();

        const { companyFacebook, companyInstagram, companyLinkedIn, companyWebsite, companyX } = socialMediaFormValues;

        const {
            legalBusinessName,
            country,
            registrationNumber,
            date,
            taxNumber,
            businessPhoneNr,
            doingBusinessAs,
            privacyPolicyUrl,
            businessEmail
        } = companyIDFormValues;

        const googlePlusCode = companyAddressFormValues?.googlePlusCode || "";
        const companyAddressCountry = companyAddressFormValues?.country?.value || "";
        const companyCity = companyAddressFormValues?.city?.value || "";
        const companyPostalCode = companyAddressFormValues?.postalCode || "";
        const companyAddressOne = companyAddressFormValues?.addressOne || "";
        const companyAddressTwo = companyAddressFormValues?.addressTwo || "";
        const companyLatitude = companyAddressFormValues?.latitude || "";
        const companyLongitude = companyAddressFormValues?.longitude || "";

        const jsonPostData = {
            companyID: localStorage.getItem("currentCompanyID"),
            name: legalBusinessName || "",
            countryValue: country?.value || "",
            registrationNumber: registrationNumber || "",
            registrationDate: date || "",
            taxPreLabel: selectedTaxCode || "",
            taxNumber: taxNumber.replace(/^NO\s*/, "") || "",
            taxPosLabel: selectedTaxCode === "NO" ? "MVA" : "",
            phoneNumberCountryCode: selectedPhoneCountryCode || "",
            phoneNumber: businessPhoneNr || "",
            businessName: doingBusinessAs || "",
            businessEmail: businessEmail || "",
            businessType: "Retail",
            mainLogoData: logoFieldValues.mainLogoData,
            compactLogoData: logoFieldValues.compactLogoData,
            location: {
                latitude: companyLatitude || "",
                longitude: companyLongitude || "",
                googlePlusCode: googlePlusCode || "",
                countryValue: companyAddressCountry || "",
                city: companyCity || "",
                postalCode: companyPostalCode || "",
                addressLine1: companyAddressOne || "",
                addressLine2: companyAddressTwo || "",
                addressNumber: ""
            },
            socialMedia: {
                website: companyWebsite || "",
                privacyPolicy: privacyPolicyUrl || "",
                linkedin: companyLinkedIn || "",
                X: companyX || "",
                facebook: companyFacebook || "",
                instagram: companyInstagram || ""
            }
        };

        setHasUnsavedChanges(false);

        Library.makeCommonPostRequest("Company", "updateCompany", jsonPostData, false, setIsLoading).then(res => {
            if (res?.data?.status === 200) {
                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    companies: {
                        ...navigations.navigationAccesses.companies,
                        createModeOnly: false
                    },
                    retailBrands: {
                        ...navigations.navigationAccesses.retailBrands,
                        createModeOnly: true,
                        navigationPanel: true
                    }
                };
                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                localStorage.removeItem("tempCountryValue");
                navigate("/brands/create");
                setShowIsCompanySavedPopup(true);
                localStorage.setItem("activeCompanies", 1);
            } else setHasUnsavedChanges(true);
        });
    };

    const generateLogoFields = (largeLogoImageData, smallLogoImageData) => {
        const logosState = [
            {
                label: "Main logo",
                varName: "mainLogoData",
                requiredType: "mandatory",
                imageData: largeLogoImageData,
                gridColumnSize: 2
            },
            {
                label: "Compact logo",
                varName: "compactLogoData",
                requiredType: "recommended",
                imageData: smallLogoImageData,
                gridColumnSize: 1
            }
        ];

        setLogoFieldsState(logosState);
    };

    const getAccountData = async () => {
        const res = await Library.makeCommonPostRequest(
            "Organization",
            "getOrganizationData",
            { id: localStorage.getItem("token") },
            false,
            setIsLoading
        );

        if (res?.data?.status !== 200) return;
        const data = res?.data?.data;
        return data;
    };

    const logosSameAsAccount = async () => {
        const data = await getAccountData();
        if (!data) return;

        const { mainImageUrl, squareImageUrl } = data?.OrganizationInfo;

        generateLogoFields(
            mainImageUrl ? { url: mainImageUrl, name: "mainLogo" } : null,
            squareImageUrl ? { url: squareImageUrl, name: "compactLogo" } : null
        );
    };
    /* #endregion METHODS */

    /* #region FORM FIELDS */
    const formCompanyFields = [
        <FormField
            type="text"
            title="Legal Business name"
            tooltip="Legal Business name"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="legalBusinessName"
            ref={el => (formCompanyFieldsRefs.legalBusinessName = el)}
            onTextChange={e => {
                setCompanyName(e);
            }}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            assistiveText="KYC"
            isDropdownSearchable={true}
            tooltip="Select country"
            requiredType="mandatoryLocked"
            varName="country"
            onDropdownChange={selectTimezoneAndPhoneNumber}
            value={companyIDCountry}
            ref={el => (formCompanyFieldsRefs.country = el)}
        />,
        <FormField
            type="number"
            title="Registration number"
            tooltip="Registration number"
            requiredType="mandatory"
            assistiveText="KYC"
            minLength={selectedTaxCode === "NO" ? 9 : undefined}
            maxLength={selectedTaxCode === "NO" ? 9 : undefined}
            varName="registrationNumber"
            ref={el => (formCompanyFieldsRefs.registrationNumber = el)}
            onTextChange={e => {
                setRegistrationNumber(e);
                if (selectedTaxCode === "NO") {
                    setTaxNumber(e);
                }
            }}
            value={registrationNumber}
        />,
        <FormField
            type="date"
            tooltip="Date"
            title="Registration Date"
            requiredType="mandatory"
            leftContent={<CalendarIcon className="flex" />}
            varName="date"
            ref={el => (formCompanyFieldsRefs.date = el)}
            datePickerPlaceholder={" "}
        />,
        <FormField
            type="taxNumber"
            title="Tax Number"
            tooltip="Tax Number"
            rightContent={selectedTaxCode === "NO" ? "MVA" : ""}
            taxNumberCountryCode={selectedTaxCode}
            varName="taxNumber"
            requiredType="mandatory"
            assistiveText="KYC"
            value={taxNumber}
            onTextChange={e => {
                /* if (selectedTaxCode === "NO") {
                    setRegistrationNumber(e);
                } */
            }}
            ref={el => (formCompanyFieldsRefs.taxNumber = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Business phone nr"
            tooltip="Enter Business phone nr"
            requiredType="mandatory"
            varName="businessPhoneNr"
            assistiveText="KYC"
            phoneNumberCountryCode={selectedPhoneCountryCode}
            ref={el => (formCompanyFieldsRefs.businessPhoneNr = el)}
            value={phoneNumber}
            onCountryCodeChange={e => {
                setSelectedPhoneCountryCode(e.value);
            }}
        />,
        <FormField
            type="text"
            title="Doing business as"
            tooltip="Doing business as"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="doingBusinessAs"
            ref={el => (formCompanyFieldsRefs.doingBusinessAs = el)}
        />,
        <FormField
            type="text"
            title="Business website"
            tooltip="Business website"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="businessWebsite"
            placeholder="https://"
            leftContent={<Glob className="flex" />}
            ref={el => (formCompanyFieldsRefs.businessWebsite = el)}
        />,
        <FormField
            type="text"
            title="Privacy policy URL"
            tooltip="Privacy policy URL"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="privacyPolicyUrl"
            leftContent={<Glob className="flex" />}
            placeholder="https://"
            ref={el => (formCompanyFieldsRefs.privacyPolicyUrl = el)}
        />,
        <FormField
            type="email"
            title="Business email"
            tooltip="Business email"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="businessEmail"
            leftContent={<img src={UrlIcon} alt="glob-icon" />}
            ref={el => (formCompanyFieldsRefs.businessEmail = el)}
        />,
        <FormField
            type="dropdown"
            title="Business type"
            dropdownData={[{ name: "Retail", value: "Retail", selected: true }]}
            isDropdownMultiselect={false}
            isDropdownSearchable={false}
            tooltip="Business type"
            requiredType="locked"
            assistiveText="KYC"
            varName="businessType"
            ref={el => (formCompanyFieldsRefs.businessType = el)}
        />
    ];

    const formCompanySocialMediaFields = [
        <FormField
            type="text"
            title="Company website"
            tooltip="Company website"
            requiredType="simple"
            varName="companyWebsite"
            leftContent={<Glob className="flex" />}
            placeholder="https://"
            //value={companyAddressFormValues.companyWebsite}
            ref={el => (formCompanySocialMediaFieldsRefs.companyWebsite = el)}
        />,
        <FormField
            type="text"
            title="Company LinkedIn"
            tooltip="Company LinkedIn"
            requiredType="simple"
            varName="companyLinkedIn"
            placeholder="https://www.linkedin.com/in/"
            leftContent={<img src={LinkedIn} alt="LinkedIn" />}
            //value={companyAddressFormValues.companyLinkedIn}
            ref={el => (formCompanySocialMediaFieldsRefs.companyLinkedIn = el)}
        />,
        <FormField
            type="text"
            title="Company X"
            tooltip="Company X"
            requiredType="simple"
            varName="companyX"
            leftContent={<img src={Twitter} alt="X" />}
            placeholder="https://www.x.com/"
            //value={companyAddressFormValues.companyX}
            ref={el => (formCompanySocialMediaFieldsRefs.companyX = el)}
        />,
        <FormField
            type="text"
            title="Company Facebook"
            tooltip="Company Facebook"
            requiredType="simple"
            varName="companyFacebook"
            placeholder="https://www.facebook.com/"
            leftContent={<img src={Facebook} alt="calender-icon" />}
            //value={companyAddressFormValues.companyFacebook}
            ref={el => (formCompanySocialMediaFieldsRefs.companyFacebook = el)}
        />,
        <FormField
            type="text"
            title="Company Instagram"
            tooltip="Company Instagram"
            requiredType="simple"
            varName="companyInstagram"
            placeholder="https://www.instagram.com/"
            leftContent={<img src={Instagram} alt="Instagram" />}
            //value={companyAddressFormValues.companyInstagram}
            ref={el => (formCompanySocialMediaFieldsRefs.companyInstagram = el)}
        />
    ];
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props, saveButtonEnabled, setReadyToSavePopup, navigations);
    }, [props?.layoutType, navigations, saveButtonEnabled]);

    /* useEffect(() => {
        checkFormIsValid();
    }, [companyAddressFormValues]); */

    useEffect(() => {
        generateLogoFields(null, null);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            checkFormIsValid();
        }, 100);
    }, [logoFieldsState]);

    useEffect(() => {
        if (!navigations?.navigationAccesses) return;

        if (!navigations.navigationAccesses.companies.createModeOnly) {
            //if user is not onboarding on company, redirecting to home page
            if (!Library.isLocalhost("Update Company Data")) {
                navigate("/");
            }
            return;
        }
    }, [navigations]);

    useEffect(() => {
        if (!companyAddressFormValues || !localStorage.getItem("tempCountryValue") || !!selectedTaxCode) return;

        const countryValue = localStorage.getItem("tempCountryValue");
        const registrationNumberValue = localStorage.getItem("tempRegistrationNumber");
        const phoneNumberValue = localStorage.getItem("tempPhoneNumber");
        const businessPhoneNr = phoneNumberValue.split(" ")[1];
        setCompanyIDCountry(countryValue);
        const countrySelectedID = countryOptions.find(country => country.value === countryValue)?.id;
        selectTimezoneAndPhoneNumber({ id: countrySelectedID, name: countryValue });
        setRegistrationNumber(registrationNumberValue?.replace(/\s/g, "") || "");
        setPhoneNumber(businessPhoneNr);
        setTaxNumber(countrySelectedID === "NO" ? registrationNumberValue?.replace(/\s/g, "") : "");
    }, [companyAddressFormValues]);

    /* #endregion */

    return (
        <StyledPage>
            <div>
                <FullScreenOverlay show={showIsAccountSavedPopup}>
                    <DialogBoxComponent
                        onClickYes={() => setShowIsAccountSavedPopup(false)}
                        title="Account is now saved!"
                        heading="Success!"
                        headingColor="#00BB00"
                        message={
                            "<div style='font-size: 12px; margin: 0 -5px;'>You can now proceed with enrolling your Company</div>"
                        }
                        // useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                        showConfirm={() => setShowIsAccountSavedPopup(false)}
                        showCancel={false}
                        confirmText="CONTINUE"
                    />
                </FullScreenOverlay>

                <FullScreenOverlay show={readyToSavePopup}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-blue-500)"
                        title="Ready to save?"
                        message="You can still change it later. No worries:)"
                        onClickYes={() => {
                            setReadyToSavePopup(false);
                            onSaveClicked();
                        }}
                        loading={isLoading}
                        loadingHeading={"SUBSCRIBING.."}
                        onClickNo={() => {
                            setReadyToSavePopup(false);
                        }}
                        cancelText="No"
                        confirmText="Yes"
                    />
                </FullScreenOverlay>

                <FullScreenOverlay show={isLoading}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-green-500)"
                        heading="SAVING..."
                        showCancel={false}
                        showConfirm={false}
                        loading={isLoading}
                    />
                </FullScreenOverlay>

                <FormCard
                    title="COMPANY ID"
                    ref={formCompanyID}
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={formCompanyFields}
                    formFieldRefs={formCompanyFieldsRefs}
                    onFormChanged={checkFormIsValid}
                    showUnsavedChangesPopup={true}
                />

                <AddressFormCardStandard
                    cardTitle="COMPANY ADDRESS"
                    ref={formCompanyAddress}
                    pinLabel={companyName}
                    unnamedPinLabel={"Unnamed Company"}
                    useGoogleMapsDarkMode={true}
                    //KYC assistive text is only for this company create page, everywhere should be false
                    hasKYCAssistiveText={true}
                    onFormChanged={checkFormIsValid}
                    //TODO: for now it is required to have locationDetails and onLocationDetailsChange, but will be refactored to be optional
                    locationDetails={{
                        googlePlusCode: companyAddressFormValues.googlePlusCode,
                        country: companyAddressFormValues.companyAddressCountry,
                        city: companyAddressFormValues.companyCity,
                        postalCode: companyAddressFormValues.companyPostalCode,
                        addressOne: companyAddressFormValues.companyAddressOne,
                        addressTwo: companyAddressFormValues.companyAddressTwo,
                        latitude: companyAddressFormValues.companyLatitude,
                        longitude: companyAddressFormValues.companyLongitude
                    }}
                    onLocationDetailsChange={locationDetails => {
                        const {
                            googlePlusCode,
                            country,
                            city,
                            postalCode,
                            addressOne,
                            addressTwo,
                            latitude,
                            longitude
                        } = locationDetails;

                        setCompanyDetails({
                            ...companyAddressFormValues,
                            googlePlusCode,
                            companyAddressCountry: country,
                            companyCity: city,
                            companyPostalCode: postalCode,
                            companyAddressOne: addressOne,
                            companyAddressTwo: addressTwo,
                            companyLatitude: latitude,
                            companyLongitude: longitude
                        });
                    }}
                />

                <UploadLogosCardStandard
                    cardTitle="COMPANY LOGO"
                    key="companyLogos"
                    ref={formAccountLogosRef}
                    logoFields={logoFieldsState}
                    onLogoFieldsChanged={() => {
                        checkFormIsValid(false);
                    }}
                    afterTitleComponent={<CardTitleButton title="SAME AS ACCOUNT" onClick={logosSameAsAccount} />}
                />

                <FormCard
                    title="COMPANY SOCIAL MEDIA"
                    ref={formCompanySocialMedia}
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={formCompanySocialMediaFields}
                    formFieldRefs={formCompanySocialMediaFieldsRefs}
                    onFormChanged={checkFormIsValid}
                    showUnsavedChangesPopup={true}
                />
            </div>
        </StyledPage>
    );
}

function setupHeaders(props, saveButtonEnabled, setReadyToSavePopup, navigations) {
    const isOnboarding = userIsOnBoardingInPage(navigations, "companies");

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Company", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/companies/create", active: true, disabled: false, title: "Companies" },
        { to: "#", active: false, disabled: true, title: "KYC POLICY" },
        { to: "/companies/users", active: false, disabled: true, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        { to: "/companies", active: false, disabled: isOnboarding, title: "View", icon: "eye" },
        { to: "/companies/create", active: true, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle="SAVE"
                btnEnabled={saveButtonEnabled}
                onClick={() => {
                    setReadyToSavePopup(true);
                }}
            />
        )
    });
}
