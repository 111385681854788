import { APP_HOMEPAGE_ROUTE } from "../config/constant";

/** Function to give the current page for the user based on the onboarding status
 * @param {Object} navigationAccesses - navigationAccesses object from the user
 * @returns {String} current page for the user
 */
export const verifyUserIsOnboarding = navigationAccesses => {
    //TODO: RENAME THIS FUNCTION
    let onboardingRoute = APP_HOMEPAGE_ROUTE;

    if (!navigationAccesses) return undefined;

    const organizationStatus = navigationAccesses?.organization?.status;

    const onboardingBillingPanel = !navigationAccesses?.organization?.navigationPanel;
    const onboardingOrganizationPanel =
        navigationAccesses?.organization?.navigationPanel && navigationAccesses?.organization?.createModeOnly;
    const onboardingCompanyPanel =
        navigationAccesses?.companies?.navigationPanel && navigationAccesses?.companies?.createModeOnly;
    const onboardingRetailBrandPanel =
        navigationAccesses?.retailBrands?.navigationPanel && navigationAccesses?.retailBrands?.createModeOnly;
    const onboardingStoresPOSPanel =
        navigationAccesses?.stores?.controlBar?.POS && !navigationAccesses?.stores?.controlBar?.stores;
    const onboardingStoresPanel =
        navigationAccesses?.stores?.navigationPanel && navigationAccesses?.stores?.createModeOnly;
    const onboardingDashboardPanel = navigationAccesses?.dashboard?.navigationPanel;

    //TODO: THIS IS A TEMPORARY FIX FOR AN ISSUE IN THE ONBOARDING PROCESS WHERE DASHBOARD IS NOT ENABLED
    const hasActiveStores = parseInt(localStorage.getItem("activeStores")) > 0;

    const homepageError =
        !onboardingBillingPanel &&
        !onboardingOrganizationPanel &&
        !onboardingCompanyPanel &&
        !onboardingRetailBrandPanel &&
        !onboardingStoresPOSPanel &&
        !onboardingStoresPanel &&
        !onboardingDashboardPanel &&
        !hasActiveStores;

    if (homepageError) {
        localStorage.clear();
        console.error("Homepage Error: No navigation panel is enabled");
        onboardingRoute = "/login";
    } else if (onboardingBillingPanel) {
        onboardingRoute = "/billing";
    } else if (onboardingOrganizationPanel) {
        onboardingRoute = "/account/create";
    } else if (onboardingCompanyPanel) {
        onboardingRoute = "/companies/create";
    } else if (onboardingRetailBrandPanel) {
        onboardingRoute = "/brands/create";
    } else if (onboardingStoresPOSPanel) {
        onboardingRoute = "/stores/pos";
    } else if (onboardingStoresPanel) {
        onboardingRoute = "/stores/create";
    } else if (onboardingDashboardPanel || organizationStatus === "Active" || hasActiveStores) {
        onboardingRoute = APP_HOMEPAGE_ROUTE;
    }

    //console.log("onboardingRoute", onboardingRoute);
    return onboardingRoute;
};

/** Function to check if user has completed onboarding
 * @param {Object} navigationAccesses - navigationAccesses object from the user
 * @returns {Boolean} true if user has completed onboarding
 */
export const hasUserCompletedOnboarding = navigations => {
    const nav = navigations?.navigationAccesses ?? navigations;

    const page = verifyUserIsOnboarding(nav);
    return page === APP_HOMEPAGE_ROUTE;
};

/** Function to check if user has access to a specific section while onboarding
 * @param {Object} navigations - navigationAccesses object from the user
 * @param {String} section - section to check access for
 * @param {Boolean} accessWithoutCreateMode - check access without create mode
 * @returns {Boolean} true if user has access to the section
 */
export const hasNavigationAccess = (navigations, section, accessWithoutCreateMode = true) => {
    const nav = navigations?.navigationAccesses ?? navigations;
    if (!nav) return false;

    /*const organizationStatus = nav.organization?.status;
    const currentOnboardingRoute = verifyUserIsOnboarding(navigations);

    //TODO: THIS IS A TEMPORARY FIX FOR AN ISSUE IN THE ONBOARDING PROCESS WHERE DASHBOARD IS NOT ENABLED
    const hasActiveStores = parseInt(localStorage.getItem("activeStores")) > 0;

    if (
        (hasActiveStores || organizationStatus === "Active") &&
        currentOnboardingRoute !== "/login" &&
        currentOnboardingRoute !== "/billing" &&
        currentOnboardingRoute !== "/account/create" &&
        currentOnboardingRoute !== "/companies/create" &&
        currentOnboardingRoute !== "/brands/create" &&
        currentOnboardingRoute !== "/stores/pos" &&
        currentOnboardingRoute !== "/stores/create"
    )
        return true;*/

    switch (section) {
        case "companies":
            if (!accessWithoutCreateMode) return nav?.companies?.navigationPanel;
            return nav?.companies?.navigationPanel && !nav?.companies?.createModeOnly;
        case "retailBrands":
            if (!accessWithoutCreateMode) return nav?.retailBrands?.navigationPanel;
            return nav?.retailBrands?.navigationPanel && !nav?.retailBrands?.createModeOnly;
        case "labelBrands":
            if (!accessWithoutCreateMode) return nav?.labelBrands?.navigationPanel;
            return nav?.labelBrands?.navigationPanel && !nav?.labelBrands?.createModeOnly;
        case "stores":
            if (!accessWithoutCreateMode) return nav?.stores?.navigationPanel;
            return nav?.stores?.navigationPanel && !nav?.stores?.createModeOnly;
        case "fulfillmentCenters":
            if (!accessWithoutCreateMode) return nav?.fulfillmentCenters?.navigationPanel;
            return nav?.fulfillmentCenters?.navigationPanel && !nav?.fulfillmentCenters?.createModeOnly;
        //remaining cases not needed for onboarding
        case "dashboard":
            if (!accessWithoutCreateMode) return nav?.dashboard?.navigationPanel;
            return nav?.dashboard?.navigationPanel && !nav?.dashboard?.createModeOnly;
        case "boimages":
            if (!accessWithoutCreateMode) return nav?.BOimages?.navigationPanel;
            return nav?.BOimages?.navigationPanel && !nav?.BOimages?.createModeOnly;
        case "retailers":
            if (!accessWithoutCreateMode) return nav?.retailers?.navigationPanel;
            return nav?.retailers?.navigationPanel && !nav?.retailers?.createModeOnly;
        case "orders":
            if (!accessWithoutCreateMode) return nav?.orders?.navigationPanel;
            return nav?.orders?.navigationPanel && !nav?.orders?.createModeOnly;
        case "products":
            if (!accessWithoutCreateMode) return nav?.products?.navigationPanel;
            return nav?.products?.navigationPanel && !nav?.products?.createModeOnly;
        case "promoters":
            if (!accessWithoutCreateMode) return nav?.promoters?.navigationPanel;
            return nav?.promoters?.navigationPanel && !nav?.promoters?.createModeOnly;
        case "organization":
            if (!accessWithoutCreateMode) return nav?.organization?.navigationPanel;
            return nav?.organization?.navigationPanel && !nav?.organization?.createModeOnly;
        case "stock":
            if (!accessWithoutCreateMode) return nav?.stock?.navigationPanel;
            return nav?.stock?.navigationPanel && !nav?.stock?.createModeOnly;

        default: {
            console.error("Invalid navigation access", section);
            return false;
        }
    }
};

export const userIsOnBoardingInPage = (navigations, section) => {
    const nav = navigations?.navigationAccesses ?? navigations;
    if (!nav) return false;

    switch (section) {
        case "companies":
            return nav?.companies?.createModeOnly;
        case "retailBrands":
            return nav?.retailBrands?.createModeOnly;
        case "storesPOS":
            return nav?.stores?.controlBar?.POS && !nav?.stores?.controlBar?.stores;
        case "stores":
            return nav?.stores?.createModeOnly && nav?.stores?.controlBar?.stores;
        case "fulfillmentCenters":
            return nav?.fulfillmentCenters?.createModeOnly;
        case "boimages":
            return nav?.BOimages?.createModeOnly;
        case "users":
            return nav?.dashboard?.navigationPanel;
        case "account":
        case "organization":
            return nav?.organization?.createModeOnly;
        default:
            return false;
    }
};

export const getAllUnlockedNavigations = navigationAccesses => {
    const updatedNavigations = {
        ...navigationAccesses,
        stores: {
            navigationPanel: true,
            createModeOnly: false,
            controlBar: {
                POS: true,
                stores: true
            }
        },
        dashboard: { navigationPanel: true, createModeOnly: false },
        //BOimages: { navigationPanel: true, createModeOnly: false },
        fulfillmentCenters: { navigationPanel: true, createModeOnly: false },
        labelBrands: { navigationPanel: true, createModeOnly: false },
        //orders: { navigationPanel: true, createModeOnly: false },
        products: { navigationPanel: true, createModeOnly: false },
        retailBrands: { navigationPanel: true, createModeOnly: false },
        //promoters: { navigationPanel: true, createModeOnly: false },
        companies: { navigationPanel: true, createModeOnly: false },
        billing: { navigationPanel: true, createModeOnly: false },
        organization: { navigationPanel: true, createModeOnly: false },
        stock: { navigationPanel: true, createModeOnly: false }
    };

    return updatedNavigations;
};
