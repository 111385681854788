import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import * as Library from "../../../utils/Library";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import LayoutContext from "../../../LayoutContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllUnlockedNavigations, hasUserCompletedOnboarding } from "../../../utils/navigationHelper";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import AppContext from "../../../AppContext";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import FormField from "../../common/forms/FormField";
import Checkbox from "../../common/checkbox/Checkbox";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;

const DivStyledText = styled.div`
    color: var(--text-icons-gray-100, #d1d1d1);

    /* Subtitle 2 */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const CheckboxWithContentStyle = styled.div`
    &.frame-313871,
    &.frame-313871 * {
        box-sizing: border-box;
    }
    &.frame-313871 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 16px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        backdrop-filter: blur(50px);
        border: 1px solid transparent;
    }
    &.frame-313871.current-step:not(.disabled) {
        border-radius: 16px;
        border: 1px solid var(--text-icons-orange-500, #f90);
    }
    &.frame-313871:not(.disabled) .checkboxDescription {
        color: var(--text-icons-gray-100, #d1d1d1);
        cursor: pointer;
    }
    &.frame-313871:not(.disabled) .main-checkbox {
        cursor: pointer;
    }
    &.frame-313871.disabled {
        cursor: not-allowed;
    }
    .frame-1000011183 {
        display: flex;
        flex-direction: row;
        gap: 26px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--text-and-icons-gray-200, #7c868b);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .checkboxDescription {
        color: var(--text-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .btn-large-solid-inverted-01 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 150px;
        position: relative;
        cursor: pointer;
    }
    .btn-large-solid-inverted-01.disabled {
        cursor: not-allowed;
    }
    .btn-small-filled {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 35px;
        position: relative;
    }
    .share {
        color: #d1d1d1;
        text-align: left;
        font-family: var(--button-text-large-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-large-font-size, 12px);
        letter-spacing: var(--button-text-large-letter-spacing, 0.0125em);
        font-weight: var(--button-text-large-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .btn-large-solid-inverted-01.disabled .share {
        color: var(--text-and-icons-gray-300, #4f5b6d);
    }
    .bottom-input-field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }
`;

/* #endregion */

export default function StorePOSPage() {
    document.title = `${APP_TITLE} - Edit Store POS`;

    /* #region STATES */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const { showIsBrandSavedPopup, setShowIsBrandSavedPopup, setShowIsStorePosSavedPopup } = useContext(LayoutContext);
    const { setHasUnsavedChanges } = useContext(AppContext);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formFields1Refs = [];
    const [dropDownData] = useState([
        { name: "Front Systems", value: "6537dc8613db5d57a8cc5815" },
        { name: "Traze", value: "653122e4a2673ea91c967c5b" },
        { name: "Other", value: "other" }
    ]);

    const [selectedPOS, setSelectedPOS] = useState(null);
    const [selectedPOSName, setSelectedPOSName] = useState(null);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [posNumOfSteps, setPosNumOfSteps] = useState(null);
    const [posCurrentStep, setPosCurrentStep] = useState(null);
    const [posSteps, setPosSteps] = useState([]); // example: { isChecked: false, inputText: "" }
    const [posEmailSupport, setPosEmailSupport] = useState("");
    const [posConnectFields, setPosConnectFields] = useState([]);

    const [isViewPage, setIsViewPage] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(
            props,
            saveButtonEnabled,
            () => {
                setShowDialogBox(true);
            },
            navigations,
            isViewPage
        );
    }, [saveButtonEnabled, navigations]);

    useEffect(() => {
        if (posCurrentStep > 0) setHasUnsavedChanges(true);
    }, [posCurrentStep, posSteps, termsAndConditionsAccepted]);

    useEffect(() => {
        getRetailBrandRequest();
    }, []);

    useEffect(() => {
        if (!termsAndConditionsAccepted) {
            setSaveButtonEnabled(false);
            return;
        }

        if (!["6537dc8613db5d57a8cc5815"].includes(selectedPOS)) {
            Library.showToastMessage({
                title: `Configuration not yet available for ${selectedPOSName}`,
                type: "error"
            });
            setSaveButtonEnabled(false);
            return;
        }

        if (posCurrentStep === posNumOfSteps && selectedPOS) {
            //if (posNumOfSteps !== null) console.log("Configuration steps completed", posSteps);
            setSaveButtonEnabled(true);
        } else {
            setSaveButtonEnabled(false);
        }
    }, [posCurrentStep, selectedPOS, termsAndConditionsAccepted]);
    /* #endregion */

    /* #region METHODS */

    const getRetailBrandRequest = async () => {
        const postData = {
            retailBrandID: JSON.parse(localStorage.getItem("retailBrands"))?.[0]?._id
        };
        const res = await Library.makePostRequest("getRetailBrand", postData, false, setIsLoading);
        if (res?.data?.status === 200) {
            const retailBrandData = res.data?.data;

            const POSData = retailBrandData?.POS?.POSdata;
            const { _id, name, emailSupport } = POSData || {};
            const connectFieldsV2 = retailBrandData?.POS?.connectFieldsV2 || [];

            if (_id && name) {
                setSelectedPOS(_id);
                setSelectedPOSName(name);
                setPosEmailSupport(emailSupport?.join(", "));
                setPosConnectFields(connectFieldsV2);

                if (_id === "6537dc8613db5d57a8cc5815") {
                    setPosNumOfSteps(5);

                    if (Object.keys(connectFieldsV2).length === 2) {
                        const NewStepsArray = Array.from({ length: 5 });
                        const newSteps = NewStepsArray.map((field, index) => {
                            if (index === 2) return { isChecked: true, inputText: connectFieldsV2?.["API key"] };
                            else if (index === 4)
                                return { isChecked: true, inputText: connectFieldsV2?.["SaleStoreId"] };
                            else return { isChecked: true, inputText: "" };
                        });
                        setPosSteps(newSteps);
                        setPosCurrentStep(5);
                        setTermsAndConditionsAccepted(true);

                        setIsViewPage(true);
                        setTimeout(() => {
                            setHasUnsavedChanges(false);
                        }, 100);
                    } else {
                        setPosSteps([]);
                        setPosCurrentStep(0);
                    }
                }
            } else {
                setSelectedPOS("other");
                setSelectedPOSName("Other");
            }
        }
    };

    const updateRetailBrandPOSRequest = async postData => {
        setHasUnsavedChanges(false);
        const res = await Library.makeCommonPostRequest(
            "POS",
            "updateRetailBrandPOS",
            postData,
            false,
            setSaveLoading,
            updateRetailBrandError
        );
        if (res?.data?.status === 200) {
            const activeStores = parseInt(localStorage.getItem("activeStores")) || 0;

            if (activeStores === 0) {
                setShowIsStorePosSavedPopup(true);

                navigate("/stores/create");
                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    stores: {
                        navigationPanel: true,
                        createModeOnly: true,
                        controlBar: {
                            POS: true,
                            stores: true
                        }
                    }
                };

                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
            } else {
                Library.showToastMessage({
                    title: "POS data saved!",
                    type: "success"
                });

                const updatedNavigations = getAllUnlockedNavigations(navigations.navigationAccesses);
                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
            }
        } else {
            setHasUnsavedChanges(true);
        }
    };

    const updateRetailBrandError = data => {
        setHasUnsavedChanges(true);
        console.log("updateRetailBrandPOSRequest error", data);
        if (data?.POSstatus !== "Connected") {
            /* Library.showToastMessage({
                title: "Error saving POS data",
                type: "error"
            }); */
        }
    };

    const onSelectedPOSChange = item => {
        const { value } = item;
        setSelectedPOS(value);

        if (value === "6537dc8613db5d57a8cc5815") {
            setPosNumOfSteps(5);
            setPosCurrentStep(0);
            setPosSteps([]);
        } else {
            setPosNumOfSteps(null);
            setPosCurrentStep(null);
            setPosSteps([]);
        }
    };

    const checkboxIsDisabled = stepNum => {
        if (posNumOfSteps === null) return true;
        if (posCurrentStep < stepNum) return true;
        else if (posCurrentStep === stepNum) return false;
        else return false;
    };

    const checkboxIsChecked = stepNum => {
        if (posNumOfSteps === null) return false;
        if (posCurrentStep < stepNum) return false;
        else if (posCurrentStep === stepNum) return posSteps?.[stepNum]?.isChecked;
        else return true;
    };

    const onCheckboxClick = (stepClickNum, inputRef) => {
        if (posNumOfSteps === null) return;

        if (posCurrentStep === stepClickNum) {
            onCheckboxSelected(stepClickNum, inputRef);
        }

        if (posCurrentStep > stepClickNum) {
            const newStep = { isChecked: false, inputText: posSteps?.[stepClickNum]?.inputText };

            const newSteps = posSteps.map((step, index) => {
                if (index < stepClickNum) return step;
                else if (index === stepClickNum) return newStep;
                else return { isChecked: false, inputText: step?.inputText };
            });
            setPosSteps(newSteps);
            setPosCurrentStep(stepClickNum);
        }
    };

    const onCheckboxSelected = (stepClickNum, inputRef) => {
        let inputText = "";
        if (inputRef?.isValid && inputRef?.getValue) {
            const isvalid = inputRef?.isValid(true);
            const value = inputRef?.getValue();
            if (!isvalid) return;
            inputText = value;
        }

        if (posCurrentStep === stepClickNum) {
            if (!posSteps?.[stepClickNum]) {
                const newStep = { isChecked: true, inputText: inputText };
                setPosSteps([...posSteps, newStep]);
            } else {
                const allSteps = posSteps.map((step, index) => {
                    if (index === stepClickNum) return { isChecked: true, inputText: inputText };
                    else return step;
                });
                setPosSteps(allSteps);
            }

            setPosCurrentStep(stepClickNum + 1);
        }
    };

    const onSaveClicked = async () => {
        const postData = {
            retailBrandID: JSON.parse(localStorage.getItem("retailBrands"))?.[0]?._id
            //posID: selectedPOS
        };

        if (selectedPOS === "6537dc8613db5d57a8cc5815") {
            postData.connectFields = {
                "API key": posSteps?.[2]?.inputText,
                "SaleStoreId": posSteps?.[4]?.inputText
            };
            postData.status = "Connected";
        }

        await updateRetailBrandPOSRequest(postData);
    };

    const sendEmailClicked = async () => {
        const retailer_company_name = JSON.parse(localStorage.getItem("retailBrands"))?.[0]?.name;
        const org_nr = localStorage.getItem("tempRegistrationNumber");
        const emailFields = { retailer_company_name, org_nr };
        const toEmail = posEmailSupport;
        const res = await Library.sendCommonEmail("sendEmailFSVirtualStoreRequest", emailFields, toEmail);

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                title: "Email sent!",
                type: "success",
                description: "Please wait for Front Systems to create a virtual store for BOtag in your system."
            });
        }
    };
    /* #endregion */

    return (
        <StyledPage>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={showDialogBox}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Ready to save?"
                    message="Connection with each store will be established as soon as the store is created."
                    onClickYes={() => {
                        onSaveClicked();
                        setShowDialogBox(false);
                    }}
                    onClickNo={() => {
                        setShowDialogBox(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={saveLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="SAVING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={saveLoading}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showIsBrandSavedPopup}>
                <DialogBoxComponent
                    headingFontSize="16px"
                    buttonWidth="50%"
                    onClickYes={() => setShowIsBrandSavedPopup(false)}
                    title="Retail brand created."
                    heading="Success..."
                    headingColor="#00BB00"
                    message="<div style='font-size: 12px; margin: 0 -5px;'>Let’s proceed with getting Front Systems ready.</div>"
                    showConfirm={true}
                    showCancel={false}
                    confirmText="CONTINUE"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showTermsAndConditions}>
                <TermsAndPrivacyPanel
                    type="terms"
                    onCloseClicked={() => {
                        setShowTermsAndConditions(false);
                    }}
                />
            </FullScreenOverlay>

            <CardComponentContainer title="POS" spacing="16px">
                <div className="flex flex-col gap-16px">
                    <div style={{ display: "flex", width: "100%", position: "relative", gap: "16px" }}>
                        <FormField
                            type="dropdown"
                            title="POS"
                            tooltip="Select the POS system you are using."
                            dropdownData={dropDownData}
                            varName="dropdownSingleItem"
                            isDropdownMultiselect={false}
                            ref={el => (formFields1Refs.dropdownSingleItem = el)}
                            style={{ width: "393px", flexShrink: 0 }}
                            onDropdownChange={onSelectedPOSChange}
                            value={selectedPOSName}
                            requiredType="locked"
                        />
                        <DivStyledText
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px",
                                alignItems: "center",
                                alignSelf: "center",
                                width: "100%",
                                flexShrink: 1
                            }}>
                            <Checkbox
                                disabled={isViewPage}
                                isChecked={termsAndConditionsAccepted}
                                onCheckboxClick={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}
                            />
                            <label
                                style={{
                                    color: isViewPage ? "var(--text-icons-gray-300)" : "var(--text-icons-gray-100)"
                                }}>
                                I have read and I agree with the integration{" "}
                                <u
                                    className="cursor-pointer text-default"
                                    onClick={() => setShowTermsAndConditions(!showTermsAndConditions)}>
                                    Terms & Conditions
                                </u>
                            </label>
                        </DivStyledText>
                    </div>
                </div>
            </CardComponentContainer>

            <CardComponentContainer title="INTEGRATION" spacing="16px">
                {(selectedPOS === "other" || selectedPOS === "653122e4a2673ea91c967c5b") && (
                    <div className="flex flex-col gap-16px">Not Integrated</div>
                )}

                {selectedPOS && selectedPOS === "6537dc8613db5d57a8cc5815" && (
                    <div
                        className="flex flex-column gap-16px"
                        style={{
                            borderRadius: "10px",
                            border: "1px solid var(--backgrounds-lines-n-100, #3C485A)",
                            padding: "16px",
                            marginTop: "24px"
                        }}>
                        <label
                            style={{
                                color: !termsAndConditionsAccepted
                                    ? "var(--text-icons-gray-300)"
                                    : "var(--text-icons-gray-100)"
                            }}>
                            REQUIREMENTS
                        </label>

                        <CheckboxWithContent
                            description="You are subscribed to the Front Systems XL plan."
                            disabled={!termsAndConditionsAccepted || checkboxIsDisabled(0) || isViewPage}
                            isChecked={checkboxIsChecked(0)}
                            onCheckboxClick={inputRef => onCheckboxClick(0, inputRef)}
                            isCurrentStep={posCurrentStep === 0}
                        />

                        <CheckboxWithContent
                            description="Complete the Front Systems form to get an API key granting Botag access to your POS."
                            rightButton={{
                                title: "GO TO FORM",
                                onClick: () => {
                                    Library.openNewPage(
                                        "https://frontsystems.wufoo.com/forms/z1463mt51l4iseg&sa=D&source=editors&ust=1723630453977729&usg=AOvVaw1I2LAby2B5xBeDnzWmzSGO"
                                    );
                                },
                                disabled: checkboxIsChecked(2)
                            }}
                            disabled={!termsAndConditionsAccepted || checkboxIsDisabled(1) || isViewPage}
                            isChecked={checkboxIsChecked(1)}
                            onCheckboxClick={inputRef => onCheckboxClick(1, inputRef)}
                            isCurrentStep={posCurrentStep === 1}
                        />

                        <CheckboxWithContent
                            description="Share API key"
                            bottomInputField={{
                                placeholder: "Paste API key here",
                                invalidAssistiveText: "Invalid API key",
                                defaultValue: posConnectFields?.["API key"] || ""
                            }}
                            disabled={!termsAndConditionsAccepted || checkboxIsDisabled(2) || isViewPage}
                            isChecked={checkboxIsChecked(2)}
                            onCheckboxClick={inputRef => onCheckboxClick(2, inputRef)}
                            isCurrentStep={posCurrentStep === 2}
                        />

                        <CheckboxWithContent
                            description="Request Front Systems to create a virtual store for BOtag in your system and provide you with the Virtual Store ID."
                            rightButton={{
                                title: "SEND EMAIL",
                                onClick: () => sendEmailClicked(),
                                disabled: checkboxIsChecked(4)
                            }}
                            disabled={!termsAndConditionsAccepted || checkboxIsDisabled(3) || isViewPage}
                            isChecked={checkboxIsChecked(3)}
                            onCheckboxClick={inputRef => onCheckboxClick(3, inputRef)}
                            isCurrentStep={posCurrentStep === 3}
                        />

                        {/* <CheckboxWithContent
                            description="Make sure all web-shop configurations are set up."
                            disabled={!termsAndConditionsAccepted || checkboxIsDisabled(4)}
                            isChecked={checkboxIsChecked(4)}
                            onCheckboxClick={inputRef => onCheckboxClick(4, inputRef)}
                            isCurrentStep={posCurrentStep === 4}
                        /> */}

                        <CheckboxWithContent
                            description="Insert Virtual Store ID"
                            bottomInputField={{
                                placeholder: "Paste Virtual Store ID here",
                                invalidAssistiveText: "Invalid Virtual Store ID",
                                defaultValue: posConnectFields?.["SaleStoreId"] || ""
                            }}
                            disabled={!termsAndConditionsAccepted || checkboxIsDisabled(4) || isViewPage}
                            isChecked={checkboxIsChecked(4)}
                            onCheckboxClick={inputRef => onCheckboxClick(4, inputRef)}
                            isCurrentStep={posCurrentStep === 4}
                        />
                    </div>
                )}
            </CardComponentContainer>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
function setupHeaders(props, saveButtonEnabled, onBtnClicked, navigations, isViewPage) {
    const createModeStores =
        navigations?.navigationAccesses?.stores?.controlBar?.stores &&
        navigations?.navigationAccesses?.stores?.createModeOnly;

    const storesTo = createModeStores ? "/stores/create" : "/stores";

    const storeEnabled =
        navigations?.navigationAccesses?.stores?.controlBar?.stores ||
        !navigations?.navigationAccesses?.stores?.controlBar;

    const hasStockAccess = hasUserCompletedOnboarding(navigations);

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Stores", icon: "fa-magnifying-glass" }]);

    props.setControlBarCenterBtns([
        { to: storesTo, active: false, disabled: !storeEnabled, title: "Stores" },
        { to: "/stores/staff", active: false, disabled: true, title: "Staff" },
        { to: "/stores/pos", active: true, disabled: false, title: "POS" },
        { to: "/stock", active: false, disabled: !hasStockAccess, title: "Stock" }
    ]);

    props.setControlBarRightBtns([
        { /*to: "#", */ active: !isViewPage, disabled: isViewPage, title: "Add", icon: "plus" },
        { to: "/stores", active: isViewPage, disabled: !isViewPage, title: "View", icon: "eye" },
        { to: "/stores/pos", active: false, disabled: true, title: "Edit", icon: "pencil-line" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle="SAVE"
                btnEnabled={saveButtonEnabled && !isViewPage}
                onClick={onBtnClicked}
            />
        )
    });
}

export const CheckboxWithContent = ({
    isChecked,
    onCheckboxClick,
    description,
    rightButton,
    bottomInputField,
    disabled,
    isCurrentStep = false,
    useSelectedBorder = true,
    style
}) => {
    const { title, onClick, disabled: rightDuttonIsDisabled } = rightButton || {};

    const { placeholder, onChange, invalidAssistiveText, defaultValue } = bottomInputField || {};
    let inputRef = useRef(null);

    const onChangeInput = () => {
        if (inputRef?.isValid) {
            //force validation
            setTimeout(() => {
                inputRef?.isValid(true);
            }, 50);
        }

        if (onChange) onChange();
    };

    return (
        <CheckboxWithContentStyle
            className={`frame-313871 ${disabled ? "disabled" : ""} ${
                isCurrentStep && useSelectedBorder ? "current-step" : ""
            }`}
            style={style}>
            <div className="frame-1000011183">
                <Checkbox
                    isChecked={isChecked}
                    onCheckboxClick={() => !disabled && onCheckboxClick && onCheckboxClick(inputRef)}
                    disabled={disabled}
                />
                <div
                    className="checkboxDescription"
                    onClick={() => !disabled && onCheckboxClick && onCheckboxClick(inputRef)}
                    dangerouslySetInnerHTML={{ __html: description }}></div>
                {rightButton && (
                    <BtnLargeSolid
                        title={title}
                        onClick={onClick}
                        disabled={rightDuttonIsDisabled}
                        normalColor="var(--Buttons-Orange-Active)"
                        hoverColor="var(--Buttons-Orange-Hover)"
                        activeColor="var(--Buttons-Orange-Active)"
                        textStyle={{ fontSize: "12px" }}
                        style={{ width: "150px" }}
                    />
                )}
            </div>
            {bottomInputField && (
                <FormField
                    type="text"
                    ref={el => (inputRef = el)}
                    varName="bottomInputField"
                    placeholder={placeholder}
                    onTextChange={onChangeInput}
                    requiredType={disabled ? "locked" : "mandatory"}
                    invalidAssistiveText={invalidAssistiveText}
                    value={defaultValue}
                />
            )}
        </CheckboxWithContentStyle>
    );
};

/* #endregion */
