import React, { useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import styled from "styled-components";
import footerlogo from "../../../assets/images/footer-logologin.png";
import preenrollmentLeftImage from "../../../assets/images/BOstageR-Landing_Graphic.png";
import loginTopImg from "../../../assets/images/login-top-img.png";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import * as Library from "../../../utils/Library";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import { APP_TITLE } from "../../../config/constant";
import moment from "moment/moment";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";
import { useNavigate, useParams } from "react-router";
import DropdownOverlay, { OverlayType } from "../../common/dropdownOverlay/DropdownOverlay";
import { getDropdownData } from "../../../utils/dropdownData";
import { Link } from "react-router-dom";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .loading-filters {
        opacity: 0.5;
        filter: blur(4px);
    }

    & {
        height: 100dvh;
        overflow: scroll;

        background: var(--backgrounds-lines-n-600);
        display: flex;
        justify-content: center;
    }

    .FrameLeft {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #262e3e;
        position: relative; /* Added to position children absolutely */
        align-items: center; /* Center children horizontally */
        justify-content: space-around;
        padding: 25px 0px 25px 0px;
    }
    .LeftTitle-logo {
        width: 100%;
        height: 85px;
        padding: 0px 55px;
    }
    .btn-group-downlaod {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .downlaod-btn {
        background-color: #d37e00;
        padding: 10px;
        height: 36px;
        width: 166px;
        border-radius: 4px;
        font-size: 10px;
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }
    .FrameRight {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-direction: column;
    }

    .Footer {
        padding-bottom: 20px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }

    .TermsConditions {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .PrivacySecurity {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .BotagNorgeAs2025 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .AppVersion120 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .LoginTopImg {
        width: 132px;
        height: 42px;
    }

    .LeftContainer {
        width: 100%;
        height: 750px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .LeftImg {
        display: flex;
        width: 741px;
        height: 100%;
        object-fit: contain;
    }

    /* For screens larger than 1920x1080 (Full HD) */
    @media screen and (min-width: 1921px) {
        .LeftContainer {
            height: 850px;
        }
    }

    /* For screens larger than 2560x1440 (QHD / 1440p) */
    @media screen and (min-width: 2400px) {
        .LeftContainer {
            height: 1000px;
        }
        .LeftImg {
            width: 848px;
        }
    }

    /* For screens larger than 3840x2160 (4K UHD) */
    @media screen and (min-width: 2560px) {
        .LeftContainer {
            height: 1100px;
        }
        .LeftImg {
            width: 948px;
        }
    }

    /* For screens larger than 5120x2880 (5K) */
    @media screen and (min-width: 2880px) {
        .LeftContainer {
            height: 1350px;
        }
        .LeftImg {
            width: 1048px;
        }
    }

    /* For screens larger than 7680x4320 (8K UHD) */
    @media screen and (min-width: 7681px) and (min-height: 4321px) {
        body {
            background-color: #b0b0b0;
            font-size: 26px;
        }
    }

    .LeftTitle {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 50px;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        letter-spacing: -0.06rem;

        display: flex;
        align-self: center;
    }

    .LeftTitle strong {
        display: contents;
        color: #fff;
        font-family: Roboto;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.06rem;
    }

    .Frame313264 {
        max-width: 400px;
        min-width: 320px;
        flex-direction: column;
        gap: 15px;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
    }

    .Image1 {
        width: 208px;
        height: 51px;
        background: url(${footerlogo}) 0% 0% / contain no-repeat;
    }

    .Frame313326 {
        align-self: stretch;
        //height: 290px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .Frame313266 {
        align-self: stretch;
        //height: 262px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        display: flex;
    }

    .Frame313263 {
        align-self: stretch;
        height: 23px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        display: flex;
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .LoginWithYourCredentials {
        align-self: stretch;
        text-align: center;
        color: white;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        height: 23px;
    }

    .Frame313260 {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 11px;
        display: flex;
        align-self: stretch;
    }

    .InputField {
        height: 50px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
        align-self: stretch;
    }

    .InputField.error {
        height: 81px !important;
    }

    .EmailAddress {
        align-self: stretch;
        color: #d1d1d1;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
        height: 16px;
    }

    .PrefixLabel {
        align-self: stretch;
        color: #7c868b;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
        height: 14px;
        align-self: stretch;

        border: 0px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame313324 {
        align-self: stretch;
        width: 100%;
        height: 28px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        display: flex;
        background: transparent;
        outline: none;
        border: 0;

        //color: var(--Primary-White-500, #fff);
        color: var(--text-icons-green-500, #0b0) !important;
        //-webkit-text-fill-color: #7c868b !important;
        padding-left: 0px;

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    //placeholder color:
    .Frame313324.placeholder {
        color: #7c868b !important;
        -webkit-text-fill-color: #7c868b !important;
    }

    .Frame313324.field-disabled {
        color: #7c868b !important;
        cursor: not-allowed;
    }

    .Frame313324 {
        height: 17px;
    }

    .Line2 {
        align-self: stretch;
        height: 0px;
        width: 100%;
        border-top: 1px #3c485a solid;
    }

    .Password {
        align-self: stretch;
        color: #7c868b;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Frame313261 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .Frame313327 {
        width: 393px;
        height: 28px;
        position: relative;
    }

    .EyeOff {
        width: 16px;
        height: 16px;
        top: 0px;
        position: absolute;
        right: 8px;
    }

    .Icon {
        width: 16px;
        height: 16px;
        position: absolute;
    }

    .ForgotPassword {
        align-self: stretch;
        text-align: right;
        color: #d1d1d1;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.18px;
        word-wrap: break-word;
    }

    .mandatory-fields {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
    }

    .mandatory-fields-span {
        color: var(--text-and-icons-gray-200);
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: 12px;
        font-weight: var(--subtitle-3-font-weight, 400);
    }

    .Share {
        color: #4f5b6d;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15px;
        word-wrap: break-word;
    }

    .loginTermsPrivacyPolicies {
        align-self: stretch;
        text-align: center;
        color: #d1d1d1;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Error {
        width: 100%;
        height: 100%;
        padding: 16px;
        background: rgba(255, 54, 54, 0.1);
        border-radius: 4px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        display: inline-flex;
    }

    .EmailInvalidSection {
        flex: 1 1 0;
        text-align: center;
        color: #ff3636;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        transition: all 0.3s ease;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px transparent;
    }

    .header {
        padding: 24px 24px 0px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        height: 64px;
        position: relative;
        backdrop-filter: blur(40px);
    }
    .image-2 {
        flex-shrink: 0;
        width: 96px;
        height: 30px;
        position: relative;
        object-fit: cover;
    }
    .frame-29222 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29518 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .marker-pin-04 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29219 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .bell-02 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }

    .frame-1 {
        display: flex;
        flex-direction: column;
        gap: 39px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 58px;
        margin-top: 40px;
    }
    .the-brand-stage-for-botag-technology {
        color: #ffffff;
        text-align: center;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 58px;
    }
    .the-brand-stage-for-botag-technology-span {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 100;
    }
    .the-brand-stage-for-botag-technology-span2 {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 300;
    }
    .the-brand-stage-for-botag-technology-span3 {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 100;
    }
    .mobileBackground {
        border-radius: 16px;
        background: rgba(16, 20, 27, 0.7);
        backdrop-filter: blur(50px);
        padding: 0 24px 11px 24px;
    }

    .ellipse-2 {
        background: #ff9900;
        border-radius: 50%;
        width: 343px;
        height: 423px;
        position: absolute;
        left: 11px;
        top: 781px;
        filter: blur(201.45px);
    }
    .ellipse-1 {
        background: #ff99004d;
        border-radius: 50%;
        width: 250px;
        height: 1005px;
        position: absolute;
        left: -87px;
        top: 378px;
        filter: blur(130.95px);
    }
    .rectangle-10 {
        background: rgba(16, 20, 27, 0.8);
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .frame-313865 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .frame-313866 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .terms-conditions {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .privacy-security {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .b-otag-norge-as-2025 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .app-version-1-2-0 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
`;

const StyledThankYou = styled.div`
    &.frame-313266,
    &.frame-313266 * {
        box-sizing: border-box;
    }
    &.frame-313266 {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }
    .image-1 {
        flex-shrink: 0;
        width: 208px;
        height: 51px;
        position: relative;
        object-fit: contain;
    }
    .line-4 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-259 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-286 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .thank-you-for-pre-enrolling-on-b-otag {
        color: #ffffff;
        text-align: center;

        font-size: 32px;
        font-weight: 700;
        position: relative;
    }
    .frame-285 {
        display: flex;
        flex-direction: column;
        gap: 22px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .please-check-your-email-for-next-steps {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        font-weight: var(--heading-4-font-weight, 400);
        position: relative;
        //width: 327px;
    }

    .BOstageColor.enabled.download-pdf-btn {
        background: var(--BOStageR-Orange-Active, #d37e00) !important;
    }
`;

const StyledCheckboxesSection = styled.div`
    & {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .input-field {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .your-email {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313324 {
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .martin-tigerofsweden-se {
        color: var(--primary-white-500, #ffffff);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .line-2 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .i-m-a-label-brand {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 13px);
        font-weight: bold;
        position: relative;
        align-self: stretch;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        height: 20px;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        position: relative;
        margin-top: 15px;
        cursor: pointer;
    }
    .checkbox-label-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: 13px;
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        flex: 1;
        cursor: pointer;
        margin-top: 10px;
    }
    .i-m-a-store-brand {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: 13px;
        font-weight: bold;
        position: relative;
        align-self: stretch;
        margin-top: 15px;
    }
`;

/* #endregion */

export default function PreEnrollmentInvitedPage(props) {
    document.title = `${APP_TITLE} - Pre Enrollment`;

    /* #region STATES */
    const FrameRightMobile = {
        placeItems: "flex-start",
        height: "100dvh"
    };

    const { showThankRedirectedYouOnLoad } = props;
    const { inviteID } = useParams();
    const navigate = useNavigate();

    const [showTAC, setShowTAC] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isPageLocked, setIsPageLocked] = useState(false);

    const [currentInviteType, setCurrentInviteType] = useState("");
    /* #endregion */

    /* #region EFFECTS */

    useEffect(() => {
        if (!inviteID && !Library.isLocalhost("PreEnrollmentInvitedPage with no inviteID")) {
            setIsPageLocked(true);
            Library.showToastMessage({
                type: "error",
                title: "Error",
                description: "Invalid invite link!"
            });
            navigate("/login");
        }
    }, [inviteID]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    /* #endregion */

    return (
        <StyledPage style={{ overflow: "visible" }}>
            {/* <FullScreenLoader isLoading={loadingCompanies} /> */}
            {showTAC && <TermsAndPrivacyPanel type="terms" onCloseClicked={() => setShowTAC(false)} />}
            {showPrivacy && <TermsAndPrivacyPanel type="privacy" onCloseClicked={() => setShowPrivacy(false)} />}

            {!isMobile && (
                <div className="FrameLeft">
                    <div className="LeftTitle-logo">
                        <img src={loginTopImg} className="LoginTopImg" alt="BOtag Technology" />
                    </div>

                    <div className="LeftContainer">
                        <img src={preenrollmentLeftImage} className="LeftImg" alt="BOtag Technology" />
                    </div>
                </div>
            )}

            {isMobile && (
                <>
                    <div className="ellipse-2"></div>
                    <div className="ellipse-1"></div>
                    <div className="rectangle-10"></div>
                </>
            )}

            <div className="FrameRight" style={isMobile ? FrameRightMobile : {}}>
                <FrameRightComponent
                    inviteID={inviteID}
                    isMobile={isMobile}
                    showThankRedirectedYouOnLoad={showThankRedirectedYouOnLoad}
                    setShowTAC={setShowTAC}
                    setShowPrivacy={setShowPrivacy}
                    setIsPageLocked={setIsPageLocked}
                    currentInviteType={currentInviteType}
                    setCurrentInviteType={setCurrentInviteType}
                />
                {!isMobile && (
                    <div className="Footer">
                        <div
                            className="TermsConditions hover-not-allowed d-none"
                            onClick={() => {
                                //setShowTAC(true);
                            }}>
                            Terms & Conditions
                        </div>
                        <div
                            className="PrivacySecurity hover-not-allowed d-none"
                            onClick={() => {
                                //setShowTAC(true);
                            }}>
                            Privacy & Security
                        </div>
                        <div className="BotagNorgeAs2025">BOtag Norge AS © {moment().format("yyyy")}</div>
                        <div className="AppVersion120">App Version {packageJson.version}</div>
                    </div>
                )}
            </div>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */

const FrameRightComponent = ({
    inviteID,
    isMobile,
    showThankRedirectedYouOnLoad,
    setShowTAC,
    setShowPrivacy,
    setIsPageLocked,
    currentInviteType,
    setCurrentInviteType
}) => {
    /* #region VARS */
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [showThankYou, setShowThankYou] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [brandIsInvalid, setBrandIsInvalid] = useState(false);
    const [brandName, setBrandName] = useState("");
    const [website, setWebsite] = useState("");
    const [websiteIsInvalid, setWebsiteIsInvalid] = useState(false);
    const [instagram, setInstagram] = useState("");
    const [instagramIsInvalid, setInstagramIsInvalid] = useState(false);
    const [company, setCompany] = useState("");
    const [companyIsInvalid, setCompanyIsInvalid] = useState(false);
    const [country, setCountry] = useState({
        value: "",
        countryFromDropdown: false
    });
    const [countryIsInvalid, setCountryIsInvalid] = useState(false);
    const [registrationNr, setRegistrationNr] = useState("");
    const [registrationNrIsInvalid, setRegistrationNrIsInvalid] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
    const [email, setEmail] = useState("");
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);

    const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
    const [companyAlreadyExists, setCompanyAlreadyExists] = useState(false);

    const [showCountryDropdown, setShowCountryDropdown] = useState(false);
    const [countryOptionsData] = useState(getDropdownData("countryOptions"));

    const flexMobileContainer = {
        width: "100%",
        display: "flex",
        gap: isMobile ? "16px" : "46px",
        flexDirection: isMobile ? "column" : "row"
    };
    /* #endregion */

    /* #region METHODS */
    const handleChangeRegistrationNr = e => {
        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
        inputValue = inputValue.slice(0, 9); // Limit to 9 digits

        // Add space after every 3 digits
        const formattedValue = inputValue.replace(/(\d{3})(?=\d)/g, "$1 ");

        setRegistrationNr(formattedValue);
    };

    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleValidation = () => {
        if (buttonDisable) return false;
        let formIsValid = true;
        if (!brandName) {
            formIsValid = false;
            setBrandIsInvalid(true);
        }
        /* if (!website) {
            formIsValid = false;
            setWebsiteIsInvalid(true);
        } */
        if (!company) {
            formIsValid = false;
            setCompanyIsInvalid(true);
        }
        if (!country?.value && !country?.countryFromDropdown) {
            formIsValid = false;
            setCountryIsInvalid(true);
        }
        if (!firstName) {
            formIsValid = false;
            setFirstNameIsInvalid(true);
        }
        // if (!lastName) {
        //     formIsValid = false;
        //     setLastNameIsInvalid(true);
        // }
        if (!email) {
            formIsValid = false;
            setEmailIsInvalid(true);
        } else if (isNaN(email)) {
            if (!validateEmail(email)) {
                formIsValid = false;
                setEmailIsInvalid(true);
            } else setEmailIsInvalid(false);
        }

        /* if (!checkboxLabelBrandSelected && !checkboxStoreBrandSelected) {
            formIsValid = false;
        }*/
        return formIsValid;
    };

    const handleSubmit = e => {
        setBrandIsInvalid(false);
        setWebsiteIsInvalid(false);
        setInstagramIsInvalid(false);
        setCompanyIsInvalid(false);
        setCountryIsInvalid(false);
        setRegistrationNrIsInvalid(false);
        setFirstNameIsInvalid(false);
        setLastNameIsInvalid(false);
        setEmailIsInvalid(false);
        setEmailAlreadyExists(false);
        setCompanyAlreadyExists(false);

        setErrorMessage("");
        e.preventDefault();
        if (handleValidation()) {
            setButtonDisable(true);

            if (!currentInviteType) {
                Library.showToastMessage({
                    type: "error",
                    title: "Error in invite type",
                    description: "Invalid invite type! Try refreshing the page and try again."
                });
                return;
            }

            const formData = {
                inviteID: inviteID,
                organizationInfo: {
                    organizationName: brandName,
                    stages: ["BOstageR"]
                },
                retailBrandInfo: {
                    retailBrandName: brandName,
                    //website: "https://" + website,
                    //instagram: "https://www.instagram.com/" + instagram
                    website: website?.toLowerCase(),
                    instagram: instagram?.toLowerCase()
                },
                companyInfo: {
                    companyName: company,
                    countryName: country?.value,
                    registrationNumber: registrationNr?.replace(/\s/g, "")
                },
                userInfo: {
                    userFirstName: firstName,
                    userLastName: lastName,
                    userEmail: email
                }
            };
            setIsLoading(true);

            Library.makeCommonPostRequest(
                "Organization",
                "createOrganization",
                formData,
                false,
                setButtonDisable,
                undefined,
                undefined,
                false
            ).then(res => {
                setIsLoading(false);
                if (res.data.status !== 200) {
                    const data = res.data.data;
                    if (data && data?.user_already_created) {
                        handleInvitationError(
                            "Login to your account and add the brand from there. If this doesn't make sense, please contact us at <b>support@botag.no</b>.",
                            "Your email has an account already!",
                            false,
                            setIsPageLocked
                        );
                        setEmailAlreadyExists(true);
                    } else if (data && data?.company_already_created) {
                        handleInvitationError(
                            "Login to your account and add the brand from there. If this doesn't make sense, please contact us at <b>support@botag.no</b>.",
                            "Company already exists!",
                            false,
                            setIsPageLocked
                        );
                        setCompanyAlreadyExists(true);
                    } else handleInvitationError(res.data.message, "Error", false, setIsPageLocked);
                    return;
                }

                setShowThankYou(true);
            });
        }
    };

    const handleKeyPress = e => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    const onDownloadPDFClicked = lang => {
        let pdfURL = "";

        if (lang === "en") {
            pdfURL = "https://botagtechnology.com/wp-content/uploads/2024/12/BOtag-x-FS-New-Sales-Channel-EN-1.pdf";
        } else {
            pdfURL = "https://botagtechnology.com/wp-content/uploads/2024/12/BOtag-x-FS-New-Sales-Channel-NO-1.pdf";
        }
        window.open(pdfURL, "_blank");
    };

    const getInviteData = () => {
        Library.makeCommonPostRequest(
            "Invites",
            "getInvite",
            { inviteID: inviteID },
            false,
            setIsLoading,
            undefined,
            undefined,
            false
        ).then(res => {
            if (res.data.status !== 200) {
                handleInvitationError(res.data.message, "Invitation Error", true, setIsPageLocked);
                return;
            }
            const data = res.data.data;
            if (!data?.sentToEntityCountry || !data?.newEntityName) {
                navigate("/login");

                Library.showToastMessage({
                    type: "error",
                    title: "Missing Data",
                    description: "Missing Retail Brand or Country",
                    onClose: () => setIsPageLocked(false)
                });
                return;
            }

            if (!data || !["invited", "accepted"].includes(data?.status?.status?.toLowerCase())) {
                handleInvitationError("This invitation is no longer valid.", "Invitation Error", true, setIsPageLocked);
                return;
            }
            if (
                data?.inviteType?.toLowerCase() !== "retailbrand" &&
                data?.inviteType?.toLowerCase() !== "retailer" &&
                data?.inviteType?.toLowerCase() !== "labelbrand"
            ) {
                handleInvitationError("Invalid invite type.", "Invitation Error", true, setIsPageLocked);
                return;
            }

            setCurrentInviteType(data?.inviteType?.toLowerCase());

            /* if (data?.status?.status?.toLowerCase() === "invited") {
                updateInviteRequest();
            } */

            setCountry({
                value: data?.sentToEntityCountry,
                countryFromDropdown: true
            });
            setBrandName(data?.newEntityName);
            setEmail(data?.contactEmail);
            setFirstName(data?.contactFirstName);
            setLastName(data?.contactLastName);
        });
    };

    const handleInvitationError = (errorMessage, errorTitle = "Invitation Error", redirect = true, setIsPageLocked) => {
        setIsPageLocked(true);
        Library.showToastMessage({
            type: "error",
            title: errorTitle,
            description: errorMessage,
            onClose: () => setIsPageLocked(false)
        });
        if (redirect) navigate("/login");
    };

    /* const updateInviteRequest = () => {
        Library.makeCommonPostRequest(
            "Invites",
            "updateInvite",
            { inviteID: inviteID, status: "Enrolling" },
            false,
            setIsLoading
        ).then(res => {
            if (res.data.status !== 200) {
                return;
            }
        });
    }; */

    // eslint-disable-next-line no-unused-vars
    const updateRetailerInvite = ({ inviteID, justCreatedLabelBrandID, justCreatedRetailBrandID }) => {
        Library.makeCommonPostRequest(
            "Invites",
            "updateRetailerInvite",
            { inviteID: inviteID, justCreatedLabelBrandID, justCreatedRetailBrandID },
            false,
            setIsLoading
        ).then(res => {
            if (res.data.status !== 200) {
                return;
            }
        });
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (
            brandName &&
            company &&
            country?.value &&
            country?.countryFromDropdown &&
            firstName &&
            // lastName &&
            email
        ) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [brandName, company, country?.value && country?.countryFromDropdown, firstName, email]);

    useEffect(() => {
        if (inviteID) getInviteData();
    }, [inviteID]);

    // useEffect(() => {
    //     if (showCountryDropdown) {
    //         const closeDropdown = event => {
    //             if (!event.target.closest(".countryDropdown")) {
    //                 setShowCountryDropdown(false);
    //             }
    //         };

    //         document.addEventListener("click", closeDropdown);
    //         return () => document.removeEventListener("click", closeDropdown);
    //     }
    // }, [showCountryDropdown]);
    /* #endregion */

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                justifyContent: "center"
                //overflowY: "visible",
                //overflowX: "hidden"
            }}>
            {isMobile && (
                <div className="header">
                    <img className="image-2" src={loginTopImg} alt="" />
                    <div className="frame-29222">
                        <div className="frame-29518"></div>
                    </div>
                    <div className="frame-29219">
                        <div className="frame-29518"></div>
                    </div>
                </div>
            )}
            <div
                className={`Frame313264 ${
                    isMobile && !showThankYou && !showThankRedirectedYouOnLoad ? "mobileBackground" : ""
                }`}
                style={{
                    height: showThankYou || showThankRedirectedYouOnLoad ? "100%" : "auto",
                    overflow: "auto"
                }}>
                {!showThankYou && !showThankRedirectedYouOnLoad ? (
                    <>
                        <div className="Image1" style={{ marginBottom: "0px" }} />

                        {isMobile && (
                            <div className="frame-1">
                                <div className="the-brand-stage-for-botag-technology">
                                    <span>
                                        <span className="the-brand-stage-for-botag-technology-span">
                                            THE STORE BRAND STAGE FOR 
                                            <br />
                                        </span>
                                        <span className="the-brand-stage-for-botag-technology-span2">BO</span>
                                        <span className="the-brand-stage-for-botag-technology-span3">
                                            TAG TECHNOLOGY
                                        </span>
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="Frame313326">
                            <div className="Frame313266">
                                <div className="Frame313263">
                                    <div className="LoginWithYourCredentials">PRE-ENROLLMENT FORM</div>
                                </div>
                                <div className={`Error ${errorMessage ? "" : "hidden"}`}>
                                    <div className="EmailInvalidSection">{errorMessage}</div>
                                </div>
                                <ContainerLoader isLoading={isLoading} isLoadingOverlay={true} addBlurFilters={true}>
                                    <div className="Frame313260">
                                        <SingleField
                                            title="Company"
                                            type="text"
                                            value={company}
                                            onChange={e => setCompany(e.target.value)}
                                            isInvalid={companyIsInvalid || companyAlreadyExists}
                                            errorMessage={
                                                companyAlreadyExists
                                                    ? "Company already exists."
                                                    : "Company is required."
                                            }
                                            isRequiredAsterisk={true}
                                        />
                                        <SingleField
                                            title="Retail Brand"
                                            isDisabled={true}
                                            type="text"
                                            value={brandName}
                                            onChange={e => setBrandName(e.target.value)}
                                            isInvalid={brandIsInvalid}
                                            errorMessage="Brand name is required."
                                            isRequiredAsterisk={true}
                                            className="field-disabled"
                                        />

                                        <SingleField
                                            title="Website"
                                            type="text"
                                            value={website}
                                            onChange={e => setWebsite(e.target.value?.toLowerCase())}
                                            isInvalid={websiteIsInvalid}
                                            errorMessage="Website is required."
                                            //prefixLabel="https://"
                                            //isRequiredAsterisk={true}
                                            placeholder="https://"
                                            className={!website ? "placeholder" : ""}
                                        />

                                        <SingleField
                                            title="Instagram"
                                            type="text"
                                            value={instagram}
                                            onChange={e => setInstagram(e.target.value?.toLowerCase())}
                                            isInvalid={instagramIsInvalid}
                                            errorMessage="Instagram is required."
                                            //prefixLabel="https://www.instagram.com/"
                                            limit={60}
                                            placeholder="https://www.instagram.com/"
                                            className={!instagram ? "placeholder" : ""}
                                        />

                                        <div style={flexMobileContainer}>
                                            <div
                                                className={`InputField w-100 ${countryIsInvalid ? "error" : ""}`}
                                                style={{ gap: "0" }}>
                                                <div
                                                    className="flex flex-row w-100 cursor-pointer"
                                                    style={{ position: "relative" }}>
                                                    <SingleField
                                                        title="Country"
                                                        isDisabled={true}
                                                        type="text"
                                                        value={country?.value}
                                                        onChange={e => setCountry(e.target.value)}
                                                        isInvalid={countryIsInvalid}
                                                        isLabel={true}
                                                        showDropdownIcon={true}
                                                        errorMessage="Country is required."
                                                        isRequiredAsterisk={true}
                                                        onClick={() => {
                                                            //setShowCountryDropdown(!showCountryDropdown);
                                                        }}
                                                        className="field-disabled"
                                                    />

                                                    {showCountryDropdown && (
                                                        <DropdownOverlay
                                                            isSearchable={true}
                                                            data={countryOptionsData}
                                                            onSingleItemClick={singleItemData => {
                                                                setCountry({
                                                                    value: singleItemData?.value,
                                                                    countryFromDropdown: true
                                                                });
                                                                setShowCountryDropdown(false);
                                                            }}
                                                            isChangeColor={true}
                                                            overlayType={OverlayType?.UNI_SELECT}
                                                            isRequiredAsterisk={true}
                                                            style={{
                                                                width: "100%",
                                                                top: "56px",
                                                                zIndex: 1000,
                                                                position: "absolute"
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                                {countryIsInvalid && (
                                                    <div className="EmailInvalidSection">{errorMessage}</div>
                                                )}
                                            </div>

                                            <SingleField
                                                title="Registration nr"
                                                type="text"
                                                value={registrationNr}
                                                onChange={e => handleChangeRegistrationNr(e)}
                                                isInvalid={registrationNrIsInvalid}
                                                errorMessage="Registration nr is required."
                                                limit={40}
                                            />
                                        </div>

                                        <div style={flexMobileContainer}>
                                            <SingleField
                                                title="Your first name"
                                                type="text"
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                isInvalid={firstNameIsInvalid}
                                                errorMessage="First name is required."
                                                limit={60}
                                                isRequiredAsterisk={true}
                                            />

                                            <SingleField
                                                title="Your last name"
                                                type="text"
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                isInvalid={lastNameIsInvalid}
                                                errorMessage="Last name is required."
                                                limit={60}
                                                // isRequiredAsterisk={true}
                                            />
                                        </div>

                                        <SingleField
                                            title="Your email"
                                            type="text"
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                                setEmailIsInvalid(false);
                                            }}
                                            isInvalid={emailIsInvalid || emailAlreadyExists}
                                            errorMessage={
                                                emailAlreadyExists ? "Email already exists." : "Email is required."
                                            }
                                            isRequiredAsterisk={true}
                                        />

                                        <div className={`Frame313261 ${isLoading ? "loading-filters" : ""}`}>
                                            <div className="mandatory-fields">
                                                <span
                                                    className={`mandatory-fields-span ${
                                                        buttonDisable ? "text-orange-500" : ""
                                                    }`}>
                                                    *mandatory fields
                                                </span>
                                            </div>
                                            <div className="mandatory-fields">
                                                <span className="mandatory-fields-span2">
                                                    Problems pre-enrolling? Let us know:{" "}
                                                </span>
                                                <span className="mandatory-fields-span2 text-white">
                                                    <a
                                                        className="text-gray-200"
                                                        href="mailto:support@botag.no?subject=Problems%20pre-enrolling"
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        support@botag.no
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                        <BtnLargeSolid
                                            className={`w-100 ${isLoading ? "loading-filters" : ""}`}
                                            disabled={isLoading || buttonDisable}
                                            useBOstageColors={false}
                                            onClick={handleSubmit}
                                            onKeyUp={handleKeyPress}
                                            textStyle={{ fontSize: "0.75rem", color: "#fff" }}
                                            style={{ height: "45px" }}
                                            // normalColor="var(--Buttons-Orange-Outline)"
                                            normalColor="var(--Buttons-Orange-Active)"
                                            hoverColor="var(--Buttons-Orange-Hover)"
                                            activeColor="var(--Buttons-Orange-Active)">
                                            CREATE ACCOUNT
                                        </BtnLargeSolid>
                                        <div
                                            className={`loginTermsPrivacyPolicies ${
                                                isLoading ? "loading-filters" : ""
                                            }`}>
                                            <span style={{ fontSize: "11px" }}>
                                                By continuing, you agree to BOtag Norge AS{" "}
                                            </span>
                                            <span
                                                className="underline cursor-pointer"
                                                onClick={() => {
                                                    setShowTAC(true);
                                                    setShowPrivacy(false);
                                                }}>
                                                Terms of Use
                                            </span>
                                            <span> and </span>
                                            <span
                                                className="underline cursor-pointer"
                                                onClick={() => {
                                                    setShowTAC(false);
                                                    setShowPrivacy(true);
                                                }}>
                                                Privacy Policy
                                            </span>
                                            <span>.</span>
                                        </div>
                                    </div>
                                </ContainerLoader>
                            </div>
                        </div>
                    </>
                ) : (
                    <StyledThankYou className="frame-313266">
                        <img className="image-1" src={footerlogo} alt="BOStageR" />
                        <div className="line-4"></div>
                        <div className="frame-259">
                            <div className="frame-286">
                                <div className="thank-you-for-pre-enrolling-on-b-otag">
                                    Thank you for
                                    <br />
                                    pre-enrolling {brandName} on BOtag
                                </div>
                            </div>
                            <div className="frame-285">
                                {showThankRedirectedYouOnLoad && (
                                    <div className="frame-160">
                                        <div className="please-check-your-email-for-next-steps">
                                            As a <span style={{ fontWeight: "bold" }}>Store Brand</span> you are being
                                            redirected to <span style={{ fontWeight: "bold" }}>BO</span>stageR, the{" "}
                                            <span style={{ fontWeight: "bold" }}>BO</span>tag platform for Store Brands.
                                        </div>
                                    </div>
                                )}
                                <div className="frame-160">
                                    <div className="please-check-your-email-for-next-steps">
                                        We are preparing your trial account now. You’ll get an email with credentials as
                                        soon as it’s ready.
                                        <br /> <br />
                                        In the meantime, take a look at what BOtag can do for your business by
                                        downloading our brochure.
                                    </div>
                                    {/* <BtnLargeSolid
                                        className="download-pdf-btn"
                                        onClick={onDownloadPDFClicked}
                                        style={{ width: "100%", height: "45px" }}
                                        textStyle={{ fontSize: "0.75rem", color: "#fff" }}
                                        // normalColor="var(--Buttons-Orange-Outline)"
                                        normalColor="var(--Buttons-Orange-Active)"
                                        hoverColor="var(--Buttons-Orange-Hover)"
                                        activeColor="var(--Buttons-Orange-Active)">
                                        LAST NED BOTAG-BROSJYRE
                                    </BtnLargeSolid>
                                    <BtnLargeSolid
                                        className="download-pdf-btn"
                                        onClick={() => onDownloadPDFClicked("en")}
                                        style={{ width: "100%", height: "45px" }}
                                        textStyle={{ fontSize: "0.75rem", color: "#fff" }}
                                        // normalColor="var(--Buttons-Orange-Outline)"
                                        normalColor="var(--Buttons-Orange-Active)"
                                        hoverColor="var(--Buttons-Orange-Hover)"
                                        activeColor="var(--Buttons-Orange-Active)">
                                        DOWNLOAD BOTAG BROCHURE
                                    </BtnLargeSolid> */}
                                </div>
                            </div>
                        </div>
                    </StyledThankYou>
                )}
            </div>
            {isMobile && (
                <div
                    className="frame-313865"
                    style={{ marginTop: "13px", marginBottom: "30px", gap: "0px", height: "37px" }}>
                    <div className="frame-313866">
                        <div className="terms-conditions cursor-pointer" onClick={() => setShowTAC(true)}>
                            Terms &amp; Conditions
                        </div>
                        <div className="privacy-security cursor-pointer" onClick={() => setShowPrivacy(true)}>
                            Privacy &amp; Security
                        </div>
                    </div>
                    <div className="b-otag-norge-as-2025">BOtag Norge AS © {moment().format("yyyy")}</div>
                    <div className="app-version-1-2-0">App Version {packageJson.version}</div>
                </div>
            )}
        </div>
    );
};

const SingleField = ({
    title,
    type,
    value,
    onChange,
    onKeyUp,
    isInvalid = false,
    isRequiredAsterisk = false,
    errorMessage,
    isDisabled = false,
    limit = 80,
    prefixLabel,
    onClick,
    isLabel = false,
    showDropdownIcon = false,
    placeholder,
    className = "",
    loading = false
}) => {
    return (
        <div className={`InputField w-100 ${isInvalid ? "error" : ""}`} onClick={onClick}>
            <div className="EmailAddress">
                {title}{" "}
                {isRequiredAsterisk ? (
                    <span
                        style={{
                            color: !value ? "var(--text-and-icons-orange-500)" : "var(--text-and-icons-gray-200)"
                        }}>
                        *
                    </span>
                ) : (
                    ""
                )}
                {/* {loading && <Loader size={50} />} */}
            </div>
            <div className="flex flex-row w-100">
                {prefixLabel && <div className="PrefixLabel">{prefixLabel}</div>}

                <Field
                    isLabel={isLabel}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    disabled={isDisabled}
                    className={`Frame313324 ${className}`}
                    placeholder={placeholder}
                    style={{
                        borderColor: isInvalid ? "red" : "",
                        color: isInvalid ? "red" : isDisabled ? "#7c868b" : ""
                    }}
                    maxLength={limit}
                    loading={loading}
                />

                {showDropdownIcon && (
                    <div className="droppedRemove" style={{ height: "14px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.52864 6.02864C3.78899 5.76829 4.2111 5.76829 4.47145 6.02864L8.00004 9.55723L11.5286 6.02864C11.789 5.76829 12.2111 5.76829 12.4714 6.02864C12.7318 6.28899 12.7318 6.7111 12.4714 6.97145L8.47145 10.9714C8.2111 11.2318 7.78899 11.2318 7.52864 10.9714L3.52864 6.97145C3.26829 6.7111 3.26829 6.28899 3.52864 6.02864Z"
                                fill="#D1D1D1"
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div className="Line2" style={{ marginTop: "6px" }} />
            {isInvalid && <div className="EmailInvalidSection">{errorMessage}</div>}
        </div>
    );
};

const Field = props => {
    const propsRecognized = {
        type: props.type,
        value: props.value,
        onChange: props.onChange,
        onKeyUp: props.onKeyUp,
        disabled: props.disabled,
        className: props.className,
        style: props.style,
        maxLength: props.maxLength,
        placeholder: props.placeholder
    };
    return !props.isLabel ? <input {...propsRecognized} /> : <label {...propsRecognized}>{props.value}</label>;
};

/* #endregion */
