import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import { useNavigate, useOutletContext } from "react-router";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import * as Library from "../../../utils/Library";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import AppContext from "../../../AppContext";
import { hasUserCompletedOnboarding } from "../../../utils/navigationHelper";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
    }
`;
/* #endregion */

/* #region LIST COMPONENT PROPS */
const listColumns = [
    {
        name: "First Name",
        selector: row => row?.firstName ?? "--"
    },
    {
        name: "Last Name",
        selector: row => row?.lastName ?? "--"
    },
    {
        name: "Role",
        selector: row => row?.role ?? "--"
    },
    {
        name: "Stage Level",
        selector: row => row?.stageLevel ?? "Account" //TODO: Implement stage level
    },
    {
        name: "Phone number",
        selector: row => row?.mobile ?? "--"
    },
    {
        name: "Email",
        selector: row => row?.email ?? "--"
    }
];
/* #endregion */

export default function AccountUsersInvitePage() {
    document.title = `${APP_TITLE} - Account Users Invite`;

    /* #region STATES */
    const formAccountUsersInviteID = useRef();
    const formFields1Refs = [];
    const formData = formAccountUsersInviteID?.current?.getAllFieldValues();

    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [dropdownData] = useState([
        { id: "1", value: "accountAdmin", name: "Account Admin" },
        { id: "2", value: "accountManager", name: "Account Manager" },
        { id: "3", value: "accountUser", name: "Account User" }
    ]);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { setHasUnsavedChanges } = useContext(AppContext);

    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    /* #endregion */

    /* #region METHODS */
    const checkFormIsValid = (highlightValidation = false) => {
        const allAccountUsersInviteFieldsAreValid =
            formAccountUsersInviteID?.current?.allFieldsAreValid(highlightValidation);
        const allFieldsAreValid = allAccountUsersInviteFieldsAreValid;

        setSaveBtnEnabled(allFieldsAreValid);
        return allFieldsAreValid;
    };

    const openInviteDialogBox = () => {
        setOpenInviteDialog(true);
    };

    const onInviteBtnClicked = () => {
        setIsLoading(true);

        const phoneNum = formData.phoneNumber.split(" ")[1];
        const phoneNumCountryCode = formData.phoneNumber.split(" ")[0];

        const inviteData = {
            platform: "BOstageR",
            firstName: formData.firstName,
            lastName: formData.lastName,
            role: formData.role.name,
            email: formData.email,
            phoneNumber: phoneNum,
            phoneNumberCountryCode: phoneNumCountryCode,
            organizationID: localStorage.getItem("currentOrganizationID"),
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
        };

        setHasUnsavedChanges(false);

        Library.makeCommonPostRequest("Invites", "inviteUser", inviteData, false, setIsLoading).then(res => {
            setOpenInviteDialog(false);
            if (res.data.status === 200) {
                Library.showToastMessage({
                    type: "success",
                    title: "Invitation Sent!",
                    message: `${formData.firstName} will get an invitation by email with the guidelines to join this account.`
                });
                navigate("/account/users");
            } else {
                setHasUnsavedChanges(true);
            }
        });
    };
    /* #endregion */

    /* #region FORM FIELDS */
    const formRetailerFields = [
        <FormField
            type="text"
            title="First Name"
            tooltip="Enter the first name."
            tooltipMaxWidth="224px"
            requiredType="mandatory"
            varName="firstName"
            ref={el => (formFields1Refs.firstName = el)}
        />,
        <FormField
            type="text"
            title="Last Name"
            tooltip="Enter the last name."
            tooltipMaxWidth="224px"
            requiredType="simple"
            varName="lastName"
            ref={el => (formFields1Refs.lastName = el)}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropdownData}
            title="Role"
            tooltip="Enter the Role"
            requiredType="mandatory"
            varName="role"
            ref={el => (formFields1Refs.role = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Phone number"
            tooltip="Enter phone number"
            requiredType="simple"
            varName="phoneNumber"
            ref={el => (formFields1Refs.phoneNumber = el)}
        />,
        <FormField
            type="email"
            title="Email"
            tooltip="Enter the email address."
            tooltipMaxWidth="224px"
            requiredType="mandatory"
            varName="email"
            ref={el => (formFields1Refs.email = el)}
        />
    ];
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, saveBtnEnabled, openInviteDialogBox, hasUserCompletedOnboarding);
    }, [props?.layoutType, saveBtnEnabled]);
    /* #endregion */

    return (
        <StyledPage>
            <FullScreenOverlay show={openInviteDialog}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    loadingHeading="INVITING..."
                    title="Ready to invite?"
                    message={`Ready to invite ${formData?.firstName} at ${formData?.email}?`}
                    loading={isLoading}
                    onClickYes={() => {
                        onInviteBtnClicked();
                    }}
                    onClickNo={() => {
                        setOpenInviteDialog(false);
                    }}
                />
            </FullScreenOverlay>
            <FormCard
                title="INVITE USER"
                ref={formAccountUsersInviteID}
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={formRetailerFields}
                formFieldRefs={formFields1Refs}
                onFormChanged={checkFormIsValid}
                showUnsavedChangesPopup={true}
            />
            <div className="flex flex-dir-column gap-16 w-100">
                <ListComponent
                    title="Account Users"
                    title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    showOnlyLast10={true}
                    api={{
                        useCommonPostRequest: true,
                        commonContext: "Users",
                        endpoint: "getUsersData",
                        formData: {
                            organizationID: localStorage.getItem("currentOrganizationID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={10}
                    currentPage={1}
                    queryKey={"AccountsListUsersInvite"}
                    actions={{ includeActions: true, extraActions: [{ title: "Details" }] }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active"] }
                        ]
                    }}
                />
            </div>
        </StyledPage>
    );
}

function setupHeaders(props, onOpenDialod, onBtnClicked, hasUserCompletedOnboarding) {
    props.setControlBarLeftBtns([{ to: "#", disabled: false, title: "Users", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/account", active: false, disabled: false, title: "Account" },
        {
            to: "/account/users",
            active: true,
            disabled: true, //!hasUserCompletedOnboarding(props?.navigations),
            title: "Users"
        }
    ]);
    props.setControlBarRightBtns([
        {
            to: "/account/users",
            active: false,
            disable: true, //!hasUserCompletedOnboarding(props?.navigations),
            title: "View",
            icon: "eye"
        },
        { to: "#", active: true, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="INVITE" btnEnabled={onOpenDialod} onClick={onBtnClicked} />
        )
    });
}
