import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import InfinityGridProductsCards from "../../common/cardContainers/InfinityGridProductsCards";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { DeleteAllProductsFromCompany } from "./ProductsUtils";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import * as Library from "../../../utils/Library";
import OrganizeFiltersHeader from "../../common/mainApp/mainWindowHeaders/OrganizeFiltersHeader";
import { hasUserCompletedOnboarding } from "../../../utils/navigationHelper";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    &.hideOverflow {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function ProductPage() {
    document.title = `${APP_TITLE} - Products`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const navigate = useNavigate();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [noDataFound, setNoDataFound] = useState(false);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [searchValue, setSearchValue] = useState("");
    const [labelBrandsDropdownData, setLabelBrandsDropdownData] = useState([]);
    const [currentLabelBrandID, setCurrentLabelBrandID] = useState(null);
    const infiniteGridQuerykey = "ProductsGrid";
    const listQueryKey = "ProductsList";

    const [deleteDialog, setDeleteDialog] = useState({
        show: false,
        GTINsFound: "--",
        labelBrandsFound: "--",
        productsFound: "--"
    });

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    /* #endregion */

    /* #region Methods */
    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const resetInfiniteGrid = () => {
        AppQueryClient.clear();
    };

    const onSearch = searchValue => {
        setSearchValue(searchValue);
    };

    const onDeleteClick = async () => {
        setIsDeleteLoading(true);
        setDeleteDialog({ show: true, GTINsFound: "--", labelBrandsFound: "--", productsFound: "--" });
        const res = await DeleteAllProductsFromCompany({
            companyObjID: localStorage.getItem("currentCompanyID"),
            debugMode: true
        }).finally(() => setIsDeleteLoading(false));
        if (res?.data?.status !== 200) return;

        const resData = res?.data?.data;
        const { GTINsFound, labelBrandsFound, productsFound } = resData;

        if (GTINsFound === 0 && productsFound === 0) {
            Library.showToastMessage({
                type: "warning",
                title: "No products found",
                message: "No products and GTINs found to delete."
            });
            setDeleteDialog({ show: false, GTINsFound: "--", labelBrandsFound: "--", productsFound: "--" });
            return;
        }

        setDeleteDialog({ show: true, GTINsFound, labelBrandsFound, productsFound });
    };

    const deleteRequest = async () => {
        setIsDeleteLoading(true);
        const res = await DeleteAllProductsFromCompany({
            companyObjID: localStorage.getItem("currentCompanyID"),
            debugMode: false
        }).finally(() => {
            setIsDeleteLoading(false);
            setDeleteDialog({ show: false, GTINsFound: "--", labelBrandsFound: "--", productsFound: "--" });
        });
        if (res?.data?.status !== 200) return;

        const resData = res?.data?.data;
        const { productsDeleted, GTINsDeleted } = resData;

        Library.showToastMessage({
            type: "success",
            title: "All products deleted",
            message: `Successfully deleted ${productsDeleted?.deletedCount} products and ${GTINsDeleted?.deletedCount} GTINs.`
        });

        onClickRefreshAction();
    };
    const onLabelBrandChange = item => {
        setCurrentLabelBrandID(item?.value);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, onClickRefreshAction, navigations, labelBrandsDropdownData, onLabelBrandChange);
    }, [props?.layoutType, searchValue, labelBrandsDropdownData]);

    useEffect(() => {
        if (currentLabelBrandID) {
            onClickRefreshAction();
        }
    }, [currentLabelBrandID]);

    useEffect(() => {
        resetInfiniteGrid();
    }, [searchValue]);

    useEffect(() => {
        let dropdownData = [{ value: "all", name: "All", selected: true }];
        Library.makePostRequest(
            "getConnectedBrands",
            { companyID: localStorage.getItem("currentCompanyID") },
            false
        ).then(res => {
            const { data } = res;
            if (data?.status === 200) {
                const brands = data?.data.map(brand => ({
                    value: brand?._id,
                    name: brand?.name
                }));
                dropdownData = dropdownData.concat(brands);
                setCurrentLabelBrandID(dropdownData[0].value);
            } else {
                setCurrentLabelBrandID("");
            }
            setLabelBrandsDropdownData(dropdownData);
        });
    }, []);

    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "BOtag ID",
            selector: row => row?.botagID ?? "--"
        },
        {
            name: "Brand",
            selector: row => row?.brand ?? "--"
        },
        {
            name: "Style name",
            selector: row => row?.styleName ?? "--"
        },
        {
            name: "Reference",
            selector: row => row?.reference ?? "--"
        },
        {
            name: "Colors",
            selector: row => row?.colorNames?.join(", ") ?? "--"
        },
        {
            name: "Sizes",
            selector: row => row?.sizes?.join(", ") ?? "--"
        },
        {
            name: "Category",
            selector: row => row?.category ?? "--"
        }
    ];
    /* #endregion */

    return (
        <StyledPage className={viewLayoutStyle === "Grid" ? "hideOverflow" : ""}>
            <FullScreenOverlay show={deleteDialog?.show}>
                <DialogBoxComponent
                    loading={isDeleteLoading}
                    loadingHeading="PREPARING..."
                    heading="Warning"
                    headingColor="var(--text-icons-orange-500)"
                    title="Ready to delete products?"
                    onClickYes={deleteRequest}
                    onClickNo={() => setDeleteDialog({ show: false })}
                    cancelText="NO"
                    confirmText="YES"
                    message={`We found ${deleteDialog?.GTINsFound} GTINs from ${deleteDialog?.productsFound} products, belonging to
                     ${deleteDialog?.labelBrandsFound} label brands. Are you sure you want to delete all these Products and GTINs?`}
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={noDataFound}>
                <DialogBoxComponent
                    heading="ups..."
                    headingColor="var(--text-icons-green-500)"
                    title="NO PRODUCTS TO SHOW!"
                    onClickNo={() => {
                        //setNoDataFound(false);
                        navigate("/labelbrands/request");
                    }}
                    cancelText="SEND REQUEST"
                    showConfirm={false}
                    buttonWidth="150px"
                    message="You are not yet connected to any Brand. Products will show after you connect to brands."
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridProductsCards
                    className="grid"
                    minColumnWidth="294px"
                    queryKey={infiniteGridQuerykey}
                    barcode={searchValue}
                    setNoDataFound={setNoDataFound}
                    currentLabelBrandID={currentLabelBrandID}
                    //searchName={searchValue}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Products"
                    columns={listColumns}
                    api={{
                        endpoint: "getProductsData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            barcode: searchValue,
                            ...(currentLabelBrandID !== "all" &&
                                currentLabelBrandID && { brandIDs: [currentLabelBrandID] })
                            //searchName: searchValue
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Details",
                                onClick: undefined
                            },
                            {
                                title: "BOimage",
                                onClick: undefined
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    currentLabelBrandID={currentLabelBrandID}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, onRefreshAction, navigations, labelBrandsDropdownData, onLabelBrandChange) {
    const createMode = navigations?.navigationAccesses?.products?.createModeOnly;
    const hasStockAccess = hasUserCompletedOnboarding(navigations);
    const hasArchiveAccess = false;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Products", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/products", active: true, disabled: false, title: "Products" },
        { to: "/stock", active: false, disabled: !hasStockAccess, title: "Stock" },
        { to: "#", active: false, disabled: !hasArchiveAccess, title: "Archive" }
    ]);
    props.setControlBarRightBtns([
        { to: "/products", active: true, disabled: createMode, title: "View", icon: "eye" },
        /*
        { to: "/products/add", active: false, disabled: false, title: "Add", icon: "plus" },
        { active: false, disabled: false, title: "Delete All", icon: "garbage", onClick: onDeleteClick },
         */
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <OrganizeFiltersHeader
                visible={false}
                gridEnabled={true}
                listEnabled={true}
                hideGridList={false}
                onRefreshAction={onRefreshAction}
                primaryOrganize={{
                    visible: true,
                    dropdownData: labelBrandsDropdownData,
                    onDropdownChange: onLabelBrandChange
                }}
            />
        )
    });
}
