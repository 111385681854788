import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext, useParams } from "react-router";
import * as Library from "../../../utils/Library";
import StoreHours from "./createStoreComponents/StoreHours";
import DeliveryOptionsAvailable from "./createStoreComponents/DeliveryOptionsAvailable";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import LayoutContext from "../../../LayoutContext";
import { useDispatch, useSelector } from "react-redux";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import FormFieldImageUpload from "../../common/forms/FormFieldImageUpload";
import AddressFormCardStandard from "../../common/forms/AddressFormCardStandard";
import {
    getAllUnlockedNavigations,
    hasUserCompletedOnboarding,
    userIsOnBoardingInPage
} from "../../../utils/navigationHelper";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import AppContext from "../../../AppContext";
import StorePOSIntegration from "./createStoreComponents/StorePOSIntegration";
import AppletPickerPreview from "./createStoreComponents/AppletPickerPreview";
import IpadFs01 from "../../../assets/images/fs-pos-full1.png";
import IpadFs02 from "../../../assets/images/fs-pos-full2.png";
import IpadFs03 from "../../../assets/images/fs-pos-full3.png";
import IpadFs04 from "../../../assets/images/fs-pos-full4.png";
import PopupContext from "../../../PopupContext";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import Checkbox from "../../common/checkbox/Checkbox";
import { CircularProgress } from "@mui/material";
import { triggeredTypes } from "../../../utils/utilHelpers";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .frame-1000011239,
    .frame-1000011239 * {
        box-sizing: border-box;
    }
    .frame-1000011239 {
        background: var(--backgrounds-and-lines-n-100, #3c485a);
        border-radius: 9999px;
        padding: 2px 8px 2px 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .fetch-from-google-maps {
        color: #ffffff;
        text-align: left;
        font-family: var(--label-xs-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        display: flex;
        gap: 5px;
    }
    .circular-loader {
        width: 10px !important;
        height: 10px !important;
    }
    .disabled-open-hours-btn {
        cursor: not-allowed;
        opacity: 0.3;
    }
`;

/* #endregion */

export default function CreateStorePage() {
    const params = useParams();
    const storeID = params?.storeID;
    document.title = `${APP_TITLE} - ${!!storeID ? "Edit Store" : "Create Store"} `;

    /* #region STATES */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { showIsStorePosSavedPopup, setShowIsStorePosSavedPopup } = useContext(LayoutContext);
    const {
        showStorePOSConfirmConnect,
        setShowStorePOSConfirmConnect,
        setShowStoreAndPOSOnboardingCompleted,
        setShowStoreWithoutPOSOnboardingCompleted
    } = useContext(PopupContext);
    const { setHasUnsavedChanges, userData } = useContext(AppContext);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    const navigations = useSelector(state => state.navigationAccesses);
    const userAppData = useSelector(state => state.userData.userAppData);
    const inviteType = userAppData?.invite?.inviteType;

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStoreData, setIsLoadingStoreData] = useState(false);
    const [isOpeningHoursLoading, setIsOpeningHoursLoading] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const [editStoreLoading, setEditStoreLoading] = useState(false);
    const [createStoreLoading, setCreateStoreLoading] = useState(false);
    const [storeId, setStoreId] = useState("");
    const [isOpenHoursBtnEnabled, setIsOpenHoursBtnEnabled] = useState(false);
    const [openingHoursError, setOpeningHoursError] = useState({ error: false, status: 0 });
    const retailBrandData = userData?.retailBrands?.[0];
    const isUserOnboarding = userIsOnBoardingInPage(navigations?.navigationAccesses, "stores");

    const [retailBrands, setRetailBrands] = useState([]);

    const [storeFormData, setStoreFormData] = useState({
        retailBrandID: "",
        retailBrandName: "",
        storeName: "",
        storeFacadeData: null,
        storeInteriorData: null,
        googlePlusCode: "",
        country: "",
        city: "",
        postalCode: "",
        addressLine1: "",
        addressLine2: "",
        shoppingCenter: "false",
        latitude: "",
        longitude: "",
        pickupEnabled: true,
        courierEnabled: false,
        shippingEnabled: false,
        storeHoursData: {
            monday: { hourStart: "", hourEnd: "", closedAllDay: true },
            tuesday: { hourStart: "", hourEnd: "", closedAllDay: true },
            wednesday: { hourStart: "", hourEnd: "", closedAllDay: true },
            thursday: { hourStart: "", hourEnd: "", closedAllDay: true },
            friday: { hourStart: "", hourEnd: "", closedAllDay: true },
            saturday: { hourStart: "", hourEnd: "", closedAllDay: true },
            sunday: { hourStart: "", hourEnd: "", closedAllDay: true }
        }
    });

    const [selectedPOS, setSelectedPOS] = useState(null);
    const [selectedPOSName, setSelectedPOSName] = useState(null);
    //const [posEmailSupport, setPosEmailSupport] = useState(null);
    const [POSStoreID, setPOSStoreID] = useState(null);

    const [showAppletPicker, setShowAppletPicker] = useState({ show: false, imgSrc: "" });
    const showStorePOSIntegration = true;
    const connectButtonEnabled = true;
    //const [POSConnectCompleted, setPOSConnectCompleted] = useState(false);
    const [showPOSErrorPopup, setShowPOSErrorPopup] = useState(false);
    const [showTAC, setShowTAC] = useState(false);
    const [showPOSConfirmDisconnection, setShowPOSConfirmDisconnection] = useState(false);
    const [showCorrectCompanyPopup, setShowCorrectCompanyPopup] = useState(false);
    const [correctCompanyChecked, setCorrectCompanyChecked] = useState(false);

    const [ignorePOSConnect, setIgnorePOSConnect] = useState(false);

    const [posIsConnecting, setPosIsConnecting] = useState(false);
    const [posIsConnected, setPosIsConnected] = useState(false);
    const [posConnectFailed, setPosConnectFailed] = useState(false);

    const [connectFields, setConnectFields] = useState(null);
    const [stockSharingSelected, setStockSharingSelected] = useState([false, false, true, false]);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!storeID) {
            setStoreFormData({ ...storeFormData, storeName: retailBrandData?.name });
            return;
        }
        //getStoreData();
    }, [storeID]);

    useEffect(() => {
        setupHeaders(
            props,
            saveButtonEnabled,
            () => {
                setShowDialogBox(true);
            },
            storeID,
            isUserOnboarding,
            navigations
        );
    }, [saveButtonEnabled, isLoading, navigations, isUserOnboarding]);

    useEffect(() => {
        if (!userData) return;

        const selectedRetailBrandID = storeFormData?.retailBrandID;

        const retailBrandData = userData?.retailBrands?.map(item => ({
            name: item.name,
            value: item._id,
            selected: item._id.toString() === selectedRetailBrandID.toString()
        }));

        if (retailBrandData?.length === 1) retailBrandData[0].selected = true;

        setRetailBrands(retailBrandData);
    }, [userData]);

    useEffect(() => {
        getRetailBrandRequest();

        if (!storeID) {
            const disableShowCorrectCompanyPopup = localStorage.getItem("disableShowCorrectCompanyPopup");
            if (disableShowCorrectCompanyPopup === "true") return;
            setShowCorrectCompanyPopup(true);
        }
    }, []);

    useEffect(() => {
        checkFormIsValid();
    }, [posIsConnected, stockSharingSelected, showPOSErrorPopup, posIsConnecting]);

    useEffect(() => {
        const element = document.querySelector(".BO-card-component");
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, []);
    /* #endregion */

    /* #region METHODS */

    const getStoreData = async () => {
        const formData = {
            id: storeID
        };
        const res = await Library.makeCommonPostRequest(
            "Stores",
            "getStoreData",
            formData,
            false,
            setIsLoadingStoreData
        );
        const data = res?.data?.data;

        setStoreId(data?._id);

        const { openHours } = data;

        setStoreFormData({
            retailBrandID: data?.retailBrandID || "",
            retailBrandName: data?.retailBrandData?.name || "",
            storeName: data?.name || "",
            storeFacadeData: data?.pictureURL || null,
            storeInteriorData: data?.interiorURL || null,
            googlePlusCode: data?.googlePlusCode || "",
            country: data?.country || "",
            city: data?.city || "",
            postalCode: data?.postalCode || "",
            addressLine1: data?.address || "",
            addressLine2: data?.addressNumber || "",
            shoppingCenter: data?.shoppingCenter || "",
            latitude: data?.latitude || "",
            longitude: data?.longitude || "",
            pickupEnabled: data?.pickupEnabled || false,
            courierEnabled: data?.courierEnabled || false,
            shippingEnabled: data?.shippingEnabled || false,
            storeHoursData: {
                monday: readStoreHoursForDay(openHours, "monday"),
                tuesday: readStoreHoursForDay(openHours, "tuesday"),
                wednesday: readStoreHoursForDay(openHours, "wednesday"),
                thursday: readStoreHoursForDay(openHours, "thursday"),
                friday: readStoreHoursForDay(openHours, "friday"),
                saturday: readStoreHoursForDay(openHours, "saturday"),
                sunday: readStoreHoursForDay(openHours, "sunday")
            }
        });

        if (storeID) {
            const { ID, connectFieldsV2, status } = data?.POS;
            if (ID && status === "Connected") {
                setPosIsConnected(true);
                setConnectFields(connectFieldsV2);
                setPOSStoreID(connectFieldsV2?.StoreId);
            }
        }

        const termsAndStockSelected = data?.stockSharingAcceptedTerms;
        const stockSharingOnlyBOImages = data?.stockSharing !== "All";
        const stockSharingAll = data?.stockSharing === "All";

        const stockSharingArray = [
            termsAndStockSelected,
            termsAndStockSelected,
            stockSharingOnlyBOImages,
            stockSharingAll
        ];
        setStockSharingSelected(stockSharingArray);
    };

    const readStoreHoursForDay = (data, dayStr) => {
        const dayData = data?.[dayStr];
        if (!data) return { hourStart: "", hourEnd: "", closedAllDay: false, openAllDay: false };

        const res = {
            hourStart: dayData?.hourStart || "",
            hourEnd: dayData?.hourEnd || "",
            closedAllDay: dayData?.closedAllDay || false,
            openAllDay: dayData?.openAllDay || false
        };

        return res;
    };

    const checkFormIsValid = (highlightValidation = false) => {
        const allCompanyIdFieldsAreValid = formCompanyID.current.allFieldsAreValid(highlightValidation);
        const allAddressFieldsAreValid = formCompanyAddress.current.allFieldsAreValid(highlightValidation);
        const allStoreHoursFieldsAreValid = formStoreHoursRef.current.allFieldsAreValid(highlightValidation);
        const allDeliveryOptionsFieldsAreValid = formDeliveryOptionsRef.current.allFieldsAreValid(highlightValidation);
        const POSCompletedInCreateMode = !storeID ? posIsConnected : true;
        const isStoreNameValid = formCompanyFieldsRefs.storeName.isValid(highlightValidation);
        const isGooglePlusCodeValid = !!storeFormData.googlePlusCode;

        const allFieldsAreValid =
            allCompanyIdFieldsAreValid &&
            allAddressFieldsAreValid &&
            allStoreHoursFieldsAreValid &&
            allDeliveryOptionsFieldsAreValid;

        setIsOpenHoursBtnEnabled(isStoreNameValid && isGooglePlusCodeValid);
        const isUserOnboarding = userIsOnBoardingInPage(navigations?.navigationAccesses, "stores");
        const stockSharingIsValid = stockSharingSelected[0] ? stockSharingSelected[1] : true;

        let enableSaveButton = allFieldsAreValid && stockSharingIsValid;
        if (isUserOnboarding) {
            if (posConnectFailed) enableSaveButton = allFieldsAreValid;
            else enableSaveButton = allFieldsAreValid && POSCompletedInCreateMode;
        }
        setSaveButtonEnabled(enableSaveButton && !posIsConnecting);

        return allFieldsAreValid;
    };

    const getRetailBrandRequest = async () => {
        const postData = {
            retailBrandID: JSON.parse(localStorage.getItem("retailBrands"))?.[0]?._id
        };
        const res = await Library.makePostRequest("getRetailBrand", postData, false, setIsLoading);
        if (res?.data?.status === 200) {
            const retailBrandData = res.data?.data;

            const POSData = retailBrandData?.POS?.POSdata;
            const { _id, name } = POSData || {};

            if (_id && name) {
                setSelectedPOS(_id);
                setSelectedPOSName(name);
                //setPosEmailSupport(emailSupport?.join(", "));
            } else {
                setSelectedPOS("other");
                setSelectedPOSName("Other");
            }
        }

        if (storeID) getStoreData();
    };

    const onConnectClicked = ({ posStoreID, stockSharingSelected }) => {
        setPOSStoreID(posStoreID);
        setShowStorePOSConfirmConnect(true);
        //setStockSharingSelected(stockSharingSelected);
    };

    const onIgnoreClick = () => {
        setIgnorePOSConnect(true);
        setPosIsConnecting(false);
    };

    const storePOSconnectRequest = async () => {
        try {
            if (!connectButtonEnabled) return;
            setIgnorePOSConnect(false);

            const postData = {
                retailBrandID: retailBrandData?._id,
                //storeID: storeId,
                connectFields: {
                    StoreId: POSStoreID
                }
            };
            //console.log("storePOSconnectRequest", postData, retailBrandData);
            setPosIsConnecting(true);
            const res = await Library.makeCommonPostRequest(
                "POS",
                "storePOSconnect",
                postData,
                false,
                undefined,
                connectStoreError,
                undefined,
                false
            );

            //sleep for 7 seconds
            await new Promise(resolve => setTimeout(resolve, 7000));
            setPosIsConnecting(false);

            if (ignorePOSConnect) {
                console.log("POS Connection ignored", res);
                setIgnorePOSConnect(false);
                return;
            }

            if (res?.data?.status === 200 && res?.data?.data?.POSstatus === "Connected") {
                onAfterConnectStorePOS();
            } else connectStoreError(res?.data);
        } catch (error) {
            console.log("storePOSconnectRequest error", error);
            setPosIsConnecting(false);
            connectStoreError(error);
        }
    };

    const onAfterConnectStorePOS = () => {
        if (ignorePOSConnect) {
            console.log("POS onAfterConnectStorePOS ignored");
            setIgnorePOSConnect(false);
            return;
        }

        setPosConnectFailed(false);
        setPosIsConnected(true);

        Library.showToastMessage({
            type: "success",
            title: "Successfully connected!",
            message: "The Connection with the POS in this store is now established! Please proceed to saving the store."
        });
    };

    const onSaveClicked = async () => {
        const storeIDValues = formCompanyID.current.getAllFieldValues();
        const locationValues = formCompanyAddress.current.getAllFieldValues();
        const storeHours = formStoreHoursRef.current.getAllFieldValues();
        const deliveryOptions = formDeliveryOptionsRef.current.getAllFieldValues();

        const postData = {
            retailBrandID: storeIDValues?.toBrand?.value,
            StoreID: storeId,
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
            name: storeIDValues?.storeName,
            facadeImageData: storeIDValues?.storeFacade?.url || storeIDValues?.storeFacade,
            interiorImageData: storeIDValues?.storeInterior?.url || storeIDValues?.storeInterior,
            latitude: locationValues?.latitude,
            longitude: locationValues?.longitude,
            googlePlusCode: locationValues?.googlePlusCode,
            country: locationValues?.country?.name,
            city: locationValues?.city?.name,
            postalCode: locationValues?.postalCode,
            address: locationValues?.addressOne,
            addressNumber: locationValues?.addressTwo,
            shoppingCenter: locationValues?.shoppingCenter?.value ?? "false",
            openHours: storeHours,
            pickupEnabled: deliveryOptions?.pickupEnabled,
            courierEnabled: deliveryOptions?.courierEnabled,
            shippingEnabled: deliveryOptions?.shippingEnabled
        };
        if (posIsConnected) {
            postData.POS_ID = selectedPOS;
            postData.POSstatus = "Connected";
            postData.POSconnectFields = {
                StoreId: POSStoreID
            };
        }

        if (stockSharingSelected[0] && stockSharingSelected[1] && stockSharingSelected[3]) {
            postData.stockSharing = "All";
        } else postData.stockSharing = "BOimages";

        if (stockSharingSelected[0] && stockSharingSelected[1]) postData.stockSharingAcceptedTerms = true;
        else postData.stockSharingAcceptedTerms = false;

        setHasUnsavedChanges(false);

        if (storeID) {
            const res = await Library.makeCommonPostRequest(
                "Stores",
                "updateStore",
                postData,
                false,
                setEditStoreLoading
            );
            if (res.status === 200) {
                Library.showUpdateMessageAndGoBack(navigate);
                return true;
            } else setHasUnsavedChanges(true);
        } else {
            // const res = await Library.makePostRequest("createStore", postData, false, setCreateStoreLoading);
            const res = await Library.makeCommonPostRequest(
                "Stores",
                "createStore",
                postData,
                false,
                setCreateStoreLoading
            );
            if (res?.data?.status === 200) {
                /* navigate("/stores");

                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    stores: {
                        ...navigations.navigationAccesses.stores,
                        createModeOnly: false
                    }
                };

                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                */
                let currentStores = parseInt(localStorage.getItem("activeStores")) || 0;
                currentStores += 1;
                localStorage.setItem("activeStores", currentStores.toString());

                /* if (currentStores === 1) {
                    //setShowIsStoreSavedPopup(true); //TODO: remove this popup
                } else {
                    const updatedNavigations = getAllUnlockedNavigations(navigations.navigationAccesses);
                    dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                }

                
                */
                //if user is onboarding...
                if (isUserOnboarding) {
                    onAfterCreateStoreInOnboarding();
                } else {
                    navigate("/stores");

                    Library.showToastMessage({
                        type: "success",
                        title: "Store created!",
                        message: "The store has been successfully created!"
                    });
                }

                return true;
            } else setHasUnsavedChanges(true);
        }
        return false;
    };

    const updateInviteRequest = inviteID => {
        Library.makeCommonPostRequest(
            "Invites",
            "updateInvite",
            { inviteID: inviteID, status: "Enrolled" },
            false,
            setIsLoading
        ).then(res => {
            if (res.data.status !== 200) {
                return;
            }
            localStorage.setItem("status", "Enrolled");
        });
    };

    const onAfterCreateStoreInOnboarding = () => {
        localStorage.setItem("status", "Enrolled");

        if (posConnectFailed) setShowStoreWithoutPOSOnboardingCompleted(true);
        else setShowStoreAndPOSOnboardingCompleted(true);

        const updatedNavigations = getAllUnlockedNavigations(navigations.navigationAccesses);
        dispatch(setNavigations({ navigationAccesses: updatedNavigations }));

        const inviteID = userAppData?.invite?._id;
        if (inviteID) updateInviteRequest(inviteID);
        navigate("/stores");
    };

    const connectStoreError = error => {
        if (ignorePOSConnect) {
            console.log("POS Connection ignored with error", error);
            setIgnorePOSConnect(false);
            return;
        }

        const errorResponse = error?.message;
        if (error?.data?.canContinueWithOnboarding === false) {
            setPosIsConnected(false);
            setPosConnectFailed(false);

            Library.showToastMessage({
                type: "error",
                title: "POS connection attempt failed!",
                message: errorResponse
            });
        } else {
            setPosConnectFailed(true);
            setShowPOSErrorPopup({
                show: true,
                storeSaved: true,
                posSaved: false,
                isRetailer: inviteType === "Retailer"
            });
        }
    };

    const handleGetOpenHours = async ({ triggeredFrom = "" }) => {
        const { storeName } = formCompanyID.current.getAllFieldValues();
        const { googlePlusCode } = formCompanyAddress.current.getAllFieldValues();
        if (!storeName || !googlePlusCode) {
            setOpeningHoursError({ error: true, status: 0 });
            return;
        }
        const openHoursFormData = {
            storeName,
            address: googlePlusCode
        };

        const res = await Library.makeCommonPostRequest(
            "Stores",
            "fetchGoogleMapsPlacesData",
            openHoursFormData,
            false,
            setIsOpeningHoursLoading,
            () => {},
            undefined,
            false
        );
        const status = res?.data?.status;
        const isFormInputTrigger = triggeredFrom === triggeredTypes.FORM_INPUT;

        if (status === 200) {
            if (isFormInputTrigger) {
                Library.showToastMessage({
                    type: "success",
                    title: "Success!",
                    message:
                        "Store info completed! Address details and open hours successfully fetched from Google maps!"
                });
            } else {
                Library.showToastMessage({
                    type: "success",
                    title: "Success!",
                    message: "Store hours retrieved and updated successfully!"
                });
            }
            setOpeningHoursError({ error: false, status: 200 });
        } else if (status === 404) {
            if (isFormInputTrigger) setOpeningHoursError({ error: true, status: 404 });
            else {
                Library.showToastMessage({
                    type: "warning",
                    title: "Unable to retrieve store hours",
                    message: "Please ensure the store is listed on Google Maps."
                });
            }
            return;
        } else if (status === 500) {
            if (isFormInputTrigger) setOpeningHoursError({ error: true, status: 500 });
            else {
                Library.showToastMessage({
                    type: "error",
                    title: "Fetching store hours failed",
                    message: "Check your internet connection or insert manually."
                });
            }
            return;
        } else if (status === 401) {
            if (isFormInputTrigger) setOpeningHoursError({ error: true, status: 401 });
            else {
                Library.showToastMessage({
                    type: "warning",
                    title: "Fetching store hours failed",
                    message:
                        "This store name in Google maps is different. Please change store name above or insert time manually."
                });
            }
            return;
        } else {
            if (isFormInputTrigger) setOpeningHoursError({ error: true, status: 404 });
            else {
                Library.showToastMessage({
                    type: "warning",
                    title: "Unable to retrieve store hours",
                    message: "Please ensure the store is listed on Google Maps."
                });
            }
            return;
        }

        const openHours = res?.data?.data?.response?.openingHours;

        setStoreFormData(() => ({
            ...storeFormData,
            storeHoursData: {
                monday: readStoreHoursForDay(openHours, "monday"),
                tuesday: readStoreHoursForDay(openHours, "tuesday"),
                wednesday: readStoreHoursForDay(openHours, "wednesday"),
                thursday: readStoreHoursForDay(openHours, "thursday"),
                friday: readStoreHoursForDay(openHours, "friday"),
                saturday: readStoreHoursForDay(openHours, "saturday"),
                sunday: readStoreHoursForDay(openHours, "sunday")
            }
        }));
    };

    const onDisconnectClicked = () => {
        setShowPOSConfirmDisconnection(true);
    };

    const handleTextInputBlur = () => {
        if (openingHoursError.error && openingHoursError.status !== 0 && openingHoursError.status !== 401) {
            Library.showToastMessage({
                type: "warning",
                title: "Unable to retrieve store hours",
                message:
                    "Store info not completed! We could not fetch store details. Please try again or insert data manually."
            });
        } else if (openingHoursError.error && openingHoursError.status === 0) {
            Library.showToastMessage({
                type: "warning",
                title: "Store name missing",
                message: "Please enter store name to get opening hours from google."
            });
        } else if (openingHoursError.error && openingHoursError.status === 401) {
            Library.showToastMessage({
                type: "warning",
                title: "Fetching store hours failed",
                message:
                    "This store name in Google maps is different. Please change store name above or insert time manually."
            });
        }
        setOpeningHoursError({ error: false, status: 0 });
    };

    /* #endregion */

    /* #region FORM FIELDS */
    const formCompanyAddress = useRef();
    const formCompanyID = useRef();
    const formStoreHoursRef = useRef();
    const formDeliveryOptionsRef = useRef();

    const formCompanyFieldsRefs = [];

    const formCompanyFields = [
        <FormField
            type="dropdown"
            title="To Brand"
            tooltip="Select a brand to associate"
            requiredType={isUserOnboarding ? "mandatoryLocked" : "mandatory"}
            varName="toBrand"
            ref={el => (formCompanyFieldsRefs.toBrand = el)}
            value={storeFormData?.retailBrandName}
            dropdownData={retailBrands}
        />,
        <FormField
            type="text"
            title="Store Name"
            tooltip="Store Name"
            requiredType="mandatory"
            varName="storeName"
            ref={el => (formCompanyFieldsRefs.storeName = el)}
            value={storeFormData?.storeName}
        />,
        <FormFieldImageUpload
            title="Store Facade"
            requiredType="mandatory"
            ref={el => (formCompanyFieldsRefs.storeFacade = el)}
            varName="storeFacade"
            imageURL={storeFormData?.storeFacadeData}
        />,
        <FormFieldImageUpload
            title="Store Interior"
            requiredType="recommended"
            ref={el => (formCompanyFieldsRefs.storeInterior = el)}
            varName="storeInterior"
            imageURL={storeFormData?.storeInteriorData}
        />
    ];

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenOverlay show={isOpeningHoursLoading}>
                <DialogBoxComponent
                    headingColor="rgba(0, 174, 239, 1)"
                    loading={true}
                    showCancel={false}
                    showConfirm={false}
                    loadingHeading="FETCHING STORE HOURS FROM GOOGLE MAPS..."
                />
            </FullScreenOverlay>
            <FullScreenLoader isLoading={isLoading || isLoadingStoreData} />

            <FullScreenOverlay show={showTAC}>
                <TermsAndPrivacyPanel type="terms" onCloseClicked={() => setShowTAC(false)} />
            </FullScreenOverlay>

            <FullScreenOverlay show={showCorrectCompanyPopup && !showIsStorePosSavedPopup}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Correct company?"
                    useAlternativeLayout={
                        <div style={{ margin: "0 -5px" }}>
                            <span>
                                This store will operate under{" "}
                                {localStorage.getItem("currentCompanyName") || "current company"}. If you have stores
                                that operate under a different company you need to add a new company to this account or
                                create a new BOstageR account.
                            </span>

                            <div className="flex flex-row items-center justify-center mt-16px mb-32px">
                                <Checkbox
                                    style={{ top: "-1px", position: "relative" }}
                                    isChecked={correctCompanyChecked}
                                    onCheckboxClick={() => {
                                        setCorrectCompanyChecked(!correctCompanyChecked);
                                    }}
                                />
                                <span className="ml-8px">Don't show again</span>
                            </div>
                        </div>
                    }
                    onClickYes={() => {
                        if (correctCompanyChecked) localStorage.setItem("disableShowCorrectCompanyPopup", "true");
                        setShowCorrectCompanyPopup(false);
                    }}
                    showCancel={false}
                    confirmText="CONFIRM"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showDialogBox}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Ready to save?"
                    message={
                        !!storeID
                            ? "You can still change it later. No worries:)"
                            : "Store status will become ‘Active’ and will be ready to accept invitations from brands to become their fulfillment centers."
                    }
                    onClickYes={() => {
                        onSaveClicked();
                        setShowDialogBox(false);
                    }}
                    onClickNo={() => {
                        setShowDialogBox(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showPOSErrorPopup}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-red-500)"
                    title="Connection attempt failed!"
                    message="Something is preventing the connection with your POS to be established. Please contact support@botag.no.<br /><br />
                             You can continue with onboarding."
                    onClickYes={() => {
                        setShowPOSErrorPopup(false);
                        storePOSconnectRequest();
                    }}
                    heading="ATTENTION.."
                    onClickNo={() => {
                        setShowPOSErrorPopup(false);
                    }}
                    cancelText="TRY LATER"
                    confirmText="RETRY"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={createStoreLoading || editStoreLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="SAVING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={createStoreLoading || editStoreLoading}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showIsStorePosSavedPopup}>
                <DialogBoxComponent
                    title="POS data saved."
                    message="Let’s create your first store now!"
                    onClickYes={() => setShowIsStorePosSavedPopup(false)}
                    heading={"SUCCESS..."}
                    headingColor="var(--text-icons-green-500)"
                    confirmText="CONTINUE"
                    showCancel={false}
                    confirmState="secondary"
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showAppletPicker?.show}>
                <AppletPickerPreview
                    imgSrc={showAppletPicker?.imgSrc}
                    onClose={() => setShowAppletPicker({ show: false })}
                    isOpen={showAppletPicker?.show}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showStorePOSConfirmConnect}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Confirm connect."
                    message="<div style='font-size: 12px; margin: 0 -5px;'>Connecting will start a sequence of processes to connect your POS to BOtag. This may take a few seconds. If the connection fails, please revise all requirements and try again or contact support@botag.no</div>"
                    onClickYes={() => {
                        setShowStorePOSConfirmConnect(false);
                        storePOSconnectRequest();
                    }}
                    heading="JUST CHECKING..."
                    onClickNo={() => {
                        setShowStorePOSConfirmConnect(false);
                    }}
                    cancelText="CANCEL"
                    confirmText="CONNECT"
                    confirmState="secondary"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showPOSConfirmDisconnection}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    title="Confirm disconnection"
                    message="<div style='font-size: 12px; margin: 0 -5px;'>This store won’t be connected to the BOtag network. It wont be sharing stock nor showing on HeyBO as a BOtag store.</div>"
                    onClickYes={() => {
                        setShowPOSConfirmDisconnection(false);
                        setPosIsConnected(false);
                    }}
                    heading="JUST CHECKING..."
                    onClickNo={() => {
                        setShowPOSConfirmDisconnection(false);
                    }}
                    cancelText="CANCEL"
                    confirmText="CONFIRM"
                    confirmState="secondary"
                />
            </FullScreenOverlay>

            <FormCard
                title="STORE ID"
                ref={formCompanyID}
                numOfFieldsPerLine={2}
                showDefaultRightLegends={true}
                formFields={formCompanyFields}
                formFieldRefs={formCompanyFieldsRefs}
                onFormChanged={checkFormIsValid}
                showUnsavedChangesPopup={true}
            />

            <AddressFormCardStandard
                cardTitle="STORE LOCATION"
                ref={formCompanyAddress}
                pinLabel={storeFormData?.storeName}
                unnamedPinLabel={"Unnamed Store"}
                useGoogleMapsDarkMode={true}
                usesShoppingCenter={true}
                hasKYCAssistiveText={false}
                onFormChanged={checkFormIsValid}
                locationDetails={{
                    googlePlusCode: storeFormData.googlePlusCode,
                    country: storeFormData.country,
                    city: storeFormData.city,
                    postalCode: storeFormData.postalCode,
                    addressOne: storeFormData.addressLine1,
                    addressTwo: storeFormData.addressLine2,
                    latitude: storeFormData.latitude,
                    longitude: storeFormData.longitude,
                    shoppingCenter: storeFormData.shoppingCenter
                }}
                handleGetOpenHours={handleGetOpenHours}
                handleTextInputBlur={handleTextInputBlur}
                onLocationDetailsChange={locationDetails => {
                    const {
                        googlePlusCode,
                        country,
                        city,
                        postalCode,
                        addressOne,
                        addressTwo,
                        latitude,
                        longitude,
                        shoppingCenter
                    } = locationDetails;

                    setStoreFormData({
                        ...storeFormData,
                        googlePlusCode: googlePlusCode,
                        country: country,
                        city: city,
                        postalCode: postalCode,
                        addressLine1: addressOne,
                        addressLine2: addressTwo,
                        latitude: latitude,
                        longitude: longitude,
                        shoppingCenter: shoppingCenter
                    });
                }}
            />

            <StoreHours
                title="Store Hours"
                ref={formStoreHoursRef}
                storeHours={storeFormData?.storeHoursData}
                onFormChanged={checkFormIsValid}
                afterTitleComponent={
                    <FetchFromGoogleComponent
                        isBtnEnabled={isOpenHoursBtnEnabled}
                        isLoading={isOpeningHoursLoading}
                        onClick={handleGetOpenHours}
                    />
                }
            />

            <DeliveryOptionsAvailable
                title="Delivery Options Available"
                ref={formDeliveryOptionsRef}
                deliveryOptions={storeFormData}
                onDeliveryOptionsChanged={deliveryOptions => {
                    setStoreFormData({
                        ...storeFormData,
                        pickupEnabled: deliveryOptions.pickupEnabled,
                        courierEnabled: deliveryOptions.courierEnabled,
                        shippingEnabled: deliveryOptions.shippingEnabled
                    });
                }}
                onFormChanged={checkFormIsValid}
            />

            {showStorePOSIntegration && (
                <StorePOSIntegration
                    selectedPOS={selectedPOS}
                    selectedPOSName={selectedPOSName}
                    isStoreIDMandatory={isUserOnboarding}
                    onConnectClick={onConnectClicked}
                    setShowTAC={setShowTAC}
                    onIgnoreClick={onIgnoreClick}
                    posIsConnecting={posIsConnecting}
                    posIsConnected={posIsConnected}
                    posConnectFailed={posConnectFailed}
                    setPosIsConnected={setPosIsConnected}
                    onThumbnailClick={index => {
                        let imgSrc = "";

                        switch (index) {
                            case 1:
                                imgSrc = IpadFs01;
                                break;
                            case 2:
                                imgSrc = IpadFs02;
                                break;
                            case 3:
                                imgSrc = IpadFs03;
                                break;
                            case 4:
                                imgSrc = IpadFs04;
                                break;
                            default:
                                break;
                        }
                        setShowAppletPicker({ show: true, imgSrc: imgSrc });
                    }}
                    connectFields={connectFields}
                    stockSharingSelected={stockSharingSelected}
                    setStockSharingSelected={setStockSharingSelected}
                    onDisconnectClicked={onDisconnectClicked}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, saveButtonEnabled, onBtnClicked, storeID, isUserOnboarding, navigations) {
    const hasStockAccess = hasUserCompletedOnboarding(navigations);

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Stores", icon: "fa-magnifying-glass" }]);

    props.setControlBarCenterBtns([
        { to: "#", active: true, disabled: false, title: "Stores" },
        { to: "/stores/staff", active: false, disabled: true, title: "Staff" },
        { to: "/stores/pos", active: false, disabled: false, title: "POS" },
        { to: "/stock", active: false, disabled: !hasStockAccess, title: "Stock" }
    ]);
    if (storeID) {
        props.setControlBarRightBtns([
            { to: "/stores", active: false, disabled: false, title: "Back", icon: "chevron-left" },
            { to: "#", active: true, disabled: false, title: "Edit", icon: "pencil-line" }
        ]);
    } else {
        props.setControlBarRightBtns([
            { to: "/stores", active: false, disabled: isUserOnboarding, title: "View", icon: "eye" },
            { to: "/stores/create", active: true, disabled: false, title: "Add", icon: "plus" },
            { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" },
            { to: "#", active: false, disabled: true, title: "Edit", icon: "edit" }
        ]);
    }
    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle={!storeID ? "SAVE" : "UPDATE"}
                //btnEnabled={saveButtonEnabled && !isUserOnboarding}
                btnEnabled={saveButtonEnabled}
                onClick={onBtnClicked}
            />
        )
    });
}

/* #region small components */
const FetchFromGoogleComponent = ({ isLoading, onClick, isBtnEnabled }) => {
    return (
        <div
            className={`frame-1000011239 ${!isBtnEnabled && "disabled-open-hours-btn"}`}
            onClick={() => isBtnEnabled && onClick({ triggeredFrom: triggeredTypes.OPEN_HOURS_BUTTON })}>
            <div className="fetch-from-google-maps">
                {isLoading ? (
                    <>
                        <CircularProgress className="circular-loader" />
                        Updating...
                    </>
                ) : (
                    "Update from Google maps"
                )}
            </div>
        </div>
    );
};
/* #endregion */
