import "../../../assets/css/components/TotalBotagSales.css";
import TotalSalesGraph from "../../pages/dashboardGraphs/TotalSalesGraph";
import { formatPrice } from "../../../utils/Formatter";

const TotalBotagSales = ({
    title,
    totalSalesChartSeries,
    totalValue = 0,
    totalValueCurrency = "NOK",
    totalOrders = 0,
    avgOrder = 0,
    avgOrderCurrency = "kr",
    totalComparisonPercent = "--",
    options
}) => {
    return (
        <div className="total-botag-sales-container">
            <div className="total-botag-heading">
                <p style={{ color: "#D1D1D1" }}>
                    {title} &nbsp; <i className="fa-light fa-circle-info"></i>
                </p>
            </div>
            <div className="total-botag-graph-container">
                <div>
                    <h2>
                        {totalValue || 0} <span style={{ fontSize: "18px" }}>{totalValueCurrency}</span>
                    </h2>
                    <p style={{ color: "#D1D1D1" }}>{totalComparisonPercent}% compared to set period</p>
                </div>
                <div>
                    <TotalSalesGraph type="line" height={"28px"} options={options} series={totalSalesChartSeries} />
                </div>
            </div>

            <div>
                <div className="total-botag-sales-hr"></div>
            </div>
            <div className="total-botag-footer">
                <div>
                    <p style={{ color: "#D1D1D1", fontSize: "12px" }}>Total orders</p>
                    <p style={{ fontSize: "16px", color: "#ffffff" }}>{formatPrice(totalOrders, "", 0, true)}</p>
                </div>
                <div>
                    <p style={{ color: "#D1D1D1", fontSize: "12px" }}>Avg. order</p>
                    <p style={{ fontSize: "16px", color: "#ffffff" }}>
                        {formatPrice(avgOrder, "", 0, true)} {avgOrderCurrency}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TotalBotagSales;
