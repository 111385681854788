import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { AppQueryClient } from "../../../api/queryClient";
import { useSearchParams } from "react-router-dom";
import LayoutContext from "../../../LayoutContext";
import * as Library from "../../../utils/Library";
import { useSelector } from "react-redux";
import { defaultStatusColors } from "../../../utils/CardUtils";
import OrganizeFiltersHeader from "../../common/mainApp/mainWindowHeaders/OrganizeFiltersHeader";
import StockExpandedComponent from "./StockExpandedComponent";
import { Tooltip } from "antd";
import { formatColorHex } from "../../../utils/Formatter";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .ant-tooltip-inner {
        background-color: #4369aa !important;
    }

    .rdt_ExpanderRow {
        background: rgb(16, 20, 27);
    }

    .frame-1000011360,
    .frame-1000011360 * {
        box-sizing: border-box;
    }
    .frame-1000011360 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011324 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .image {
        border-radius: 4px;
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        position: relative;
        object-fit: cover;
    }
    .frame-111 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .regulator-parka {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }

    //TOOLTIP INNER
    &.frame-1000011326 {
        //background: rgba(255, 255, 255, 0.1);
        background: #4f535a;
        margin: 0px;
        padding: 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        backdrop-filter: blur(5px);
        width: 130px;
        height: 130px;
        border-radius: 4px;
    }
    .rectangle-256 {
        border-radius: 4px;
        flex-shrink: 0;
        width: 120px;
        height: 121px;
        position: relative;
        object-fit: cover;
    }
    .frame-1000011327 {
        flex-shrink: 0;
        width: 120px;
        height: 120px;
        position: absolute;
        left: -7px;
        top: 53px;
        overflow: visible;
        //round corners
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-radius: 7px;
    }
`;
/* #endregion */

export default function StockPage() {
    document.title = `${APP_TITLE} - Products Stock`;

    /* #region STATES */
    const layoutCtx = React.useContext(LayoutContext);
    const { layoutType } = layoutCtx;
    const navigations = useSelector(state => state.navigationAccesses);

    const [searchParams] = useSearchParams();
    const storeID = searchParams.get("storeID");
    const storeName = searchParams.get("storeName");

    const listQueryKey = "StockPageList";

    const [loadingStoreFilters, setLoadingStoreFilters] = useState(false);

    const [allStoresList, setAllStoresList] = useState([]);
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);

    const [filteredStores, setFilteredStores] = useState([]);
    const [selectedLabelBrand, setSelectedLabelBrand] = useState(null);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!layoutType) return;

        setupHeaders({
            layoutCtx,
            onClickRefreshAction,
            navigations,
            allStoresList,
            allLabelBrandsList,
            filteredStores,
            selectedLabelBrand,
            onLabelBrandChange,
            onStoreChange,
            setAllStoresList,
            dropdownOnSingleItemClick
        });
    }, [layoutType, allStoresList, allLabelBrandsList, filteredStores, selectedLabelBrand]);

    useEffect(() => {
        getStockPageFilters();
    }, []);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Style Name",
            //selector: row => row?.productName ?? "--"
            selector: row => ProductWithImage({ name: row?.productName, image: row?.image })
        },
        {
            name: "Product Type",
            selector: row => row?.productType || "--"
        },
        {
            name: "Collection/year",
            selector: row => row?.collection || "--"
        },
        {
            name: "Reference/GTIN",
            selector: row => row?.reference || "--"
        },
        {
            name: "Colors",
            selector: row => (row?.colors?.length ? getMultipleColors(row?.colors) : "--")
        },
        {
            name: "Sizes",
            selector: row => row?.size ?? "All"
        },
        {
            name: "Stores",
            selector: row => row?.totalStoresWithStock ?? "--"
        },
        {
            name: "Stock",
            selector: row => row?.stockSum ?? "--"
        },
        {
            name: "Average RP",
            selector: row => row?.averagePrice ?? "--"
        },
        {
            name: "Wholesale P",
            selector: row => row?.wholesalePrice ?? "--"
        },
        {
            name: "Gross Margin",
            selector: row => row?.grossMargin ?? "--"
        },
        {
            name: "Gender",
            selector: row => row?.gender || "--"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const onClickRefreshAction = () => {
        AppQueryClient.invalidateQueries(listQueryKey);
    };

    const mapListData = data => {
        const result = data?.result?.map(item => {
            let stockTotal = 0;
            let firstImage = "";
            //let totalPrice = 0;
            let itemsCount = 0;

            return {
                ...item,
                expandItems: item?.expandItems?.map(expandItem => {
                    stockTotal += expandItem?.stock?.reduce((acc, item) => acc + item, 0);
                    if (!firstImage) firstImage = expandItem?.images?.[0]?.URL;
                    //totalPrice = totalPrice + (expandItem?.averagePrice ?? 0);
                    itemsCount = itemsCount + 1;

                    return {
                        ...expandItem,
                        BOtagID: item?.BOtagID,
                        productName: item?.productName,
                        reference: item?.reference
                    };
                }),
                stockSum: stockTotal,
                image: firstImage //,
                //averagePrice: totalPrice / itemsCount
            };
        });

        return {
            ...data,
            result: result
        };
    };

    const getStockPageFilters = async () => {
        const res = await Library.makePostRequest(
            "getStockPageFilters",
            {
                companyID: localStorage.getItem("currentCompanyID")
            },
            false,
            setLoadingStoreFilters
        );
        const data = res?.data?.data;
        if (res?.status !== 200) return [];

        const labelBrands = data?.map(item => {
            return {
                name: item?.labelBrand?.name?.[0],
                value: item?.labelBrand?._id
            };
        });

        //remove duplicates
        const uniqueLabelBrands = [...new Map(labelBrands.map(item => [item.value, item])).values()];
        setAllLabelBrandsList(uniqueLabelBrands);

        const stores = data?.map(item => {
            const tmpStores = item?.stores?.map(store => {
                return {
                    name: store?.name,
                    value: store?._id
                };
            });

            return tmpStores;
        });

        const allStores = stores.flat();

        //remove duplicates
        const uniqueStores = [...new Map(allStores.map(item => [item.value, item])).values()];
        const allStoresCount = uniqueStores.length;

        const uniqueStoresIncludeAll = [
            {
                name: `All stores (${allStoresCount})`,
                value: "selectall",
                selected: true
            },
            ...uniqueStores
        ];

        //if storeID select it and unselect everything else
        if (storeID) {
            const allStoresSelected = uniqueStoresIncludeAll.map(item => {
                return {
                    ...item,
                    selected: item?.value === storeID
                };
            });

            setFilteredStores([{ name: storeName, value: storeID, selected: true }]);
            setAllStoresList(allStoresSelected);
        } else {
            //set all stores selected
            const allStoresSelected = uniqueStoresIncludeAll.map(item => {
                return {
                    ...item,
                    selected: true
                };
            });

            setAllStoresList(allStoresSelected);
            setFilteredStores(allStoresSelected);
        }

        const firstLabelBrand = uniqueLabelBrands?.[0];
        setSelectedLabelBrand(firstLabelBrand);

        return data;
    };

    const onLabelBrandChange = valueObj => {
        setSelectedLabelBrand(valueObj);
    };

    const onStoreChange = (selections, dropdownDataWithSelections) => {
        setFilteredStores(selections);
        setAllStoresList(dropdownDataWithSelections);
    };

    const dropdownOnSingleItemClick = item => {
        if (item?.value !== "selectall") {
            const isAllSelected = allStoresList?.[0]?.selected === true;

            //if "All Stores" is selected and was clicked on another store, then we need to deselect "All Stores"
            if (isAllSelected) {
                const allStores = allStoresList?.map((store, index) => {
                    return {
                        ...store,
                        selected: index === 0 ? false : store?.value === item?.value ? item?.selected : store?.selected
                    };
                });

                setAllStoresList(allStores);
            }
            return;
        }

        //item clicked is "All Stores" then manually set all stores items with selected as true
        const allSelected = item?.selected === true;

        const allStores = allStoresList?.map(item => {
            return {
                ...item,
                selected: allSelected
            };
        });

        setAllStoresList(allStores);
    };

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenLoader isLoading={loadingStoreFilters} />

            <ListComponent
                tableClassName="expandedRowsCentered"
                title="Products Stock"
                columns={listColumns}
                api={{
                    useCommonPostRequest: true,
                    commonContext: "Stock",
                    endpoint: "getStocksData",
                    formData: {
                        storeIDs: filteredStores?.map(item => item?.value)?.filter(item => item !== "selectall"),
                        labelBrandIDs: [selectedLabelBrand?.value]
                        //filteredLabelBrands?.map(item => item?.value)?.filter(item => item !== "selectall")
                    },
                    onSuccess: mapListData
                }}
                pageLimit={10}
                currentPage={1}
                queryKey={listQueryKey}
                selectableRows={false}
                actions={{
                    includeActions: true,
                    editIsVisible: false,
                    suspendIsVisible: false,
                    removeIsVisible: false,
                    extraActions: [
                        {
                            title: "Details"
                        }
                    ]
                }}
                includeStatus={{
                    stickyStatus: true,
                    statusFromPath: "status",
                    statusColors: defaultStatusColors
                }}
                expandableRows={true}
                customExpandedComponent={item => {
                    return StockExpandedComponent({
                        data: item?.data
                    });
                }}
            />
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
function setupHeaders({
    layoutCtx,
    onClickRefreshAction,
    navigations,
    allStoresList,
    allLabelBrandsList,
    filteredStores,
    selectedLabelBrand,
    onLabelBrandChange,
    onStoreChange,
    setAllStoresList,
    dropdownOnSingleItemClick
}) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    const {
        setLayoutType,
        setControlBarLeftBtns,
        setControlBarCenterBtns,
        setControlBarRightBtns,
        setFilterHeaderProperties
    } = layoutCtx;
    if (!setFilterHeaderProperties) return;

    setControlBarLeftBtns([{ to: "#", disabled: true, title: "Products", icon: "fa-magnifying-glass" }]);
    setControlBarCenterBtns([{ to: "/stock", active: true, disabled: createMode, title: "STOCK" }]);
    setControlBarRightBtns([{ to: "#", active: true, disabled: false, title: "View", icon: "eye" }]);

    setLayoutType("List");

    setFilterHeaderProperties({
        customContentHeader: (
            <OrganizeFiltersHeader
                visible={true}
                gridEnabled={false}
                listEnabled={true}
                hideGridList={false}
                onRefreshAction={onClickRefreshAction}
                primaryOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    selectedValue: selectedLabelBrand?.name
                }}
                extraOrganize={{
                    visible: true,
                    dropdownData: allStoresList,
                    onDropdownChange: onStoreChange,
                    disabled: false,
                    type: "multiSelect",
                    dropdownOnSingleItemClick
                }}
            />
        )
    });
}

const getColorSquare = color => {
    let hexColor = color?.colorHexCode ?? "#000";
    const colorName = color?.colorName ?? "--";
    if (!hexColor?.includes("#")) hexColor = "#" + hexColor;

    return (
        <Tooltip title={colorName} placement="top" color="rgba(255, 255, 255, 0.1)">
            <div
                alt="colors"
                style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: formatColorHex(hexColor),
                    border: `1px solid ${formatColorHex(hexColor)}`,
                    borderRadius: "2px",
                    marginRight: "4px"
                }}
            />
        </Tooltip>
    );
};

const getMultipleColors = colors => {
    //filter colors only first 3 max
    const colorsArray = colors.slice(0, 3);
    const colorsExtraCount = colors.length - 3;
    const extraColorsNames = colors.slice(3).map(color => color?.colorName);

    const resultDiv = (
        <div style={{ display: "flex", alignItems: "start" }}>
            {colorsArray.map((color, index) => {
                return getColorSquare(color);
            })}
            {colors?.length > 3 && (
                <Tooltip
                    title={`+${colorsExtraCount} Colors: ${extraColorsNames.join(", ")}`}
                    placement="top"
                    color="rgba(255, 255, 255, 0.1)">
                    <div
                        style={{
                            width: "16px",
                            height: "16px",
                            backgroundColor: "var(--backgrounds-lines-n-500, #141922)",
                            border: "1px solid var(--backgrounds-lines-n-500, #141922)",
                            borderRadius: "2px",
                            marginRight: "4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "8px"
                        }}>
                        +{colorsExtraCount}
                    </div>
                </Tooltip>
            )}
        </div>
    );

    return resultDiv;
};

const ProductWithImage = ({ name, image }) => {
    if (image?.length > 0) {
        if (!image?.startsWith("http")) {
            image = `https://${image}`;
        }
    }

    return (
        <StyledPage>
            <div className="frame-1000011360">
                <div className="frame-1000011324">
                    {image ? (
                        <Tooltip
                            title={<ImageTooltipInner image={image} />}
                            placement="right"
                            color="rgba(255, 255, 255, 0.1)"
                            //trigger={["click"]}
                            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                            <img className="image" src={image} alt="" />
                        </Tooltip>
                    ) : (
                        <div className="image" style={{ backgroundColor: "#4f535a" }} />
                    )}
                </div>
                <div className="frame-111">
                    <Tooltip title={name} placement="top" color="rgba(255, 255, 255, 0.1)">
                        <div className="regulator-parka text-one-line">{name}</div>
                    </Tooltip>
                </div>
            </div>
        </StyledPage>
    );
};

const ImageTooltipInner = ({ image }) => {
    return (
        <StyledPage className="frame-1000011326">
            <a href={image} target="_blank" rel="noreferrer">
                <img className="rectangle-256" src={image} alt="product" />
            </a>
        </StyledPage>
    );
};
/* #endregion */
