export const APP_TITLE = process.env.REACT_APP_APP_TITLE || "BOstageR";
export const APP_HOMEPAGE_ROUTE = "/dashboard"; //default route when user is not onboarding

export const BLOB_URL = `https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/`;
export const BLOB_ACCOUNT_IMAGE = "account-images";
export const BLOB_BOIMAGE_CONTAINER = "bo-images";
export const BLOB_BRAND_IMAGE = "brand-images";
export const BLOB_COMPANY_IMAGE = "company-images";
export const BLOB_PRODUCT_CONTAINER = "product-images";
export const BLOB_STAFF_CONTAINER = "staff-images";
export const BLOB_STORE_CONTAINER = "store-images";
