import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import StoresGridCard from "../gridCards/StoresGridCard";
import * as Library from "../../../utils/Library";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

/**
 * Renders an infinite grid of store cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className=""] - Additional CSS class name for the component.
 * @param {number} [props.limit=10] - The number of cards to fetch per page.
 * @param {string} [props.queryKey] - The query key for the infinite query component.
 * @param {number} [props.minColumnWidth=395] - The minimum width of each grid column.
 * @param {number} [props.minimumCardsFill=10] - The minimum number of cards to fill the grid.
 * @param {Function} [props.onEditAction] - Callback function when the edit button is clicked.
 * @param {Function} [props.onDeleteAction] - Callback function when the delete button is clicked.
 * @param {Function} [props.onSuspendAction] - Callback function when the suspend button is clicked.
 * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */
export default function InfinityGridStoresCards(props) {
    const pageLimit = props?.limit || 10;

    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [queryKey] = useState(props?.queryKey ?? uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getStoresData",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            limit: pageLimit
        },
        () => {
            setScroll(true);
        },
        true,
        "Stores"
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() + 1);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            //console.log("scroll event added");
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            //console.log("scroll event removed");
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        //console.log("scrolling");
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            //console.log("fetching next page");
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    const getStatusValue = row => {
        const statusFromPath = props?.statusFromPath;
        const status = getArrayFieldValue(row, statusFromPath.split(".") || "Unknown");
        //console.log("status", status);

        //TODO: This is a temporary fix for the "Not Connected" status value
        if (status === "Not Connected") return "Not connected";

        return status;
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 8 }, () => (
                        <CardGridSkeleton key={uuidv4()} height="420.28px" />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            return (
                                <StoresGridCard
                                    key={item?.ourID}
                                    image={item?.logoURL}
                                    title={item?.name}
                                    id={item?.ourID}
                                    isCardEnabled={getStatusValue(item)?.toLowerCase() === "active"}
                                    isCardSuspended={getStatusValue(item)?.toLowerCase() === "suspended"}
                                    data={{
                                        createdBy: item?.createdBy,
                                        createdOn: item?.createdOn,
                                        retailBrand: item?.retailBrandName,
                                        country: item?.country,
                                        city: item?.city,
                                        address: item?.address,
                                        company: item?.companyName,
                                        POSsystem: item?.POSsystem,
                                        POSstatus: item?.POSstatus,
                                        //deliveries: item?.deliveries,
                                        status: item?.status,

                                        //EXTRA PARAMETERS FOR DELIVERIES
                                        pickup: item?.pickup,
                                        courier: item?.courier,
                                        shipping: item?.shipping
                                    }}
                                    edit={props?.onEditAction ? { onClick: () => props?.onEditAction(item) } : null}
                                    delete={
                                        props?.onDeleteAction ? { onClick: () => props?.onDeleteAction(item) } : null
                                    }
                                    suspend={{
                                        isReenable: item?.status?.toLowerCase() === "suspended",
                                        onClick: props?.onSuspendAction ? () => props?.onSuspendAction(item) : null
                                    }}
                                    primaryButton={{ title: "Details" }}
                                    secondaryButton={{ title: "Add Staff" }}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <StoresGridCard isCardEnabled={false} key={index} />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton height="420.28px" key={index} />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
