import StoresPage from "../components/pages/stores/StoresPage";
import CreateStorePage from "../components/pages/stores/CreateStorePage";
import StoresPOSPage from "../components/pages/stores/StorePOSPage";

const routes = [
    {
        path: "/stores",
        element: <StoresPage />
    },
    {
        path: "/stores/create",
        element: <CreateStorePage />
    },
    {
        path: "/stores/edit/:storeID",
        element: <CreateStorePage />
    },
    {
        path: "/stores/pos",
        element: <StoresPOSPage />
    }
];

export default routes;
