import React, { useContext, useEffect, useState } from "react";
import InfinityGridFFCsCards from "../../common/cardContainers/InfinityGridFFCsCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import OrganizeFiltersHeader from "../../common/mainApp/mainWindowHeaders/OrganizeFiltersHeader";
import LayoutContext from "../../../LayoutContext";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

const infiniteGridQuerykey = "FFCGrid";
const listQueryKey = "FFCList";

export default function FullfillmentCentersPage() {
    document.title = `${APP_TITLE} - Fullfillment Centers`;

    /* #region VARS */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { setLayoutType } = useContext(LayoutContext);
    const navigate = useNavigate();
    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle] = useState("List");
    const [currentRetailBrand, setCurrentRetailBrand] = useState(JSON.parse(localStorage.retailBrands)?.[0]);
    const [retailBrandDropdownData, setRetailBrandDropdownData] = useState([]);
    const [allStoresList, setAllStoresList] = useState([]);
    /* #endregion */

    /* #region METHODS */
    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            currentRetailBrand,
            setCurrentRetailBrand,
            retailBrandDropdownData,
            allStoresList
        );
    }, [props?.layoutType, currentRetailBrand, retailBrandDropdownData, allStoresList]);

    useEffect(() => {
        const interval = setInterval(() => {
            onClickRefreshAction();
        }, 180000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLayoutType("List");

        const brands = JSON.parse(localStorage.retailBrands);

        const brandDropdownData = brands?.map(brand => {
            return {
                name: brand?.name,
                value: brand?._id
            };
        });
        setRetailBrandDropdownData(brandDropdownData);

        const activeStores = localStorage?.activeStores ?? "0";
        //TODO: Get all stores
        const allStores = [
            {
                name: `All Stores (${activeStores})`,
                value: "all",
                selected: true
            }
        ];

        setAllStoresList(allStores);
    }, []);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Store Name",
            selector: row => row?.storeName ?? "--"
        },
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand ?? "--"
        },
        {
            name: "Affiliated to",
            selector: row => row?.affiliatedTo ?? "--"
        },
        {
            name: "Accepted on",
            selector: row => formatDefaultDateTime(row?.acceptedOn)
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridFFCsCards
                    minColumnWidth="238px"
                    limit={12}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Fulfilment Centers"
                    columns={listColumns}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Details",
                                //onClick: () => navigate("/ffcenters/details"),
                                isDisabled: () => true
                            },
                            {
                                title: "Stock",
                                onClick: row => navigate(`/stock?storeID=${row?.storeID}&storeName=${row?.storeName}`),
                                isDisabled: () => false
                            }
                        ]
                    }}
                    includeStatus={{
                        width: "100px",
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    api={{
                        endpoint: "getFulfillmentCentersData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID")
                        }
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(
    props,
    onRefreshAction,
    navigations,
    currentRetailBrand,
    setCurrentRetailBrand,
    retailBrandDropdownData,
    allStoresList
) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters", active: true, disabled: createMode, title: "AFFILIATIONS" },
        { to: "/ffcenters/management", active: false, disabled: false, title: "MANAGEMENT" },
        { to: "/stock", active: false, disabled: false, title: "STOCK" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/labelbrands/request", active: false, disabled: createMode, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <OrganizeFiltersHeader
                visible={true}
                gridEnabled={false}
                listEnabled={true}
                onRefreshAction={onRefreshAction}
                searchBoxPlaceholder="Search"
                defaultViewOption="List"
                primaryOrganize={{
                    visible: true,
                    dropdownData: retailBrandDropdownData,
                    onDropdownChange: item => {
                        setCurrentRetailBrand({ _id: item?.value, name: item?.name });
                    },
                    selectedValue: currentRetailBrand?.name,
                    disabled: true
                }}
                extraOrganize={{
                    visible: true,
                    dropdownData: allStoresList,
                    //onDropdownChange: onStoreChange,
                    //selectedValue: filteredStores?.[0]?.name,
                    disabled: true
                }}
            />
        )
    });
}
