import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";
import moment from "moment/moment";
/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying store information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the store.
 * @param {string} props.id - The ID of the store.
 * @param {boolean} props.hasBorders - Indicates if the card has borders.
 * @param {string} [props.titlePrefix="STORE"] - The prefix for the title.
 * @param {string} [props.idPrefix="BOTAG ID"] - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the store.
 * @param {Object[]} props.details - The details to display on the card.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.edit - The edit button configuration.
 * @param {Object} props.suspend - The suspend button configuration.
 * @param {Object} props.delete - The delete button configuration.
 * @returns {JSX.Element} The rendered grid card component.
 */
export default function StoresGridCard(props) {
    const status = props?.data?.status || "--";
    const deliveries = [];

    if (props?.data?.pickup) deliveries.push("PK");
    if (props?.data?.courier) deliveries.push("CO");
    if (props?.data?.shipping) deliveries.push("SH");

    let deliveriesText = deliveries.join(", ");
    if (deliveriesText === "") deliveriesText = props?.id ? "None" : "-";

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={props?.primaryButton ?? { title: "Details" }}
                secondaryButton={props?.secondaryButton ?? { title: "Add Staff" }}
                title={props?.title}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "STORE"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageFit="cover"
                details={[
                    { key: "Created by:", value: props?.data?.createdBy || "--" },
                    {
                        key: "Created on:",
                        value: props?.data?.createdOn ? moment(props?.data?.createdOn).format("DD/MM/YYYY") : "--"
                    },
                    { key: "Company:", value: props?.data?.company || "--" },
                    { key: "Retail brand: ", value: props?.data?.retailBrand || "--" },

                    { key: "POS:", value: props?.data?.POSsystem || "--" },
                    {
                        key: "POS status:",
                        value: getSpanWithColor(props?.data?.POSstatus || "--", defaultStatusColors)
                    },
                    { key: "Deliveries:", value: deliveriesText },
                    { key: "Status:", value: getSpanWithColor(status ?? "--", defaultStatusColors) }
                ]}
                edit={{ hidden: false, ...props?.edit }}
                suspend={props?.suspend}
                delete={props?.delete}
            />
        </StyledPage>
    );
}
