import { toast } from "react-toastify";
import axios from "axios";
import { useInfiniteQuery } from "react-query";
import toastSuccess from "./../assets/images/toast-success.png";
import toastWarning from "./../assets/images/toast-warning.png";
import toastError from "./../assets/images/toast-error.png";
import toastInfo from "./../assets/images/toast-info.png";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import {
    BLOB_ACCOUNT_IMAGE,
    BLOB_BOIMAGE_CONTAINER,
    BLOB_BRAND_IMAGE,
    BLOB_COMPANY_IMAGE,
    BLOB_PRODUCT_CONTAINER,
    BLOB_STAFF_CONTAINER,
    BLOB_STORE_CONTAINER
} from "../config/constant";

/**
 * Displays a toast message with the specified options.
 *
 * @param {Object} props - The properties of the toast message.
 * @param {string} props.title - The title of the toast message.
 * @param {string} props.description - The description of the toast message.
 * @param {string} props.message - The message of the toast.
 * @param {string} [props.type="success"] - The type of the toast message. Can be "success", "warning", "error", or "info".
 * @param {number} [props.autoClose=5000] - The time in milliseconds before the toast message automatically closes.
 * @param {function} props.onClose - The function to call when the toast message closes.
 */
export function showToastMessage(props) {
    const { title, description, message, type = "success", autoClose = 5000, onClose = undefined } = props;

    const descriptionStr = description || message;

    const htmlBody = descriptionStr ? (
        <div>
            <div>{title}</div>
            <div
                style={{ fontSize: "12px", color: "var(--text-icons-gray-100, #D1D1D1)", marginTop: "5px" }}
                dangerouslySetInnerHTML={{ __html: descriptionStr }}
            />
        </div>
    ) : (
        title
    );

    switch (type) {
        case "success":
            toast.success(htmlBody, {
                theme: "dark",
                icon: () => <img alt="success" src={toastSuccess} />,
                progressStyle: { background: "#00BB00" },
                autoClose: autoClose,
                onClose: onClose ? onClose : () => {}
            });
            return;
        case "warning":
            toast.warning(htmlBody, {
                theme: "dark",
                icon: () => <img alt="warning" src={toastWarning} />,
                progressStyle: { background: "#ff9900" },
                autoClose: autoClose,
                onClose: onClose ? onClose : () => {}
            });
            return;
        case "error":
            toast.error(htmlBody, {
                theme: "dark",
                icon: () => <img alt="error" src={toastError} />,
                progressStyle: { background: "#FF3636" },
                autoClose: autoClose,
                onClose: onClose ? onClose : () => {}
            });
            return;
        case "info":
            toast.info(htmlBody, {
                theme: "dark",
                icon: () => <img alt="info" src={toastInfo} />,
                progressStyle: { background: "#00AEEF" },
                autoClose: autoClose,
                onClose: onClose ? onClose : () => {}
            });
            return;
        default:
            console.error("Invalid type provided for showToastMessage method!");
            return;
    }
}

/** @deprecated use showToastMessage instead */
export function showSuccessMessage(message, autoClose = 5000, onClose = () => {}) {
    //toast.configure();
    toast.success(message, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="success" src={toastSuccess} />,
        autoClose: autoClose,
        onClose: () => {
            if (onClose) onClose();
        }
    });
}

/** @deprecated use showToastMessage instead */
export function showWarningMessage(message, autoClose = 5000, onClose = () => {}) {
    //toast.configure();
    toast.warning(message, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="warning" src={toastWarning} />,
        progressStyle: { background: "#ff9900" },
        autoClose: autoClose,
        onClose: () => {
            if (onClose) onClose();
        }
    });
}

/** @deprecated use showToastMessage instead */
export function showErrorMessage(message, autoClose = 5000, onClose = () => {}) {
    //toast.configure();
    toast.error(message, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="error" src={toastError} />,
        autoClose: autoClose,
        onClose: () => {
            if (onClose) onClose();
        }
    });
}

/** Requested Standard: All edit pages after update should call this method to show success message and go back to previous page. */
export function showUpdateMessageAndGoBack(navigate, onClose = () => {}) {
    const message = "Changes saved successfully!";
    const subMessage = "You can now proceed.";
    const autoClose = 5000;

    toast.success(
        <div>
            <div>{message}</div>
            <div style={{ fontSize: "12px", color: "var(--text-icons-gray-100, #D1D1D1)", marginTop: "5px" }}>
                {subMessage}
            </div>
        </div>,
        {
            theme: "dark",
            icon: ({ theme, type }) => <img alt="success" src={toastSuccess} />,
            autoClose: autoClose,
            onClose: () => {
                if (onClose) onClose();
            }
        }
    );
    if (navigate) navigate(-1);
    else console.error("Navigation function not provided to showUpdateMessageAndGoBack method.");
}

export function defaultApiError(error) {
    let message = "An error occurred while trying to process your request.";
    message = error?.message ? `${message} ${error.message}.` : message;

    //toast.configure();
    toast.error(message, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="error" src={toastError} />,
        autoClose: false
    });
}

export function showConfirmationMessage(message, onConfirm = () => {}, onDeny = () => {}) {
    Swal.fire({
        title: "",
        text: "",
        html: message,
        icon: "warning",
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#205380",
        denyButtonColor: "#205380",
        confirmButtonText: "YES",
        denyButtonText: "NO",
        background: "#1d2530",
        color: "#fff",
        iconColor: "rgb(255, 153, 0)",
        width: "500px",
        padding: "2em",
        reverseButtons: false
    }).then(result => {
        if (result.isConfirmed) {
            onConfirm();
        }
        if (result.isDenied || result.isDismissed) {
            onDeny();
        }
    });
}

export function makePostRequest(
    endpoint,
    data,
    useFormData = false,
    setShowLoader = null,
    onError = () => {},
    onUploadProgress = undefined,
    onErrorShowMsg = true
) {
    return new Promise((resolve, reject) => {
        const api_url = `${process.env.REACT_APP_API_URL}${endpoint}?code=${process.env.REACT_APP_API_CODE}`;

        let axiosConfig = !useFormData
            ? {}
            : {
                  "Content-Type": "multipart/form-data",
                  "Access-Control-Allow-Origin": "*"
              };

        if (setShowLoader) setShowLoader(true);

        if (onUploadProgress) axiosConfig = { ...axiosConfig, onUploadProgress: onUploadProgress };

        axios
            .post(api_url, data, axiosConfig)
            .then(response => {
                if (response.data?.status === 200) {
                    resolve(response);
                } else {
                    const message =
                        response.data?.message && typeof response.data?.message === "string"
                            ? response.data?.message
                            : "An error occurred while trying to process your request.";
                    if (onErrorShowMsg) showErrorMessage(message);
                    onError(response);
                    resolve(response);
                }
            })
            .catch(error => {
                onError(error);
                const message = error?.message
                    ? `${error.message}.`
                    : "An error occurred while trying to process your request.";
                if (onErrorShowMsg) showErrorMessage(message);
                //reject(error);
            })
            .finally(() => {
                if (setShowLoader) {
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 0);
                }
            });
    });
}

export function makeCommonPostRequest(
    context,
    endpoint,
    data,
    useFormData = false,
    setShowLoader = null,
    onError = () => {},
    onUploadProgress = undefined,
    onErrorShowMsg = true
) {
    const commonEndpoint = "callCommonAPI";
    const params = {
        context: context,
        endpoint: endpoint,
        paramsData: data
    };

    return new Promise((resolve, reject) => {
        const api_url = `${process.env.REACT_APP_API_URL}${commonEndpoint}?code=${process.env.REACT_APP_API_CODE}`;

        let axiosConfig = !useFormData
            ? {}
            : {
                  "Content-Type": "multipart/form-data",
                  "Access-Control-Allow-Origin": "*"
              };

        if (setShowLoader) setShowLoader(true);

        if (onUploadProgress) axiosConfig = { ...axiosConfig, onUploadProgress: onUploadProgress };

        axios
            .post(api_url, params, axiosConfig)
            .then(response => {
                if (response.data?.status === 200) {
                    resolve(response);
                } else {
                    const message =
                        response.data?.message && typeof response.data?.message === "string"
                            ? response.data?.message
                            : "An error occurred while trying to process your request.";
                    if (onErrorShowMsg) showErrorMessage(message);
                    resolve(response);
                }
            })
            .catch(error => {
                onError(error);
                //reject(error);
            })
            .finally(() => {
                if (setShowLoader) {
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 0);
                }
            });
    });
}

export function sendCommonEmail(
    emailFunctionName,
    emailFields,
    toEmail,
    setShowLoader = null,
    onError = () => {},
    onErrorShowMsg = true
) {
    const context = "sendEmail";

    const commonEndpoint = "callCommonAPI";
    const params = {
        context,
        functionName: emailFunctionName,
        paramsData: { ...emailFields, toEmail }
    };

    return new Promise((resolve, reject) => {
        const api_url = `${process.env.REACT_APP_API_URL}${commonEndpoint}?code=${process.env.REACT_APP_API_CODE}`;

        const axiosConfig = {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
        };

        if (setShowLoader) setShowLoader(true);

        axios
            .post(api_url, params, axiosConfig)
            .then(response => {
                if (response.data?.status === 200) {
                    resolve(response);
                } else {
                    const message =
                        response.data?.message && typeof response.data?.message === "string"
                            ? response.data?.message
                            : "An error occurred while trying to process your request.";
                    if (onErrorShowMsg) showErrorMessage(message);
                    resolve(response);
                }
            })
            .catch(error => {
                onError(error);
                //reject(error);
            })
            .finally(() => {
                if (setShowLoader) {
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 0);
                }
            });
    });
}

export const usePostInfiniteQuery = (
    queryKey,
    api,
    formData,
    onSuccess,
    useBOCommon,
    BOCommonOperation,
    keepPreviousData = false
) => {
    const queryKeyArray = typeof queryKey === "string" ? [queryKey] : queryKey;
    return useInfiniteQuery({
        queryKey: queryKeyArray,
        queryFn: async ({ pageParam = 1 }) => {
            formData.page = pageParam;

            let tmp = null;
            if (!localStorage.getItem("token")) {
                console.error("Request cancelled, user not logged in.");
                return [];
            }
            if (!useBOCommon) tmp = await makePostRequest(api, formData);
            else tmp = await makeCommonPostRequest(BOCommonOperation, api, formData);
            const res = tmp?.data;
            if (res?.status !== 200) {
                /* if (![undefined, "ok", "success", ""].includes(res?.message?.toLowerCase()))
                    showErrorMessage(res?.message); */
                return [];
            }

            return res;
        },

        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.nextPage;
        },
        onSuccess: onSuccess,
        keepPreviousData: keepPreviousData
    });
};

//TODO: makeGetRequest make return promise
export function makeGetRequest(endpoint, data = [], onError = () => {}) {
    let api_url = `${process.env.REACT_APP_API_URL}${endpoint}?code=${process.env.REACT_APP_API_CODE}`;

    // If data is provided, append it as query parameters
    if (data) {
        const params = new URLSearchParams();

        // Loop through the data object and add each key-value pair to the URL
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params.append(key, data[key]);
            }
        }

        // Append the parameters to the URL
        api_url += `&${params.toString()}`;
    }

    return axios
        .get(api_url)
        .then(response => {
            if (response.status === 200) {
                return response;
            } else {
                throw new Error("Error code " + response.status);
            }
        })
        .catch(error => {
            defaultApiError(error);

            console.error("API Request Error:", error);
            //throw error;
            onError(error);
        });
}

export function uploadBlobFile(fileBlob, container, setShowLoader = null) {
    return new Promise((resolve, reject) => {
        if (
            ![
                BLOB_ACCOUNT_IMAGE,
                BLOB_BOIMAGE_CONTAINER,
                BLOB_BRAND_IMAGE,
                BLOB_COMPANY_IMAGE,
                BLOB_COMPANY_IMAGE,
                BLOB_PRODUCT_CONTAINER,
                BLOB_STAFF_CONTAINER,
                BLOB_STORE_CONTAINER
            ].includes(container)
        ) {
            reject(`Container ${container} not recognized for uploading files. Try again later.`);
            return;
        }

        if (!fileBlob || !fileBlob.name) {
            reject("File selected is not recognized as compatible for upload. Try again later.");
            return;
        }

        const formData = new FormData();
        formData.append("containerName", container);
        formData.append("files", fileBlob);
        formData.append("blobName", fileBlob.name);

        makePostRequest("uploadBlobFile", formData, true, setShowLoader)
            .then(res => {
                resolve(res);
            })
            .catch(error => reject(error));
    });
}

export async function getUserIP() {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
}

export function openNewPage(url) {
    if (!url) {
        showErrorMessage("Can't open new page, URL is not defined.");
        return;
    }

    window.open(url, "_blank");
}

export function stripHtmlTags(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}

export function showEnv() {
    console.log("Env Vars:", process.env);
}

export function isLocalhost(functionality) {
    if (localStorage.getItem("debugPass") === "gamechanger") return true;
    if (functionality === "Logout Timer") return true;
    if (!functionality) {
        console.error("Functionality name not provided for isLocalhost function.");
        return false;
    }
    console.log(`NOTE: Functionality only available in localhost: ${functionality}`);
    return window.location.hostname === "localhost";
}
