import React from "react";
import FullScreenOverlay from "./overlay/FullScreenOverlay";
import DialogBoxComponent from "./dialogs/DialogBoxComponent";
import LayoutContext from "../../LayoutContext";
import AppContext from "../../AppContext";
import { useNavigate } from "react-router";
import PopupContext from "../../PopupContext";

export default function PopupManager() {
    /* #region VARS */
    const navigate = useNavigate();
    const { showIsSubscribedPopup, setShowIsSubscribedPopup, isLoadingAccountPage } = React.useContext(LayoutContext);
    const {
        showStoreAndPOSOnboardingCompleted,
        setShowStoreAndPOSOnboardingCompleted,
        showStoreWithoutPOSOnboardingCompleted,
        setShowStoreWithoutPOSOnboardingCompleted
    } = React.useContext(PopupContext);

    const { isLoadingApp } = React.useContext(AppContext);
    /* #endregion */

    return (
        <div>
            <FullScreenOverlay show={showIsSubscribedPopup && !isLoadingApp && !isLoadingAccountPage}>
                <DialogBoxComponent
                    title="Subscribed!"
                    message={
                        "<div style='font-size: 12px; margin: 0 -10px;'>We will send you an email when the trial period starts. You can now continue activating your account.</div>"
                    }
                    onClickYes={() => {
                        setShowIsSubscribedPopup(false);
                    }}
                    heading="SUCCESS..."
                    headingColor="var(--text-icons-green-500)"
                    showCancel={false}
                    confirmText="CONTINUE"
                    confirmState="secondary"
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showStoreAndPOSOnboardingCompleted && !isLoadingApp}>
                <DialogBoxComponent
                    headingFontSize="18px"
                    buttonWidth="50%"
                    title="Store created and <br />POS connection established <span style='color: #00BB00;'>Onboarding completed!</span>"
                    message={`<div style='font-size: 12px; margin: 0 -10px;'>
                                This store is now connected to BOtag, sharing real-time stock and prices.
                                <br /><br />
                                You can now proceed to requesting Label Brands to <br/>affiliate this store as their fulfillment center.
                                <br /><br />
                                Also, you can continue enrolling more stores; check invitations from Label Brands or explore your account.
                              </div>`}
                    showCloseButton={true}
                    onClickCloseBtn={() => setShowStoreAndPOSOnboardingCompleted(false)}
                    heading="SUCCESS!"
                    headingColor="var(--text-icons-green-500)"
                    showConfirm={true}
                    showCancel={true}
                    confirmText="REQUEST BRANDS"
                    cancelText="ENROLL STORES"
                    confirmState="secondary"
                    cancelState="secondary"
                    showTopButton={true}
                    topButtonText="CHECK INVITES"
                    topButtonState="secondary"
                    onClickYes={() => {
                        navigate("/labelbrands/request");
                        setShowStoreAndPOSOnboardingCompleted(false);
                    }}
                    onClickNo={() => {
                        navigate("/stores/create");
                        setShowStoreAndPOSOnboardingCompleted(false);
                    }}
                    onClickTopButton={() => {
                        navigate("/labelbrands/invitations");
                        setShowStoreAndPOSOnboardingCompleted(false);
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showStoreWithoutPOSOnboardingCompleted && !isLoadingApp}>
                <DialogBoxComponent
                    headingFontSize="18px"
                    buttonWidth="50%"
                    title="<div style='margin: 0 -15px;'>
                            Store created but <br />
                            <span style='color: #FF9900;'>POS connection not established</span>
                            <span style='color: #00BB00;'>Onboarding completed!</span>
                           </div>"
                    message={`<div style='font-size: 12px; margin: 0 -10px;'>
                                This store now created however, is not yet connected to BOtag.
                                <br /><br />
                                You can now proceed to requesting Label Brands to <br />affiliate this store as their fulfillment center.
                                <br />
                                <span style='color: #FF9900;'>
                                    However, this store can only become a fulfillment center when the POS is connected.
                                </span>
                                <br /><br />
                                You can also continue enrolling more stores, check invites from Label Brands, or explore your account.
                              </div>`}
                    showCloseButton={true}
                    onClickCloseBtn={() => setShowStoreWithoutPOSOnboardingCompleted(false)}
                    heading="ATTENTION!"
                    headingColor="var(--text-icons-red-500)"
                    showConfirm={true}
                    showCancel={true}
                    confirmText="REQUEST BRANDS"
                    cancelText="ENROLL STORES"
                    confirmState="secondary"
                    cancelState="secondary"
                    showTopButton={true}
                    topButtonText="CHECK INVITES"
                    topButtonState="secondary"
                    onClickYes={() => {
                        navigate("/labelbrands/request");
                        setShowStoreWithoutPOSOnboardingCompleted(false);
                    }}
                    onClickNo={() => {
                        navigate("/stores/create");
                        setShowStoreWithoutPOSOnboardingCompleted(false);
                    }}
                    onClickTopButton={() => {
                        navigate("/labelbrands/invitations");
                        setShowStoreWithoutPOSOnboardingCompleted(false);
                    }}
                />
            </FullScreenOverlay>
        </div>
    );
}
