import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import Edit02 from "@untitled-ui/icons-react/build/esm/Edit02";
import Settings03 from "@untitled-ui/icons-react/build/esm/Settings03";
import CreditCard02 from "@untitled-ui/icons-react/build/esm/CreditCard02";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import noImage from "../../../assets/images/no-image.png";
import { useSelector } from "react-redux";
import { accountStatusColors, getSpanWithColor } from "../../../utils/CardUtils";
import { hasNavigationAccess, verifyUserIsOnboarding } from "../../../utils/navigationHelper";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    &.main-sidebar {
        background: var(--backgrounds-lines-n-300);
        width: 220px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .id-area {
        flex: 0 0 auto;
    }

    .bottom-section {
        flex: 0 0 auto;
    }

    .menu-buttons-container {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    .menu-buttons-container::-webkit-scrollbar-thumb {
        background-color: var(--backgrounds-lines-n-200);
        border-radius: 8px;
    }

    .id-logo {
        text-align: center;
        padding: 0;
    }

    .logo-name {
        font-family: Roboto;
        height: 76px;
        margin: 8px 8px 10px 8px;
        padding: 0 !important;
    }

    .logo-name > img {
        height: 76px;
        object-fit: contain;
        width: 100%;
    }

    .logo-name > .editComp {
        display: none;
    }

    .logo-name:hover > .editComp {
        display: block;
        z-index: 1555555;
        color: var(--text-icons-gray-100);
        background-color: #1d2530;
        border-color: #3b3c3d;
        width: 49px;
        position: absolute;
        top: 35px;
        left: 85px;
    }

    .id-name {
        font-size: 0.875rem;
        margin-bottom: 8px;
        text-transform: uppercase;
        text-align: center;
    }

    .id-name.description {
        font-size: 10px;
    }

    .id-details {
        margin: 8px;
        padding: 8px;
    }

    .id-details .active-company {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        position: relative;
    }

    .id-details .active-company > .icon {
        position: absolute;
        right: 0;
        top: -2px;
    }

    .id-details .active-company > .icon svg {
        width: 12px;
        height: 12px;
        color: var(--text-icons-gray-100);
    }

    .id-details .active-company > hr {
        height: 1px;
        width: 100%;
        border-color: var(--backgrounds-lines-n-200);
        margin: 16px 0 12px 0;
    }

    .id-details ul {
        margin: 0;
        padding: 0;
    }

    .id-details ul li {
        color: var(--text-icons-gray-100);
        font-size: 12px;
        line-height: normal;
        list-style-type: none;
        margin: 0 0 8px 0;
    }

    .id-details ul li:last-child {
        margin-bottom: 0px;
    }

    .id-details ul li span {
        color: var(--text-icons-blue-500);
    }

    .id-details ul li span.orng {
        color: var(--text-icons-orange-500) !important;
    }

    .id-details .anchor-mini-button {
        float: right;
        color: var(--text-icons-gray-300);

        border-radius: 3px;
        position: relative;
        top: -2px;
        right: 2px;
        text-align: center;
        width: 20px;
    }

    .id-details .anchor-mini-button > i {
        margin: 3px;
    }

    .id-details .anchor-mini-button:hover {
        color: var(--text-icons-gray-100);
        border-color: var(--text-icons-gray-100);
    }

    .navitems-area {
        background: var(--backgrounds-lines-n-400);
        width: 100%;
    }

    .navitems-area ul {
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .navitems-area ul li {
        display: block;
        font-size: 12px;
        list-style-type: none;
        text-align: center;
    }

    .navitems-area ul li.disabled a {
        color: var(--text-icons-gray-300);
    }

    .navitems-area ul li a {
        border: 1px solid var(--backgrounds-lines-n-200);
        border-top-color: transparent;
        color: var(--text-icons-gray-100);
        display: block;
        padding: 0px;
        position: relative;
        text-align: left;
        width: 100%;
    }

    .menu-images {
        line-height: 46px;
        width: 100%;
        display: flex;
    }

    .menu-img-bg {
        position: relative;
        padding: 0 8px 0 25px;
    }

    .navitems-area ul li .menu-images img,
    .navitems-area.bottom-section ul li .menu-images img {
        width: 20px;
    }

    .navitems-area.bottom-section .menu-images {
        line-height: 42px;
        justify-content: center;
    }

    .navitems-area.bottom-section a {
    }

    .navitems-area ul li a.active,
    .navitems-area ul li a:active {
        border: 1px solid var(--App-Accent-Outline) !important;
        position: relative;
        text-decoration: none;
    }

    .navitems-area ul li a:hover {
        border: 1px solid var(--App-Accent-Outline);
        text-decoration: none;
    }

    .navitems-area.bottom-section {
        border-right: 1px solid var(--backgrounds-lines-n-200);
        padding: 0;
        width: 100%;
    }

    .navitems-area.bottom-section ul {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .navitems-area.bottom-section ul li.bottom-items {
        height: 72px;
        flex: 1;
    }

    .navitems-area.bottom-section ul li a {
        border: 1px solid var(--backgrounds-lines-n-200);
        font-size: 10px;
        padding: 16px 8px;
        text-align: center;
        height: 70px;
    }
`;
/* #endregion */

/**
 * Represents a styled div component for the navigation bar.
 * @param {object} props - The props of the component
 * @param {string} props.className - The class name of the component
 * @param {Array} props.menuBtns - The menu buttons
 * @param {string} props.menuBtns[].title - The title of the button
 * @param {string} props.menuBtns[].to - The link of the button
 * @param {string} props.menuBtns[].icon - The icon of the button
 * @param {string} props.menuBtns[].isActive - The active state of the button
 * @param {string} props.menuBtns[].enabled - The enabled state of the button
 * @param {string} props.menuBtns[].menuOnIcon - The "on" icon of the button
 * @param {string} props.menuBtns[].menuOffIcon - The "off" icon of the button
 * @returns {JSX.Element} - The rendered component
 */
function NavigationBar(props) {
    /* #region STATES */
    const navigate = useNavigate();
    const navigations = useSelector(state => state.navigationAccesses);

    useRef();
    const [organizationName, setOrganizationName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [navigationBarLogo, setNavigationBarLogo] = useState("");
    const [status, setStatus] = useState("");
    const [billingPlan, setBillingPlan] = useState("");
    const [trialPeriod, setTrialPeriod] = useState("60");
    const [activeCompanies, setActiveCompanies] = useState("");
    const [activeRetailBrands, setActiveRetailBrands] = useState("");
    const [activeStores, setActiveStores] = useState("");
    const [activeFFCenters, setActiveFFCenters] = useState("");
    const [disableBilling, setDisableBilling] = useState(true);

    const [hasAccessActiveCompanies, setHasAccessActiveCompanies] = useState(false);
    const [hasAccessActiveRetailBrands, setHasAccessActiveRetailBrands] = useState(false);
    const [hasAccessActiveStores, setHasAccessActiveStores] = useState(false);
    const [hasAccessActiveFFCenters, setHasAccessActiveFFCenters] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const interval = setInterval(refreshNavigationBar, 3000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        refreshNavigationBar();
    }, []);

    useEffect(() => {
        if (navigations?.navigationAccesses) {
            checkHasAccessPage(navigations?.navigationAccesses);
        }
    }, [navigations]);
    /* #endregion */

    /* #region METHODS */
    const checkHasAccessPage = navigationAccesses => {
        if (!navigationAccesses) return;

        const hasAccessActiveCompaniesBTN = hasNavigationAccess(navigationAccesses, "companies");
        //navigationAccesses.companies.navigationPanel && !navigationAccesses.companies.createModeOnly;
        const hasAccessActiveRetailBrandsBTN = hasNavigationAccess(navigationAccesses, "retailBrands");
        //navigationAccesses.retailBrands.navigationPanel && !navigationAccesses.retailBrands.createModeOnly;
        const hasAccessActiveStoresBTN = hasNavigationAccess(navigationAccesses, "stores");
        //navigationAccesses.stores.navigationPanel && !navigationAccesses.stores.createModeOnly;
        const hasAccessActiveFFCentersBTN = hasNavigationAccess(navigationAccesses, "fulfillmentCenters");
        //navigationAccesses.fulfillmentCenters.navigationPanel && !navigationAccesses.fulfillmentCenters.createModeOnly;
        const hasAccessBillingBTN = navigations?.navigationAccesses?.billing?.navigationPanel;

        setHasAccessActiveCompanies(hasAccessActiveCompaniesBTN);
        setHasAccessActiveRetailBrands(hasAccessActiveRetailBrandsBTN);
        setHasAccessActiveStores(hasAccessActiveStoresBTN);
        setHasAccessActiveFFCenters(hasAccessActiveFFCentersBTN);
        setDisableBilling(!hasAccessBillingBTN);
    };

    const isEmptyLocalStorageItem = item => {
        const value = localStorage.getItem(item);
        return [null, undefined, "", "undefined", "null", "-"].includes(value);
    };

    const getLocalStorageItem = (item, defaultError = "") => {
        const value = localStorage.getItem(item);
        return isEmptyLocalStorageItem(item) ? defaultError : value;
    };

    const getTextColorClassNameForValue = value => {
        const val = [null, undefined, "", "undefined", "null", 0, "0", "--", "-", "Not selected"].includes(value)
            ? "text-orange-500"
            : "text-blue-500";

        return val;
    };

    const companyHasLogo = () => {
        return ![null, undefined, "", "undefined", "null"].includes(navigationBarLogo);
    };

    const refreshNavigationBar = () => {
        const auth = localStorage.getItem("token");
        if (!auth) {
            navigate("/login");
        }

        setCompanyName(getLocalStorageItem("currentCompanyName"));
        setOrganizationName(getLocalStorageItem("currentOrganizationName"));
        setNavigationBarLogo(getLocalStorageItem("currentOrganizationLogo"));

        setBillingPlan(getLocalStorageItem("billingPlan"));
        setTrialPeriod(getLocalStorageItem("trialDaysLeft"));
        setActiveCompanies(getLocalStorageItem("activeCompanies"));
        setActiveRetailBrands(getLocalStorageItem("activeRetailBrands"));
        setActiveStores(getLocalStorageItem("activeStores"));
        setActiveFFCenters(getLocalStorageItem("activeFFCenters"));

        let status = getLocalStorageItem("status");
        const userIsOnboarding = verifyUserIsOnboarding(navigations?.navigationAccesses) !== "/dashboard";

        if (!userIsOnboarding) {
            const activeConnectedBrands = parseInt(getLocalStorageItem("activeConnectedBrands")) || 0;
            if (activeConnectedBrands > 0) status = "Active";
            else status = "Enrolled";
        }

        setStatus(status);

        checkHasAccessPage(navigations?.navigationAccesses);
    };

    const logoClicked = () => {
        /*if (status >= 10 || status === 'Completed') {
            navigate("/account/company/logo");
        }*/
    };
    /* #endregion */

    return (
        <StyledPage className={`main-sidebar ${props?.className || ""}`}>
            <div className="id-area">
                <div className={companyHasLogo() ? "id-logo" : "id-logo no-logo"}>
                    <div className={companyHasLogo() ? "logo-name logoimg" : "logo-name"} onClick={null}>
                        {companyHasLogo() ? (
                            <>
                                <img
                                    alt=""
                                    className="leftmainlogo"
                                    src={navigationBarLogo}
                                    style={{ paddingLeft: "16px", paddingRight: "16px" }}
                                />
                                {status === 100 || status === "Completed" ? (
                                    <span className="btn btn-success editComp saveIt" onClick={logoClicked}>
                                        <i className="fa-thin fa-edit saveIt"></i>
                                    </span>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            <>
                                <img
                                    alt=""
                                    className="leftmainlogo"
                                    src={noImage}
                                    style={{ paddingLeft: "16px", paddingRight: "16px", background: "#151922" }}
                                />
                            </>
                        )}
                    </div>
                    <div className="id-name description text-gray-200" style={{ marginBottom: "3px" }}>
                        Account Name
                    </div>
                    <div className="id-name text-blue-500">{organizationName || "--"}</div>
                </div>
                <div className="id-details n-600 border-radius">
                    <div className="active-company">
                        <div className="icon hidden">
                            <ChevronDown />
                        </div>
                        <div className="id-name description text-gray-100">Active Company</div>
                        <div className={`id-name ${getTextColorClassNameForValue(companyName)} m-0`}>{companyName}</div>
                        <hr />
                    </div>
                    <ul>
                        <li>
                            <label className="pr-5px">Current Status:</label>
                            {getSpanWithColor(status, accountStatusColors, "text-orange-500")}
                        </li>
                        <li>
                            Billing Plan:
                            <span className={`${getTextColorClassNameForValue(billingPlan)} pl-5px`}>
                                {billingPlan || "Not selected"}
                            </span>
                            <Link
                                to={disableBilling ? "#" : "/billing"}
                                className={disableBilling ? "hover-not-allowed" : ""}>
                                <span className="anchor-mini-button">
                                    <Edit02
                                        className={!disableBilling ? "text-default cursor-pointer" : "text-inactive"}
                                        height="13px"
                                    />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <label className="pr-5px">Trial period:</label>
                            <span className="text-blue-500 pl-5px">{trialPeriod ? `${trialPeriod} days` : "--"}</span>
                        </li>
                        <li>
                            Active Companies:
                            <span className={getTextColorClassNameForValue(activeCompanies) + " pl-5px"}>
                                {activeCompanies || "-"}
                            </span>
                            <Link
                                to={hasAccessActiveCompanies ? "/companies" : "#"}
                                className={!hasAccessActiveCompanies ? "hover-not-allowed" : "cursor-pointer"}>
                                <span className="anchor-mini-button" style={{ color: "#4F5B6D" }}>
                                    <ChevronRight
                                        className={hasAccessActiveCompanies ? "text-default" : "text-inactive"}
                                        height="13px"
                                    />
                                </span>
                            </Link>
                        </li>
                        <li>
                            Active Brands:
                            <span className={getTextColorClassNameForValue(activeRetailBrands) + " pl-5px"}>
                                {activeRetailBrands || "-"}
                            </span>
                            <Link
                                to={hasAccessActiveRetailBrands ? "/brands" : "#"}
                                className={!hasAccessActiveRetailBrands ? "hover-not-allowed" : "cursor-pointer"}>
                                <span className="anchor-mini-button">
                                    <ChevronRight
                                        className={hasAccessActiveRetailBrands ? "text-default" : "text-inactive"}
                                        height="13px"
                                    />
                                </span>
                            </Link>
                        </li>
                        <li>
                            Active Stores:
                            <span className={getTextColorClassNameForValue(activeStores) + " pl-5px"}>
                                {activeStores || "-"}
                            </span>
                            <Link
                                to={hasAccessActiveStores ? "/stores" : "#"}
                                className={!hasAccessActiveStores ? "hover-not-allowed" : "cursor-pointer"}>
                                <span className="anchor-mini-button">
                                    <ChevronRight
                                        className={hasAccessActiveStores ? "text-default" : "text-inactive"}
                                        height="13px"
                                    />
                                </span>
                            </Link>
                        </li>
                        <li>
                            Active FFCenters:
                            <span className={getTextColorClassNameForValue(activeFFCenters) + " pl-5px"}>
                                {activeFFCenters || "-"}
                            </span>
                            <Link
                                to={hasAccessActiveFFCenters ? "/ffcenters?layoutType=list" : "#"}
                                className={!hasAccessActiveFFCenters ? "hover-not-allowed" : "cursor-pointer"}>
                                <span className="anchor-mini-button">
                                    <ChevronRight
                                        className={hasAccessActiveFFCenters ? "text-default" : "text-inactive"}
                                        height="13px"
                                    />
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="menu-buttons-container navitems-area">
                <ul>
                    {props?.menuBtns?.map(btn => {
                        const key = uuidv4();
                        return <MenuBtn key={key} btn={btn} />;
                    })}
                </ul>
            </div>

            <div className="navitems-area bottom-section">
                <ul>
                    <li className="bottom-items disabled">
                        <Link to="#" className="hover-not-allowed" onClick={e => e.preventDefault()}>
                            <div className="menu-images mb-8px">
                                <Settings03 className="disabled text-inactive" height="100%" width="16px" />
                            </div>
                            <span>Settings</span>
                        </Link>
                    </li>

                    <li className={!disableBilling ? "bottom-items" : "bottom-items disabled"}>
                        <Link
                            to={disableBilling ? "#" : "/billing"}
                            className={`hover-pointer ${
                                window.location.pathname.startsWith("/billing") ? "active" : ""
                            }`}>
                            <div className="menu-images mb-8px">
                                <CreditCard02 className="disabled" height="100%" width="16px" />
                            </div>
                            <span>Billing</span>
                        </Link>
                    </li>

                    <li className="bottom-items disabled">
                        <Link to="#" className="hover-not-allowed" onClick={e => e.preventDefault()}>
                            <div className="menu-images mb-8px">
                                <HelpCircle className="disabled text-inactive" height="100%" width="16px" />
                            </div>
                            <span>Support</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </StyledPage>
    );
}

export default NavigationBar;

/* #region SMALL COMPONENTS */
const MenuBtn = ({ btn }) => {
    return (
        <li className={`menu-item ${btn?.enabled ? "" : "disabled"} ${btn?.isActive ? "active" : ""}`}>
            <Link
                to={btn?.enabled ? btn?.to : "#"}
                onClick={btn?.enabled && btn?.onClick}
                className={`${btn?.isActive ? "active" : ""} ${btn?.enabled ? "" : "hover-not-allowed"}`}>
                <div className="menu-images">
                    <div className="menu-img-bg">
                        {btn?.icon && (
                            <span className={btn?.enabled ? "menu-enabled" : "disabled"}>
                                <i className={` fa-xl ${btn?.icon}`} aria-hidden="true"></i>
                            </span>
                        )}

                        {btn?.menuOnIcon && (
                            <img
                                alt={btn?.title}
                                className={btn?.enabled ? "menu-enabled" : "disabled"}
                                src={btn?.enabled ? btn?.menuOnIcon.icon : btn?.menuOffIcon.icon}
                            />
                        )}

                        {btn?.iconSVG && (
                            <btn.iconSVG
                                alt={btn?.title}
                                height="100%"
                                width="16px"
                                className={btn?.enabled ? "menu-enabled text-default" : "disabled text-inactive"}
                            />
                        )}
                    </div>

                    <span className="text-one-line" style={{ marginRight: "25px" }}>
                        {btn?.title}
                    </span>
                </div>
            </Link>
        </li>
    );
};

/* #endregion */
