import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { APP_TITLE } from "../../../config/constant";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import styled from "styled-components";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import ListComponent from "../../common/lists/ListComponent";
import InfinityGridLabelBrandRequestsCards from "../../common/cardContainers/InfinityGridLabelBrandRequestsCards";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import * as Library from "../../../utils/Library";

import { maskEmail, maskName } from "../../../utils/valuesMasking";
import { defaultStatusColors } from "../../../utils/CardUtils";

import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import ProductFiltersHeader from "../../common/mainApp/mainWindowHeaders/ProductFiltersHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

export default function LabelBrandsRequestsPage() {
    document.title = `${APP_TITLE} - Label Brands Requests`;

    /* #region VARS */
    const [onRemoveDialog, setRemoveDialog] = useState(false);
    const [onResendDialogLoading, setResendDialogLoading] = useState(false);

    const [inviteID, setInviteID] = useState("");
    const [showRequestedAlreadyDialog, setShowRequestedAlreadyDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { MainLayoutProps, layoutType } = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);
    // const navigate = useNavigate();

    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    const infiniteGridQuerykey = "labelBrandsRequestsInfiniteGrid";
    const listQueryKey = "labelBrandsRequestsList";

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    /* #endregion */

    /* #region METHODS */

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const handleResendRequest = async data => {
        setIsLoading(true);
        await Library.makePostRequest(
            "resendInviteToLabelBrand",
            { inviteID: data?._id },
            false,
            setIsLoading,
            undefined,
            undefined,
            false
        ).then(res => {
            const { status, message } = res?.data;
            if (status === 200) {
                Library.showToastMessage({
                    type: "success",
                    title: "Successfully resent!",
                    message: "The invitation was resent successfully"
                });
            } else if (status === 401) {
                setShowRequestedAlreadyDialog(true);
                setErrorMessage(message);
            }
        });
    };
    const handleRemoveRequest = async () => {
        setResendDialogLoading(true);
        const BOstagesAccountID = localStorage.getItem("BOstagesAccountID");
        await Library.makeCommonPostRequest(
            "Invites",
            "hideInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: BOstagesAccountID
            },
            false,
            setResendDialogLoading
        ).then(res => {
            if (res?.status?.data?.status === 200) {
                Library.showToastMessage({
                    type: "success",
                    title: "Successfully removed!",
                    message: "The invitation card was removed successfully"
                });
            }
            handleCloseDialog();
            onClickRefreshAction();
        });
    };

    const handleOpenDialog = inviteID => {
        setInviteID(inviteID);
        setRemoveDialog(true);
    };

    const handleCloseDialog = () => {
        setRemoveDialog(false);
        setInviteID("");
    };
    const handleCloseDialogBox = () => {
        setShowRequestedAlreadyDialog(false);
        setErrorMessage("");
        onClickRefreshAction();
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, onClickRefreshAction, navigations);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Request sent to",
            selector: row => row?.labelBrand?.name ?? "--"
        },
        {
            name: "BOtag ID",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Requested on",
            selector: row => formatDefaultDateTime(row?.createdAt) ?? "--"
        },
        {
            name: "Accepted on",
            selector: row => (row?.statusLog ? formatDefaultDateTime(row?.statusLog[0]?.date) : "--")
        },
        {
            name: "Contact person",
            selector: row =>
                row?.status === "Accepted" ? row?.contactFirstName : maskName(row?.contactFirstName) ?? "--"
        },
        {
            name: "Contact email",
            selector: row => (row?.status === "Accepted" ? row?.contactEmail : maskEmail(row?.contactEmail) ?? "--")
        },
        {
            name: "Requested stores",
            selector: row => row?.requestedStores ?? "--"
        },
        {
            name: "Accepted stores",
            selector: row => row?.acceptedStores ?? "--"
        },
        {
            name: "To brand",
            selector: row => row?.retailBrand?.name ?? "--"
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={onRemoveDialog}>
                <DialogBoxComponent
                    loading={onResendDialogLoading}
                    heading={onResendDialogLoading ? "REMOVING......" : "JUST CHECKING..."}
                    title="Ready to remove?"
                    message="Removing the invite only removes this card and does not remove the connection."
                    headingColor={onResendDialogLoading ? "var(--text-icons-green-500)" : "var(--text-icons-blue-500)"}
                    onClickYes={handleRemoveRequest}
                    onClickNo={handleCloseDialog}
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={showRequestedAlreadyDialog}>
                <DialogBoxComponent
                    heading={"ATTENTION..."}
                    title="REQUEST ALREADY ACCEPTED"
                    message={errorMessage}
                    headingColor={"var(--text-icons-green-500)"}
                    showCancel={false}
                    showConfirm={true}
                    confirmText="CLOSE"
                    onClickYes={handleCloseDialogBox}
                />
            </FullScreenOverlay>
            <StyledPage>
                {viewLayoutStyle === "Grid" && (
                    <InfinityGridLabelBrandRequestsCards
                        minColumnWidth="370px"
                        queryKey={infiniteGridQuerykey}
                        onResendRequest={handleResendRequest}
                        onRemoveRequest={handleOpenDialog}
                    />
                )}
                {viewLayoutStyle === "List" && (
                    <ListComponent
                        title="LABEL BRANDS"
                        //title2="(LAST 10 INVITED)"
                        columns={listColumns}
                        api={{
                            endpoint: "getRequestsToLabelBrands",
                            formData: {
                                companyID: localStorage.getItem("currentCompanyID"),
                                BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                                page: 1,
                                limit: 10
                            }
                            // onSuccess: mapListData
                        }}
                        pageLimit={10}
                        currentPage={1}
                        queryKey={listQueryKey}
                        actions={{
                            includeActions: true,
                            editIsVisible: false,
                            suspendIsVisible: false,
                            // removeIsVisible: false,
                            extraActions: [
                                {
                                    title: "Remove",
                                    onClick: row => {
                                        handleOpenDialog(row?._id);
                                    },
                                    isDisabled: row => {
                                        return (
                                            row?.status !== "Accepted" &&
                                            row?.status !== "Declined" &&
                                            row?.status !== "Pending"
                                        );
                                    }
                                },
                                {
                                    title: "Resend",
                                    onClick: row => {
                                        handleResendRequest(row);
                                    },
                                    isDisabled: row => {
                                        return (
                                            row?.status !== "Requested" &&
                                            row?.status !== "Invited" &&
                                            row?.status !== "Pending"
                                        );
                                    }
                                }
                            ]
                        }}
                        includeStatus={{
                            stickyStatus: true,
                            statusFromPath: "status",
                            statusColors: defaultStatusColors
                        }}
                        sortByCreatedAt={true}
                    />
                )}
            </StyledPage>
        </StyledPage>
    );
}

function setupHeaders(props, onRefreshAction, navigations) {
    const createMode = navigations?.navigationAccesses?.labelbrands?.createModeOnly;

    props.setControlBarLeftBtns([
        //{ to: "#", disabled: true, title: "Label Brands", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([
        { to: "/labelbrands/connections", active: false, disabled: createMode, title: "CONNECTIONS" },
        { to: "/labelbrands/invitations", active: false, disabled: false, title: "INVITATIONS" },
        { to: "/labelbrands/request", active: true, disabled: false, title: "REQUESTS" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/labelbrands/request", active: false, disabled: createMode, title: "Request", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    //TODO: Change this to a "Request" button
    props.setFilterHeaderProperties({
        customContentHeader: (
            <ProductFiltersHeader
                visible={true}
                gridEnabled={true}
                listEnabled={true}
                onRefreshAction={onRefreshAction}
                searchBoxPlaceholder="Search"
            />
        )
    });
}
