import React, { useEffect, useState } from "react";
import styled from "styled-components";
import check0 from "../../../assets/icons/check2.svg";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";
import Checkbox from "../../common/checkbox/Checkbox";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import { FeeHandler, handleCurrency } from "../../../utils/utilHelpers";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    &.ui-card-pricing,
    &.ui-card-pricing * {
        box-sizing: border-box;
    }
    &.ui-card-pricing {
        background: linear-gradient(208deg, #f90 -104.93%, rgba(20, 25, 34, 0) 16.51%), rgb(20, 25, 34);
        border-radius: 24px;
        padding: 35px 24px 24px 24px;
        display: flex;
        flex-direction: column;
        //gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        width: 350px;
        margin-top: -22px;
    }
    .group-1000011155 {
        flex-shrink: 0;
        width: 104.71px;
        height: 96.81px;
        position: absolute;
        right: 0.29px;
        bottom: 0.19px;
        overflow: visible;
    }
    .frame-1000011181 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011252 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313259 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 35px;
    }
    .b-ostandard {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
    .benefit-from-our-launch-offer-subscribing-to-b-otag-at-lower-costs {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }

    .benefit-from-our-launch-offer-subscribing-to-b-otag-at-lower-costs-span {
        color: var(--text-and-icons-gray-200, #7c868b);
        font-size: 14px;
        font-weight: 400;
        line-height: 1.15;
    }
    .frame-313337 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 58px;
        padding: 0px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: absolute;
        right: 6px;
        top: 55px;
        transform-origin: 0 0;
        transform: rotate(15deg) scale(1, 1);
    }
    ._750-kr {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: left;
        position: relative;
    }
    ._750-kr-span {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-size: 10px;
        font-weight: 400;
        text-decoration: line-through;
    }
    ._750-kr-span2 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-size: 8px;
        font-weight: 400;
    }
    .frame-1000011249 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-2 {
        align-self: stretch;
        flex-shrink: 0;
        height: 1px;
        position: relative;
        overflow: visible;
        stroke-width: 1px;
        background: #262e3e;
    }
    .frame-1000011244 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011255 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313261 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .check {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .turn-your-stores-into-fulfillment-hub-for-the-brands-you-sell {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        line-height: 1.15;
    }
    .frame-313264 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .check2 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .get-product-master-data-automatically-and-directly-in-your-pos {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        line-height: 1.15;
    }
    .frame-313262 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .check3 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .share-real-time-stock-with-your-suppliers-for-a-stronger-support {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        line-height: 1.15;
    }
    .frame-1000011242 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 5px;
        padding: 2px 4px 2px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 55px;
        position: absolute;
        right: 23px;
        top: 4px;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
    }
    .on-limited-stock {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        font-weight: 400;
        position: relative;
    }
    .frame-313263 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .check4 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .reorder-automatically-upon-strong-stock-rotation {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        line-height: 1.15;
    }
    .line-22 {
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
        overflow: visible;
    }
    .frame-1000011266 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011268 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011267 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .monthly-fee {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: 18px;
        letter-spacing: 0.0025em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 21px;
    }
    .frame-1000011262 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011246 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313288 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313266 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3132622 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .first-2-months {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
    }
    .frame-3132612 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._0-kr {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
        height: 29px;
    }
    ._0-kr-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 25px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    ._0-kr-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 16px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    .frame-3132632 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .per-retailer {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
    }
    .frame-10000112492 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-3132623 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .after-2nd-month {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
    }
    .after-2nd-month-span {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 14px;
        font-weight: 400;
    }
    .after-2nd-month-span2 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 10px;
        font-weight: 400;
    }
    .after-2nd-month-span3 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 14px;
        font-weight: 400;
    }
    ._600-kr {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
        height: 29px;
    }
    ._600-kr-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 25px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    ._600-kr-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 16px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    .line-23 {
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
        overflow: visible;
    }
    .frame-1000011248 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3132662 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011265 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .commission-fee {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: 18px;
        letter-spacing: 0.0025em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 21px;
    }
    .frame-3132642 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011254 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011257 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .first-10-sales {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
    }
    ._0 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
        height: 29px;
    }
    ._0-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 25px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    ._0-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 16px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    .frame-1000011258 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .after-10th-sale {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
    }
    .after-10th-sale-span {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 12px;
        font-weight: 400;
    }
    .after-10th-sale-span2 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 10px;
        font-weight: 400;
    }
    .after-10th-sale-span3 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 12px;
        font-weight: 400;
    }
    ._4-5 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
        height: 29px;
    }
    ._4-5-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 25px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    ._4-5-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 16px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    .frame-1000011259 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .after-2nd-month2 {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
    }
    .after-2nd-month-2-span {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 12px;
        font-weight: 400;
    }
    .after-2nd-month-2-span2 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 10px;
        font-weight: 400;
    }
    .after-2nd-month-2-span3 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-size: 12px;
        font-weight: 400;
    }
    ._9-75 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
        height: 29px;
    }
    ._9-75-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 25px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    ._9-75-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 16px;
        letter-spacing: -0.015em;
        font-weight: 400;
    }
    .payment-fees-included {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-313336 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 58px;
        padding: 2px 4px 2px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: absolute;
        right: -19.14px;
        top: 55px;
        transform-origin: 0 0;
        transform: rotate(15deg) scale(1, 1);
    }
    ._11-75 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: left;
        font-size: 10px;
        font-weight: 400;
        position: relative;
    }
    ._11-75-span {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-size: 10px;
        font-weight: 400;
        text-decoration: line-through;
    }
    ._11-75-span2 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-size: 10px;
        font-weight: 400;
    }
    .frame-1000011250 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin-top: 16px;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 16px;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check5 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }
    .text-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 12px;
        font-weight: 400;
    }
    .text-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
    }
    .frame-162 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .cancel-anytime-during-the-trial-offer-valid-for-1-year-subscription {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
        line-height: 1.15;
    }
    .btn-large-solid-inverted-01 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .btn-small-filled {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 35px;
        position: relative;
    }
    .share {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-size: var(--button-text-large-font-size, 12px);
        letter-spacing: var(--button-text-large-letter-spacing, 0.0125em);
        font-weight: var(--button-text-large-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .frame-313333 {
        background: linear-gradient(95.35deg, rgba(255, 153, 0, 1) 0%, rgba(255, 153, 0, 0) 100%);
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 132.09px;
        height: 20px;
        position: absolute;
        right: -46.78px;
        top: -19px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        transform-origin: 0 0;
        transform: rotate(35.78deg) scale(1, 1);
    }
    .texcon-member {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .up-to-3-stores-540-kr-per-additional-store,
    .up-to-3-stores-540-kr-per-additional-store * {
        box-sizing: border-box;
    }
    .up-to-3-stores-540-kr-per-additional-store {
        color: var(--text-and-icons-orange-500, #ff9900);
        text-align: center;
        position: relative;
        align-self: stretch;
    }
    .up-to-3-stores-540-kr-per-additional-store-span4 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    .up-to-3-stores-540-kr-per-additional-store-span5 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
    }
    .up-to-3-stores-540-kr-per-additional-store-span6 {
        color: var(--text-and-icons-orange-500, #ff9900);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
`;
/* #endregion */

/** Renders a Texcon billing plan card component.
 * @param {Object} props - The component props.
 * @param {Object} props.subscriptionPlanData - The subscription plan data.
 * @param {boolean} props.isMonthly - The billing period.
 * @param {Function} props.onSelectPlan - The function to call when the plan is selected.
 * @param {boolean} props.showLabelBrandPrices - Whether to show the label brand prices.
 * @returns {JSX.Element} The rendered billing plan card component.
 */
export default function StandardBillingPlanCard({
    subscriptionPlanData = null,
    isMonthly = false,
    onSelectPlan = () => {},
    isTexconMember = false,
    isEuroPlan = false,
    isNonTexconMember = false
}) {
    /* #region COMPUTED VALUES */

    const commissionFee = isTexconMember ? "9,75" : "11,75";
    const monthlyValues = {
        monthlyFeeFirst2Months: "0",
        monthlyFeeAfter2ndMonth: FeeHandler({ isMonthly: true, isEuroPlan, isNonTexconMember, isTexconMember }),
        monthlyFeeAfter2ndMonthBefore: "750",
        commissionFeeFirst10Sales: "0",
        commissionFeeAfter10thSale: "4,5",
        commissionFeeAfter2ndMonth: commissionFee,
        commissionFeeAfter2ndMonthBefore: "11,75"
    };

    const yearlyValues = {
        monthlyFeeFirst2Months: "0",
        monthlyFeeAfter2ndMonth: FeeHandler({ isMonthly: false, isEuroPlan, isNonTexconMember, isTexconMember }),
        monthlyFeeAfter2ndMonthBefore: "7.200",
        commissionFeeFirst10Sales: "0",
        commissionFeeAfter10thSale: "4,5",
        commissionFeeAfter2ndMonth: commissionFee,
        commissionFeeAfter2ndMonthBefore: "11,75"
    };

    const currency = handleCurrency({ isEuroPlan });

    /* #endregion */

    /* #region VARS */
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false);
    const [currentValues, setCurrentValues] = useState(isMonthly ? monthlyValues : yearlyValues);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setCurrentValues(isMonthly ? monthlyValues : yearlyValues);
    }, [isMonthly]);

    useEffect(() => {
        const subscriptionID = localStorage.getItem("subscriptionPlanID");
        if (subscriptionID) {
            setIsDisabled(true);
        }
    }, [subscriptionPlanData]);
    /* #endregion */

    return (
        <StyledPage className="ui-card-pricing">
            <FullScreenOverlay show={showTermsAndConditions}>
                <TermsAndPrivacyPanel
                    type="terms"
                    onCloseClicked={() => {
                        setShowTermsAndConditions(false);
                    }}
                />
            </FullScreenOverlay>
            <div className="group-1000011155">
                <BackgroundImg alt="" />
            </div>

            <div className="frame-1000011181">
                <div className="frame-1000011252">
                    <div className="frame-313259">
                        <div className="b-ostandard">BOstandard</div>
                    </div>
                    <div className="benefit-from-our-launch-offer-subscribing-to-b-otag-at-lower-costs">
                        <p className="benefit-from-our-launch-offer-subscribing-to-b-otag-at-lower-costs-span">
                            Benefit from our <b>Launch Offer</b>, subscribing to <b>BO</b>tag at lower costs*.
                        </p>
                    </div>
                </div>
                <div className="frame-1000011249">
                    <div className="line-2" src="line-20.svg" />
                    <div className="frame-1000011244">
                        <div className="frame-1000011255">
                            <div className="frame-313261">
                                <img className="check" src={check0} alt="" />
                                <div className="turn-your-stores-into-fulfillment-hub-for-the-brands-you-sell">
                                    Turn your stores into fulfillment hub for the Brands you sell
                                </div>
                            </div>
                            <div className="frame-313264">
                                <img className="check" src={check0} alt="" />
                                <div className="get-product-master-data-automatically-and-directly-in-your-pos">
                                    Get product master data automatically and directly in your POS
                                </div>
                            </div>
                            <div className="frame-313262">
                                <img className="check" src={check0} alt="" />
                                <div className="share-real-time-stock-with-your-suppliers-for-a-stronger-support">
                                    Share real-time stock with your
                                    <br />
                                    suppliers for a stronger support
                                </div>
                                <div className="frame-1000011242">
                                    <div className="on-limited-stock">
                                        On limited
                                        <br />
                                        stock
                                    </div>
                                </div>
                            </div>
                            <div className="frame-313263">
                                <img className="check" src={check0} alt="" />
                                <div className="reorder-automatically-upon-strong-stock-rotation">
                                    Reorder automatically upon strong stock rotation
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line-2" />
                    <div className="frame-1000011266">
                        <div className="frame-1000011268">
                            <div className="frame-1000011267">
                                <div className="monthly-fee">{isMonthly ? "Monthly" : "Annual"} Fee</div>
                                <div className="frame-1000011262">
                                    <div className="frame-1000011246">
                                        <div className="frame-313288">
                                            <div className="frame-313266">
                                                <div className="frame-3132622">
                                                    <div className="first-2-months">First 2 months</div>
                                                </div>
                                                <div className="frame-3132612">
                                                    <div className="_0-kr">
                                                        <span>
                                                            <span className="_0-kr-span">
                                                                {currentValues?.monthlyFeeFirst2Months}
                                                            </span>
                                                            <span className="_0-kr-span2">{currency}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frame-10000112492">
                                        <div className="frame-313288">
                                            <div className="frame-313266">
                                                <div className="frame-3132623">
                                                    <div className="after-2nd-month">
                                                        <span>
                                                            <span className="after-2nd-month-span">After 2</span>
                                                            <sup className="after-2nd-month-span2">nd</sup>{" "}
                                                            <span className="after-2nd-month-span3">month</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="frame-3132612">
                                                    <div className="_600-kr">
                                                        <span>
                                                            <span className="_600-kr-span">
                                                                {currentValues?.monthlyFeeAfter2ndMonth}
                                                            </span>
                                                            <span className="_600-kr-span2">{currency}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="up-to-3-stores-540-kr-per-additional-store">
                                    <span>
                                        <span className="up-to-3-stores-540-kr-per-additional-store-span4">
                                            Up to 3 stores - {isTexconMember ? "540" : isEuroPlan ? "67,5" : "675"}
                                        </span>
                                        <span className="up-to-3-stores-540-kr-per-additional-store-span5">
                                            {currency}
                                        </span>{" "}
                                        <span className="up-to-3-stores-540-kr-per-additional-store-span6">
                                            {!isMonthly ? "added store per month" : "per additional store"}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="frame-313337" style={{ right: !isMonthly && "-9px" }}>
                            {isTexconMember && (
                                <div className="_750-kr">
                                    <span>
                                        <span className="_750-kr-span">
                                            {currentValues?.monthlyFeeAfter2ndMonthBefore}
                                        </span>
                                        <span className="_750-kr-span2">kr</span>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="line-2" />
                    <div className="frame-1000011248">
                        <div className="frame-3132662">
                            <div className="frame-1000011265">
                                <div className="commission-fee">Commission Fee</div>
                                <div className="frame-3132642">
                                    <div className="frame-1000011254">
                                        <div className="frame-1000011257">
                                            <div className="first-10-sales">First 10 sales</div>
                                            <div className="_0">
                                                <span>
                                                    <span className="_0-span">
                                                        {currentValues?.commissionFeeFirst10Sales}
                                                    </span>
                                                    <span className="_0-span2">%</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="frame-1000011258">
                                            <div className="after-10th-sale">
                                                <span>
                                                    <span className="after-10th-sale-span">After 10</span>
                                                    <sup className="after-10th-sale-span2">th</sup>{" "}
                                                    <span className="after-10th-sale-span3">sale</span>
                                                </span>
                                            </div>
                                            <div className="_4-5">
                                                <span>
                                                    <span className="_4-5-span">
                                                        {currentValues?.commissionFeeAfter10thSale}
                                                    </span>
                                                    <span className="_4-5-span2">%</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="frame-1000011259">
                                            <div className="after-2nd-month2">
                                                <span>
                                                    <span className="after-2nd-month-2-span">After 2</span>
                                                    <sup className="after-2nd-month-2-span2">nd</sup>{" "}
                                                    <span className="after-2nd-month-2-span3">month</span>
                                                </span>
                                            </div>
                                            <div className="_9-75">
                                                <span>
                                                    <span className="_9-75-span">
                                                        {currentValues?.commissionFeeAfter2ndMonth}
                                                    </span>
                                                    <span className="_9-75-span2">%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="payment-fees-included">Payment fees included</div>
                            </div>
                        </div>
                        {isTexconMember && (
                            <div className="frame-313336">
                                <div className="_11-75">
                                    <span>
                                        <span className="_11-75-span">
                                            {currentValues?.commissionFeeAfter2ndMonthBefore}
                                        </span>
                                        <span className="_11-75-span2">%</span>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="line-1"></div>
                </div>
            </div>
            <div className="frame-1000011250">
                <div className="checkbox-label">
                    <Checkbox
                        className="checkbox"
                        style={{ top: "-3px" }}
                        isChecked={isTermsAccepted || isDisabled}
                        disabled={isDisabled}
                        onCheckboxClick={() => setIsTermsAccepted(!isTermsAccepted)}
                    />
                    <div className="text">
                        <span>
                            <span className="text-span">I agree with the&nbsp;</span>
                            <span className="text-span2 cursor-pointer" onClick={() => setShowTermsAndConditions(true)}>
                                Terms &amp; Conditions
                            </span>
                        </span>
                    </div>
                </div>
                <div className="frame-162">
                    <div className="cancel-anytime-during-the-trial-offer-valid-for-1-year-subscription">
                        *Cancel anytime during the trial.
                        <br />
                        Offer valid for 1 year subscription
                    </div>
                </div>
                <BtnLargeSolid
                    style={{ opacity: isDisabled ? 0.5 : 1 }}
                    disabled={!isTermsAccepted || isDisabled}
                    textStyle={{ fontSize: "12px", color: isDisabled && "#D1D1D1" }}
                    activeColor="var(--App-Accent-Outline, #ff9900)"
                    normalColor="var(--App-Accent-Accent, #d37e00)"
                    hoverColor="var(--App-Accent-Hover, #e48900)"
                    disabledColor={!isDisabled ? "var(--Buttons-All-Platforms-Gray-500, #10141b)" : "#d22626"}
                    onClick={() => onSelectPlan(subscriptionPlanData)}>
                    {isDisabled ? "CANCEL" : "SELECT PLAN"}
                </BtnLargeSolid>
            </div>
            <div className="frame-313333">
                <div className="texcon-member">{isTexconMember ? "TEXCON MEMBER" : "LAUNCH OFFER"}</div>
            </div>
        </StyledPage>
    );
}

const BackgroundImg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="107" height="99" viewBox="0 0 107 99" fill="none">
            <g opacity="0.3">
                <g filter="url(#filter0_f_1351_139038)">
                    <path
                        d="M2 12.9596C2 6.90678 7.33048 2 13.906 2H94.8054C101.381 2 106.711 6.90678 106.711 12.9596V87.4287C106.711 93.4815 101.381 98.3883 94.8054 98.3883H13.906C7.33047 98.3883 2 93.4815 2 87.4287V12.9596Z"
                        fill="url(#paint0_linear_1351_139038)"
                    />
                </g>
                <path
                    d="M37.4805 54.694C37.4805 48.6412 43.1191 43.7344 50.0746 43.7344H88.18C95.1356 43.7344 100.774 48.6412 100.774 54.694V87.8538C100.774 93.9066 95.1356 98.8134 88.18 98.8134H50.0746C43.1191 98.8134 37.4805 93.9066 37.4805 87.8538V54.694Z"
                    fill="url(#paint1_linear_1351_139038)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_1351_139038"
                    x="0"
                    y="0"
                    width="108.711"
                    height="100.388"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1351_139038" />
                </filter>
                <linearGradient
                    id="paint0_linear_1351_139038"
                    x1="-60.3927"
                    y1="-76.6791"
                    x2="35.5628"
                    y2="76.2492"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0.431522" stopColor="#FF9900" />
                    <stop offset="1" stopColor="#11141B" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1351_139038"
                    x1="129.1"
                    y1="-2.99449"
                    x2="74.0866"
                    y2="86.7677"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF9900" />
                    <stop offset="1" stopColor="#141922" />
                </linearGradient>
            </defs>
        </svg>
    );
};
