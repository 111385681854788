import React, { useEffect, useState } from "react";
import InfinityGridCompanyUsersCards from "../../common/cardContainers/InfinityGridCompanyUsersCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useOutletContext } from "react-router";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { userIsOnBoardingInPage } from "../../../utils/navigationHelper";
import { useSelector } from "react-redux";
import OrganizeFiltersHeader from "../../common/mainApp/mainWindowHeaders/OrganizeFiltersHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function CompaniesUsersPage() {
    document.title = `${APP_TITLE} - Companies Users`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    const navigations = useSelector(state => state.navigationAccesses);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, navigations);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const displayName = (firstName, lastName, defaultValue = "--") => {
        let result = defaultValue;
        if (firstName) result = firstName + " " + (lastName || "");

        return result;
    };

    const listColumns = [
        {
            name: "First Name",
            selector: row => row?.firstName ?? "--"
        },
        {
            name: "Last Name",
            selector: row => row?.lastName ?? "--"
        },
        {
            name: "Role",
            selector: row => row?.role ?? "--"
        },
        {
            name: "Stage Level",
            selector: row => row?.stageLevel ?? "Account" //TODO: Implement stage level
        },
        {
            name: "Mobile",
            selector: row => row?.mobile ?? "--"
        },
        {
            name: "Email",
            selector: row => row?.email ?? "--"
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridCompanyUsersCards minColumnWidth="238px" limit={12} defaultSecondTitle="Company:" />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Company Users"
                    columns={listColumns}
                    api={{
                        endpoint: "getUsersData",
                        useCommonPostRequest: true,
                        commonContext: "Users",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            organizationID: localStorage.getItem("currentOrganizationID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"getUsersData " + localStorage.getItem("currentCompanyID")}
                    actions={{ includeActions: true, extraActions: [{ title: "Details" }] }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, navigations) {
    const isUserCompaniesOnboarding = userIsOnBoardingInPage(navigations, "companies");
    const companiesTo = isUserCompaniesOnboarding ? "/companies/create" : "/companies";

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Company", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: companiesTo, active: false, disabled: false, title: "Companies" },
        { to: "/companies/kyc-policy", active: false, disabled: true, title: "KYC POLICY" },
        { to: "/companies/users", active: true, disabled: false, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "/companies/users/invite", active: false, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: <OrganizeFiltersHeader visible={true} gridEnabled={true} listEnabled={true} />
    });
}
