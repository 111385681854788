import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DotsGrid from "@untitled-ui/icons-react/build/esm/DotsGrid";
import List from "@untitled-ui/icons-react/build/esm/List";
import RefreshCcw01 from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import RefreshCcw05 from "@untitled-ui/icons-react/build/esm/RefreshCcw05";
import { ReactComponent as IconSearch } from "../../../../assets/icons/icon-search.svg";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/chevron-down.svg";
import { ReactComponent as IconTrash } from "../../../../assets/icons/trash-01.svg";
import { ReactComponent as IconHand } from "../../../../assets/icons/hand.svg";
import { ReactComponent as IconUpload } from "../../../../assets/icons/upload-01.svg";
import { ReactComponent as IconClose } from "../../../../assets/icons/x-close1.svg";
import { ReactComponent as IconTrashActive } from "../../../../assets/icons/active-trash.svg";
import { ReactComponent as IconHandActive } from "../../../../assets/icons/active-hand.svg";
import { useLocation } from "react-router";
//import FormField from "../../forms/FormField";
import LayoutContext from "../../../../LayoutContext";
import Checkbox from "../../checkbox/Checkbox";
import FormField from "../../forms/FormField";
import { Tooltip } from "antd";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    &.main-window-header,
    &.main-window-header * {
        box-sizing: border-box;
    }
    &.main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        padding: 15px 24px 15px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        //height: 68px;
        position: relative;
        flex-wrap: wrap;
        row-gap: 16px;
    }
    .frame-42 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-34 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 565px;
        position: relative;
        flex: 1;
    }
    .frame-52 {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
        flex: 1;
    }

    .frame-313369 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133692 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313371 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        width: 100%;
        height: 100%;
        //position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        //position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .select-all {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .frame-313372 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313368 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 10px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133712 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    ._0-selected {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        cursor: pointer;
    }
    .x-close {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-259 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .icon-list {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .icon-search {
        flex-shrink: 0;
        width: 40px;
        height: 37px;
        position: relative;
        cursor: pointer;
    }
    .frame-29 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        height: 37px;
        //width: 100%;
        //height: 100%;
        //position: absolute;
        /*right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%; */
    }
    .frame-29-export:hover {
        background-color: #008800;
    }
    .trash-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .hand {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .upload-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 380px;
        height: 37px;
        position: relative;
    }
    .frame-50 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .product-name-ean-code {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: "Roboto-Italic", sans-serif;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        position: relative;
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
    }
    .product-name-ean-code.has-value {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto", -apple-system, BlinkMacSystemFont, sans-serif;
        font-style: normal;
    }
    .search-sm {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }
    .frame-260 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .input-selector-inverted2 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 250px;
        height: 37px;
        position: relative;
    }
    .frame-502 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .replay {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .chevron-right {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-313361 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133682 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        //background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133713 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .organize {
        //color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .refresh-ccw-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .refresh-ccw-05 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .icon-toggler {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-292 {
        background: var(--buttons-all-platforms-btn-action-green, #008800);
        border-radius: 4px 0px 0px 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 37px;
        position: relative;
    }
    .dots-grid {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-293 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 0px 4px 4px 0px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 37px;
        position: relative;
        //transform-origin: 0 0;
        //transform: rotate(0deg) scale(1, 1);
    }
    .list {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }

    .view-type-select .active {
        border-color: var(--App-Accent-Active);
        background-color: var(--App-Accent-Active);
    }

    .btn-group .filter-btn:hover,
    .btn-group .filter-btn:focus,
    .btn-group .filter-btn:active {
        border-color: var(--App-Accent-Active) !important;
        background-color: var(--App-Accent-Active) !important;
        outline: none !important;
    }

    .btn-group .filter-btn.disabled:hover {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .filter-btn:focus {
        outline: none !important;
    }

    .filter-btn.disabled:focus {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .organize-select-field .Frame-50 {
        display: none !important;
    }

    //CUSTOM CSS
    @media screen and (max-width: 1554px) {
        .frame-34 {
            order: 3;
            justify-content: left;
        }

        .frame-52 {
            order: 2;
            justify-content: right;
        }

        .frame-42 {
            order: 1;
            justify-content: left;
        }

        .input-selector-inverted {
            width: 358px;
        }

        .frame-42 .frame-313372 {
            width: 295px !important;
        }
    }

    @media screen and (max-width: 1300px) {
        .frame-42 .frame-313372 {
            width: 263px !important;
        }
    }

    @media screen and (max-width: 1174px) {
        .frame-52 {
            order: 1;
            justify-content: right;
        }

        .frame-42 {
            order: 2;
        }

        .frame-34 {
            order: 3;
            justify-content: right;
        }
    }

    .button-disabled {
        cursor: not-allowed !important;
        color: var(--text-and-icons-gray-300) !important;
    }
    .button-disabled::placeholder {
        color: var(--text-and-icons-gray-300) !important;
    }

    .button-enabled {
        cursor: pointer;
        color: var(--text-and-icons-gray-100) !important;
    }

    /* .button-enabled:hover {
        background: var(--App-Accent-Active) !important;
    } */
`;

const FilterHeaderStyles = styled.div`
    .filter-section .form-control {
        //width: 348px;
        border-color: var(--backgrounds-lines-n-400);
        background: var(--backgrounds-lines-n-400);
        border-width: 1px;
        height: 37px;
        border-radius: 4px;
        max-width: none;
        font-style: italic;
        display: inline-flex;
        color: var(--text-icons-gray-300);

        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        transition: none;
        color: var(--text-icons-gray-200);
    }

    .filter-section .form-control::placeholder {
        color: var(--text-icons-gray-300);
    }

    .filter-section .form-control:hover,
    .filter-section btn:hover {
        border-color: var(--App-Accent-Outline);
    }

    .filter-section .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .filter-btn {
        width: 40px;
        height: 37px;
        font-size: 16px;
        background-color: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
        color: var(--text-icons-gray-100);
    }

    .filter-btn-with-label {
        display: inline-grid;
        width: auto;
        text-align: center;
        font-size: 10px;
        margin-left: 0px;
        position: relative;
    }

    .filter-btn-with-label > span {
        text-align: center;
        font-size: 10px;
        color: var (--text-icons-gray-200);
    }

    .filter-btn-with-label > .btn,
    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        opacity: 1;
        background-color: var(--backgrounds-lines-n-400) !important;
        color: var (--text-icons-gray-100);
        border-color: var(--backgrounds-lines-n-400);
    }

    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn:hover {
        border-color: var(--App-Accent-Outline);
    }

    .filter-section {
        display: flex;
        flex-basis: unset !important;
    }

    .filter-section.left {
        float: left;
    }

    .filter-section.right {
        float: right;
    }

    .organize-select {
        width: 100px;
        //min-width: 290px;
    }

    .organize-select .placeholder {
        font-style: normal;
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .organize-select.disabled .placeholder {
        color: var(--text-icons-gray-300);
    }

    .new-organize-select,
    .new-organize-select * {
        box-sizing: border-box;
    }
    .new-organize-select {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .new-organize-select.disabled .organize-text,
    .new-organize-select.disabled .chevron-down {
        color: var(--text-icons-gray-300) !important;
    }
    .new-organize-select.disabled path {
        stroke: var(--text-icons-gray-300) !important;
    }
    .frame-313371 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .organize-text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }

    .not-allowed {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-section > p {
        margin-right: 10px;
        padding: 0px 0px;
        color: var(--text-icons-gray-100);
        font-family: "Roboto";
        font-size: 12px;
        line-height: 37px;
        margin-bottom: 0;
    }

    .filter-section span {
        color: var(--text-icons-gray-200);
    }

    .showItems .sc-hHLeRK,
    .showItems .sc-hHLeRK.fhIruZ {
        display: block !important;
    }

    .filter-container {
        display: flex;
        gap: 6px;
    }

    .view-type-select .active {
        border-color: var(--App-Accent-Active);
        background-color: var(--App-Accent-Active);
    }

    .btn-group .filter-btn:hover,
    .btn-group .filter-btn:focus,
    .btn-group .filter-btn:active {
        border-color: var(--App-Accent-Active) !important;
        background-color: var(--App-Accent-Active) !important;
        outline: none !important;
    }

    .btn-group .filter-btn.disabled:hover {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .filter-btn:focus {
        outline: none !important;
    }

    .filter-btn.disabled:focus {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .organize-select-field .Frame-50 {
        display: none !important;
    }
`;
/* #endregion */

/** OrganizeFiltersHeader provides a header with search and layout options for filtering data.
 *
 * @param {object} props
 * @param {string} props.className The CSS class name for the component.
 * @param {Function} props.onSearch Function to handle search input changes.
 * @param {object} props.primaryOrganize The primary organize dropdown data.
 * @param {boolean} props.primaryOrganize.visible The visibility of the primary organize dropdown.
 * @param {Array} props.primaryOrganize.dropdownData The dropdown data for the primary organize dropdown.
 * @param {string} props.primaryOrganize.selectedValue The selected value for the primary organize dropdown.
 * @param {Function} props.primaryOrganize.onDropdownChange The function to handle the primary organize dropdown changes.
 * @param {boolean} [props.primaryOrganize.disabled=false] The disabled state of the primary organize dropdown.
 * @param {string} [props.primaryOrganize.type="singleSelect"] The type of dropdown to be used. Can be "singleSelect" or "multiSelect".
 * @param {object} props.extraOrganize The extra organize dropdown data.
 * @param {boolean} [props.extraOrganize.visible=false] The visibility of the extra organize dropdown.
 * @param {Array} props.extraOrganize.dropdownData The dropdown data for the extra organize dropdown.
 * @param {string} props.extraOrganize.selectedValue The selected value for the extra organize dropdown.
 * @param {Function} props.extraOrganize.onDropdownChange The function to handle the extra organize dropdown changes.
 * @param {boolean} [props.extraOrganize.disabled=false] The disabled state of the extra organize dropdown.
 * @param {string} [props.extraOrganize.type="singleSelect"] The type of dropdown to be used. Can be "singleSelect" or "multiSelect".
 * @param {Function} props.onResetAction Reset action click.
 * @param {Function} props.onRefreshAction Function to refresh data.
 * @param {boolean} [props.hideGridList=false] The visibility of the grid and list buttons.
 * @param {boolean} [props.gridEnabled=false] The visibility of the grid button.
 * @param {boolean} [props.listEnabled=false] The visibility of the list button.
 * @param {Function} props.onLayoutTypeChange Function tgo notify the layout type changes.
 * @param {Array} props.selectedItems The selected items.
 * @param {Function} props.handleSelectAll Function to handle select all items.
 * @param {Function} props.handleRemoveAll Function to handle remove all items.
 * @param {Function} props.handleSuspendAll Function to handle suspend all items.
 * @param {Function} props.setSearchValue Function to set the search value.
 * @param {string} [props.searchValue=""] The search value.
 * @param {string} [props.searchPlaceholder="Search"] The placeholder text for the search input.
 * @param {number} props.onClearSelectedProducts Function to clear selected products.
 * @param {number} props.clearSelectedDisabled The disabled state of the clear selected products button.
 * @param {number} props.selectedProductsQuantity The quantity of selected products.
 * @param {string} [props.defaultViewOption="Grid"] The default view option for the layout type.
 * @param {boolean} [props.visibleExport=true] The visibility of the export button.
 * @param {boolean} [props.visibleSuspension=true] The visibility of the suspension button.
 * @param {boolean} [props.visibleRestore=false] The visibility of the restore button.
 * @param {Function} props.handleExportIconClick Function to handle the export icon click.
 
 * @returns {JSX.Element} The rendered OrganizeFiltersHeader component.
 */
export default function OrganizeFiltersHeader(props) {
    const {
        className = "",
        onSearch, //TODO: Not implemented yet
        primaryOrganize = {
            visible: true,
            dropdownData: undefined,
            selectedValue: undefined,
            onDropdownChange: undefined,
            disabled: true,
            type: "singleSelect" //singleSelect or multiSelect
        },
        extraOrganize = {
            visible: false,
            dropdownData: undefined,
            selectedValue: undefined,
            onDropdownChange: undefined,
            disabled: true,
            type: "singleSelect" //singleSelect or multiSelect
        },
        /*secondaryOrganize = {
            visible: true,
            dropdownData: [],
            selectedValue: undefined, //TODO: Not working properly, use selected: true in dropdownData for now
            onDropdownChange: undefined,
            disabled: true
        },*/
        onResetAction,
        onRefreshAction,
        hideGridList = false,
        gridEnabled = false,
        listEnabled = false,
        onLayoutTypeChange,
        searchPlaceholder = "Search",
        visibleExport = true,
        visibleSuspension = true,
        visibleRestore = false,
        defaultViewOption = "Grid"
    } = props;

    /* #region VARS */
    const secondaryOrganize = { visible: true }; //TODO: hardcoded for now
    const location = useLocation();
    const { layoutType, setLayoutType } = useContext(LayoutContext);

    const [selectAll, setSelectAll] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        //Set layout type to default on component mount (Grid by default)
        if (listEnabled && !gridEnabled) setLayoutType("List");
        else setLayoutType("Grid");
    }, [location]);
    useEffect(() => {
        setLayoutType(defaultViewOption);
    }, [defaultViewOption]);

    useEffect(() => {
        if (props.selectedItems?.length === 0) {
            setSelectAll(false);
        }
    }, [props.selectedItems]);
    /* #endregion */

    /* #region METHODS */
    const handleLayoutChange = layout => {
        if (layout === "List" && !listEnabled) return;
        if (layout === "Grid" && !gridEnabled) return;

        setLayoutType(layout);
        if (onLayoutTypeChange) onLayoutTypeChange(layout);
    };

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        if (props.handleSelectAll) {
            props.handleSelectAll(!selectAll);
        }
    };
    const handleDeselectAllItems = () => {
        setSelectAll(false);
        if (props.handleSelectAll) {
            props.handleSelectAll(false);
        }
    };

    const handleSearchChange = e => {
        const value = e.target.value;
        props?.setSearchValue(value);
        if (onSearch) onSearch(value);
    };

    const clearSearch = () => {
        props?.setSearchValue("");
        if (onSearch) onSearch("");
    };
    /* #endregion */

    return (
        <StyledPage className={`main-window-header ${className}`}>
            <div className="frame-42">
                <div className="frame-313369">
                    <div className="frame-3133692">
                        <div className="frame-313371">
                            <Checkbox
                                className="checkbox2"
                                isChecked={selectAll}
                                onCheckboxClick={handleSelectAllChange}
                            />
                            <div className="select-all">Select all</div>
                        </div>
                    </div>
                </div>
                <div className="frame-313372" style={{ width: "263px", justifyContent: "left" }}>
                    <div className="frame-313368" onClick={handleDeselectAllItems}>
                        <div className="frame-3133692">
                            <div
                                className={`frame-3133712 ${
                                    props.clearSelectedDisabled ? "button-disabled" : "button-enabled"
                                }`}>
                                <div>{props.selectedProductsQuantity || 0} selected</div>
                                <IconClose onClick={props.onClearSelected} />
                            </div>
                        </div>
                    </div>
                    <div className="frame-259">
                        <div className="icon-list">
                            <div
                                className={`icon-search ${props.selectedItems?.length ? "" : "button-disabled"}`}
                                onClick={e =>
                                    props.selectedItems?.length ? props?.handleRemoveAll() : e.preventDefault()
                                }>
                                <div className="frame-29">
                                    {props.selectedItems?.length ? (
                                        <IconTrashActive className="trash-01" />
                                    ) : (
                                        <IconTrash className="trash-01" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="icon-list" style={{ display: visibleSuspension ? "" : "none" }}>
                            <div
                                className={`icon-search ${props.selectedItems?.length ? "" : "button-disabled"}`}
                                onClick={e =>
                                    props.selectedItems?.length ? props?.handleSuspendAll() : e.preventDefault()
                                }>
                                <div className="frame-29 ">
                                    {props.selectedItems?.length ? (
                                        <IconHandActive className="hand" />
                                    ) : (
                                        <IconHand className="hand" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="icon-list" style={{ display: visibleRestore ? "" : "none" }}>
                            <Tooltip title={`${props.selectedItems?.length ? "Restore" : ""}`} placement="top">
                                <div
                                    className={`icon-search ${props.selectedItems?.length ? "" : "button-disabled"}`}
                                    onClick={e =>
                                        props.selectedItems?.length ? props?.handleSuspendAll() : e.preventDefault()
                                    }>
                                    <div className="frame-29 ">
                                        {props.selectedItems?.length ? (
                                            <svg
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.33398 10.6663V1.33301M8.93431 2.05275L5.04181 5.16675C4.68591 5.45147 4.50796 5.59382 4.44354 5.76581C4.38705 5.9166 4.38705 6.08275 4.44354 6.23354C4.50796 6.40552 4.68591 6.54788 5.04181 6.8326L8.93431 9.9466C9.48909 10.3904 9.76648 10.6123 9.99993 10.6126C10.203 10.6128 10.395 10.5205 10.5217 10.3618C10.6673 10.1794 10.6673 9.82414 10.6673 9.11368V2.88567C10.6673 2.17521 10.6673 1.81997 10.5217 1.63752C10.395 1.47885 10.203 1.38654 9.99993 1.38676C9.76648 1.38701 9.48909 1.60892 8.93431 2.05275Z"
                                                    stroke="#00BB00"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.33398 10.6663V1.33301M8.93431 2.05275L5.04181 5.16675C4.68591 5.45147 4.50796 5.59382 4.44354 5.76581C4.38705 5.9166 4.38705 6.08275 4.44354 6.23354C4.50796 6.40552 4.68591 6.54788 5.04181 6.8326L8.93431 9.9466C9.48909 10.3904 9.76648 10.6123 9.99993 10.6126C10.203 10.6128 10.395 10.5205 10.5217 10.3618C10.6673 10.1794 10.6673 9.82414 10.6673 9.11368V2.88567C10.6673 2.17521 10.6673 1.81997 10.5217 1.63752C10.395 1.47885 10.203 1.38654 9.99993 1.38676C9.76648 1.38701 9.48909 1.60892 8.93431 2.05275Z"
                                                    stroke="#4f5b6d"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="icon-list" style={{ display: visibleExport ? "" : "none" }}>
                            <div className="icon-search">
                                <div className="frame-29 frame-29-export">
                                    <IconUpload className="upload-01" onClick={props?.handleExportIconClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-34">
                <div className={`input-selector-inverted ${onSearch ? "" : "button-disabled"}`}>
                    <div className="frame-50"></div>
                    <div className="search">
                        <div className="frame-48">
                            <input
                                type="text"
                                disabled={!onSearch}
                                className={`product-name-ean-code ${
                                    onSearch ? "" : "button-disabled cursor-disabled"
                                } ${props?.searchValue ? "has-value" : ""}`}
                                placeholder={searchPlaceholder}
                                value={props?.searchValue}
                                onChange={handleSearchChange}
                            />
                            {props?.searchValue ? (
                                <IconClose className="search-sm" onClick={clearSearch} />
                            ) : (
                                <IconSearch className={`search-sm ${onSearch ? "" : "button-disabled"}`} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FilterHeaderStyles className="frame-52">
                <div className="frame-260">
                    <OrganizeDropdownSelector organize={primaryOrganize} type={primaryOrganize?.type} />

                    <OrganizeDropdownSelector organize={extraOrganize} type={extraOrganize?.type} />

                    <div className="frame-313361">
                        <div className="frame-313372">
                            <div className={`frame-3133682 ${secondaryOrganize?.visible ? "" : "d-none"}`}>
                                <div className="frame-3133692">
                                    <div className="frame-3133713 button-disabled">
                                        <div className="organize">Organize</div>
                                        <ChevronDown className="chevron-down" src="chevron-down0.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-259">
                                <span
                                    className={`filter-btn-with-label ${
                                        onResetAction === undefined ? "disabled" : ""
                                    }`}>
                                    <button
                                        type="button"
                                        className="btn btn-success filter-btn"
                                        onClick={onResetAction}>
                                        <RefreshCcw01 width={"16px"} />
                                    </button>
                                </span>
                                <span
                                    className={`filter-btn-with-slabel ${
                                        onRefreshAction === undefined ? "disabled" : ""
                                    }`}>
                                    <button
                                        type="button"
                                        className="btn btn-success filter-btn"
                                        onClick={onRefreshAction}>
                                        <RefreshCcw05 width={"16px"} />
                                    </button>
                                </span>
                            </div>
                        </div>

                        <span className="view-type-select" style={{ display: hideGridList ? "none" : "block" }}>
                            <div className="btn-group flex">
                                <button
                                    type="button"
                                    className={`btn filter-btn grid-btn ${
                                        layoutType === "Grid" && gridEnabled ? "active" : ""
                                    } ${!gridEnabled ? "disabled" : ""}`}
                                    onClick={() => handleLayoutChange("Grid")}>
                                    <DotsGrid width={"16px"} />
                                </button>
                                <button
                                    type="button"
                                    className={`btn filter-btn list-btn ${
                                        layoutType === "List" && listEnabled ? "active" : ""
                                    } ${!listEnabled ? "disabled" : ""}`}
                                    onClick={() => handleLayoutChange("List")}>
                                    <List width={"16px"} />
                                </button>
                            </div>
                        </span>
                    </div>
                </div>
            </FilterHeaderStyles>
        </StyledPage>
    );
}

const OrganizeDropdownSelector = props => {
    const type = props?.type || "singleSelect";

    return type === "singleSelect" ? (
        <OrganizeSingleDropdown {...props} />
    ) : type === "multiSelect" ? (
        <OrganizeMultiDropdown {...props} />
    ) : (
        <div>ERROR</div>
    );
};

const OrganizeSingleDropdown = props => {
    const [defaultDropdownData] = useState([{ value: "", name: "--", id: "", selected: true }]);
    const { visible, dropdownData, selectedValue, onDropdownChange, disabled } = props?.organize || {};

    return (
        <FilterHeaderStyles className={`input-selector-inverted2 button-disabled ${visible ? "" : "d-none"}`}>
            <FormField
                className="w-100"
                type="dropdown"
                dropdownData={dropdownData || defaultDropdownData}
                value={selectedValue}
                onDropdownChange={onDropdownChange}
                varName="dropdownSingleItem"
                isDropdownMultiselect={false}
                isDropdownSearchable={false}
                styling={{
                    backgroundColor: "var(--backgrounds-lines-n-400)",
                    requiredTypeColor: "var(--backgrounds-lines-n-500, #141922)",
                    dropdownOverlayTop: "45px"
                }}
                requiredType={!disabled ? "simple" : "locked"}
                disableTooltip={true}
            />
        </FilterHeaderStyles>
    );
};

const OrganizeMultiDropdown = props => {
    const [defaultDropdownData] = useState([{ value: "", name: "--", id: "", selected: true }]);
    const { visible, dropdownData, selectedValue, onDropdownChange, disabled, dropdownOnSingleItemClick } =
        props?.organize || {};

    return (
        <FilterHeaderStyles className={`input-selector-inverted2 button-disabled ${visible ? "" : "d-none"}`}>
            <FormField
                className="w-100"
                type="dropdown"
                dropdownData={dropdownData || defaultDropdownData}
                value={selectedValue}
                onDropdownChange={onDropdownChange}
                varName="dropdownSingleItem"
                isDropdownMultiselect={true}
                isDropdownSearchable={false}
                styling={{
                    backgroundColor: "var(--backgrounds-lines-n-400)",
                    requiredTypeColor: "var(--backgrounds-lines-n-500, #141922)",
                    dropdownOverlayTop: "45px"
                }}
                requiredType={!disabled ? "simple" : "locked"}
                disableTooltip={true}
                dropdownOnSingleItemClick={dropdownOnSingleItemClick}
                firstElementIsSelectAll={true}
            />
        </FilterHeaderStyles>
    );
};
