import Login from "../components/pages/login/LoginPage";
import PreEnrollmentInvited from "../components/pages/landingRoutes/PreEnrollmentInvitedPage";
import PreEnrollment from "../components/pages/landingRoutes/PreEnrollmentPage";
import ResetPasswordPage from "../components/pages/resetPassword/ResetPasswordPage";
import EmptyPage from "../components/pages/EmptyPage";
import Page404 from "../components/pages/Page404";
import AcceptPage from "../components/pages/landingRoutes/AcceptPage";
import PreEnrollmentFS from "../components/pages/landingRoutes/PreEnrollmentFSPage";

const routes = [
    {
        path: "/*",
        element: <Page404 />
    },
    {
        path: "/",
        element: <EmptyPage />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/login/:inviteID",
        element: <Login />
    },
    {
        path: "/pre-enrollment-user",
        element: <AcceptPage />
    },
    {
        path: "/pre-enrollment-user/:inviteID",
        element: <AcceptPage />
    },
    {
        path: "/resetpassword",
        element: <ResetPasswordPage />
    },
    {
        path: "/pre-enrollment-invited",
        element: <PreEnrollmentInvited showThankRedirectedYouOnLoad={false} />
    },
    {
        path: "/pre-enrollment-invited/:inviteID",
        element: <PreEnrollmentInvited showThankRedirectedYouOnLoad={false} />
    },
    {
        path: "/pre-enrollment",
        element: <PreEnrollment showThankRedirectedYouOnLoad={false} />
    },
    {
        path: "/pre-enrollment-fs",
        element: <PreEnrollmentFS showThankRedirectedYouOnLoad={false} />
    },
    {
        path: "/pre-enrollment/thank-you",
        element: <PreEnrollment showThankRedirectedYouOnLoad={true} />
    }
];

export default routes;
