import React, { useState } from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying brand information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the brand.
 * @param {string} props.id - The ID of the brand.
 * @param {boolean} props.hasBorders - Indicates if the card has borders.
 * @param {string} [props.titlePrefix="BRAND"] - The prefix for the title.
 * @param {string} [props.idPrefix="BOTAG ID"] - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the brand.
 * @param {Object[]} props.details - The details to display in the card.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.edit - The edit button configuration.
 * @param {Object} props.suspend - The suspend button configuration.
 * @param {Object} props.delete - The delete button configuration.
 * @returns {JSX.Element} The rendered FFCInvitationsGridCard component.
 */
export default function LabelBrandInvitationsGridCard(props) {
    const status = props?.data?.status ?? "--";
    const [customStatus, setCustomStatus] = useState(status);
    if (customStatus === "Invited") setCustomStatus("Requested");
    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={{
                    title: props?.primaryButton?.title || "Remove",
                    onClick: props?.primaryButton?.onClick
                }}
                secondaryButton={{
                    title: props?.secondaryButton?.title || "Resend",
                    onClick: props?.secondaryButton?.onClick
                }}
                title={props?.title}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "BRAND"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageHasPadding={true}
                //isImageWithOverlay={true}
                //imageOverlay={props?.imageOverlay}
                //overLayBackgroundColor={props?.imageOverlay && "rgba(20, 25, 34, 0.8)"}
                //overLayBorderTop={props?.imageOverlay && "1px solid rgba(30, 38, 49, 1)"}
                details={[
                    { key: "Last request:", value: props?.data?.invitedOn || "--" },
                    { key: "Accepted on:", value: props?.data?.acceptedOn || "--" },
                    { key: "Contact person:", value: props?.data?.contactPerson || "--" },
                    { key: "Contact email:", value: props?.data?.contactEmail || "--" },

                    { key: "Requested stores:", value: props?.data?.requestedStores || "--" },
                    { key: "Accepted stores:", value: props?.data?.acceptedStores ?? "--" },
                    { key: "To brand:", value: props?.data?.retailBrand || "--" },
                    { key: "Request status:", value: getSpanWithColor(customStatus ?? "--", defaultStatusColors) }
                ]}
                edit={{ hidden: true, ...props?.edit }}
                suspend={props?.suspend}
                delete={props?.delete}
            />
        </StyledPage>
    );
}
