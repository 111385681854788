import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FormField from "./FormField";
import DropdownOverlay, { OverlayType } from "../dropdownOverlay/DropdownOverlay";
import * as Library from "../../../utils/Library";
import useDebounce from "../../../utils/hooks/useDebounce";
import { v4 as uuidv4 } from "uuid";

/** A Custom Form Field Component with tags and dropdown multi-item (should be used only with type="tags")
 *
 * @param {string} value - The value of the input field
 * @param {function} onTextBlur - The function to call when the input field loses focus
 * @param {function} onSelectedItemChange - The function to call when an item is selected from the autocomplete dropdown
 * @param {integer} formFieldColumSize - The size of the grid column to span
 * @param {boolean} [itemNotSelectedWillClear=true] - Whether to clear the input field if an item is not selected from the autocomplete dropdown. If false, the input field will retain the text entered by the user with value as null.
 * @param {boolean} [noSearchResultsHideMessage=false] - Whether to hide the message when there are no search results
 * @param {array} autoCompleteStaticData - The data to be used for autocomplete. This should be a simple array of strings. Example: ["Portugal", "Spain", "France", "Germany", "Italy"]
 * @param {object} autoCompleteAPIData - The data to be used for autocomplete. This should be an object with the following properties:
 *     - apiName: The name of the API to call
 *     - apiSearchFieldName: The name of the field to use for searching in the Forrm Data
 *     - includeInAPIFormData: Other data to also include in the API call
 *     - returnArrayName: The name of the field to retrieve the array data result
 *     - returnFieldIDName: The name of the field to retrieve the data ID
 *     - returnFieldValueName: The name of the field to retrieve the data value
 *     - useBOCommon: Whether to use the BOCommon API instead
 *     - BOCommonContext: The context to use for the BOCommon API (useBOCommon must be true)
 *     - customMapping: A custom function to map the API data to the autocomplete dropdown data
 * All other FormField props are supported (e.g. title, requiredType, etc.)
 */
const FormFieldTagsDropdown = forwardRef((props, ref) => {
    const {
        addSelectAllOption,
        scrollableSize,
        value,
        onTextBlur,
        onSelectedItemChange,
        onFormCardChanges,
        formFieldColumSize,
        //new props for autocomplete
        //itemNotSelectedWillClear = true,
        noSearchResultsHideMessage = false,
        autoCompleteStaticData = [],
        autoCompleteAPIData = {
            apiName: "",
            apiSearchFieldName: "",
            includeInAPIFormData: {},
            returnArrayName: "",
            returnFieldIDName: "id",
            returnFieldValueName: "name",
            useBOCommon: false,
            BOCommonContext: "",
            customMapping: null
        },
        allowNewTags = false
    } = props;

    /* #region VARS */
    const formFieldRef = useRef(null);
    const [fieldHasFocus, setFieldHasFocus] = useState(false);
    const [showAutoComplete, setShowAutoComplete] = useState(false);

    const [autoCompleteDropdownData, setAutoCompleteDropdownData] = useState(autoCompleteStaticData);
    const [currentText, setCurrentText] = useState(value);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [currentTagNames, setCurrentTagNames] = useState([]);
    const [inputID] = useState(uuidv4());
    /* #endregion */

    /* #region COMPUTED */
    const isAutoCompleteAvailable = autoCompleteStaticData && autoCompleteStaticData?.length > 0;
    const debouncedText = useDebounce(currentText, 500);
    /* #endregion */

    /* #region METHODS */
    const handleTextChange = text => {
        setCurrentText(text);

        if (isAutoCompleteAvailable) {
            if (text.length === 0) {
                setShowAutoComplete(false);
                return;
            }
            setShowAutoComplete(true);
        }
    };

    const handleTextBlur = text => {
        if (onTextBlur) {
            onTextBlur(text);
        }
    };

    const handleSingleItemClick = item => {
        const name = item?.name;
        const value = item?.value;

        if (item?.value === "selectAll") {
            if (onFormCardChanges) onFormCardChanges(true);
            formFieldRef.current.onSelectAllClicked(autoCompleteDropdownData);
            setShowAutoComplete(false);
            return;
        }

        const isItemChecked = currentTagNames.includes(name);

        if (!isItemChecked) formFieldRef.current.outsideTagAdd(name, value);
        else formFieldRef.current.outsideTagRemove(name);

        //setShowAutoComplete(false);
        //setHideDropdownTimeout(true);
        //setFieldHasFocus(false);
        setSelectedItem(item);

        if (onFormCardChanges) onFormCardChanges(true);
    };

    //TODO: NEEDS TESTING ON BOCOMMON
    const handleApiRequest = async () => {
        if (!autoCompleteAPIData || !autoCompleteAPIData.apiName || !debouncedText) return;

        // Call API
        const isBOCommon = autoCompleteAPIData.useBOCommon;
        const apiName = autoCompleteAPIData.apiName;
        const apiSearchFieldName = autoCompleteAPIData.apiSearchFieldName;
        const includeInAPIFormData = autoCompleteAPIData.includeInAPIFormData;
        const BOCommonContext = autoCompleteAPIData.BOCommonContext;

        const formData = {
            ...includeInAPIFormData,
            [apiSearchFieldName]: debouncedText
        };

        // Call API
        let res = null;
        if (isBOCommon) {
            res = await Library.makeCommonPostRequest(BOCommonContext, apiName, formData, false, null);
        } else {
            res = await Library.makePostRequest(apiName, formData, false, null);
        }

        if (res && res.status === 200) {
            let apiData = res?.data?.[autoCompleteAPIData?.returnArrayName];
            if (!apiData) {
                // Try to evaluate the returnArrayName when it also uses dots or optional chaining (e.g. res?.data?.array?.data)
                const evalStr = `res?.data?.${autoCompleteAPIData?.returnArrayName}`;

                // eslint-disable-next-line no-eval
                const tmp = eval(evalStr);
                if (tmp && Array.isArray(tmp)) {
                    apiData = tmp;
                }
            }
            if (!apiData) {
                setAutoCompleteDropdownData([]);
                setShowAutoComplete(false);
                return;
            }

            if (!autoCompleteAPIData?.customMapping) {
                const mappedResults = apiData?.map(data => ({
                    name: data[autoCompleteAPIData?.returnFieldValueName],
                    value: data[autoCompleteAPIData?.returnFieldIDName]
                }));
                setAutoCompleteDropdownData(mappedResults);
                setShowAutoComplete(true);
            } else {
                const mappedResults = autoCompleteAPIData?.customMapping(apiData);
                setAutoCompleteDropdownData(mappedResults);
                setShowAutoComplete(true);
            }
        }
    };

    const isValid = (highlight = false) => {
        const isvalid = formFieldRef.current.isValid(highlight);
        return isvalid;
    };

    const getValue = () => {
        return formFieldRef.current.getValue();
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {}, [showAutoComplete]);

    useEffect(() => {
        if (!autoCompleteAPIData || !autoCompleteAPIData.apiName || !fieldHasFocus || !debouncedText) return;
        handleApiRequest();
    }, [debouncedText]);

    useEffect(() => {
        if (!onSelectedItemChange || !selectedItem) return;
        onSelectedItemChange(selectedItem);
    }, [selectedItem]);

    useEffect(() => {
        if (!showAutoComplete) return;
        const closeDropdown = e => {
            if (
                showAutoComplete &&
                !e.target.closest(".form-field-text-tags-dropdown-" + inputID) &&
                !e.target.closest(".input-" + inputID)
            )
                setShowAutoComplete(false);
        };

        document.addEventListener("click", closeDropdown);
        return () => {
            document.removeEventListener("click", closeDropdown);
        };
    }, [showAutoComplete]);

    useEffect(() => {
        if (!autoCompleteStaticData) return;
        setAutoCompleteDropdownData(autoCompleteStaticData);
    }, [autoCompleteStaticData]);
    /* #endregion */

    useImperativeHandle(ref, () => ({
        isValid,
        getValue
    }));

    return (
        <div style={{ gridColumn: `span ${formFieldColumSize || 1}` }}>
            <FormField
                {...props}
                onTextChange={handleTextChange}
                onTextBlur={handleTextBlur}
                inputID={inputID}
                onFocus={_ => {
                    const toogleVisible = !showAutoComplete === true;

                    if (toogleVisible) setShowAutoComplete(true);
                    else setShowAutoComplete(false);

                    setFieldHasFocus(toogleVisible);
                    setCurrentText("");
                }}
                ref={formFieldRef}
                onCurrentValueChange={value => {
                    setCurrentTagNames(value.map(tag => tag.tagName));
                }}
                type="tags"
                tagAddWhenEnterPressed={allowNewTags}
                tagAsDropdown={true}
                onTagFocus={() => {
                    const toogleVisible = !showAutoComplete === true;

                    if (toogleVisible) {
                        setShowAutoComplete(true);
                    }
                    setFieldHasFocus(toogleVisible);
                    //setShowAutoComplete(toogleVisible);
                    setCurrentText("");
                }}
                tagDropdownVisible={showAutoComplete}
            />

            <div style={{ zIndex: 99999, position: "relative" }}>
                <DropdownOverlay
                    className={`form-field-text-tags-dropdown-${inputID}`}
                    isSearchable={false}
                    isVisible={showAutoComplete}
                    data={autoCompleteDropdownData}
                    overlayType={OverlayType.MULTI_SELECT}
                    onSingleItemClick={handleSingleItemClick}
                    isChangeColor={true}
                    overrideSearchValue={currentText}
                    noSearchResultsHideMessage={noSearchResultsHideMessage}
                    checkedItemNames={currentTagNames ?? []}
                    scrollableSize={scrollableSize}
                    addSelectAllOption={addSelectAllOption}
                />
            </div>
        </div>
    );
});

export default FormFieldTagsDropdown;
